import React, { Component } from 'react'

import { AddNetworkDialogComponent } from './AddNetworkDialogComponent'

type Props = {|
  +setSubmitDialogOpenState: Function,
  +ive: Ive,
  +children: React$Element<any>
|}

class AddNetworkDialogContainer extends Component<Props, State> {
    props: Props
    state: State
  
    state = {
      network: '',
      hosts: '',
      networkName: '',
      isOpen: false
    }

  handleChange = (change) => {
      this.setState({ ...this.state, ...change })
    }

  handleNetworkChange = (change) => {
    if (this.state.hosts === '6') {
      let newValue = `${change.network}/29`
      this.setState({ ...this.state,  network: newValue })
    } else if (this.state.hosts === '14') {
      let newValue = `${change.network}/28`
      this.setState({ ...this.state,  network: newValue })
    } else if (this.state.hosts === '30') {
      let newValue = `${change.network}/27`
      this.setState({ ...this.state,  network: newValue })
    } else if (this.state.hosts === '62') {
      let newValue = `${change.network}/26`
      this.setState({ ...this.state,  network: newValue })
    } else if (this.state.hosts === '126') {
      let newValue = `${change.network}/25`
      this.setState({ ...this.state,  network: newValue })
    } else {
      let newValue = `${change.network}/29`
      this.setState({ ...this.state,  network: newValue })
    }
  }

  toggleOpenState = () => {
      this.setState({ isOpen: !this.state.isOpen })
    }

      render() {
          return(
              <AddNetworkDialogComponent
                children={this.props.children}
                handleChange={this.handleChange}
                handleNetworkChange={this.handleNetworkChange}
                setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
                toggleOpenState={this.toggleOpenState}
                isOpen={this.state.isOpen}
                ive={this.props.ive}
                network={this.state.network}
                hosts={this.state.hosts}
                networkName={this.state.networkName}
              />
          )
      }
  }  

export const AddNetworkForm = AddNetworkDialogContainer
  
  
  