import React, { Component } from 'react'

import { IpsecFormComponent } from './IpsecFormComponent'

type Props = {|
  +submit: Function,
  +ive: Ive,
  +children: React$Element<any>,
  +dialogAction: 'Add' | 'Edit',
  +ipsecSite?: IpsecSite,
  +ipsecSites: IpsecSites[]
|}
type State = {|
  ipsecSite: IpsecSite,
  isOpen: boolean
|}

const DEFAULT_SITE = {
  name: '',
  localSubnets: [],
  peerIp: '',
  peerSubnets: [],
  psk: '',
  repeatedPsk: ''
}

class IpsecFormContainer extends Component<Props, State> {
  props: Props
  state: State

  state = {
    ipsecSite: DEFAULT_SITE,
    isOpen: false,
    capsLockWarning: false
  }

  submit = () => {
    this.props.submit(this.state.ipsecSite)
    this.setState({ ipsecSite: DEFAULT_SITE })
    this.toggleOpenState()
  }

  handleNameChange = (value) => {
    const siteState = { ...this.state.ipsecSite, name: value }
    this.setState({ ...this.state, ipsecSite: siteState })
  }

  handleOnChangePeerSubnets = (value) => {
    const siteState = { ...this.state.ipsecSite, peerSubnets: value }
    this.setState({ ...this.state, ipsecSite: { ...siteState } })
  }

  handleOnChangeLocalSubnets = (value) => {
    const siteState = { ...this.state.ipsecSite, localSubnets: value }
    this.setState({ ...this.state, ipsecSite: { ...siteState } })
  }

  handleOnChange = (inputName: string, value: IdentityObject[]) => {
    // // react-select v3.1.0 requires the following fix here to change "value" to an array in case it is "null"
    if (value === null) {
      value = []
    }
    const ipsecSiteState = { ...this.state.ipsecSite, [inputName]: value }
    this.setState({ ...this.state, ipsecSite: { ...ipsecSiteState } })
  }

  handleOnChangePeerIp = (value: string) => {
    this.handleOnChange('peerIp', value)
  }

  handleOnChangePsk = (value: string) => {
    this.handleOnChange('psk', value)
  }

  handleOnChangeRepeatedPsk = (value: string) => {
    this.handleOnChange('repeatedPsk', value)
  }

  toggleOpenState = () => {
    this.setState({ isOpen: !this.state.isOpen })
    if (this.props.ipsecSite) {
      this.setState({ ipsecSite: this.props.ipsecSite })
    }
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }


  render() {
    return (
      <IpsecFormComponent
        children={this.props.children}
        handleNameChange={this.handleNameChange}
        handleOnChangeLocalSubnets={this.handleOnChangeLocalSubnets}
        handleOnChangePeerIp={this.handleOnChangePeerIp}
        handleOnChangePeerSubnets={this.handleOnChangePeerSubnets}
        handleOnSelectPeerSubnets={this.handleOnSelectPeerSubnets}
        handleOnChangePsk={this.handleOnChangePsk}
        handleOnChangeRepeatedPsk={this.handleOnChangeRepeatedPsk}
        dialogAction={this.props.dialogAction}
        submit={this.submit}
        handleChange={this.handleChange}
        capsLockWarning={this.state.capsLockWarning}
        onKeyDown={this.onKeyDown}
        toggleOpenState={this.toggleOpenState}
        isOpen={this.state.isOpen}
        ipsecSite={this.state.ipsecSite}
        ive={this.props.ive}
      />
    )
  }
}

export const IpsecForm = IpsecFormContainer
