import React, { useRef } from 'react';
import {
  Modal, Button, Panel, FormGroup, ControlLabel, FormControl, Schema, Icon, Form, Divider
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import QRCode from "react-qr-code";

const center = {
  width: 'inherit', alignItems: 'center', display: 'flex', justifyContent: 'center'
}
const { StringType } = Schema.Types
const model = Schema.Model({
  otpToken: StringType()
    .isRequired('This field is required.')
    .pattern(/^[0-9]*$/, 'Please use only numbers.')
    .maxLength(6, 'The maximum number of characters is 6.')
    .minLength(6, 'The minimum nubmer of characters is 6.')
})

function InputField(props) {
  const { name, label, type, accepter, data, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        type={type}
        data={data}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
    +toggleOpenState: Function,
    +handleChange: Function,
    +isOpen: boolean,
    +username: string,
    +otp: { 
      confirmed: boolean, 
      configUrl: string 
    },
    +otpToken: string,
    +getAccountOtpQrRequest: Function,
    +setSubmitDialogOpenState: Function	
  |}

export const AccountOtpComponent = (props: Props) => {
  var otpForm = useRef(null)

  return (
    <div style={{ display: 'inline' }}>
          {React.cloneElement(props.children, {
            onClick: props.toggleOpenState
          })}
          <Modal backdrop="static" show={props.isOpen} size="lg">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '25px' }}>
                Setup Two Factor Authentication for {props.username}
              </Modal.Title>
            </Modal.Header>
              {props.otp.confirmed ? 
                (<Panel> 
                  <strong style={{ ...center, marginTop: '10px', color: 'red' }}>Your Two Factor Authentication Device is already confirmed.</strong>
                  <div style={{ ...center, marginTop: '10px'}}>In case of a lost device, or deactivating this device, please contact support.</div>
                </Panel>
              ) : (
              <Panel>
                <div style={center}>
                  Setup of the Two Factor Authentication device for user <strong>&nbsp;{props.username}</strong> <br/>
                </div>
                <div style={center}>
                  Download a Two Factor Authentication app like "Authy" or "Google Authenticator" for your chosen 2FA device.
                </div>
                <div style={center}>
                  Then Scan the QR code generated here:
                </div>
                <div style={{ ...center, marginTop: "15px", marginBottom: "15px"}}><QRCode value={`${props.otp.configUrl}`}/></div>
                <Divider/>
                  <Form
                    model={model}
                    ref={(ref) => (otpForm = ref)}
                    fluid
                    checkTrigger="none"
                  >
                    <div style={{ ...center, marginTop: '15px' }}>
                      <InputField
                        autoFocus
                        name="otpToken"
                        disabled={props.otp.confirmed}
                        label="Activate Two Factor Authentication with your chosen device:"
                        type="otpToken"
                        data={props.otpToken}
                        onChange={(otpToken, event) =>
                          props.handleChange({ otpToken, event })
                        }
                        
                      />
                      <Button 
                        disabled={props.otp.confirmed} 
                        appearance='primary'
                        onClick={(e) => {
                          if (otpForm.check()) {
                            e.preventDefault()
                            props.setSubmitDialogOpenState({
                              dialogId: 'ACTIVATE_OTP',
                              payload: { otpToken: props.otpToken }
                          })}
                        }}
                      >
                        <Icon icon="check-circle"/> Activate
                      </Button>
                    </div>
                  </Form> 

                <div style={{...center, marginTop: '20px' }}>
                  In case your QR doesn't work, you can use this URL in a QRcode generator like "qr-code-generator.com": <br/>
                </div>
                <div style={{...center, marginTop: '15px' }}>
                  {props.otp.configUrl}
                </div>
              </Panel>
              )}           
            <Modal.Footer>
              <Button onClick={props.toggleOpenState} color="red">
                Cancel
              </Button>
            </Modal.Footer>
      </Modal>
    </div>
  )
}

