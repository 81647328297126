// @flow
import React from 'react'
import { Container, Content, Header } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { IveCard } from 'components/IveCard'

type Props = {
  +ive: Ive,
  +timezone: string,
  +vmId: string
}

const IveDetailsComponent = (props: Props) => {
  const { ive, timezone } = props
  return (
    <Container
      style={{ 
        backgroundColor: '#FFFFFF', 
        boxShadow: '3px 0 2px 0 #e0e0e0', 
        maxHeight: '90vh' }}
    >
      <Header
        style={{
          height: '60px',
          background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
        }}
      >
        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
          Isolated Virtual Environment Dashboard
        </h3>
      </Header>
      <Content style={{  overflow: 'auto' }}>
        <IveCard firstIve lastIve ive={ive} timezone={timezone} />
      </Content>
    </Container>
  )
}

export { IveDetailsComponent }
