import React, { Fragment } from 'react'
import { ConnectedRouter as Router } from 'connected-react-router/immutable'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Login } from './login/Login'
import { Frame } from './FrameComponent'
import { PasswordReset	 } from '../components/PasswordReset/PasswordReset'
import { InvitedUser } from '../components/InvitedUser/InvitedUser'

type Props = {|
  token: string,
  getApproversRequest: Function,
  getAccountRequest: Function,
  history: History
|}

export const RoutesComponent = (props: Props) => (
  <Fragment>
    <Router history={props.history}>
      <Switch>
        <Route
          path="/"
          render={() =>
            props.token ? (
              <Frame
                getAccountRequest={props.getAccountRequest}
                getApproversRequest={props.getApproversRequest}
              />
            ) : (
              // public Switch when not authenticated
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                <Route exact path="/dashboard/recent" render={() => <Redirect to="/login" />} />
                <Route path="/login" component={Login}/>
                <Route
                  exact path="/reset/:uidb64/:token"
                  component={PasswordReset}
                />
                <Route
                  exact path="/invite/:uidb64/:token"
                  component={InvitedUser}
                />
              </Switch>
            )
          }
        />       
      </Switch>
    </Router>
  </Fragment>
)
