import React from 'react'
import {
  Modal, Panel
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'
import { CreditCardSignUpForm } from './CreditCardSignUpForm'

import { postCreditCardInAppSignUpRequest } from 'modules/operations/account'
const mapStateToProps = (state) => ({
  groupActivated: state.getIn(['account', 'groupInfo', 'groups', '0', 'activated']),
  billingActive: state.getIn(['account', 'groupInfo', 'groups', '0', 'billingActive'])
})


type Props = {|
  +groupInfo: object
|}


const SetCreditCardModal = (props: Props) => {
  const { 
    groupActivated,
    billingActive
  } = props
  

  return (
    billingActive ? (
      <Modal backdorp="static" show={billingActive && !groupActivated} size="md">
        <Modal.Header closeButton={false}>
          <Modal.Title>Submit your credit card info and activate your account</Modal.Title>
        </Modal.Header>
        <Panel style={{ marginLeft: '1.5vw'}}>
          <span style={{ justifyContent: 'center' }}>
            Your account is currently on hold because your payment information needs to be updated or your account is not activated yet.
            After entering your first payment information you will receive an Email with an activation link. 
            <div style={{ color: 'red' }}> If you already did provide payment information, please check your email and maybe your spam for the activation link or contact support.</div>
          </span>
          <div>
            <CreditCardSignUpForm postCreditCardSignUpRequest={props.postCreditCardInAppSignUpRequest}/>
          </div>
        </Panel>
        
      </Modal>
    ) : null
  )
}

//   https://github.com/facebook/flow/issues/7125
//   $FlowFixMe
export const SetCreditCardDialog = connect(
  mapStateToProps,
  ({
    postCreditCardInAppSignUpRequest,
  }: ReduxActions)
) (ToJs(SetCreditCardModal))

