import React, { Component } from 'react';

import { UserInviteComponent } from './UserInviteComponent'

type Props = {|
  +groupMembers: Array,
  +setSubmitDialogOpenState: Function,
  +children: React$Element<any>
|}

class UserInviteContainer extends Component<Props, State> {
    props: Props
    state: State
  
    state = {
      email: '',
      lastName: '',
      firstName: '',
      redirectUrl: '',
      isOpen: false
    }

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
        let url = "localhost:3000/reset/"
        this.setState({ ...this.state, redirectUrl: url })
    } else if (process.env.NODE_ENV === 'production') {
        let url = "cmc.gxp-cloud.com/reset/"
        this.setState({ ...this.state, redirectUrl: url })
    } else {
        let url = "staging.gxpcloud.org/reset/"
        this.setState({ ...this.state, redirectUrl: url })
    }
  }
  handleChange = (change) => {
      this.setState({ ...this.state, ...change })
    }

  toggleOpenState = () => {
      this.setState({ isOpen: !this.state.isOpen })
    }

      render() {
          return(
              <UserInviteComponent
                children={this.props.children}
                handleChange={this.handleChange}
                setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
                groupMembers={this.props.groupMembers}
                toggleOpenState={this.toggleOpenState}
                isOpen={this.state.isOpen}
                email={this.state.email}
                lastName={this.state.lastName}
                firstName={this.state.firstName}
                redirectUrl={this.state.redirectUrl}
              />
          )
      }
  }  

export const UserInvite = UserInviteContainer
  
  
  