// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  blueprintList: [],
  blueprint: {
    networks: [],
    firewallRules: [],
    dnatRules: [],
    snatRules: []
  },
  topology: '<SVG/>'
})

const moduleName = 'blueprints'

const {
  blueprints,
  getBlueprintsRequest,
  getBlueprintsRequestFailure,
  getBlueprintRequest,
  getBlueprintRequestFailure,
  getBlueprintTopologyRequest,
  getBlueprintTopologyRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_BLUEPRINTS_REQUEST: {
      creator: [
        (payload) => undefined,
        () => ({
          method: 'GET',
          endpoint: `/blueprints/`
        })
      ],
      reducer: (state) => state,
      onError: (e) => getBlueprintsRequestFailure(e)
    },
    GET_BLUEPRINTS_REQUEST_SUCCESS: (state, action) =>
      state.set('blueprintList', fromJS(action.response.results)),
    GET_BLUEPRINTS_REQUEST_FAILURE: (state) => state,
    GET_BLUEPRINT_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/blueprints/${payload.blueprintId}/`
        })
      ],
      onError: (e) => getBlueprintRequestFailure(e)
    },
    GET_BLUEPRINT_REQUEST_SUCCESS: (state, action) =>
      state.set('blueprint', fromJS(action.response)),
    GET_BLUEPRINT_REQUEST_FAILURE: (state) => state,
    GET_BLUEPRINT_TOPOLOGY_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/blueprints/${payload.blueprintId}/topology/`,
          isSvg: true
        })
      ],
      onError: (e) => getBlueprintTopologyRequestFailure(e)
    },
    GET_BLUEPRINT_TOPOLOGY_REQUEST_SUCCESS: (state, action) =>
      state.set('topology', fromJS(action.response)),
    GET_BLUEPRINT_TOPOLOGY_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export {
  blueprints,
  getBlueprintsRequest,
  getBlueprintRequest,
  getBlueprintTopologyRequest
}
