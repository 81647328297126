// @flow

import React, { useRef } from 'react'
import {
  FormGroup,
  Form,
  Modal,
  Schema,
  FormControl,
  ControlLabel,
  Button
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { StringType } = Schema.Types
const model = Schema.Model({
  currentPassword: StringType()
    .isRequired('This field is required.')
    .rangeLength(
      8,
      30,
      'The number of characters can only be between 8 and 30'
    ),
  password: StringType()
    .isRequired('This field is required.')
    .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Passwords: >8 characters, upper- & lowercase, number and special !@#$&*')
    .rangeLength(8,30, 'The number of characters can only be between 8 and 30.'),
  repeatedPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
    .isRequired('This field is required.')
})

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
  +hasPasswordSet: boolean,
  +handleSetPassword: Function,
  +onKeyDown: Function,
  +capsLockWarning: Boolean,
  +currentPassword: string,
  +newPassword: string,
  +repeatedPassword: string,
  +handleChange: Function
|}

const SetPasswordDialogComponent = (props: Props) => {
  const {
    hasPasswordSet,
    handleSetPassword,
    onKeyDown,
    capsLockWarning,
    currentPassword,
    newPassword,
    repeatedPassword,
    handleChange
  } = props
  var setPasswordForm = useRef(null)
  return (
    <Modal backdorp="static" show={!hasPasswordSet} size="sm">
      <Modal.Header closeButton={false}>
        <Modal.Title>Set your own Password</Modal.Title>
      </Modal.Header>
      <span>
        Before the console can be used you need to set your own password.
      </span>
      <Form
        model={model}
        ref={(ref) => (setPasswordForm = ref)}
        fluid
        checkTrigger="none"
      >
        <InputField
          autoFocus
          name="currentPassword"
          id="first-time-password-current"
          label="Current password"
          value={currentPassword}
          onChange={(currentPassword) => handleChange({ currentPassword })}
          type="password"
          onKeyDown={onKeyDown}
        />
        <InputField
          name="password"
          id="first-time-password-new"
          label="New password"
          value={newPassword}
          onChange={(newPassword) => handleChange({ newPassword })}
          type="password"
          onKeyDown={onKeyDown}
        />
        <InputField
          name="repeatedPassword"
          id="first-time-password-repeated"
          label="Repeat password"
          value={repeatedPassword}
          onChange={(repeatedPassword) => handleChange({ repeatedPassword })}
          type="password"
          onKeyDown={onKeyDown}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {capsLockWarning ? (
            <span style={{ margingRight: '100px', color: '#ff0000' }}>
              Caps Lock is On!
            </span>
          ) : (
            <div></div>
          )}
          <Button
            color="blue"
            style={{ marginLeft: '15px' }}
            label="Submit"
            type="submit"
            id="first-time-password-submit"
            onClick={(e) => {
              if (setPasswordForm.check()) {
                e.preventDefault()
                handleSetPassword()
              }
            }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export { SetPasswordDialogComponent }
