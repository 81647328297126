// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { OverviewComponent } from './OverviewComponent'
import { ToJs } from 'utils/ToJs'
import { getOverviewReportsRequest } from 'modules/operations/overview'
import { getIveReportRequest } from 'modules/operations/ives'
import { getVmReportRequest } from 'modules/operations/vms'

type ReduxState = {|
  timezone: string,
  reports: ReportOverview[]
|}
type ReduxActions = {|
  getOverviewReportsRequest: Function,
  getOverviewSslVpnAccountsRequest: Function,
  getVmReportRequest: Function,
  getIveReportRequest: Function
|}

type Props = ReduxState & ReduxActions

const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    reports: state.getIn(['overview', 'reports'])
  }: ReduxState)

export class OverviewContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getOverviewReportsRequest()
  }

  render() {
    return [
      <OverviewComponent
        getVmReportRequest={this.props.getVmReportRequest}
        getIveReportRequest={this.props.getIveReportRequest}
        key="reports"
        timezone={this.props.timezone}
        reports={this.props.reports}
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Overview = connect(
  mapStateToProps,
  ({
    getOverviewReportsRequest,
    getVmReportRequest,
    getIveReportRequest
  }: ReduxActions)
)(ToJs(OverviewContainer))
