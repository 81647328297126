import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BillingInformationComponent } from './BillingInformationComponent';
import { 
  getInvoiceListRequest,
  getDraftInvoiceRequest,
  getFinalInvoiceRequest,
  getCcInfoRequest 
} from 'modules/operations/account';

import { ToJs } from 'utils/ToJs';

const mapStateToProps = (state) => ({
    invoices: state.getIn(['account', 'invoices']),
    ccInfo: state.getIn(['account', 'ccInfo'])
  })

type Props = {|
    +getInvoiceListRequest: Function,
    +getDraftInvoiceRequest: Function,
    +getFinalInvoiceRequest: Function,
    +getCcInfoRequest: Function
  |}

  class BillingInformationContainer extends Component<Props> {
      props:Props

    // state ={}

    componentDidMount() {
      this.props.getInvoiceListRequest()
      this.props.getCcInfoRequest()
    }

    render() {
      return (
          <BillingInformationComponent
            invoices={this.props.invoices}
            ccInfo={this.props.ccInfo}
            getDraftInvoiceRequest={this.props.getDraftInvoiceRequest}
            getFinalInvoiceRequest={this.props.getFinalInvoiceRequest}
          />
      )}

  }

export { BillingInformationContainer }

export const BillingInformation = connect(mapStateToProps, {
  getInvoiceListRequest,
  getDraftInvoiceRequest,
  getFinalInvoiceRequest,
  getCcInfoRequest 
})(ToJs(BillingInformationContainer))