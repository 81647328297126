// @flow

import React, { Component } from 'react'
import { FirewallDialogComponent } from './FirewallDialogComponent'
import { validate } from './validate'

type Props = {|
  +submit: Function,
  +children: React$Element<any>,
  +dialogAction: 'Add' | 'Edit',
  +rule?: FirewallRule,
  +applications: IdentityObject[],
  +vms: IdentityObject[],
  +vnicGroups: IdentityObject[],
  +firewallRules: { +user: FirewallRule[], +immutable: FirewallRule[] }
|}
type State = {|
  errors: FirewallDialogErrors,
  rule: FirewallRule,
  isOpen: boolean
|}

const DEFAULT_RULE = {
  action: 'accept',
  applications: [],
  destinations: [],
  destinationVms: [],
  destinationVnicGroups: [],
  enabled: true,
  id: '',
  immutable: false,
  loggingEnabled: false,
  matchTranslated: false,
  name: '',
  ruleTag: 0,
  sources: [],
  sourceVms: [],
  sourceVnicGroups: [],
  updatedDatetime: new Date().toISOString(),
  createdDatetime: new Date().toISOString()
}

export class FirewallDialogContainer extends Component<Props, State> {
  props: Props
  state: State

  state = {
    errors: {
      name: '',
      general: ''
    },
    rule: DEFAULT_RULE,
    isOpen: false
  }

  submit = () => {
    if (this.handleValidate()) {
      this.props.submit(this.state.rule)
      this.setState({ rule: DEFAULT_RULE })
      this.toggleOpenState()
    }
  }

  handleNameChange = (value) => {
    const ruleState = { ...this.state.rule, name: value }
    const errorState = { ...this.state.errors, name: '' }
    this.setState({
      ...this.state,
      rule: ruleState,
      errors: errorState
    })
  }

  handleOnChange = (inputName: string, item: IdentityObject[]) => {
    const ruleState = { ...this.state.rule, [inputName]: item }
    this.setState({ ...this.state, rule: { ...ruleState } })
  }

  handleOnChangeApplications = (
    value: IdentityObject[],
    item: IdentityObject
  ) => {
    this.setState((prevState) => ({
      ...this.state,
      rule: {
        ...this.state.rule,
        applications: prevState.rule.applications.includes(item)
          ? prevState.rule.applications.filter((app) => app !== item)
          : [...prevState.rule.applications, item]
      }
    }))
  }

  handleOnChangeDestinations = (value) => {
    const ruleState = { ...this.state.rule, destinations: value }
    this.setState({ ...this.state, rule: { ...ruleState } })
  }

  handleOnChangeSources = (value: IdentityObject[]) => {
    this.handleOnChange('sources', value)
  }

  handleOnChangeDestinationVms = (
    value: IdentityObject,
    item: IdentityObject
  ) => {
    this.setState((prevState) => ({
      ...this.state,
      rule: {
        ...this.state.rule,
        destinationVms: prevState.rule.destinationVms.includes(item)
          ? prevState.rule.destinationVms.filter((vm) => vm !== item)
          : [...prevState.rule.destinationVms, item]
      }
    }))
  }

  handleOnChangeSourceVms = (value: IdentityObject, item: IdentityObject) => {
    this.setState((prevState) => ({
      ...this.state,
      rule: {
        ...this.state.rule,
        sourceVms: prevState.rule.sourceVms.includes(item)
          ? prevState.rule.sourceVms.filter((vm) => vm !== item)
          : [...prevState.rule.sourceVms, item]
      }
    }))
  }

  handleOnChangeDestinationVnicGroups = (
    value: IdentityObject,
    item: IdentityObject
  ) => {
    this.setState((prevState) => ({
      ...this.state,
      rule: {
        ...this.state.rule,
        destinationVnicGroups: prevState.rule.destinationVnicGroups.includes(
          item
        )
          ? prevState.rule.destinationVnicGroups.filter((vnic) => vnic !== item)
          : [...prevState.rule.destinationVnicGroups, item]
      }
    }))
  }

  handleOnChangeSourceVnicGroups = (
    value: IdentityObject,
    item: IdentityObject
  ) => {
    this.setState((prevState) => ({
      ...this.state,
      rule: {
        ...this.state.rule,
        sourceVnicGroups: prevState.rule.sourceVnicGroups.includes(item)
          ? prevState.rule.sourceVnicGroups.filter((vnic) => vnic !== item)
          : [...prevState.rule.sourceVnicGroups, item]
      }
    }))
  }

  toggleEnabledState = (event) => {
    const rule = { ...this.state.rule, enabled: !this.state.rule.enabled }
    this.setState({ ...this.state, rule })
  }

  toggleMatchTranslatedState = (event) => {
    const rule = { ...this.state.rule, matchTranslated: !this.state.rule.matchTranslated }
    this.setState({ ...this.state, rule })
  }

  handleValidate = () => {
    const { rule } = this.state
    const { dialogAction } = this.props
    const { immutable, user } = this.props.firewallRules
    const rules = immutable.concat(user)
    const errors = validate({ rule, rules, dialogAction })
    this.setState({
      ...this.state.errors,
      name: errors.name,
      general: errors.general
    })
    return !Object.keys(errors).length
  }

  toggleOpenState = () => {
    this.setState({ isOpen: !this.state.isOpen })
    if (this.props.rule) {
      this.setState({ rule: this.props.rule })
    }
  }

  render() {
    return (
      <FirewallDialogComponent
        errors={this.state.errors}
        children={this.props.children}
        dialogAction={this.props.dialogAction}
        handleNameChange={this.handleNameChange}
        handleOnChangeApplications={this.handleOnChangeApplications}
        handleOnChangeDestinations={this.handleOnChangeDestinations}
        handleOnChangeDestinationVms={this.handleOnChangeDestinationVms}
        handleOnChangeDestinationVnicGroups={
          this.handleOnChangeDestinationVnicGroups
        }
        handleOnChangeSources={this.handleOnChangeSources}
        handleOnChangeSourceVms={this.handleOnChangeSourceVms}
        handleOnChangeSourceVnicGroups={this.handleOnChangeSourceVnicGroups}
        isOpen={this.state.isOpen}
        applications={this.props.applications}
        vms={this.props.vms}
        vnicGroups={this.props.vnicGroups}
        rule={this.state.rule}
        submit={this.submit}
        toggleEnabledState={this.toggleEnabledState}
        toggleMatchTranslatedState={this.toggleMatchTranslatedState}
        toggleOpenState={this.toggleOpenState}
      />
    )
  }
}

export const FirewallDialog = FirewallDialogContainer
