import React, { useRef } from 'react';
import {
  Button,
  ControlLabel,
  Schema,
  Form,
  FormGroup,
  FormControl,
  Modal,
  SelectPicker
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { StringType } = Schema.Types
const model = Schema.Model({
  networkName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_.-]*$/, 'Please use only letters, numbers and _ - ')
    .maxLength(35, 'The maximum number of characters is 35'),
  hosts: StringType()
    .isRequired('This field is required.'),
  network: StringType()
    .isRequired('This field is required.')
    .pattern(/(^192\.168\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.[0]$)|(^172\.([1][6-9]|[2][0-9]|[3][0-1])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.[0]$)|(^10\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.[0]$)/, 'Please use private IPv4 network spaces - 10.x.x.0, 172.16.x.0, 192.168.x.0')
})

function InputField(props) {
  const { name, label, type, accepter, data, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        type={type}
        data={data}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
    +ive: Ive,
    +children: React$Element<any>,
    +iveId: string,
    +hosts: string,
    +network: string,
    +networkName: string,
    +handleChange: Function,
    +checkDuplicateNetwork: Function,
    +handleNetworkChange: Function,
    +toggleOpenState: Function,
    +setSubmitDialogOpenState: Function,
    +isOpen: boolean
  |}

export const AddNetworkDialogComponent = (props: Props) => {
    var addNetworkForm = useRef(null)
    const hostsChoices = [
      {
        "label": "5",
        "value": "6"
      },
      {
        "label": "13",
        "value": "14"
      },
      {
        "label": "29",
        "value": "30"
      },
      {
        "label": "61",
        "value": "62"
      },
      {
        "label": "125",
        "value": "126"
      }
    ]

    // check for duplicate networks, which disable the submit.
    const checkDuplicate = props.ive.networks.map(e => e.network.includes(props.network))
    const checkDuplicateNetwork = checkDuplicate.includes(true)

    return (
        <div style={{ display: 'inline' }}>
          {React.cloneElement(props.children, {
            onClick: props.toggleOpenState
          })}
          <Modal backdrop="static" show={props.isOpen} size="sm">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '25px' }}>
                Add local network to IVE {props.ive.name}
              </Modal.Title>
            </Modal.Header>
            <Form
              model={model}
              ref={(ref) => (addNetworkForm = ref)}
              fluid
              checkTrigger="none"
            >
                <InputField
                    autoFocus
                    id="name"
                    name="networkName"
                    style={{ width: 'inherit' }}
                    label="Local network name"
                    onChange={(networkName, event) =>
                        props.handleChange({ networkName, event })
                      }
                    value={props.networkName}
                />
                <InputField
                    name="hosts"
                    label="Ammount of IPaddress spaces"
                    style={{ width: 'inherit' }}
                    id="hosts"
                    onChange={(hosts, event) =>
                      props.handleChange({ hosts, event })
                    }
                    labelKey="label"
                    valueKey="value"
                    data={hostsChoices}
                    placeholder="This choice will determine your netmask"
                    searchable={false}
                    accepter={SelectPicker}
                  />
                <InputField
                    name="network"
                    label="Local network subnet address"
                    style={{ width: 'inherit' }}
                    id="local-network"
                    onChange={(network, event) =>
                      props.handleNetworkChange({ network, event })
                    }
                  />
                  {props.network ? (<div>The complete network will be {props.network}</div>) : ('')}                  
            <Modal.Footer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '25px',
                  marginBottom: '15px'
                }}
              >
                {checkDuplicateNetwork ? (
                      <span style={{ margingRight: '100px', color: '#ff0000' }}>
                        Make sure: No duplicate network.
                      </span>
                    ) : (
                      <div></div>
                    )}
                <div>
                  <Button onClick={props.toggleOpenState} color="red">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="blue"
                    id="submit"
                    disabled={checkDuplicateNetwork}
                    onClick={(e) => {
                      if (addNetworkForm.check()) {
                        e.preventDefault()
                        props.setSubmitDialogOpenState({
                          dialogId: 'ADD_IVE_NETWORK',
                          payload: {
                            name: props.networkName,
                            hosts: props.hosts,
                            network: props.network
                          },
                          meta: { iveId: props.ive.id }
                        })
                      }
                    }}
                  >
                    Add Network
                  </Button>
                </div>
              </div>
            </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}