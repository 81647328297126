// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { HardwareChangeComponent } from './HardwareChangeComponent'
import { getVmRequest, getVmAssetTiersRequest } from 'modules/operations/vms'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type ReduxState = {|
  +vm: Vm
|}
type ReduxActions = {|
  +getVmRequest: Function,
  +getVmAssetTiersRequest: Function,
  +setSubmitDialogOpenState: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}
type State = {| componentUpdateCount: number |}

const mapStateToProps = (state) =>
  ({
    vm: state.getIn(['vms', 'vm']),
    assetTiers: state.getIn(['vms', 'assetTiers'])
  }: ReduxState)

const DEFAULT_STATE = { componentUpdateCount: 0 }

export class HardwareChangeContainer extends Component<Props, State> {
  props: Props
  state: State = DEFAULT_STATE

  state = {
    vmTierName: '',
  }

  componentDidMount() {
    const { vmId } = this.props
    this.props.getVmRequest({ vmId })
    this.props.getVmAssetTiersRequest()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({ componentUpdateCount: this.state.componentUpdateCount + 1 })
  }

  handleChange = (change) => {
    this.setState({ ...this.state, ...change })
  }

  handleOpenSubmitDialog = () => {
    this.props.setSubmitDialogOpenState({
      dialogId: 'CHANGE_HARDWARE_VM',
      payload: { name: this.state.vmTierName },
      meta: { vmId: this.props.vm.id, name: this.props.vm.name }
    })
  }


  render() {
    return (
      <div>
        {this.props.assetTiers && (
          <HardwareChangeComponent
            vm={this.props.vm}
            vmTierName={this.state.vmTierName}
            assetTiers={this.props.assetTiers}
            handleOpenSubmitDialog={this.handleOpenSubmitDialog}
            handleChange={this.handleChange}
          />
        )}  
      </div>
    )
  }
}
// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const HardwareChange = connect(
  mapStateToProps,
  ({
    getVmRequest,
    getVmAssetTiersRequest,
    setSubmitDialogOpenState
  }: ReduxActions)
)(ToJs(HardwareChangeContainer))
