// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SubmitDialogComponent } from './SubmitDialogComponent'
import { ToJs } from 'utils/ToJs'
import {
  setSubmitDialogOpenState,
  clearSubmitDialogOpenState,
  setSubmitDialogSubmittingState,
  setSubmitDialogErrorState,
  clearSubmitDialogErrorState,
  clearSubmitDialogIsValidState,
  setDatetime
} from 'modules/components/submitDialog'
import { clearModalOpenState } from 'modules/components/gxpModal'
import { getApproversRequest } from 'modules/operations/approvers'
import {
  postIvesRequest,
  postIvesDeleteRequest,
  postSslVpnAccountRequest,
  postSslVpnAccountDeleteRequest,
  postNetworkCreateRequest,
  postNetworkDeleteRequest,
  postVnicAddPublicIpRequest,
  postFirewallRequest,
  postIpsecSitesRequest,
  postNatRequest
} from 'modules/operations/ives'
import { 
  postUserInviteRequest,
  putAccountDeactivateRequest, 
  putAccountRoleChangeRequest,
  postAccountOtpActivateRequest 
} from 'modules/operations/account'
import {
  postVmRequest,
  postVmPowerOnRequest,
  postVmPowerOffRequest,
  postVmRebootRequest,
  postVmRevertRestorePointRequest,
  postVmSnapshotCreateRequest,
  postVmSnapshotRevertRequest,
  postVmSnapshotDeleteRequest,
  postVmDeleteRequest,
  postVmHardwareChangeRequest
} from 'modules/operations/vms'
import {
  postVmDiskConfigRequest,
  postVmDiskDeleteRequest
} from 'modules/operations/vms'
import { putApprovalsRequest } from 'modules/operations/approvals'

import { Message } from 'rsuite'

const defaultState = {
  electronicSignature: '',
  approver: undefined,
  approverInputDisabled: true,
  dateTimeInputsDisabled: true,
  electronicSignatureInputDisabled: false,
  customerUnderstand: false
}

type Props = {|
  +setSubmitDialogOpenState: Function,
  +clearSubmitDialogOpenState: Function,
  +setSubmitDialogSubmittingState: Function,
  +setSubmitDialogErrorState: Function,
  +clearSubmitDialogErrorState: Function,
  +clearSubmitDialogIsValidState: Function,
  +clearModalOpenState: Function,
  +executionDate: Date,
  +executionTime: Date,
  +setDatetime: Function,
  +postIvesRequest: Function,
  +postIvesDeleteRequest: Function,
  +postSslVpnAccountRequest: Function,
  +postSslVpnAccountDeleteRequest: Function,
  +postVmRequest: Function,
  +postVmPowerOnRequest: Function,
  +postVmPowerOffRequest: Function,
  +postVmRebootRequest: Function,
  +postUserInviteRequest: Function,
  +putAccountDeactivateRequest: Function,
  +putAccountRoleChangeRequest: Function,
  +postAccountOtpActivateRequest: Function,
  +postVmRevertRestorePointRequest: Function,
  +postVmSnapshotCreateRequest: Function,
  +postVmSnapshotRevertRequest: Function,
  +postVmSnapshotDeleteRequest: Function,
  +postVmDeleteRequest: Function,
  +postVmHardwareChangeRequest: Function,
  +postVmDiskConfigRequest: Function,
  +postVmDiskDeleteRequest: Function,
  +postIvesDeleteRequest: Function,
  +postSslVpnAccountRequest: Function,
  +putApprovalsRequest: Function,
  +postNetworkCreateRequest: Function,
  +postNetworkDeleteRequest: Function,
  +postVnicAddPublicIpRequest: Function,
  +postFirewallRequest: Function,
  +postIpsecSitesRequest: Function,
  +postNatRequest: Function,
  +settings: {
    +payload: {},
    +meta: { +name: string, +iveId: string },
    +dialogId: string
  },
  +timezone: string,
  +errors: {
    +approver: ?string,
    +electronicSignature: ?string,
    +executionDatetime: ?string
  },
  +isOpen: boolean,
  +isSubmitting: boolean,
  +isValid: boolean,
  +approvers: IdentityObject[]
|}

type State = {|
  electronicSignature: string,
  approver: ?User,
  approverInputDisabled: true,
  dateTimeInputsDisabled: true,
  electronicSignatureInputDisabled: false,
|}

class SubmitDialogContainer extends Component<Props, State> {
  state: State = defaultState
  props: Props

  getSubmitDialogConfig = () => {
    const { settings } = this.props
    switch (settings.dialogId) {
      case 'DEPLOY_IVE':
        return {
          onSave: (data) =>
            this.props.postIvesRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Deploy IVE: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will deploy a new Isolated Virtual Environment.
                    Deploying an IVE will take a few minutes. During this time you can still use the GxP-Cloud Management Console.
                    {/* <div style={{ color: 'red' }}>The Isolated Virtual Environment will have a minimal uptime off X hours.</div> */}
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'DEPLOY_VM':
        return {
          onSave: (data) =>
            this.props.postVmRequest(
              { ...data, ...settings.payload },
              { ...settings.meta }
            ),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Deploy VM in IVE: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will deploy a new Virtual Machine. Deploying a VM will take a few minutes. During this time you can still use the GxP-Cloud Management Console.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'DELETE_IVE':
        return {
          onSave: (data) =>
            this.props.postIvesDeleteRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Delete IVE: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will delete your Isolated Virtual Environment and
                    all related Virtual Machines. Please make sure that no
                    important data is lost upon the deletion of this Isolated
                    Virtual Environment.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
        case 'ADD_IVE_NETWORK':
          return {
            onSave: (data) =>
              this.props.postNetworkCreateRequest({
                ...data,
                ...settings.payload,
                iveId: settings.meta.iveId
              }),
            title: (
              <>
                <div style={{ fontSize: '22px' }}>
                  Add network to Isolated Virtual Environment
                </div>
                <br />
                <Message
                  showIcon
                  type="info"
                  description={
                    <div
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'
                      }}
                    >
                      This task will add the entered network to your Isolated Virtual Environment.
                      {/* <div style={{ color: 'red' }}>The Network will have a minimal uptime off X hours.</div> */}
                    </div>
                  }
                  title="Information"
                />
              </>
            ),
            electronicSignatureOnly: false
          }
          case 'DELETE_IVE_NETWORK':
            return {
              onSave: (data) =>
                this.props.postNetworkDeleteRequest({
                  ...data,
                  ...settings.payload,
                  iveId: settings.meta.iveId
                }),
              title: (
                <>
                  <div style={{ fontSize: '22px' }}>
                    Delete network from Isolated Virtual Environment
                  </div>
                  <br />
                  <Message
                    showIcon
                    type="warning"
                    description={
                      <div
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word'
                        }}
                      >
                        This task will delete the selected network from your Isolated Virtual Environment. Please make sure the network contains no more assets before deleting the network.
                      </div>
                    }
                    title="Warning"
                  />
                </>
              ),
              electronicSignatureOnly: false
            }


      
      case 'ADD_PUBLIC_IP':
          return {
              onSave: (data) =>
                  this.props.postVnicAddPublicIpRequest({ ...data, ...settings.payload }),
              title: (
                  <>
                    <div style={{ fontSize: '22px' }}>
                      Add Public IP address to Isolated Virtual Environment
                    </div>
                    <br />
                    <Message
                      showIcon
                      type="info"
                      description={
                        <div
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                          }}
                        >
                          This task will add an extra public IP to your Isolated Virtual Environment. Please keep in mind this is permanent until you delete the IVE.
                        </div>
                      }
                      title="Information"
                  />
              </>
          ),
          electronicSignatureOnly: true // set to false to turn approvals and scheduling on
      }
        

      case 'SAVE_FIREWALL_RULES':
        return {
          onSave: (data) =>
            this.props.postFirewallRequest({
              ...data,
              ...settings.payload,
              iveId: settings.meta.iveId
            }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>
                Publish Firewall configuration
              </div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will publish your Firewall Configuration to the
                    Isolated Virtual Environment.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      // Ipsec sites dialog
      case 'ADD_IPSEC_SITES':
        return {
          onSave: (data) =>
            this.props.postIpsecSitesRequest({
              ...data,
              ...settings.payload,
              iveId: settings.meta.iveId
            }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>
                Publish IPsec VPN configuration
              </div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will publish your IPsec VPN Configuration to the
                    Isolated Virtual Environment.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'SAVE_NAT_RULES':
        return {
          onSave: (data) =>
            this.props.postNatRequest({
              ...data,
              ...settings.payload,
              iveId: settings.meta.iveId
            }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Publish NAT configuration</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will publish your NAT rules Configuration to the
                    Isolated Virtual Environment.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'ADD_SSL_VPN_ACCOUNT':
        return {
          onSave: (data) =>
            this.props.postSslVpnAccountRequest(
              { ...data, ...settings.payload },
              { ...settings.meta }
            ),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Add SSL-VPN account</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will create an SSL-VPN account for the Isolated
                    Virtual Environment.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'DELETE_SSL_VPN_ACCOUNT':
        return {
          onSave: (data) =>
            this.props.postSslVpnAccountDeleteRequest(data, {
              ...settings.meta
            }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Delete SSL-VPN account</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will delete the selected SSL-VPN account for the
                    Isolated Virtual Environment.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'REBOOT_VM':
        return {
          onSave: (data) =>
            this.props.postVmRebootRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Reboot VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    During this task the Virtual Machine will be temporarily
                    shut down and rebooted. Please ensure that there are no
                    active critical processes running to prevent data loss.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'VM_REVERT_RESTORE_POINT':
        return {
          onSave: (data) =>
            this.props.postVmRevertRestorePointRequest(
              { ...data, ...settings.payload },
              { ...settings.meta }
            ),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Restore VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    During this task the Virtual Machine will be temporarily
                    shut down and rebooted. The Virtual Machine will be reverted
                    to the selected restore point.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignature: false
        }
      case 'VM_SNAPSHOT_CREATE':
        return {
          onSave: (data) =>
            this.props.postVmSnapshotCreateRequest(
              { ...data, ...settings.payload },
              { ...settings.meta }
            ),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Create VM snapshot for: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    Creating a VM Snapshot will automatically create a scheduled
                    task to delete the VM Snapshot after 72 hours. You can
                    revert to this VM Snapshot for the next 72 hours. The
                    presence of a VM Snapshot limits VM Hardware changes.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: true
        }
      case 'VM_SNAPSHOT_REVERT':
        return {
          onSave: (data) =>
            this.props.postVmSnapshotRevertRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Revert VM to snapshot for: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    During this task the Virtual Machine will be temporarily
                    unavailable until the reversion to the snapshot is complete.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: true
        }
      case 'VM_SNAPSHOT_DELETE':
        return {
          onSave: (data) =>
            this.props.postVmSnapshotDeleteRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Delete VM snapshot for: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will remove the existing snapshot for this Virtual
                    Machine.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: true
        }
      case 'POWER_ON_VM':
        return {
          onSave: (data) =>
            this.props.postVmPowerOnRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Power on VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will power on the selected Virtual Machine.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'POWER_OFF_VM':
        return {
          onSave: (data) =>
            this.props.postVmPowerOffRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Power off VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will power off the selected Virtual Machine.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'DELETE_VM':
        return {
          onSave: (data) =>
            this.props.postVmDeleteRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Delete VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will delete the selected Virtual Machine.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'CHANGE_HARDWARE_VM':
        return {
          onSave: (data) =>
            this.props.postVmHardwareChangeRequest(
              { ...data, ...settings.payload },
              { ...settings.meta }
            ),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Change hardware for VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    During this task the Virtual Machine will be shut down and
                    rebooted until the hardware changes have been completed.
                    Please ensure that there are no active critical processes
                    running to prevent data loss. 
                    {/* <div style={{ color: 'red' }}>After this change your Virtual Machine is set to this Asset Tier for X hours.</div> */}
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'VM_DISK_CONFIG':
        return {
          onSave: (data) =>
            this.props.postVmDiskConfigRequest(
              { ...data, ...settings.payload },
              { ...settings.meta }
            ),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Change disk configuration for VM: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will change the disk configuration of the selected
                    Virtual Machine.
                    {/* <div style={{ color: 'red' }}>After this change your Virtual Machines disks cant be edited for X hours.</div> */}
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'DELETE_DISK_VM':
        return {
          onSave: (data) =>
            this.props.postVmDiskDeleteRequest(data, { ...settings.meta }),
          title: (
            <>
              <div
                style={{ fontSize: '22px' }}
              >{`Delete VM disk: ${settings.meta.name}`}</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will delete the selected disk from the selected
                    Virtual Machine. Please make sure critical data is backed up
                    to prevent data loss.
                    {/* <div style={{ color: 'red' }}>After this change your Virtual Machines disks cant be edited for X hours.</div> */}
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: false
        }
      case 'APPROVE_TASK':
        return {
          onSave: (data) =>
            this.props.putApprovalsRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Approve task</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    By Approving, this task will be executed.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: true
        }
      case 'DISAPPROVE_TASK':
        return {
          onSave: (data) =>
            this.props.putApprovalsRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Revoke task</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    By Revoking, this task will not be executed. The initiator will be notified.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: true
        }
        case 'INVITE_USER':
          return {
            onSave: (data) =>
            this.props.postUserInviteRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Invite User</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    The User will be created and invited based on the entered information.
                    An Email will be send to this person. He can finish the signup following the steps provided.
                  </div>
                }
                title="Information"
              />
            </>
          ),
          electronicSignatureOnly: true
          }
        case 'DELETE_USER':
          return {
            onSave: (data) =>
            this.props.putAccountDeactivateRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Delete User</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will delete the selected user's account. Please make sure this user's account is not needed anymore before deleting.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: true
          }
          case 'CHANGE_USER_ROLE':
          return {
            onSave: (data) =>
            this.props.putAccountRoleChangeRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Change User Role</div>
              <br />
              <Message
                showIcon
                type="warning"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    This task will change the selected user's role. Please make sure this is intended.
                  </div>
                }
                title="Warning"
              />
            </>
          ),
          electronicSignatureOnly: true
          }
          case 'ACTIVATE_OTP':
          return {
            onSave: (data) =>
            this.props.postAccountOtpActivateRequest({ ...data, ...settings.payload }),
          title: (
            <>
              <div style={{ fontSize: '22px' }}>Activate OTP</div>
              <br />
              <Message
                showIcon
                type="info"
                description={
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}
                  >
                    Activate your OTP device.
                  </div>
                }
                title="Info"
              />
            </>
          ),
          electronicSignatureOnly: true
          }
      default:
        return {}
    }
  }

  handleChange = (change) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }

  handleSave = () => {
    const {
      approver,
      dateTimeInputsDisabled,
      electronicSignature,
      electronicSignatureInputDisabled
    } = this.state
    const getExecutionDatetime = (date, time) => {
      date.setHours(time.getHours())
      date.setMinutes(time.getMinutes())
      return date.toISOString()
    }
    const { executionDate, executionTime } = this.props
    const executionDatetime = !dateTimeInputsDisabled
      ? getExecutionDatetime(executionDate, executionTime)
      : undefined
    this.props.setSubmitDialogSubmittingState()
    this.props.clearSubmitDialogErrorState()
    const fields: {
      approvingUsername?: string,
      electronicSignature?: string,
      executionDatetime?: string
    } =
      electronicSignatureInputDisabled && approver
        ? { approvingUsername: approver }
        : { electronicSignature }

    if (!dateTimeInputsDisabled) {
      fields.executionDatetime = executionDatetime
    }
    const { onSave } = this.getSubmitDialogConfig()
    onSave({ ...fields, dateTimeInputsDisabled })
    this.setState(defaultState)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isValid) {
      this.props.clearSubmitDialogIsValidState()
      this.props.clearSubmitDialogOpenState()
    }
  }

  componentWillUnmount() {
    this.props.clearSubmitDialogErrorState()
  }

  render() {
    const {
      errors,
      clearSubmitDialogErrorState,
      approvers,
      isOpen,
      isSubmitting,
      clearSubmitDialogOpenState,
      clearModalOpenState,
      executionDate,
      executionTime,
      setDatetime
    } = this.props
    const { title, electronicSignatureOnly } = this.getSubmitDialogConfig()
    const {
      electronicSignature,
      approver,
      approverInputDisabled,
      dateTimeInputsDisabled,
      electronicSignatureInputDisabled,
    } = this.state
    return (
      <SubmitDialogComponent
        title={title}
        clearSubmitDialogErrorState={clearSubmitDialogErrorState}
        clearModalOpenState={clearModalOpenState}
        electronicSignatureOnly={electronicSignatureOnly}
        errors={errors}
        approvers={approvers}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        electronicSignature={electronicSignature}
        approver={approver}
        executionDate={executionDate}
        executionTime={executionTime}
        approverInputDisabled={approverInputDisabled}
        dateTimeInputsDisabled={dateTimeInputsDisabled}
        electronicSignatureInputDisabled={electronicSignatureInputDisabled}
        handleSave={this.handleSave}
        handleChange={this.handleChange}
        clearSubmitDialogOpenState={clearSubmitDialogOpenState}
        setDatetime={setDatetime}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.getIn(['submitDialog', 'isOpen']),
  isSubmitting: state.getIn(['submitDialog', 'isSubmitting']),
  isValid: state.getIn(['submitDialog', 'isValid']),
  errors: state.getIn(['submitDialog', 'errors']),
  settings: state.getIn(['submitDialog', 'settings']),
  executionDate: state.getIn(['submitDialog', 'executionDate']),
  executionTime: state.getIn(['submitDialog', 'executionTime']),
  approvers: state.getIn(['approvers', 'items']),
  timezone: state.getIn(['account', 'timezone'])
})

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const SubmitDialog = connect(mapStateToProps, {
  getApproversRequest,
  setSubmitDialogOpenState,
  clearSubmitDialogOpenState,
  setSubmitDialogSubmittingState,
  setSubmitDialogErrorState,
  clearSubmitDialogErrorState,
  clearSubmitDialogIsValidState,
  clearModalOpenState,
  setDatetime,
  postNetworkCreateRequest,
  postNetworkDeleteRequest,
  postVnicAddPublicIpRequest,
  postFirewallRequest,
  postIpsecSitesRequest,
  postNatRequest,
  postIvesRequest,
  postVmRequest,
  postIvesDeleteRequest,
  postSslVpnAccountRequest,
  postSslVpnAccountDeleteRequest,
  postVmPowerOnRequest,
  postVmPowerOffRequest,
  postVmRebootRequest,
  postUserInviteRequest,
  putAccountDeactivateRequest,
  putAccountRoleChangeRequest,
  postAccountOtpActivateRequest,
  postVmRevertRestorePointRequest,
  postVmSnapshotCreateRequest,
  postVmSnapshotRevertRequest,
  postVmSnapshotDeleteRequest,
  postVmDeleteRequest,
  postVmHardwareChangeRequest,
  postVmDiskConfigRequest,
  postVmDiskDeleteRequest,
  putApprovalsRequest
})(ToJs(SubmitDialogContainer))
