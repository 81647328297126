import React from 'react'
import { Link } from 'react-router-dom'

import { Table, Whisper, Tooltip, IconButton, Icon } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { Column, HeaderCell, Cell } = Table

const styles = {
  display: 'inline-table',
  marginLeft: 188,  // same as width of sidebar
  position: 'absolute',
  width: 350,
  boxShadow: '3px 0 2px 0 #e0e0e0'
}

function vhToPixels (vh) {
  return Math.round(window.innerHeight / (100 / vh));
}

type Props = {
  +iveListSummary: string
}

export const AssetsTreeComponent = (props: Props) => {
  const { iveListSummary, loading } = props
  return (
      <Table
        style={styles}
        isTree
        height={vhToPixels(100)}
        defaultExpandAllRows
        rowKey="id"
        loading={loading}
        data={iveListSummary.results}
        renderTreeToggle={(icon, rowData) => {
          return icon
        }}
        renderEmpty={() => {
          return (
            <div>
              <div style={{ textAlign: "center", marginTop: "75px" }}>
                  Start by deploying your first
              </div>
              <Link to={'/ive-catalogue'}>
                <IconButton 
                  icon={<Icon icon="plus" />}
                  placement="left"
                  appearance="ghost"
                  style={{ marginLeft: 60, marginTop: "10px" }}  
                >
                  Isolated Virtual Environment
                </IconButton>
              </Link>
            </div>
          )
        }}
      >
        <Column width={240}>
          <HeaderCell>Assets</HeaderCell>
          <Cell dataKey="id">
            {(rowData) => {
              if (rowData.publicIp) {
                return (
                  <span>
                    [IVE] <strong>{rowData.name}</strong>
                  </span>
                )
              } else if (rowData.network) {
                return (
                  <span>
                    [Network] <strong>{rowData.name}</strong>
                  </span>
                )
              } else {
                return (
                  <span>
                    [VM] <strong>{rowData.name}</strong>
                  </span>
                )
              }
            }}
          </Cell>
        </Column>
        <Column width={110}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              if (rowData.publicIp) {
                return (
                  <span>
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Go to IVE dashboard</Tooltip>}
                    >
                      <Link to={`/ives/${rowData.id}`}>IVE Info</Link>
                    </Whisper>
                  </span>
                )
              }
            }}
          </Cell>
        </Column>
      </Table>
  )
}
