// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getIvesSummaryRequest } from 'modules/operations/ivelistsummary'
import { ToJs } from 'utils/ToJs'
import { AssetsTreeComponent } from './AssetTreeComponent'

type ReduxState = {| +timezone: string, +ivelistsummary: string |}
type ReduxActions = {| +getIvesSummaryRequest: Function |}
type Props = ReduxState & ReduxActions

const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    iveListSummary: state.getIn(['ivelistsummary', 'iveListSummary'])
  }: ReduxState)

class AssetsTreeContainer extends Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: true
    }
  }

  componentDidMount() {
    this.props.getIvesSummaryRequest()
  }

  render() {
    return (
      <div>
        {this.props.iveListSummary.results && (
          <AssetsTreeComponent iveListSummary={this.props.iveListSummary} />
        )}
      </div>
    )
  }
}

export const AssetsTree = connect(
  mapStateToProps,
  ({ getIvesSummaryRequest }: ReduxActions)
)(ToJs(AssetsTreeContainer))
