import React from 'react';

import {
  Button,
  Modal,
  Panel,
  Row,
  Col,
  Divider
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

type Props = {|
    +peerConfigs: Array,
    +iveId: string,
    +ive: Ive,
    +toggleOpenState: Function,
    +isOpen: boolean
|}

export const IpsecPeerConfigComponent = (props: Props) => {

    return (
        <div style={{ display: 'inline' }}>
          {React.cloneElement(props.children, {
            onClick: props.toggleOpenState
          })}
          <Modal backdrop="static" show={props.isOpen} size="sm">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '25px' }}>
                IPsec Peer configuration of IVE {props.ive.name}
              </Modal.Title>
              Please use this configuration(s) to setup the remote side of the IPsec site(s).            
            </Modal.Header> 
            {props.peerConfigs.peerConfigs && props.peerConfigs.peerConfigs.map((peerConfig, index) => {
                // Get into the nested configuration by taking values and slicing the given Arrays
                const peerConfigId = Object.values(peerConfig.peerConfig)
                const peerConfigInside = peerConfigId[0]
                const peerIpsecSiteConfig = Object.values(peerConfigInside)
                const peerIpsecConfiguration = peerIpsecSiteConfig[0]
                return (
                    <div>
                        <Panel 
                            header={<strong>{peerIpsecConfiguration.ipsecSiteName} peer configuration</strong>}
                            bordered
                            style={{marginTop: "10px"}}
                            key={index}
                        >
                            <Divider>
                                Credentials
                            </Divider>
                            <Row>
                                <Col md={14}>
                                    <strong>Peer ID</strong>
                                </Col>
                                <Col md={10}>
                                 {peerIpsecConfiguration.peerConfiguration.peerId}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={14}>
                                    <strong>Peer IPaddress</strong>
                                </Col>
                                <Col md={10}>
                                 {peerIpsecConfiguration.peerConfiguration.peerAddress}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={14}>
                                    <strong>Peer Subnet(s)</strong>
                                </Col>
                                {/* <Col md={10}>
                                    {peerIpsecConfiguration.peerConfiguration && peerIpsecConfiguration.peerConfiguration.peerSubnets.map((peerSubnet, index) => (
                                        <div key={index}>{peerSubnet}</div>
                                    ))}
                                </Col> */}
                                <Col>
                                    {peerIpsecConfiguration.peerConfiguration.peerSubnets}
                                </Col>
                            </Row>
                            <Divider>
                                Security
                            </Divider>
                            <Row>
                                <Col md={14}>
                                    <strong>Encryption Algorithm</strong>
                                </Col>
                                <Col md={10}>
                                    {peerIpsecConfiguration.ipsecConfiguration.encryptionAlgorithm}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={14}>
                                    <strong>Authentication Algorithm</strong>
                                </Col>
                                <Col md={10}>
                                    {peerIpsecConfiguration.ipsecConfiguration.authenticationAlgorithm}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={14}>
                                    <strong>Pre-shared Key</strong>
                                </Col>
                                <Col md={10}>
                                    {peerIpsecConfiguration.ikeConfiguration.preSharedKey}
                                </Col>
                            </Row>
                        </Panel>
                    </div>
                )
            })}     
            <Modal.Footer>
                <Button 
                    onClick={props.toggleOpenState} 
                    color="orange"
                    style={{marginTop: "15px"}}
                >
                    Return
                </Button>
            </Modal.Footer>
      </Modal>
    </div>
    )
}