import React, { Component } from 'react'

import {
  Panel,
  Table,
  Whisper,
  Tooltip,
  Icon, Row, Col
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { Column, HeaderCell, Cell } = Table

type Props = {|
    +invoices: Array,
    +ccInfo: Array,
    +getDraftInvoiceRequest: Function,
    +getFinalInvoiceRequest: Function
  |}

  class BillingInformationComponent extends Component<Props> {
    props: Props
     
    render() {
        return (
            <Panel
                header={<h5>Billing and Invoices Information</h5>}
                style={{ overflow: 'visible' }}
                key="billinginfocomp"
            >
              <Panel header={<strong>Invoice information.</strong>}>
              <Table
                height={220}
                data={this.props.invoices}
              >
                <Column width={150} align="center">
                  <HeaderCell>Invoice ID</HeaderCell>
                  <Cell dataKey="id"/>
                </Column>

                <Column width={150}>
                  <HeaderCell>Finallized Invoice</HeaderCell>
                  <Cell>
                    {(rowData) => {
                        if (rowData.draft === true) {
                          return(
                            <span>
                              <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={<Tooltip>This is a projected invoice.</Tooltip>}
                              >
                                <Icon icon="close"/>
                              </Whisper>
                            </span>
                          )
                      } else {
                        return(
                          <span>
                            <Whisper
                              placement="top"
                              trigger="hover"
                              speaker={<Tooltip>This is a final invoice</Tooltip>}
                            >
                              <Icon icon="check"/>
                            </Whisper>
                          </span>
                        )
                      }}}
                  </Cell>
                </Column>

                <Column width={200}>
                  <HeaderCell>Effective Date</HeaderCell>
                    <Cell dataKey="effectiveDate" />
                </Column>

                <Column width={100}>
                  <HeaderCell>Paid</HeaderCell>
                    <Cell>
                    {(rowData) => {
                        if (rowData.status === 'Paid') {
                          return(
                            <span>
                              <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={<Tooltip>This invoice is Paid.</Tooltip>}
                              >
                                <Icon 
                                icon="check" 
                                style={{ color: 'green' }}
                              />
                              </Whisper>
                            </span>
                          )
                      } else {
                        return(
                          <span>
                            <Whisper
                              placement="top"
                              trigger="hover"
                              speaker={<Tooltip>This invoice is Due</Tooltip>}
                            >
                              <Icon 
                                icon="close" 
                                style={{ color: 'red' }}
                              />
                            </Whisper>
                          </span>
                        )
                      }}}

                    </Cell>
                </Column>

                <Column width={200}>
                  <HeaderCell>Download</HeaderCell>
                  <Cell>
                  {(rowData) => {
                        if (rowData.draft === true) {
                          return(
                            <span>
                              <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={<Tooltip>Download invoice.</Tooltip>}
                              >
                                <Icon 
                                  icon="download"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => 
                                    this.props.getDraftInvoiceRequest({ 
                                      url: `/account/invoices/${rowData.id}/?draft=true`
                                    }
                                  )}
                                />
                              </Whisper>
                            </span>
                          )
                      } else {
                        return(
                          <span>
                            <Whisper
                              placement="top"
                              trigger="hover"
                              speaker={<Tooltip>Download invoice</Tooltip>}
                            >
                              <Icon 
                                icon="download"
                                style={{ cursor: 'pointer' }}
                                onClick={() => 
                                  this.props.getFinalInvoiceRequest({ 
                                    url: `/account/invoices/${rowData.id}/`
                                  }
                                )}
                              />
                            </Whisper>
                          </span>
                        )
                      }}}
                  </Cell>
                </Column>
              </Table>
            </Panel>
            {this.props.ccInfo ? (
              <Panel header={<strong>Credit Card payment information.</strong>}>
                {/* <Icon icon="check" onClick={() => console.log(this.props.ccInfo)} /> */}
                <Row>
                  <Col md={6}>
                    CreditCard number: 
                  </Col>
                  <Col md={12}>
                    <strong> {this.props.ccInfo.maskedCardNumber}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    CreditCard owner: 
                  </Col>
                  <Col md={12}>
                    <strong> {this.props.ccInfo.firstName} {this.props.ccInfo.lastName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    Expiration Month - Year: 
                  </Col>
                  <Col md={12}>
                    <strong> {this.props.ccInfo.expirationMonth} - {this.props.ccInfo.expirationYear}</strong>
                  </Col>
                </Row>

              </Panel>) : null
            }
            </Panel>
        )}}

export { BillingInformationComponent }