// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { IveReportsComponent } from './IveReportsComponent'
import {
  getIveReportRequest,
  getIveRequest,
  getIvesReportsRequest
} from 'modules/operations/ives'

type ReduxState = {| +timezone: string, +reports: Report[], +name: string |}
type ReduxActions = {|
  +getIveReportRequest: Function,
  +getIveRequest: Function,
  +getIvesReportsRequest: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}

const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    reports: state.getIn(['ives', 'reports']),
    name: state.getIn(['ives', 'ive', 'name'])
  }: ReduxState)

export class IveReportsContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    const { iveId } = this.props
    this.props.getIveRequest({ iveId })
    this.props.getIvesReportsRequest({ iveId })
  }

  render() {
    const { getIveReportRequest, timezone, name, reports } = this.props
    return [
      <IveReportsComponent
        data={reports}
        getIveReportRequest={getIveReportRequest}
        key="ive-reports"
        name={name}
        timezone={timezone}
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const IveReports = connect(
  mapStateToProps,
  ({
    getIveReportRequest,
    getIveRequest,
    getIvesReportsRequest
  }: ReduxActions)
)(ToJs(IveReportsContainer))
