import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getDcStatisticsRequest } from 'modules/operations/statistics'
import { getAccountRequest } from 'modules/operations/account'

import { StatisticsComponent } from './StatisticsComponent'
import { ToJs } from 'utils/ToJs'

type ReduxState = {| +dcStatistics: string |}
type ReduxActions = {| +getdcStatisticsRequest: Function, +getAccountRequest: Function |}
type Props = ReduxState & ReduxActions

const mapStateToProps = (state) =>
({
  account: state.getIn(['account']),
  groups: state.getIn(['account', 'groups']),
  dcStatistics: state.getIn(['statistics', 'dcStatistics'])
})

export class StatisticsContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getAccountRequest()
    this.props.getDcStatisticsRequest()
  }

  render() {
    if (this.props.dcStatistics) {
      const allowedGroups = this.props.account.group === "Billing" || this.props.account.group === "GxP-Cloud" || this.props.account.group === "Iperion IT" || this.props.account.group === "GxP-Cloud Dev" || this.props.account.group === "Staging"
      if (allowedGroups) {
        return (
          <StatisticsComponent
            dcStatistics={this.props.dcStatistics}
          />
        )
      } else {
        return (
          <div>Loading</div>
        )
      }
    } else {
      return (
        <div><h5>Not Found</h5></div>
      )
    }
  }
}

export const Statistics = connect(mapStateToProps, {
  getAccountRequest,
  getDcStatisticsRequest
})(ToJs(StatisticsContainer))