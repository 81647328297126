//import { render } from 'enzyme'
//Delete if everything keeps working
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

import {
  Panel,
  Row,
  Col,
  Grid,
  Divider
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { HelpDialogComponent } from './HelpDialogComponent'
import {
  setHelpModalOpenState,
  clearHelpModalOpenState
} from 'modules/components/helpDialogModal'

// import iveInfo from '../img/iveDashboard/iveInfo.png'
import addVm from '../img/iveDashboard/addVM.png'
import localNetwork from '../img/iveDashboard/localNetwork.png'
import publicIp from '../img/iveDashboard/publicIp.png'
import sslVpn from '../img/iveDashboard/sslVpn.png'
import manageFw from '../img/iveDashboard/manageFw.png'
import manageNat from '../img/iveDashboard/manageNat.png'
import manageIpsec from '../img/iveDashboard/manageIpsec.png'
import iveReport from '../img/iveDashboard/iveReport.png'
import iveChangelog from '../img/iveDashboard/iveChangelog.png'
import homeBegin from '../img/cmcDashboard/homeBegin.png'
import homeStart from '../img/cmcDashboard/homeStart.png'
import homeTask from '../img/cmcDashboard/homeTask.png'
import homeAppro from '../img/cmcDashboard/homeAppro.png'
import homeAudit from '../img/cmcDashboard/homeAudit.png'


type Props = {|
  +setHelpModalOpenState: Function,
  +clearHelpModalOpenState: Function,
  +show: boolean,
  +backdrop: string,
  +settings: {
    +payload: {},
    +meta: { +place: string },
    +modalId: string
  }
|}

const styles = {
  rows: {
    height: '85px',
    marginTop: '5px'
  },
  cols: {
    width: '50%',
    height: '85px'
  }
}

class HelpDialogContainer extends Component<Props> {
  props: Props

  // It complaines ther is no default (the '' is default here) maybe there is another way.
  getHelpModalConfig = () => {
    const { settings } = this.props
    switch (settings.modalId) {
      default:
        return {
          title: 'Error',
          content: 'Help not specified'
        }
      case 'NOT_FOUND':
        return {
          title: 'URL not found',
          content: 'The given URL is not found and probably not existing in our application',
          size: 'sm'
        }
      case 'IVE_DASHBOARD':
        return {
          title: 'IVE Dashboard explanation',
          content: 
            <Panel>
              <Grid fluid>
                {/* <strong>Isolated Virtual Environment Information</strong>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={iveInfo} alt='IVE info' /></Col>
                  <Col md={12} style={styles.cols}>
                    Here is all info corresponding your Isolated Virtual Environment.<br></br>
                    You can find your public IP here and all your data protection profiles.
                  </Col>
                </Row> */}
                <strong>Isolated Virtual Environment Actions</strong>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={addVm} alt='add VM to IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    <br></br>
                    Add an Virtual Machine to your Isolated Virtual Environment.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={localNetwork} alt='add local Network to IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    <br></br>
                    Add an extra Local Network to your Isolated Virtual Environment.<br></br>
                    Seperate your workloads on different VLANs (local networks).
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={publicIp} alt='add public IP to IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    <br></br>
                    Add an extra Public IP to your Isolated Virtual Environment.<br></br>
                    Your IVE can now accept traffic on more public IPs.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={sslVpn} alt='add SSL-VPN account to IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    Add a SSL-VPN account to your Isolated Virtual Environment.<br></br>
                    With this account and the downloaded client in the portal.<br></br>
                    Portal can be reached by clicking the  Manage SSL-VPN button <br></br>
                    and pressing the Portal button there.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={manageFw} alt='Manage Firewall of IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    Manage the Firewall rules of your Isolated Virtual Environment.<br></br>
                    Firewall rules determine which traffic can or cant access your environment and assets in it.<br></br>
                    When added, deleted or edited, be sure to publish your changes. 
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={manageNat} alt='Manage NAT rules of IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    Manage NAT rules of your Isolated Virtual Environment.<br></br>
                    NAT rules redirect traffic from for example your public IP on the outside to your Webserver on the inside.<br></br>
                    When added, deleted or edited, be sure to publish your changes.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={manageIpsec} alt='Manage IPsec sites of IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    Manage IPsec site VPN of your Isolated Virtual Environment.<br></br>
                    IPsec sites are used to connect your internal network VLANs to for example your on premise local network.<br></br>
                    When added, deleted or edited, be sure to publish your changes.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={iveChangelog} alt='See changelog of IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    See the changelog your Isolated Virtual Environment.<br></br>
                    A brief overview of all the tasks, initiators and details of the tasks of the corresponding IVE.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={iveReport} alt='See reports of IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    Download GxP-Cloud reports of your Isolated Virtual Environment.<br></br>
                    Every historicly published report corresponding to the selected IVE, is found here.
                  </Col>
                </Row>
              </Grid>
            </Panel>
          ,
          size: 'lg'
        }
        case 'HOME_DASHBOARD':
        return {
          title: 'GxP-Cloud Home Dashboard explanation.',
          content: 
          <Panel>
            <Grid fluid>
              <Row style={{ height: '100px', marginTop: '5px' }}>
                <Col md={12} style={styles.cols}><img style={{ width: '100%', height: '100px' }} src={homeBegin} alt='IVE info' /></Col>
                <Col md={12} style={styles.cols}>
                    Here is the navigation side bar through out the GxP-Cloud.<br></br>
                    On the right side is your Asset tree in which you can find your Isolated Virtual Environments and their 
                    associated Networks and Virtual Machines. Use the <strong style={{ color: 'blue' }}>IVE Info</strong> Button to navigate to the
                    corresponding Isolated Virtual Environment details Dashboard.
                </Col>
              </Row>

              <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={homeStart} alt='Start with IVE' /></Col>
                  <Col md={12} style={styles.cols}>
                    When first login and no Isolated Virtual Environment present:<br></br>
                    This button will show up in your Asset tree. To start working and deploying in the GxP-Cloud CMC, start by deploying your IVE here.<br></br>
                    You will be redirected to the Create IVE page.
                  </Col>
                </Row>

              <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={homeTask} alt='Tasks Log' /></Col>
                  <Col md={12} style={styles.cols}>
                    Here your find all task history from your Company group members.<br></br>
                    Recent tasks will show you tasks executed last week. Where the 'All Tasks' tab shows all.<br></br>
                    Details of the tasks can be found at the info buttons. <br></br>
                    This example is a declined task.
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={{ height: '140px', marginTop: '5px' }}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={homeAppro} alt='Approvals' /></Col>
                  <Col md={12} style={styles.cols}>
                    Here your find tasks pending for your approval.<br></br>
                    Company members can ask for approval to execute a task. 
                    One reason can be that the member asking for approval is not qualified to assess the impact of the given task.<br></br>
                    Details of the tasks can be found at the detail button. <br></br>
                  </Col>
                </Row>

                <Divider></Divider>
                <Row style={styles.rows}>
                  <Col md={12} style={styles.cols}><img style={{ width: '100%' }} src={homeAudit} alt='Approvals' /></Col>
                  <Col md={12} style={styles.cols}>
                    Here your find your personal audit log.<br></br>
                    The Audit Log keeps track of what you are doing. This is Log will be expanded in the future.
                  </Col>
                </Row>


            </Grid>
          </Panel>
          ,
          size: 'lg'
        }
    }
  }

  render() {
    const { showHelp, backdrop, clearHelpModalOpenState } = this.props
    const { title, content, size } = this.getHelpModalConfig()

    return (
      <HelpDialogComponent
        title={title}
        content={content}
        showHelp={showHelp}
        backdrop={backdrop}
        size={size}
        clearHelpModalOpenState={clearHelpModalOpenState}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  showHelp: state.getIn(['helpDialogModal', 'showHelp']),
  backdrop: state.getIn(['helpDialogModal', 'backdrop']),
  settings: state.getIn(['helpDialogModal', 'settings'])
})

export const HelpDialogModal = connect(mapStateToProps, {
  setHelpModalOpenState,
  clearHelpModalOpenState
})(ToJs(HelpDialogContainer))
