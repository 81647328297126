import React from 'react';

import { Row, Col, Grid, Panel, Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

import { IpsecSiteRow } from './IpsecSiteRow';
import { IpsecPeerConfig } from './peerconfig/IpsecPeerConfigContainer';

export const IpsecTable = (props: {|
  +ipsecSitesMutated: Boolean,
  +ipsecSites: ipsecSites,
  +deleteIpsecSite: Function,
  +ive: Ive,
  +iveId: string
|}) => {
  return (
    <span>
      <Panel header={<strong>Configured IPsec site(s)</strong>}>
        <Grid fluid>
          <Row style={{ fontSize: '12px' }}>
            <Col md={4}>Name</Col>
            <Col md={4}>IVE</Col>
            <Col md={3}>Local Subnets</Col>
            <Col md={2}></Col>
            <Col md={3}>Peer IPaddress</Col>
            <Col md={4}>Peer Subnets</Col>
            <Col md={3}>Delete IPsec Site</Col>
          </Row>
          {props.ipsecSites.map((site, index) => {
            return (
              <IpsecSiteRow
                ive={props.ive}
                deleteIpsecSite={props.deleteIpsecSite}
                iveId={props.iveId}
                index={index}
                key={`ipsec-site-${index}`}
                site={site}
                ipsecSites={props.ipsecSites}
              />
            )
          })}
        </Grid>
        {!props.ipsecSitesMutated ? (
          <IpsecPeerConfig
            iveId={props.ive.id}
            ive={props.ive}
          >
            <div 
              style={{ marginTop: "15px", display: "flex", justifyContent: "center"}}
            >
              <Button
                appearance="link"
                disabled={!props.ipsecSites.length}
              >
                Ipsec Peer configurations
              </Button>
            </div>          
          </IpsecPeerConfig>
        ) : (' ')}
      </Panel>
    </span>
  )
}
