// @flow
import React from 'react'
import moment from 'moment-timezone'

import { ButtonToolbar, Button, Panel, Row, Col } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
  +ive: ReportOverview,
  +timezone: string,
  +getIveReportRequest: Function,
  +getVmReportRequest: Function
|}

export const IveReportsOverviewCard = (props: Props) => {
  const { ive, timezone, getIveReportRequest, getVmReportRequest } = props

  return (
    <Panel header={<h4>{`Latest released QA documents for IVE ${ive.name}`}</h4>}>
      {ive.iveReports.length
        ? [
            ive.iveReports.map((iveReport, reportIndex) => (
              <span>
                {reportIndex === 0 && (
                  <Row style={{ marginBottom: '5px' }}>
                    <Col md={3}>Document ID</Col>
                    <Col md={6}>Type</Col>
                    <Col md={2}>Version</Col>
                    <Col md={3}>Status</Col>
                    <Col md={4}>Created</Col>
                    <Col md={3}>Download</Col>
                  </Row>
                )}
                <Row style={{ marginBottom: '5px' }}>
                  <Col md={3}>
                    <strong>{iveReport.documentId}</strong>
                  </Col>
                  <Col md={6} colSpan={2}>
                    <strong>{iveReport.type}</strong>
                  </Col>
                  <Col md={2}>
                    <strong>{iveReport.version}</strong>
                  </Col>
                  <Col md={3}>
                    <strong>{iveReport.status}</strong>
                  </Col>
                  <Col md={4}>
                    <strong>
                      {moment
                        .tz(iveReport.createdDatetime, timezone)
                        .format('DD MMM YYYY HH:mm:ss zz')}
                    </strong>
                  </Col>
                  <Col md={3}>
                    <ButtonToolbar>
                      <Button
                        color="blue"
                        appearance="ghost"
                        label="Download"
                        onClick={() =>
                          getIveReportRequest({ url: iveReport.pdf })
                        }
                      >
                        Download
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </span>
            )),

            ive.vmReports.map((vmReport, reportIndex) => (
              <span>
                <div
                  style={{
                    fontSize: '15px',
                    marginTop: '10px',
                    marginBottom: '8px'
                  }}
                >
                  QA documents for VM {vmReport.vm.name}
                </div>
                <Row style={{ marginBottom: '5px' }}>
                  <Col md={3}>Document ID</Col>
                  <Col md={6}>Type</Col>
                  {/* <Col md={4}>VM</Col> */}
                  <Col md={2}>Version</Col>
                  <Col md={3}>Status</Col>
                  <Col md={4}>Created</Col>
                  <Col md={3}>Download</Col>
                </Row>
                <Row style={{ marginBottom: '5px' }}>
                  <Col md={3}>
                    <strong>{vmReport.documentId}</strong>
                  </Col>
                  <Col md={6}>
                    <strong>{vmReport.type}</strong>
                  </Col>
                  {/* <Col md={4}><strong>{vmReport.vm.name}</strong></Col> */}
                  <Col md={2}>
                    <strong>{vmReport.version}</strong>
                  </Col>
                  <Col md={3}>
                    <strong>{vmReport.status}</strong>
                  </Col>
                  <Col md={4}>
                    <strong>
                      {moment
                        .tz(vmReport.createdDatetime, timezone)
                        .format('DD MMM YYYY HH:mm:ss zz')}
                    </strong>
                  </Col>
                  <Col md={3}>
                    <ButtonToolbar>
                      <Button
                        appearance='ghost'
                        color='blue'
                        label='Download'
                        onClick={() =>
                          getVmReportRequest({ url: vmReport.pdf })
                        }
                      >
                        Download
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </span>
            ))
          ]
        : 'There are no reports for this IVE.'}
    </Panel>
  )
}
