// @flow

import React from 'react'
import { Icon } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { DnatDialog } from './dialog'

const style = {
  icon: { cursor: 'pointer' }
}

type Props = {|
  +id: string,
  +dialogAction: string,
  +editRule: Function,
  +rule: DnatRule,
  +rules: DnatRules,
  +iveId: string
|}

export const EditRuleButton = (props: Props) => {
  const { editRule, rule, rules, iveId } = props
  return (
      <DnatDialog
        dialogAction="Edit"
        submit={editRule}
        rule={rule}
        rules={rules}
        iveId={iveId}
      >
        <Icon
          icon="edit2"
          size="lg"
          type="edit"
          id="edit-dnat-rules"
          style={style.icon}
        />
      </DnatDialog>
  )
}
