import React, { Component } from 'react'

import {
    Modal,
    Button
  } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

const mapStateToProps = (state) => ({
    groupProfileStatus: state.getIn(['account', 'groupInfo', 'groups', '0', 'status'])
  })

class CustomerDunningContainer extends Component {

    state = { show: true }

    constructor(props) {
      super()
    }

    toggleOpenState = () => {
      this.setState({ show: !this.state.show })
    }

    render() {

        return (
            this.props.groupProfileStatus && this.props.groupProfileStatus !== 'GS' ? (
            <Modal 
                backdrop="static" 
                show={
                  (this.props.groupProfileStatus === 'AL' || this.props.groupProfileStatus === 'AD') ||
                  ((this.props.groupProfileStatus === 'H' || this.props.groupProfileStatus === 'PS') && this.state.show === true)
                } 
                size="sm" 
            >
            <Modal.Header closeButton={false}>
                <Modal.Title>You are behind on pending payments</Modal.Title>
            </Modal.Header>
            {this.props.groupProfileStatus === 'H' ? (
              <div style={{ marginTop: "15px"}}> 
                You are behind for X weeks with your payments. There are no active effect right now. <br/>
                Actions will be taken if you dont take action in X ammount of time.
              </div>
            ) : null}
            {this.props.groupProfileStatus === 'PS' ? (
              <div style={{ marginTop: "15px"}}>
                You are behind for X+1 weeks with your payments. There are no active effect right now. <br/>
                Actions will be taken if you dont take action in 1 week.
              </div>
            ) : null}
            {this.props.groupProfileStatus === 'AL' ? (
              <div style={{ marginTop: "15px"}}>
                You are behind for X+2 weeks with your payments. <br/>
                <strong style={{ color: "red" }}>Your Assets are Locked now.</strong><br/>
                Your Assets are still running but are locked out for you to manage. <br/>
                Please contact customer support.
              </div>
            ) : null}
            {this.props.groupProfileStatus === 'AD' ? (
              <div style={{ marginTop: "15px"}}>
                You are behind for X+2 years with your payments. <br/>
                <strong style={{ color: "red" }}>Your Assets are Deleted now.</strong><br/>
                Your Assets are deleted from the GxP-Cloud. <br/>
                If you settle within your History profile Retention period, our team can maybe help you restore your assets.
                Please contact customer support.
              </div>
            ) : null}
            <Modal.Footer>
              {this.props.groupProfileStatus === 'H' || this.props.groupProfileStatus === 'PS' ? (
                <Button 
                  onClick={this.toggleOpenState} 
                  color="red"
                >
                  I Agree
                </Button>
              ) : ('')}
            </Modal.Footer>
        </Modal>
        ) : null
    )}

}

export const CustomerDunning = connect(
    mapStateToProps,
    ({
    }: ReduxActions)
)(ToJs(CustomerDunningContainer))