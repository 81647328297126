// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import moment from 'moment-timezone'

import { TasksListComponent } from './TasksListComponent'
import { ToJs } from 'utils/ToJs'
import { getTasksRequest, deleteTask } from 'modules/operations/tasks'
import { getAuditLogRequest } from 'modules/operations/account'

type ReduxState = {| +timezone: string, +tasks: Task[], +detail: ?any |}
type ReduxActions = {|
  +getTasksRequest: Function,
  +getAuditLogRequest: Function,
  +deleteTask: Function
|}
type Props = ReduxState &
  ReduxActions & {|
    +task: Task,
    +routing: Routing,
    +history: History,
    +match: Match
  |}
type State = {|
  taskDeleteOpen: boolean
|}
const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    tasks: state.getIn(['tasks', 'items']),
    auditLog: state.getIn(['account', 'auditLog'])
  }: ReduxState)

class TasksContainer extends Component<Props> {
  props: Props
  state: State

  state = { taskDeleteOpen: false }

  getFilter = (pathname: string) =>
    pathname.replace('/dashboard/', '').replace('/dashboard', 'recent')

  componentDidMount() {
    this.props.getTasksRequest()
    if (this.refs.tasksComponent) {
      const { history, match } = this.props
      const selectedFilter = this.getFilter(match.path)
      history.replace(`/dashboard/${selectedFilter}`)
    }
    this.props.getAuditLogRequest()
  }

  handleDeleteTask = (taskId: string) => this.props.deleteTask({ taskId })

  toggleOpenState = () => {
    this.setState({ taskDeleteOpen: !this.state.taskDeleteOpen })
  }

  render() {
    const { timezone, history, tasks } = this.props
    const selectedFilter = this.getFilter(history.location.pathname)
    const lastWeek = moment()
      .tz(timezone ? timezone : 'UTC')
      .subtract(7, 'days')

    const Tasks = () => (
      <TasksListComponent
        tableData={
          selectedFilter === 'recent'
            ? tasks.filter((task) => {
                const created = moment.tz(
                  task.createdDatetime,
                  timezone ? timezone : 'UTC'
                )
                const duration = moment
                  .duration(created.diff(lastWeek))
                  .asDays()
                return duration > 0
              })
            : tasks
        }
        timezone={timezone}
        selectedFilter={selectedFilter}
        history={history}
        handleDeleteTask={this.handleDeleteTask}
        toggleOpenState={this.toggleOpenState}
        taskDeleteOpen={this.state.taskDeleteOpen}
      />
    )

    return (
      <div ref="tasksComponent">
        <Route
          path={[
            '/dashboard/recent',
            // '/dashboard/success',
            // '/dashboard/failed',
            // '/dashboard/iq-failed',
            // '/dashboard/started',
            // '/dashboard/pending',
            // '/dashboard/pending-approval',
            '/dashboard/all'
          ]}
          component={Tasks}
        />
      </div>
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Tasks = connect(
  mapStateToProps,
  ({
    getTasksRequest,
    getAuditLogRequest,
    deleteTask
  }: ReduxActions)
)(ToJs(TasksContainer))
