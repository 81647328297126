import React, { Component } from 'react'

import {
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { StringType } = Schema.Types
const model = Schema.Model({
  password: StringType().isRequired('This field is required.'),
  newPassword: StringType()
    .isRequired('This field is required.')
    .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Passwords: >8 characters, upper- & lowercase, number and special !@#$&*')
    .rangeLength(8,30, 'The number of characters can only be between 8 and 30.'),
  repeatedPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.newPassword) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
    .isRequired('This field is required.')
})

function TextField(props) {
  const { name, label, type, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <FormControl name={name} accepter={accepter} type={type} {...rest} />
    </FormGroup>
  )
}

type Props = {|
  +handleChange: AccountPasswordHandleChange,
  +formData: AccountPasswordState,
  +putChangePassword: Function,
  +onKeyDown: Function,
  +capsLockWarning: Boolean,
  +currentPasswordRef: string
|}
// var changePasswordForm = useRef(null)
class AccountPasswordComponent extends Component<Props> {
  props: Props

  render() {
    return (
        <Form
          autoComplete="off"
          model={model}
          ref={(ref) => (this.changePasswordForm = ref)}
          checkTrigger="none"
        >
          <TextField
            autoFocus
            ref={this.props.currentPasswordRef}
            id="account-password-current"
            label="Current password"
            onChange={(currentPassword) => {
              this.props.handleChange({ currentPassword })
            }}
            onKeyDown={this.props.onKeyDown}
            type="password"
            name="password"
            value={this.props.formData.currentPassword}
          />
          <TextField
            id="account-password-new"
            label="New password"
            onChange={(newPassword) => {
              this.props.handleChange({ newPassword })
            }}
            onKeyDown={this.props.onKeyDown}
            type="password"
            name="newPassword"
            value={this.props.formData.newPassword}
          />
          <TextField
            id="account-password-repeated"
            label="Repeat password"
            onChange={(repeatedPassword) => {
              this.props.handleChange({ repeatedPassword })
            }}
            onKeyDown={this.props.onKeyDown}
            type="password"
            name="repeatedPassword"
            value={this.props.formData.repeatedPassword}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '-8px'
            }}
          >
            
            <Button
              id="account-password-submit"
              label="Submit"
              type="submit"
              appearance="ghost"
              color='blue'
              onClick={(e) => {
                if (this.changePasswordForm.check()) {
                  e.preventDefault()
                  this.props.putChangePassword()
                }
              }}
            >
              Submit Change
            </Button>
            {this.props.capsLockWarning ? (
              <span style={{ margingRight: '100px', color: '#ff0000' }}>
                Caps Lock is On!
              </span>
            ) : (
              <div></div>
            )}
          </div>
        </Form>
    )
  }
}

export { AccountPasswordComponent }
