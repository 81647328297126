// @flow

import React, { useRef } from 'react'
import {
  Button,
  ControlLabel,
  Schema,
  Form,
  FormGroup,
  FormControl,
  Modal,
  TagPicker,
  CheckPicker,
  Divider
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { StringType, ArrayType } = Schema.Types
const model = Schema.Model({
  name: StringType().isRequired('This field is required.'),
  localSubnets: ArrayType().isRequired('This field is required.'),
  peerIp: StringType()
    .isRequired('This field is required.')
    .pattern(
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
      'No Valid IPv4 Address'
    ),
  peerSubnets: ArrayType()
    .isRequired('This field is required.')
    .addRule((value) => {
      const subnetRegExp = new RegExp(
        /\b((([1-9](?:\s*[0-9]){0,2}\s*)\.(\s*[0-9](?:\s*[0-9]){0,2}\s*)\.(\s*[0-9](?:\s*[0-9]){0,2})\s*)\.(0)\/((2[4-9]|3[0-2])))|((([1-9](?:\s*[0-9]){0,2}\s*)\.(\s*[0-9](?:\s*[0-9]){0,2}\s*)\.(0)\.(0))\/((1[6-9]|2[0-3])))|((([1-9](?:\s*[0-9]){0,2}\s*)\.(0)\.(0)\.(0))\/(([89]|1[0-5])))|(((0)\.(0)\.(0)\.(0))\/(([1-7])))\b/
      )
      var check = true
      value.forEach((subnet) => {
        if (subnetRegExp.test(subnet) === false) {
          check = false
        }
      })
      return check
    }, 'No valid IPv4 CIDR subnet'),
  password: StringType()
    .isRequired('This field is required.')
    .rangeLength(
      8,
      30,
      'The number of characters can only be between 8 and 30'
    ),
  repeatedPassword: StringType()
    .isRequired('This field is required.')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
})

function InputField(props) {
  const { name, label, type, accepter, data, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        type={type}
        data={data}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
  +textRef: Object,
  +children: React$Element<any>,
  +dialogAction: string,
  +iveId: string,
  +ive: Ive,
  +ipsecSite: IpsecSite,
  +handleNameChange: Function,
  +handleOnChangeLocalSubnets: Function,
  +handleOnSelectPeerSubnets: Function,
  +handleOnChangePeerSubnets: Function,
  +handleOnChangePeerIp: Function,
  +handleOnChangePsk: Function,
  +handleOnChangeRepeatedPsk: Function,
  +handleValidate: Function,
  +toggleOpenState: Function,
  +onKeyDown: Function,
  +capsLockWarning: Boolean,
  +isOpen: boolean,
  +submit: Function
|}

export const IpsecFormComponent = (props: Props) => {
  const dialogTitle = props.dialogAction + ' IPsec Site'
  var ipsecForm = useRef(null)

  return (
    <div style={{ display: 'inline' }}>
      {React.cloneElement(props.children, {
        onClick: props.toggleOpenState
      })}
      <Modal backdrop="static" show={props.isOpen} size="sm">
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {dialogTitle}
          </Modal.Title>
        </Modal.Header>
        <Form
          model={model}
          ref={(ref) => (ipsecForm = ref)}
          fluid
          checkTrigger="none"
        >
          <Divider><strong>Local Site</strong></Divider>
          <InputField
            autoFocus
            id="name"
            name="name"
            label="IPsec Site name"
            onChange={props.handleNameChange}
            value={props.ipsecSite.name}
          />
          <InputField
            id="localSubnets"
            name="localSubnets"
            label="Local Subnets"
            labelKey="network"
            valueKey="network"
            onChange={props.handleOnChangeLocalSubnets}
            type="localSubnets"
            value={props.ipsecSite.localSubnets}
            style={{ width: 'inherit' }}
            data={props.ive.networks}
            searchable={false}
            accepter={CheckPicker}
          />
          <Divider><strong>Peer Site</strong></Divider>
          <InputField
            id="peerIp"
            name="peerIp"
            label="Peer IP Address"
            onChange={props.handleOnChangePeerIp}
            type="peerIp"
            value={props.ipsecSite.peerIp}
          />
          <InputField
            id="peerSubnets"
            name="peerSubnets"
            label="Peer Subnets"
            onChange={props.handleOnChangePeerSubnets}
            type="peerSubnets"
            value={props.ipsecSite.peerSubnets}
            creatable
            style={{ width: 'inherit' }}
            accepter={TagPicker}
          />
          <Divider><strong>Security</strong></Divider>
          <InputField
            id="psk"
            name="password"
            label="Pre Shared Key"
            onKeyDown={props.onKeyDown}
            onChange={props.handleOnChangePsk}
            type="password"
            value={props.ipsecSite.psk}
          />
          <InputField
            id="RepeatedPsk"
            name="repeatedPassword"
            label="Repeated Pre Shared Key"
            onKeyDown={props.onKeyDown}
            onChange={props.handleOnChangeRepeatedPsk}
            type="password"
            value={props.ipsecSite.repeatedPsk}
          />
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          {props.capsLockWarning ? (
              <span style={{ margingRight: '100px', color: '#ff0000' }}>
                Caps Lock is On!
              </span>
            ) : (
              <div></div>
            )}
            <Modal.Footer>
              <Button onClick={props.toggleOpenState} color="red">
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                id="submit"
                onClick={(e) => {
                  if (ipsecForm.check()) {
                    e.preventDefault()
                    props.submit()
                  }
                }}
              >
                {props.dialogAction}
              </Button>
            </Modal.Footer>
          </span>
        </Form>
      </Modal>
    </div>
  )
}
