// @flow

import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { connect } from 'react-redux'

import { Account } from './account'
import { IveDetails } from './ives/detail/IveDetailsContainer'
import { Tasks } from './tasks'
import { Approvals } from './approvals'
import { Overview } from './overview/OverviewContainer'
import { NotFound } from 'components/NotFound'
import { Sidebar } from 'components/Sidebar'
import { SetPasswordDialog } from 'components/SetPasswordDialog'
import { SubmitDialog } from 'components/SubmitDialog'
import { Modal } from 'components/Modal'
import { SetElectronicSignatureDialog } from 'components/SetElectronicSignatureDialog'
import { InternalAgreeTermsDialog } from 'components/InternalAgreeTermsDialog'
import { Websocket } from 'components/Websocket'
import { ToJs } from 'utils/ToJs'
import { Loading } from '../components/Loading'
import { AssetsTree } from '../components/AssetsTree'
import { IveCatalogue } from './ivecatalog'
import { VmCatalogue } from './vmcatalogue'
import { CreateIveForm } from './createive'
import { CreateVmForm } from './createvm'
import { Statistics } from './statistics'
import { SetCreditCardDialog } from './creditcardsignup/SetCreditCardDialog'
import { CustomerDunning } from '../components/CustomDunning/CustomerDunning'
import { Billing } from './billing'
import { Help } from './help/Help'
import { Management } from './management'
import { HelpDialogModal } from 'components/HelpCharacter/HelpDialog/HelpDialogContainer';
import { ActivateCustomer } from '../components/ActivateCustomer/ActivateCustomer';

const style = {
  main: {
    marginLeft: '188px',
    padding: '32px 32px 32px 382px', //32 for no tree (now 350 +32)
    backgroundColor: '#EBEBEB',
    height: '100vh',
    width: 'calc(100% - 156px)'
  },
  background: {
    minHeight: '100vh',
    minWidth: 'calc(100vw - 188px)'
  }
}
// styles background creates window full height and full width minus sidebar (192px) -62 now
// styles main takes background size, padding right 32px >> width is calc(100%(from background) - 160px) (192px -32px from padding)

type Props = {|
  +getAccountRequest: Function,
  +getApproversRequest: Function,
  +account: Object,
  +errors: Object,
  +router: Object,
  +fetch: Object
|}

const mapStateToProps = (state) => ({
  account: state.getIn(['account']),
  errors: state.getIn(['accountInfoForm', 'errors']),
  router: state.get('router') // needs router prop to trigger re-render on route changes
})

class FrameContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getAccountRequest()
    this.props.getApproversRequest()
    if (document.body) {
      document.body.style.backgroundSize = '0%'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { username } = this.props.account
    const { prevUsername } = prevProps.account
    if (username !== prevUsername) {
      Sentry.configureScope((scope) => {
        scope.setUser({ username })
      })
    }
  }

  render() {
    return (
      <div style={style.background}>
        <Loading />
        <SubmitDialog />
        <Modal />
        <HelpDialogModal />
        <Sidebar appearance="subtle" />
        <AssetsTree />
        <Websocket />
        <main style={style.main}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/login" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/account" component={Account} />
            <Route path="/billing" component={Billing} />
            <Route path="/help" component={Help} />
            <Route path="/ive-catalogue" component={IveCatalogue} />
            <Route path="/ives/:iveId/vm-catalogue" component={VmCatalogue} />
            <Route path="/createive" component={CreateIveForm} />
            <Route path="/ives/:iveId/vm" component={CreateVmForm} />
            <Route path="/statistics" component={Statistics} />
            <Route path="/management" component={Management} />
            <Route exact path="/ives/:iveId" component={IveDetails} />
            <Route path="/dashboard" component={Tasks} />
            <Route path="/approvals" component={Approvals} />
            <Route exact path="/overview" component={Overview} />
            {/* 
                When a authenticated user want to activate account, 
                this does logout user and redirects to activate-customer external.
            */}
            <Route exact path="/activate-customer/:customeridb64" component={ActivateCustomer}/>
            <Route path="*" component={NotFound} />
          </Switch>
          <SetCreditCardDialog />
          <SetPasswordDialog />
          <SetElectronicSignatureDialog />
          <InternalAgreeTermsDialog />
          <CustomerDunning />
        </main>
      </div>
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Frame = connect(mapStateToProps)(ToJs(FrameContainer))
