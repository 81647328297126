import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { BlueprintDialogComponent } from './BlueprintDialogComponent'
import { getBlueprintRequest } from 'modules/operations/blueprints'
import { getDatacentersRequest } from 'modules/operations/datacenters'

const mapStateToProps = state => ({
    blueprint: state.getIn(['blueprints', 'blueprint']),
    datacenters: state.getIn(['datacenters'])
})


type Props = {|
  +setSubmitDialogOpenState: Function,
  +getBlueprintRequest: Function,
  +getDatacentersRequest: Function,
  +blueprintItem: Object,
  +children: React$Element<any>
|}

class BlueprintDialogContainer extends Component<Props, State> {
    props: Props
    state: State
  
    state = {
      isOpen: false,
      datacenters: [],
      blueprintName: '',
      description: '',
      datacenter: undefined,
      blueprint: {}
    }

    handleChange = (change) => {
        this.setState({ ...this.state, ...change })
      }

    handleOnChangeLocation = (value: IdentityObject, item: IdentityObject) => {
      this.setState({ ...this.state.datacenter, datacenter: item })
    }

    toggleOpenState = () => {
        this.setState({ isOpen: !this.state.isOpen })
        if (this.props.blueprintItem)  {
            const blueprintId = this.props.blueprintItem.id
            this.props.getBlueprintRequest({ blueprintId })
            this.props.getDatacentersRequest()
        }
    }  

    render() {
        return(
            <BlueprintDialogComponent
                children={this.props.children}
                blueprintName={this.state.blueprintName}
                description={this.state.description}
                datacenters={this.props.datacenters}
                datacenter={this.state.datacenter}
                handleChange={this.handleChange}
                handleOnChangeLocation={this.handleOnChangeLocation}
                toggleOpenState={this.toggleOpenState}
                isOpen={this.state.isOpen}
                blueprintItem={this.props.blueprintItem}
                blueprint={this.props.blueprint}
                setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
            />
        )
    }
}  

export const BlueprintDialog = connect(
    mapStateToProps,
    {
      getBlueprintRequest,
      getDatacentersRequest
    }
  )(ToJs(BlueprintDialogContainer))
  
  
  
  