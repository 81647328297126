// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'
import { put, select } from 'redux-saga/effects'

const defaultState = fromJS({
  isTaskDeleting: false,
  items: [],
  item: {}
})

const moduleName = 'tasks'

const {
  tasks,
  sagas,
  getTasksRequest,
  getTasksRequestFailure,
  getTaskRequest,
  getTaskRequestFailure,
  deleteTask,
  setDeleteTaskPending,
  deleteTaskRequest,
  deleteTaskRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_TASKS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/tasks/'
        })
      ],
      reducer: (state) => state,
      onError: (e) => getTasksRequestFailure(e)
    },
    GET_TASKS_REQUEST_SUCCESS: (state, action) =>
      state.set('items', fromJS(action.response.results)),
    GET_TASKS_REQUEST_FAILURE: (state) => state,
    GET_TASK_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/tasks/${payload.taskId}/`
        })
      ],
      onError: (e) => getTaskRequestFailure(e)
    },
    GET_TASK_REQUEST_SUCCESS: (state, action) =>
      state.set('item', fromJS(action.response)).set('isTaskDeleting', false),
    GET_TASK_REQUEST_FAILURE: (state) => state,
    SET_DELETE_TASK_PENDING: (state) => state.set('isTaskDeleting', true),
    DELETE_TASK: {
      saga: function* (action) {
        const state = yield select((state) => state.get('tasks'))
        if (!state.get('isTaskDeleting')) {
          yield put(setDeleteTaskPending())
          yield put(deleteTaskRequest(action.payload))
        }
      }
    },
    DELETE_TASK_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'DELETE',
          endpoint: `/tasks/${payload.taskId}/`
        })
      ],
      onError: (e) => deleteTaskRequestFailure(e)
    },
    DELETE_TASK_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(getTasksRequest())
      }
    },
    DELETE_TASK_REQUEST_FAILURE: (state) => state.set('isTaskDeleting', false)
  },
  defaultState
)

export { tasks, sagas, getTasksRequest, getTaskRequest, deleteTask }
