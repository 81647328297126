// @flow

import React from 'react'

import { Row, Col } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const TYPE_STRING_MAPPING = {
  vmHardwareChange: 'Hardware change details',
  blueprint: 'Blueprint',
  datacenter: 'Datacenter',
  description: 'Description',
  historyProfile: 'History profile',
  availabilityProfile: 'Availability profile',
  ip: 'IP address',
  network: 'Network',
  vmTemplate: 'VM template',
  cpuCores: 'CPU cores',
  memory: 'Memory',
  disk: 'Disk',
  username: 'Username',
  firewallRule: 'Firewall rule',
  name: 'Name',
  enabled: 'Enabled',
  matchTranslated: 'Match translated',
  sources: 'Sources',
  destinations: 'Destinations',
  applications: 'Applications',
  snatRule: 'Source NAT rule',
  ipsecSite: 'IPsec sites',
  virtualNic: 'Virtual NIC',
  dnatRule: 'Destination NAT rule',
  vm: 'Virtual Machine',
  originalPortFrom: 'Original port from',
  originalPortTo: 'Original port to',
  translatedPortFrom: 'Translated port from',
  translatedPortTo: 'Translated port to',
  protocol: 'Protocol'
}

type TaskDetailType =
  | 'cpuCores'
  | 'memory'
  | 'disk'
  | 'blueprint'
  | 'datacenter'
  | 'availabilityProfile'
  | 'historyProfile'
  | 'network'
  | 'vmTemplate'
  | 'description'
  | 'username'
  | 'ip'
  | 'firewallRule'
  | 'snatRule'
  | 'dnatRule'
  | 'ipsecSite'

type TaskDetailValue = {|
  +oldValue: string,
  +newValue: string,
  +action: string,
  +name: string
|} & []

const mapValueToString = (
  type: TaskDetailType,
  value: TaskDetailValue,
  executed: boolean
) => {
  switch (type) {
    case 'cpuCores':
      return `${executed ? 'Changed' : 'Will change'} from ${
        value.oldValue
      } to ${value.newValue}`
    case 'memory':
      return `${executed ? 'Changed' : 'Will change'} from ${
        value.oldValue
      } GB to ${value.newValue} GB`
    case 'disk':
      switch (value.action) {
        case 'create':
          return `Disk ${value.name} ${
            executed ? 'created' : 'will be created'
          } with ${value.newValue} GB`
        case 'edit':
          return `Disk ${value.name} ${
            executed ? 'changed' : 'will change'
          } size from ${value.oldValue} GB to ${value.newValue} GB`
        case 'destroy':
          return `Disk ${value.name} ${
            executed ? 'was deleted' : 'will be deleted'
          }`
        default:
          console.error(`Unknown disk action ${value.action}`)
          break
      }
      break
    case 'blueprint':
    case 'datacenter':
    case 'availabilityProfile':
    case 'historyProfile':
    case 'network':
    case 'vmTemplate':
      return value.name
    case 'description':
    case 'username':
    case 'ip':
      return value
    case 'firewallRule':
    case 'snatRule':
    case 'ipsecSite':
    case 'dnatRule':
      return (
        <div>
          {value // A value here is an object literal for an entire rule
            .filter((ruleItem) => {
              // Filter out rule items which have an empty array as value
              const [ruleValue] = Object.values(ruleItem)
              return Array.isArray(ruleValue) ? ruleValue.length : true
            })
            .map((ruleItem) => {
              const [[ruleKey, ruleValue]] = Object.entries(ruleItem)
              const ruleValueString = parseMixedToString(ruleValue) || ''
              return (
                <div key={`rule-${ruleKey}`}>
                  {`${TYPE_STRING_MAPPING[ruleKey]}: ${ruleValueString}`}
                </div>
              )
            })}
        </div>
     )
    default:
      console.error(`Unknown task detail type ${type}`)
      break
  }
}

const parseMixedToString = (mixedObj: mixed): string => {
  if (Array.isArray(mixedObj)) {
    return mixedObj.join(', ')
  } else if (mixedObj instanceof Object) {
    return mixedObj.name
  } else if (typeof mixedObj === 'boolean') {
    return mixedObj.toString()
  } else if (typeof mixedObj === 'string') {
    return mixedObj
  } else {
    throw console.error(
      `Type ${typeof mixedObj} is not supported by parseMixedToString function`
    )
  }
}

const TaskDetailRow = (props: {|
  +type: TaskDetailType,
  +value: TaskDetailValue,
  +executed: boolean,
  +colSpan: number
|}) => {
  switch (props.type) {
    case 'firewallRule':
    case 'snatRule':
    case 'ipsecSite':
    case 'dnatRule':
      return (
        <div>
          <Row>
            <strong>{`${TYPE_STRING_MAPPING[props.type]} configuration.`}</strong>  
          </Row>
          <Row>
            <Col md={10}>
              <div style={{ marginTop: '5px' }}>
                {props.value
                  .filter((item) => {
                    const [value] = Object.values(item)
                    return Array.isArray(value) ? value.length : true
                  })
                  .map((item) => {
                    const [key] = Object.keys(item)
                    return (
                      <div key={`rule-${key}`}>{TYPE_STRING_MAPPING[key]}</div>
                    )
                  })}
              </div>
            </Col>
            <Col md={14}>
              <strong>
                <div style={{ marginTop: '5px' }}>
                  {props.value
                    .filter((item) => {
                      const [itemValue] = Object.values(item)
                      return Array.isArray(itemValue) ? itemValue.length : true
                    })
                    .map((item, index) => {
                      // to be  continued with rework of task details
                      const [itemValue] = Object.values(item)
                      const valueString = parseMixedToString(itemValue)
                      if (valueString.includes('[object Object]')) {
                        itemValue.map((item, index) => {
                          // console.log(item.name)
                          return <div key={`value-${index}`}>{item.name}</div>
                        })
                      } else {
                        return <div key={`value-${index}`}>{valueString}</div>
                      }
                      return <div key={`value-${index}`}>{valueString}</div>
                    })}
                </div>
              </strong>
            </Col>
          </Row>
        </div>
      )
    default:
      return (
        <Row>
          <Col md={10}>{TYPE_STRING_MAPPING[props.type]}</Col>
          <Col md={14}>
            <strong>
              {mapValueToString(props.type, props.value, props.executed)}
            </strong>
          </Col>
        </Row>
      )
  }
}

TaskDetailRow.defaultProps = { executed: true, colSpan: 1 }

export { TaskDetailRow }
