import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AccountComponent } from './AccountComponent'
import { ToJs } from 'utils/ToJs'

class AccountContainer extends Component<Props> {
  render() {
    return (
      <AccountComponent key="accountcomponent" />
    )
  }
}

export const Account = connect(undefined)(
  ToJs(AccountContainer)
)
