// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS([])

const moduleName = 'datacenters'

const {
  datacenters,
  getDatacentersRequest,
  getDatacentersRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_DATACENTERS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/datacenters/'
        })
      ],
      reducer: (state) => state,
      onError: (e) => getDatacentersRequestFailure(e)
    },
    GET_DATACENTERS_REQUEST_SUCCESS: (state, action) => fromJS(action.response),
    GET_DATACENTERS_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export { datacenters, getDatacentersRequest }
