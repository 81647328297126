// @flow

import { fromJS } from 'immutable'
import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  items: []
})

const moduleName = 'approvers'

const {
  approvers,
  getApproversRequest,
  getApproversRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_APPROVERS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/approvers/'
        })
      ],
      onError: (e) => getApproversRequestFailure(e)
    },
    GET_APPROVERS_REQUEST_SUCCESS: (state, action) =>
      state.set('items', fromJS(action.response)),
    GET_APPROVERS_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(push('/not-found'))
      }
    }
  },
  defaultState
)

export { approvers, getApproversRequest }
