// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import arrayMove from 'array-move'

import { FirewallComponent } from './FirewallComponent'
import {
  getVnicGroupsRequest,
  getApplicationsRequest
} from 'modules/operations/networking'
import {
  getFirewallRequest,
  getIveVmsRequest,
  getIveVnicsRequest,
  setFirewallState,
  deleteFirewallState
} from 'modules/operations/ives'
import { ToJs } from 'utils/ToJs'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type ReduxState = {|
  +applications: IdentityObject[],
  +firewallRules: FirewallRules,
  +firewallRulesMutated: boolean,
  +vms: IdentityObject[],
  +vnicGroups: IdentityObject[],
  +vnics: IdentityObject[]
|}
type ReduxActions = {|
  +deleteFirewallState: Function,
  +getApplicationsRequest: Function,
  +getFirewallRequest: Function,
  +getIveVmsRequest: Function,
  +getIveVnicsRequest: Function,
  +getVnicGroupsRequest: Function,
  +setFirewallState: Function,
  +setSubmitDialogOpenState: Function
|}
type Props = ReduxState &
  ReduxActions & {| +errors: ?FirewallDialogErrors, +match: Match |}

const mapStateToProps = (state) =>
  ({
    applications: state.getIn(['networking', 'applications']),
    firewallRules: state.getIn(['ives', 'firewallRules']),
    firewallRulesMutated: state.getIn(['ives', 'firewallRulesMutated']),
    vms: state.getIn(['ives', 'vms']),
    vnicGroups: state.getIn(['networking', 'vnicGroups']),
    vnics: state.getIn(['ives', 'vnics'])
  }: ReduxState)

export class FirewallContainer extends Component<Props> {
  props: Props

  addFirewallRule: Function
  deleteFirewallRule: Function
  onSortEnd: Function

  componentDidMount() {
    const { iveId } = this.props
    this.props.getFirewallRequest({ iveId })
    this.props.getIveVmsRequest({ iveId })
    this.props.getIveVnicsRequest({ iveId })
    this.props.getVnicGroupsRequest()
    this.props.getApplicationsRequest()
  }

  onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number,
    newIndex: number
  }) => {
    const { immutable, user } = this.props.firewallRules
    const oldUserIndex = oldIndex - immutable.length
    const newUserIndex = newIndex - immutable.length
    const sortedUserRules = arrayMove(user, oldUserIndex, newUserIndex)
    const seed = immutable.length + 1
    const userRules = sortedUserRules.map((rule, index) => {
      rule.ruleTag = seed + index
      return rule
    })
    const rules = { immutable, user: userRules }
    this.props.setFirewallState(rules)
  }

  addFirewallRule = (rule: FirewallRule) => {
    const { firewallRules } = this.props
    rule.ruleTag =
      firewallRules.immutable.length + firewallRules.user.length + 1
    const immutable = firewallRules.immutable
    const user = firewallRules.user.concat([rule])
    const rules = { immutable, user }
    const seed = immutable.length + 1
    const userRules = rules.user.map((rule, index) => {
      rule.ruleTag = seed + index
      return rule
    })
    const newRules = { immutable, user: userRules }
    this.props.setFirewallState(newRules)
  }

  deleteFirewallRule = (index: number) => {
    this.props.deleteFirewallState(index)
  }

  render() {
    const { iveId } = this.props
    return [
      <FirewallComponent
        addFirewallRule={this.addFirewallRule}
        applications={this.props.applications}
        deleteFirewallRule={this.deleteFirewallRule}
        firewallRules={this.props.firewallRules}
        firewallRulesMutated={this.props.firewallRulesMutated}
        iveId={iveId}
        onSortEnd={this.onSortEnd}
        setFirewallState={this.props.setFirewallState}
        setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
        vms={this.props.vms}
        vnicGroups={this.props.vnicGroups}
        vnics={this.props.vnics}
        key="ive-firewall-rules"
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Firewall = connect(
  mapStateToProps,
  ({
    deleteFirewallState,
    getFirewallRequest,
    getIveVmsRequest,
    getIveVnicsRequest,
    getVnicGroupsRequest,
    getApplicationsRequest,
    setFirewallState,
    setSubmitDialogOpenState
  }: ReduxActions)
)(ToJs(FirewallContainer))
