// @flow

import { fromJS } from 'immutable'
import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'
import moducks from './../../moducks-bootstrap'

import { setTemplateCatalogFormErrorState } from '../components/templateCatalogForm'
import { setVmHardwareChangeFormErrorState } from '../components/vmHardwareChangeForm'

const defaultState = fromJS({
  vm: {
    name: '',
    hardware: {
      disks: {
        immutable: [],
        user: []
      }
    }
  },
  assetTiers: [],
  eventlog: [],
  restorePoints: [],
  changelog: [],
  reports: []
})

const moduleName = 'vms'

const {
  vms,
  sagas,
  getVmRequest,
  getVmRequestFailure,
  getVmAssetTiersRequest,
  getVmAssetTiersRequestFailure,
  getVmEventlogRequest,
  getVmEventlogRequestFailure,
  getVmRestorePointsRequest,
  getVmRestorePointsRequestFailure,
  setVmRevertRestorePoint,
  postVmRevertRestorePointRequest,
  postVmRevertRestorePointRequestFailure,
  getVmChangelogRequest,
  getVmChangelogRequestFailure,
  postVmSnapshotCreateRequest,
  postVmSnapshotCreateRequestFailure,
  postVmSnapshotRevertRequest,
  postVmSnapshotRevertRequestFailure,
  postVmSnapshotDeleteRequest,
  postVmSnapshotDeleteRequestFailure,
  postVmPowerOnRequest,
  postVmPowerOnRequestFailure,
  postVmPowerOffRequest,
  postVmPowerOffRequestFailure,
  postVmRebootRequest,
  postVmRebootRequestFailure,
  postVmDeleteRequest,
  postVmDeleteRequestFailure,
  postVmHardwareChangeRequest,
  postVmHardwareChangeRequestFailure,
  setVmDiskConfig,
  postVmDiskConfigRequest,
  postVmDiskConfigRequestFailure,
  postVmDiskDeleteRequest,
  postVmDiskDeleteRequestFailure,
  postVmRequest,
  postVmRequestFailure,
  getVmReportsRequest,
  getVmReportsRequestFailure,
  getVmReportRequest,
  getVmReportRequestFailure,
  getVmTicketRequest,
  getVmTicketRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_VM_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/${payload.vmId}/`
        })
      ],
      onError: (e) => getVmRequestFailure(e)
    },
    GET_VM_REQUEST_SUCCESS: (state, action) =>
      state.set('vm', fromJS(action.response)),
    GET_VM_REQUEST_FAILURE: (state) => state,

// Get possible VM tiers

    GET_VM_ASSET_TIERS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/asset-tiers/`
        })
      ],
      onError: (e) => getVmAssetTiersRequestFailure(e)
    },
    GET_VM_ASSET_TIERS_REQUEST_SUCCESS: (state, action) =>
      state.set('assetTiers', fromJS(action.response.results)),
    GET_VM_ASSET_TIERS_REQUEST_FAILURE: (state) => state,

    GET_VM_EVENTLOG_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/${payload.vmId}/eventlog/`
        })
      ],
      onError: (e) => getVmEventlogRequestFailure(e)
    },
    GET_VM_EVENTLOG_REQUEST_SUCCESS: (state, action) =>
      state.set('eventlog', fromJS(action.response.results)),
    GET_VM_EVENTLOG_REQUEST_FAILURE: (state) => state,

    // Restore points

    GET_VM_RESTORE_POINTS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/${payload.vmId}/restore-points/`
        })
      ],
      onError: (e) => getVmRestorePointsRequestFailure(e)
    },
    GET_VM_RESTORE_POINTS_REQUEST_SUCCESS: (state, action) =>
      state.set('restorePoints', fromJS(action.response.results)),
    GET_VM_RESTORE_POINTS_REQUEST_FAILURE: (state) => state,

    // Revert to Restore Point

    SET_VM_REVERT_RESTORE_POINT: (state, action) =>
      state.setIn(
        ['vm', 'restorePoints', 'restorePointUid'],
        fromJS(action.payload)
      ),
    POST_VM_REVERT_RESTORE_POINT_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/restore/`
        })
      ],
      onError: (e) => postVmRevertRestorePointRequestFailure(e)
    },
    POST_VM_REVERT_RESTORE_POINT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_REVERT_RESTORE_POINT_REQUEST_FAILURE: (state) => state,

    // VM Changelog

    GET_VM_CHANGELOG_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/${payload.vmId}/changelog/`
        })
      ],
      onError: (e) => getVmChangelogRequestFailure(e)
    },
    GET_VM_CHANGELOG_REQUEST_SUCCESS: (state, action) =>
      state.set('changelog', fromJS(action.response.results)),
    GET_VM_CHANGELOG_REQUEST_FAILURE: (state) => state,

    // SNAPSHOT CREATE

    POST_VM_SNAPSHOT_CREATE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/snapshot/`
        })
      ],
      onError: (e) => postVmSnapshotCreateRequestFailure(e)
    },
    POST_VM_SNAPSHOT_CREATE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_SNAPSHOT_CREATE_REQUEST_FAILURE: (state) => state,

    // SNAPSHOT REVERT

    POST_VM_SNAPSHOT_REVERT_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/snapshot/revert/`
        })
      ],
      onError: (e) => postVmSnapshotRevertRequestFailure(e)
    },
    POST_VM_SNAPSHOT_REVERT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_SNAPSHOT_REVERT_REQUEST_FAILURE: (state) => state,

    // SNAPSHOT DELETE

    POST_VM_SNAPSHOT_DELETE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/snapshot/delete/`
        })
      ],
      onError: (e) => postVmSnapshotDeleteRequestFailure(e)
    },
    POST_VM_SNAPSHOT_DELETE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_SNAPSHOT_DELETE_REQUEST_FAILURE: (state) => state,

    // POWER ON

    POST_VM_POWER_ON_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/power-on/`
        })
      ],
      onError: (e) => postVmPowerOnRequestFailure(e)
    },
    POST_VM_POWER_ON_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_POWER_ON_REQUEST_FAILURE: (state) => state,

    // POWER OFF

    POST_VM_POWER_OFF_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/power-off/`
        })
      ],
      onError: (e) => postVmPowerOffRequestFailure(e)
    },
    POST_VM_POWER_OFF_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_POWER_OFF_REQUEST_FAILURE: (state) => state,

    // Reboot VM

    POST_VM_REBOOT_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/reboot/`
        })
      ],
      onError: (e) => postVmRebootRequestFailure(e)
    },
    POST_VM_REBOOT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_REBOOT_REQUEST_FAILURE: (state) => state,

    // Delete VM

    POST_VM_DELETE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/delete/`
        })
      ],
      onError: (e) => postVmDeleteRequestFailure(e)
    },
    POST_VM_DELETE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_DELETE_REQUEST_FAILURE: (state) => state,

    // Change Hardware Cpu and Memory

    POST_VM_HARDWARE_CHANGE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/hardware-change/`
        })
      ],
      onError: (e) => postVmHardwareChangeRequestFailure(e)
    },
    POST_VM_HARDWARE_CHANGE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_HARDWARE_CHANGE_REQUEST_FAILURE: {
      saga: function* (action) {
        yield setVmHardwareChangeFormErrorState(action.errors.body)
      }
    },

    // Disk change configuration

    SET_VM_DISK_CONFIG: (state, action) =>
      state.setIn(['vm', 'hardware'], fromJS(action.payload)),
    POST_VM_DISK_CONFIG_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/disk-config/`
        })
      ],
      onError: (e) => postVmDiskConfigRequestFailure(e)
    },
    POST_VM_DISK_CONFIG_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_DISK_CONFIG_REQUEST_FAILURE: {
      saga: function* (action) {
        yield setVmHardwareChangeFormErrorState(action.errors.body)
      }
    },

    // Disk delete

    POST_VM_DISK_DELETE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/vms/${meta.vmId}/disks/${meta.diskId}/delete/`
        })
      ],
      onError: (e) => postVmDiskDeleteRequestFailure(e)
    },
    POST_VM_DISK_DELETE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_DISK_DELETE_REQUEST_FAILURE: (state) => state,
    POST_VM_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${meta.iveId}/vm/`
        })
      ],
      onError: (e) => postVmRequestFailure(e)
    },
    POST_VM_REQUEST_SUCCESS: {
      saga: function* () {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VM_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(setTemplateCatalogFormErrorState(action.errors.body))
      }
    },
    GET_VM_REPORTS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/${payload.vmId}/reports/`
        })
      ],
      onError: (e) => getVmReportsRequestFailure(e)
    },
    GET_VM_REPORTS_REQUEST_SUCCESS: (state, action) =>
      state.set('reports', fromJS(action.response.results)),
    GET_VM_REPORTS_REQUEST_FAILURE: (state) => state,
    GET_VM_REPORT_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `${payload.url}`,
          isDownload: true
        })
      ],
      onError: (e) => getVmReportRequestFailure(e)
    },
    GET_VM_REPORT_REQUEST_SUCCESS: (state) => state,
    GET_VM_REPORT_REQUEST_FAILURE: (state) => state,
    GET_VM_TICKET_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload) => ({
          method: 'GET',
          endpoint: `/vms/${payload.vmId}/console-ticket/`
        })
      ],
      onError: (e) => getVmTicketRequestFailure(e)
    },
    GET_VM_TICKET_REQUEST_SUCCESS: (state, action) => {
      let hostname = window.location.hostname
      if (hostname === "localhost") {
        hostname = 'gxptest.com'
      } 
      window.open(`https://${hostname}/console?ticket=${action.response.ticket}&host=${action.response.host}`).focus() },
    GET_VM_TICKET_REQUEST_FAILURE: (state) => state
  },
  defaultState
)
export {
  vms,
  sagas,
  getVmRequest,
  getVmAssetTiersRequest,
  getVmEventlogRequest,
  getVmRestorePointsRequest,
  setVmRevertRestorePoint,
  postVmRevertRestorePointRequest,
  getVmChangelogRequest,
  postVmPowerOnRequest,
  postVmPowerOffRequest,
  postVmRebootRequest,
  postVmSnapshotCreateRequest,
  postVmSnapshotRevertRequest,
  postVmSnapshotDeleteRequest,
  postVmDeleteRequest,
  postVmHardwareChangeRequest,
  setVmDiskConfig,
  postVmDiskConfigRequest,
  postVmDiskDeleteRequest,
  postVmRequest,
  getVmReportsRequest,
  getVmReportRequest,
  getVmTicketRequest
}
