const minLengthError = (minLength) =>
  `Ensure this field has at least ${minLength} characters.`
const maxLengthError = (maxLength) =>
  `Ensure this field has no more than ${maxLength} characters.`
const minValueError = (minValue) =>
  `Ensure this field has a value of at least ${minValue}.`
const maxValueError = (maxValue) =>
  `Ensure this field has a value of less than or equal to ${maxValue}.`
const UNICODE_ERROR = 'Unicode characters are not permitted.'
const REQUIRED_ERROR = 'Required.'
const EMAIL_ERROR = 'Invalid email address.'
const LOGIN_ERROR = 'Invalid username/password.'
const INVALID_PASSWORD_ERROR = 'Password contains invalid characters.'
const ALLOWED_CHARS_ERROR =
  'Allowed characters: A-Z, a-z, 0-9, _ and - (no spaces)'
const ALLOWED_CHARS_WITH_SPACE_ERROR =
  'Allowed characters: A-Z, a-z, 0-9, _, -, and whitespace.'
const ALLOWED_CHARS_WITH_NO_SPACE_ERROR =
  'Allowed characters: A-Z, a-z, 0-9'
const CURRENT_PASSWORD_ERROR = 'Current password is incorrect.'
const REPEATED_PASSWORD_UNEQUAL_ERROR =
  'Password and repeated password are not equal.'
const NO_VALID_IP_IPV4 = 'No valid IPv4 address'
const NO_VALID_SUBNET_ERROR = 'No valid IPv4 CIDR subnet'
const REPEATED_PSK_UNEQUAL_ERROR = 'Psk and repeated psk are not equal.'
const REPEATED_ESIG_UNEQUAL_ERROR =
  'Electronic signature and repeated electronic signature are not equal.'
const NEW_PASSWORD_UNEQUAL_ERROR =
  'New password and repeated password are not equal.'
const NEW_PASSWORD_UNCHANGED_ERROR =
  'New password needs to be different from the current password.'
const DUPLICATE_RULE_ERROR = 'A rule with these values already exists.'
const DUPLICATE_NAME_ERROR = 'A rule with this name already exists.'
const RESERVED_PORTS_ERROR = 'Port 22 and 4443 are reserved ports.'
const ORIGINAL_PORTS_OVERLAP_ERROR =
  "Your rule's original ports overlaps original ports in existing rules."
const DATETIME_IN_PAST_ERROR = 'Datetime cannot be in the past.'
const DISK_DOWNSIZE_ERROR = 'Disk cannot be downsized.'
const DISK_ZERO_ERROR = 'Disk cannot be Zero.'
const TWO_FACTOR_ERROR = '2FA code is incorrect or expired.'

export {
  minLengthError,
  maxLengthError,
  minValueError,
  maxValueError,
  UNICODE_ERROR,
  REQUIRED_ERROR,
  EMAIL_ERROR,
  LOGIN_ERROR,
  INVALID_PASSWORD_ERROR,
  ALLOWED_CHARS_ERROR,
  NO_VALID_IP_IPV4,
  ALLOWED_CHARS_WITH_SPACE_ERROR,
  ALLOWED_CHARS_WITH_NO_SPACE_ERROR,
  CURRENT_PASSWORD_ERROR,
  REPEATED_PASSWORD_UNEQUAL_ERROR,
  REPEATED_PSK_UNEQUAL_ERROR,
  NO_VALID_SUBNET_ERROR,
  REPEATED_ESIG_UNEQUAL_ERROR,
  NEW_PASSWORD_UNEQUAL_ERROR,
  NEW_PASSWORD_UNCHANGED_ERROR,
  DUPLICATE_RULE_ERROR,
  DUPLICATE_NAME_ERROR,
  RESERVED_PORTS_ERROR,
  ORIGINAL_PORTS_OVERLAP_ERROR,
  DATETIME_IN_PAST_ERROR,
  DISK_DOWNSIZE_ERROR,
  DISK_ZERO_ERROR,
  TWO_FACTOR_ERROR
}
