// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  historyProfiles: [],
  availabilityProfiles: []
})

const moduleName = 'slp'

const {
  slp,
  sagas,
  getAvailabilityProfilesRequest,
  getAvailabilityProfilesRequestFailure,
  getHistoryProfilesRequest,
  getHistoryProfilesRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_AVAILABILITY_PROFILES_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/slp/availability-profiles/'
        })
      ],
      onError: (e) => getAvailabilityProfilesRequestFailure(e)
    },
    GET_AVAILABILITY_PROFILES_REQUEST_SUCCESS: (state, action) =>
      state.set('availabilityProfiles', fromJS(action.response)),
    GET_AVAILABILITY_PROFILES_REQUEST_FAILURE: (state) => state,
    GET_HISTORY_PROFILES_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/slp/history-profiles/'
        })
      ],
      onError: (e) => getHistoryProfilesRequestFailure(e)
    },
    GET_HISTORY_PROFILES_REQUEST_SUCCESS: (state, action) =>
      state.set('historyProfiles', fromJS(action.response)),
    GET_HISTORY_PROFILES_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export { slp, sagas, getAvailabilityProfilesRequest, getHistoryProfilesRequest }
