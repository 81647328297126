import React from 'react'
import { Container, Header, Content, Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'


type Props = {
    +dcStatistics: string,
}
export const ManagementComponent = (props: Props) => {


    return(
                <Container
                    style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', maxHeight: '90vh' }}
                    >
                    <Header
                        style={{
                        height: '60px',
                        // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)'
                        background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
                        }}
                    >
                        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
                            GxP-Cloud CMC Management
                        </h3>
                    </Header>
                    <Content style={{ overflow: "auto" }}>

                        <Panel header={<h4>Not here yet!</h4>}>


                        </Panel>
                    </Content>
                </Container>
)}