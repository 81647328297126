// @flow

import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Row, Col, Grid, Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { FirewallRuleRow } from './FirewallRuleRowComponent'

type Props = {|
  +applications: IdentityObject[],
  +deleteFirewallRule: Function,
  +isRuleDialogOpen: boolean,
  +iveId: string,
  +rules: FirewallRules,
  +vms: IdentityObject[],
  +vnicGroups: IdentityObject[],
  +toggleFirewallDialogOpenState: Function
|}

export const RulesTable = (props: Props) => {
  const { immutable, user } = props.rules
  const rules = immutable.concat(user)
  return (
    <Panel header={<strong>Configured Firewall rule(s)</strong>}>
      <Grid fluid>
        <Row style={{ fontSize: '12px' }}>
          <Col md={2}>Drag</Col>
          <Col md={2}>Enabled</Col>
          <Col md={4}>Name</Col>
          <Col md={5}>Source</Col>
          <Col md={5}>Destination</Col>
          <Col md={3}>Application</Col>
          <Col md={2}>Match translated</Col>
          <Col md={1}>Delete</Col>
        </Row>
        {rules.map((rule, index) => {
          return (
            <FirewallRuleRow
              applications={props.applications}
              deleteFirewallRule={props.deleteFirewallRule}
              disabled={index < immutable.length}
              editFirewallRule={props.editFirewallRule}
              immutable={index < immutable.length}
              userRuleIndex={index - immutable.length}
              iveId={props.iveId}
              index={index}
              key={`firewall-rule-${index}`}
              rule={rule}
              rules={props.rules}
              vms={props.vms}
              vnicGroups={props.vnicGroups}
              isRuleDialogOpen={props.isRuleDialogOpen}
            />
          )
        })}
      </Grid>
    </Panel>
  )
}

export const FirewallTable = SortableContainer(RulesTable)
