import React from 'react'
import { X, Check } from 'react-feather'
import 'rsuite/dist/styles/rsuite-default.css'

export const SyncIndicator = (props: { rulesMutated: boolean }) => (
  <span style={{ display: 'flex', justifyContent: 'left' }}>
    <div style={{ marginBottom: '20px', background: '#ffffff' }}>
      {props.rulesMutated ? (
        <X color="red" size={27} />
      ) : (
        <Check color="green" size={27} />
      )}
    </div>
    <h5>{props.rulesMutated ? ' Not in sync' : ' In sync'}</h5>
  </span>
)
