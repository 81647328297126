// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AccountInfoComponent } from './AccountInfoComponent'
import { ToJs } from 'utils/ToJs'
import {
  getAccountRequest,
  putAccountRequest,
  getAccountOtpQrRequest
} from 'modules/operations/account'
import {
  setAccountInfoFormErrorState,
  clearAccountInfoFormErrorState
} from 'modules/components/accountInfoForm'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

const mapStateToProps = (state) => ({
  account: state.getIn(['account']),
  groups: state.getIn(['account', 'groups']),
  errors: state.getIn(['accountInfoForm', 'errors'])
})

type Props = {
  +account: AccountInfoState,
  +user: User,
  +getAccountRequest: Function,
  +putAccountRequest: Function,
  +getAccountOtpQrRequest: Function,
  +setSubmitDialogOpenState: Function,
  +setAccountInfoFormErrorState: Function,
  +clearAccountInfoFormErrorState: Function
}
class AccountInfoContainer extends Component<Props, AccountInfoState> {
  props: Props
  state: AccountInfoState = {
    username: '',
    group: '',
    otp: { confirmed: '', configUrl: '' },
    firstName: '',
    lastName: '',
    timezone: '',
    groupInfo: [],
    role: '',
    groupUsername: '',
    groupGroup:''
  }

  componentDidMount() {
    this.props.getAccountRequest()
  }

  componentWillUnmount() {
    this.props.clearAccountInfoFormErrorState()
  }
  
  componentDidUpdate(prevProps: Object, prevState: Object) {
    const { account } = prevProps
    const { firstName, lastName, timezone, groupInfo } = prevState
    const groupInfoChanged =!!groupInfo !== !!account.groupInfo
    const firstNameChanged = !!firstName !== !!account.firstName
    const lastNameChanged = !!lastName !== !!account.lastName
    const timezoneChanged = !!timezone !== !!account.timezone
    if (firstNameChanged || lastNameChanged || timezoneChanged || groupInfoChanged) {
      this.setState({ ...account })
    }
  }

  handleRoleChange = (change) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }

  putAccount = () => {
    const { firstName, lastName, timezone } = this.state
    this.props.clearAccountInfoFormErrorState()
    this.props.putAccountRequest({ firstName, lastName, timezone })
  }

  handleChange: AccountInfoHandleChange = (change) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }
  render() {
    return (
      <AccountInfoComponent
        setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
        getAccountOtpQrRequest={this.props.getAccountOtpQrRequest}
        handleChange={this.handleChange}
        handleRoleChange={this.handleRoleChange}
        key="account"
        putAccount={this.putAccount}
        formData={this.state}
        groupInfo={this.state.groupInfo}
        role={this.state.role}
      />
    )
  }
}

export { AccountInfoContainer }

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const AccountInfo = connect(mapStateToProps, {
  getAccountRequest,
  getAccountOtpQrRequest,
  setSubmitDialogOpenState,
  putAccountRequest,
  setAccountInfoFormErrorState,
  clearAccountInfoFormErrorState
})(ToJs(AccountInfoContainer))
