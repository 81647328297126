// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SetPasswordDialogComponent } from './SetPasswordDialogComponent'
import { ToJs } from 'utils/ToJs'
import { putChangePasswordRequest } from 'modules/operations/account'
import {
  setPasswordDialogErrorState,
  clearPasswordDialogErrorState
} from 'modules/components/setPasswordDialog'

const mapStateToProps = (state) => {
  let errors = state.getIn(['setPasswordDialog', 'errors'])
  const requestErrors = state.getIn(['account', 'errors'])
  if (!errors.get('currentPassword') && requestErrors.get('detail')) {
    errors = errors.set('currentPassword', requestErrors.get('detail'))
  }
  if (!errors.get('newPassword') && requestErrors.get('nonFieldErrors')) {
    errors = errors.set('newPassword', requestErrors.get('nonFieldErrors'))
  }
  return {
    hasPasswordSet: state.getIn(['auth', 'user', 'hasPasswordSet'])
  }
}

type Props = {|
  +hasPasswordSet: boolean,
  +putChangePasswordRequest: Function,
  +setPasswordDialogErrorState: Function,
  +clearPasswordDialogErrorState: Function
|}

type State = {
  currentPassword: string,
  newPassword: string,
  repeatedPassword: string
}

class SetPasswordDialogContainer extends Component<Props, State> {
  props: Props
  state: State

  state = {
    currentPassword: '',
    newPassword: '',
    repeatedPassword: '',
    capsLockWarning: false
  }

  componentWillUnmount() {
    this.props.clearPasswordDialogErrorState()
  }

  handleChange = (change) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }

  handleSetPassword = () => {
    const { currentPassword, newPassword, repeatedPassword } = this.state
    this.props.putChangePasswordRequest({
      currentPassword,
      newPassword,
      repeatedPassword
    })
  }

  render() {
    const { hasPasswordSet } = this.props
    return (
      <SetPasswordDialogComponent
        hasPasswordSet={hasPasswordSet}
        handleSetPassword={this.handleSetPassword}
        handleChange={this.handleChange}
        onKeyDown={this.onKeyDown}
        currentPassword={this.state.currentPassword}
        newPassword={this.state.newPassword}
        repeatedPassword={this.state.repeatedPassword}
        capsLockWarning={this.state.capsLockWarning}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const SetPasswordDialog = connect(mapStateToProps, {
  putChangePasswordRequest,
  setPasswordDialogErrorState,
  clearPasswordDialogErrorState
})(ToJs(SetPasswordDialogContainer))
