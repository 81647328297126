// @flow

import React, { Component } from 'react'
import 'rsuite/dist/styles/rsuite-default.css'
import { IveReportsCardComponent } from './IveReportsCardComponent'

import './IveReportsComponent.css'

type Props = {|
  +name: string,
  +data: any,
  +timezone: string,
  +getIveReportRequest: Function
|}
type State = {|
  requirementSpecification: { expanded: boolean },
  technicalSpecification: { expanded: boolean },
  intallationQualification: { expanded: boolean }
|}

export class IveReportsComponent extends Component<Props, State> {
  props: Props
  state: State

  constructor() {
    super()
    this.state = {
      requirementSpecification: { expanded: false },
      technicalSpecification: { expanded: false },
      intallationQualification: { expanded: false }
    }
  }

  handleExpandChange = (type: string) => {
    const expanded = !this.state[type].expanded
    this.setState({ [type]: { expanded } })
  }

  render() {
    const { data, getIveReportRequest } = this.props
    const iveRequirementsSpecificationReports = data.filter(
      (report) => report.type === 'Requirements Specification'
    )
    const iveTechnicalSpecificationReports = data.filter(
      (report) => report.type === 'Technical Specification'
    )
    const iveInstallationAndQualificationReports = data.filter(
      (report) => report.type === 'IVE Installation and Qualification'
    )

    return (
      <span>
        <IveReportsCardComponent
          key="ive-req-docs"
          getIveReportRequest={getIveReportRequest}
          reports={iveRequirementsSpecificationReports}
          timezone={this.props.timezone}
          type={'requirementSpecification'}
          title={'Blueprint Specification'}
          marginBottom={'32px'}
        />
        <IveReportsCardComponent
          key="ive-tec-docs"
          getIveReportRequest={getIveReportRequest}
          reports={iveTechnicalSpecificationReports}
          timezone={this.props.timezone}
          type={'technicalSpecification'}
          title={'Technical Requirement Specification'}
          marginBottom={'32px'}
        />
        <IveReportsCardComponent
          key="ive-qual-docs"
          getIveReportRequest={getIveReportRequest}
          reports={iveInstallationAndQualificationReports}
          timezone={this.props.timezone}
          type={'intallationQualification'}
          title={'IVE Installation Qualification'}
          marginBottom={'0px'}
        />
      </span>
    )
  }
}
