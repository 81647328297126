// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DnatDialogComponent } from './DnatDialogComponent'
import { ToJs } from 'utils/ToJs'
import { getIveVmsRequest } from 'modules/operations/ives'

type ReduxState = {| +vms: IdentityObject[] |}
type ReduxActions = {| +getIveVmsRequest: Function |}
type Props = ReduxState &
  ReduxActions & {|
    +children: React$Element<any>,
    +dialogAction: 'Add' | 'Edit',
    +iveId: string,
    +ive:Ive,
    +rule: DnatRule,
    +rules: DnatRules,
    +submit: Function
  |}
type State = {|
  rule: DnatRule,
  defaultRule: DnatRule,
  isOpen: boolean
|}

const mapStateToProps = (state, props) =>
  ({
    vms: state.getIn(['ives', 'vms']),
    ive: state.getIn(['ives', 'ive'])
  }: ReduxState)

const DEFAULT_RULE = {
  id: '',
  originalAddress: '',
  originalPortFrom: null,
  originalPortTo: null,
  protocol: '',
  translatedAddress: '',
  translatedPortFrom: null,
  translatedPortTo: null,
  immutable: false
}

const DEFAULT_STATE = {
  isOpen: false,
  rule: DEFAULT_RULE,
  defaultRule: DEFAULT_RULE
}

export class DnatDialogContainer extends Component<Props, State> {
  state: State = DEFAULT_STATE
  props: Props

  constructor(props: Props) {
    super(props)
    const { iveId } = this.props
    this.props.getIveVmsRequest({ iveId })
  }

  submit = () => {
    this.props.submit(this.state.rule)
    this.setState(DEFAULT_STATE)
    this.toggleOpenState()
  }

  // Change public IP as Original Address
  handleOnChangeOriginalPublicIp = (value: IdentityObject, item: IdentityObject) => {
      const rule = { ...this.state.rule, originalAddress: item && item.id }
      this.setState({ ...this.state, rule })
  }

  // NAT rules Port-From always change the Port-To too
  handleOriginalPortFromChange = (value) => {
    const rule = {
      ...this.state.rule,
      originalPortFrom: value,
      originalPortTo: value
    }
    this.setState({ ...this.state, rule })
  }
  handleTranslatedPortFromChange = (value) => {
    const rule = {
      ...this.state.rule,
      translatedPortFrom: value,
      translatedPortTo: value
    }
    this.setState({ ...this.state, rule })
  }

  handleOriginalPortToChange = (value) => {
    const rule = {
      ...this.state.rule,
      originalPortTo: value,
      originalPortFrom: value
    }
    this.setState({ ...this.state, rule })
  }
  handleTranslatedPortToChange = (value) => {
    const rule = {
      ...this.state.rule,
      translatedPortTo: value,
      translatedPortFrom: value
    }
    this.setState({ ...this.state, rule })
  }
  // Rsuite onSelect returns value and item, where we want the item as object in Functions for VM and Protocols
  handleOnChangeVm = (value: IdentityObject, item: IdentityObject) => {
    const rule = { ...this.state.rule, translatedAddress: item }
    this.setState({ ...this.state, rule })
  }

  handleOnChangeProtocol = (value: IdentityObject, item: IdentityObject) => {
    const rule = { ...this.state.rule, protocol: item && item.id }
    this.setState({ ...this.state, rule })
  }

  toggleOpenState = () => {
    let newState = { isOpen: !this.state.isOpen, errors: {} }
    if (this.props.rule) {
      newState = {
        ...newState,
        rule: this.props.rule,
        defaultRule: this.props.rule
      }
    }
    this.setState({ ...newState })
  }

  render() {
    return (
      <DnatDialogComponent
        ive={this.props.ive}
        children={this.props.children}
        dialogAction={this.props.dialogAction}
        errors={this.state.errors}
        rule={this.state.rule}
        submit={this.submit}
        isOpen={this.state.isOpen}
        toggleOpenState={this.toggleOpenState}
        handleOnChangeOriginalPublicIp={this.handleOnChangeOriginalPublicIp}
        handleOriginalPortFromChange={this.handleOriginalPortFromChange}
        handleOriginalPortToChange={this.handleOriginalPortToChange}
        handleTranslatedPortFromChange={this.handleTranslatedPortFromChange}
        handleTranslatedPortToChange={this.handleTranslatedPortToChange}
        handleOnChangeVm={this.handleOnChangeVm}
        handleOnChangeProtocol={this.handleOnChangeProtocol}
        vms={this.props.vms}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const DnatDialog = connect(
  mapStateToProps,
  ({ getIveVmsRequest }: ReduxActions)
)(ToJs(DnatDialogContainer))
