import React from 'react'

import { Icon, Panel, Whisper, Tooltip, Grid, Row, Col } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {
  +accounts: Array,
  +account: ?Object,
  +iveId: string,
  +setSubmitDialogOpenState: Function
}

export const SslVpnAccountsTable = (props: Props) => {
  const { accounts } = props
  return (
    <Panel header={<h5>SSL-VPN accounts</h5>}>
      <Grid fluid>
          <Row key="sslvpn-account-row">
            <Col md={18}>
              Username
            </Col>
          <Col md={6}>Action</Col>
          </Row>
          {accounts && accounts.map((account, index) => 
          <Row style={{ marginTop: '10px' }} key={`account-${index}`}>
            <Col md={18}>
            <strong>{account.username}</strong>
            </Col>
            <Col md={6}>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Delete SSL-VPN Account</Tooltip>}
                >
                  <Icon
                    icon="trash-o"
                    size="lg"
                    id="delete-ssl-vpn"
                    onClick={() =>
                      props.setSubmitDialogOpenState({
                        dialogId: 'DELETE_SSL_VPN_ACCOUNT',
                        meta: {
                          iveId: props.iveId,
                          accountId: account.id
                        }
                      })
                    }
                    style={{ cursor: 'pointer' }}
                  />
                </Whisper>
            </Col>
          </Row>
          )}
        </Grid>
    </Panel>

  )
}
