import React, { Component } from 'react'
import {
  FormGroup,
  Modal,
  Form,
  DatePicker,
  Button,
  FormControl,
  ControlLabel,
  Divider,
  Panel,
  SelectPicker
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { isBefore } from 'date-fns'

type Props = {|
  +title: ?React$Element<any> | string,
  +electronicSignatureOnly: ?boolean,
  +approvers: IdentityObject[],
  +isOpen: boolean,
  +isSubmitting: boolean,
  +electronicSignature: string,
  +approver: any,
  +executionDate: Date,
  +executionTime: Date,
  +approverInputDisabled: boolean,
  +dateTimeInputsDisabled: boolean,
  +electronicSignatureInputDisabled: boolean,
  +clearSubmitDialogErrorState: Function,
  +handleSave: Function,
  +handleChange: Function,
  +clearModalOpenState: Function,
  +clearSubmitDialogOpenState: Function,
  +setDatetime: Function,
|}

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}
export class SubmitDialogComponent extends Component<Props> {
  props: Props

  render() {
    const {
      title,
      electronicSignatureOnly,
      approvers,
      isOpen,
      isSubmitting,
      electronicSignature,
      electronicSignatureInputDisabled,
      handleSave,
      handleChange,
      clearModalOpenState,
      clearSubmitDialogOpenState,
      setDatetime,
    } = this.props

    return (
      <Modal
        backdrop="static"
        show={isOpen}
        size="md"
        onRequestClose={clearSubmitDialogOpenState}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Panel>
          <Form fluid>
            {!electronicSignatureOnly && (
              <div style={{ marginBottom: '25px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul>
                    <li key="immediate">
                      Leave this field blank to immediately execute the task.
                    </li>
                    <li key="scheduled">
                      Select a future date and time to execute the task at the
                      selected date and time.
                    </li>
                  </ul>
                </div>
                <InputField
                  format="YYYY-MM-DD HH:mm:ss"
                  accepter={DatePicker}
                  ranges={[
                    {
                      label: 'Now',
                      value: new Date()
                    }
                  ]}
                  disabledDate={(date) => isBefore(date, new Date())}
                  style={{ width: 'inherit' }}
                  onChange={(executionDate) => {
                    handleChange({ dateTimeInputsDisabled: false })
                    setDatetime({
                      executionDate: executionDate,
                      executionTime: executionDate
                    })
                  }}
                  onClean={() => {
                    handleChange({ dateTimeInputsDisabled: true })
                  }}
                />
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul>
                    <li key="approver">
                      Select an approver to have the task approved by the
                      designated approver.
                    </li>
                    <li key="esign">
                      Provide your electronic signature to immediately sign off
                      on this task.
                    </li>
                  </ul>
                </div>
                <InputField
                  name="approver"
                  label="Select an Approver"
                  style={{ width: 'inherit' }}
                  id="task-approver"
                  labelKey="username"
                  valueKey="username"
                  data={approvers}
                  accepter={SelectPicker}
                  searchable={false}
                  disabled={electronicSignature.length >= 1}
                  onChange={(approver) => {
                    handleChange({
                      approver,
                      electronicSignatureInputDisabled: true
                    })
                  }}
                  onClean={() => {
                    handleChange({ electronicSignatureInputDisabled: false })
                  }}
                />
              </div>
            )}
            <InputField
              id="electronic-signature"
              name="esignature"
              disabled={electronicSignatureInputDisabled}
              label="Electronic signature"
              onChange={(electronicSignature) =>
                handleChange({ electronicSignature })
              }
              value={electronicSignature}
              type="password"
              autoFocus
            />

            <Modal.Footer>

              <Button color="red" onClick={() => clearSubmitDialogOpenState()}>
                Cancel
              </Button>
              <Button
                color="blue"
                style={{ marginLeft: '15px' }}
                primary="true"
                type="submit"
                disabled={isSubmitting}
                onClick={(e) => {
                  e.preventDefault()
                  if (!isSubmitting) {
                    handleSave()
                    clearModalOpenState()
                  }
                }}
              >
                OK
              </Button>
            </Modal.Footer>
          </Form>
        </Panel>
      </Modal>
    )
  }
}
