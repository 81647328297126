// @flow

import React, { useRef } from 'react'
import {
  FormGroup,
  Form,
  Modal,
  Schema,
  FormControl,
  ControlLabel,
  Button,
  Whisper,
  Tooltip
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { StringType } = Schema.Types
const model = Schema.Model({
  password: StringType()
    .isRequired('This field is required.')
    .rangeLength(
      8,
      30,
      'The number of characters can only be between 8 and 30'
    ),
  repeatedPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
    .isRequired('This field is required.')
})

function TextField(props) {
  const { name, label, type, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl name={name} accepter={accepter} type={type} {...rest} />
    </FormGroup>
  )
}

type Props = {|
  +hasElectronicSignatureSet: boolean,
  +hasPasswordSet: boolean,
  +handlePutElectronicSignature: Function,
  +onKeyDown: Function,
  +capsLockWarning: Boolean,
  +electronicSignature: string,
  +groupActivated: Boolean,
  +repeatedElectronicSignature: string,
  +handleChange: Function
|}

export const SetElectronicSignatureDialogComponent = (props: Props) => {
  const {
    hasElectronicSignatureSet,
    hasPasswordSet,
    onKeyDown,
    capsLockWarning,
    handlePutElectronicSignature,
    electronicSignature,
    repeatedElectronicSignature,
    handleChange
  } = props

  var setEsignatureForm = useRef(null)

  return (
    hasPasswordSet ? (
      <Modal backdorp="static" show={!hasElectronicSignatureSet} size="md">
        <Modal.Header closeButton={false}>
          <Modal.Title>Set your electronic signature:</Modal.Title>
        </Modal.Header>
        <span>
          Before the cloud management console can be used you need to set your electronic
          signature.<br></br>
          <br></br>
          Your electronic signature will be used to sign off tasks in the cloud management console.
          This way your actions within the console will be keeping track of.<br></br>
          <br></br>
          Dont forget your electronic signature!
        </span>
        <Form
          model={model}
          ref={(ref) => (setEsignatureForm = ref)}
          fluid
          checkTrigger="none"
        >
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Use letters and numbers, minimal of 8 characters.</Tooltip>}
          >
            <TextField
              autoFocus
              name="password"
              label="Electronic signature"
              onChange={(electronicSignature) =>
                handleChange({ electronicSignature })
              }
              onKeyDown={onKeyDown}
              id="electronic-signature"
              value={electronicSignature}
              type="password"
            />
          </Whisper>
          <TextField
            name="repeatedPassword"
            label="Repeated electronic signature"
            onChange={(repeatedElectronicSignature) =>
              handleChange({ repeatedElectronicSignature })
            }
            onKeyDown={onKeyDown}
            id="electronic-signature-repeated"
            value={repeatedElectronicSignature}
            type="password"
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {capsLockWarning ? (
              <span style={{ margingRight: '100px', color: '#ff0000' }}>
                Caps Lock is On!
              </span>
            ) : (
              <div></div>
            )}
            <Button
              color="blue"
              style={{ marginLeft: '15px' }}
              label="Submit"
              type="submit"
              id="electronic-signature-submit"
              onClick={(e) => {
                if (setEsignatureForm.check()) {
                  e.preventDefault()
                  handlePutElectronicSignature()
                }
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    ) : null
  )
}
