// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  errors: { currentPassword: '', newPassword: '', repeatedPassword: '' }
})

const moduleName = 'accountPasswordForm'

export const {
  accountPasswordForm,
  sagas,
  setAccountPasswordFormErrorState,
  clearAccountPasswordFormErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_ACCOUNT_PASSWORD_FORM_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_ACCOUNT_PASSWORD_FORM_ERROR_STATE: () => defaultState
  },
  defaultState
)
