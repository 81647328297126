// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import { VnicGroupCard } from 'components/VnicGroupCard'
import { AddNetworkForm } from './NetworkDialog/AddNetworkDialogContainer'
import { HelpCharacter } from 'components/HelpCharacter/HelpCharacterContainer'

import {
  ButtonToolbar,
  Icon,
  IconButton,
  Whisper,
  Tooltip,
  Panel,
  Row,
  Col,
  Grid,
  Divider,
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'


const ActionsComponent = (props: {|
  +ive: Ive,
  +match: Match,
  +vmInIve: Function,
  +setModalOpenState: Function,
  +setSubmitDialogOpenState: Function
|}) => (
  <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
    {props.match.path === '/ives' && (
      <Link to={`/ives/${props.ive.id}`}>
        <IconButton
          id={`ive-details-${props.ive.id}`}
          tooltip="Details"
          tooltipPosition="top-center"
          touch={true}
        >
          details
        </IconButton>
      </Link>
    )}
    <ButtonToolbar>
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>Add VM to IVE</Tooltip>}
      >
        <Link to={`/ives/${props.ive.id}/vm-catalogue`}>
          <IconButton
            icon={<Icon icon="plus-square" />}
            appearance="primary"
            placement="left"
            disabled={props.ive.locked}
          >
            Add VM
          </IconButton>
        </Link>
      </Whisper>

      <AddNetworkForm
          iveId={props.ive.id}
          ive={props.ive}
          setSubmitDialogOpenState={props.setSubmitDialogOpenState}
          key='addNetworkKey'
      >
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Add local network to IVE</Tooltip>}
        >
          <IconButton  
              icon={<Icon icon="cloud" />}
              appearance="ghost" 
              disabled={props.ive.locked}
              placement="left"
              key='addNetworkDrop'
          />
        </Whisper>
      </AddNetworkForm>

      <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Add extra public IP to IVE</Tooltip>}
      >
          <IconButton
            icon={<Icon icon="cloud-upload" />}
            appearance="ghost" 
            disabled={props.ive.locked || props.ive.publicIp.length > 5}
            key='addIpDrop'
            onClick={() =>
              props.setSubmitDialogOpenState({
                dialogId: 'ADD_PUBLIC_IP',
                payload: { iveId: props.ive.id }
              })
            }
          />
      </Whisper>

  
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>Manage SSL-VPN</Tooltip>}
      >
        <IconButton
          icon={<Icon icon="user-circle-o" />}
          appearance="ghost"
          disabled={props.ive.locked}
          onClick={() =>
            props.setModalOpenState({
              modalId: 'SSL_VPN',
              meta: { iveId: props.ive.id, name: props.ive.name }
            })
          }
        />
      </Whisper>
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>Manage Firewall</Tooltip>}
      >
        <IconButton
          icon={<Icon icon="shield" />}
          appearance="ghost"
          disabled={props.ive.locked}
          onClick={() =>
            props.setModalOpenState({
              modalId: 'FIREWALL',
              meta: { iveId: props.ive.id, name: props.ive.name }
            })
          }
        />
      </Whisper>
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>Manage NAT-rules</Tooltip>}
      >
        <IconButton
          icon={<Icon icon="flow" />}
          appearance="ghost"
          disabled={props.ive.locked}
          onClick={() =>
            props.setModalOpenState({
              modalId: 'NAT_RULES',
              meta: { iveId: props.ive.id, name: props.ive.name }
            })
          }
        />
      </Whisper>
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>Manage IPsec-sites</Tooltip>}
      >
        <IconButton
          icon={<Icon icon="exchange" />}
          appearance="ghost"
          disabled={props.ive.locked}
          onClick={() =>
            props.setModalOpenState({
              modalId: 'IPSEC',
              meta: { iveId: props.ive.id, name: props.ive.name }
            })
          }
        />
      </Whisper>
    </ButtonToolbar>
    <span style={{ display: 'flex', marginLeft: '10px' }}>
      <ButtonToolbar>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>IVE Changelog</Tooltip>}
        >
          <IconButton
            icon={<Icon icon="check-square-o" />}
            color='orange'
            appearance="ghost"
            onClick={() =>
              props.setModalOpenState({
                modalId: 'IVE_CHANGELOG',
                meta: { iveId: props.ive.id, name: props.ive.name }
              })
            }
          />
        </Whisper>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>IVE Reports</Tooltip>}
        >
          <IconButton
            icon={<Icon icon="detail" />}
            color='orange'
            appearance="ghost"
            onClick={() =>
              props.setModalOpenState({
                modalId: 'IVE_REPORTS',
                meta: { iveId: props.ive.id, name: props.ive.name }
              })
            }
          />
        </Whisper>
      </ButtonToolbar>
    </span>
    <span style={{ display: 'flex', marginLeft: '10px' }}>
      <Whisper
        placement="top"
        trigger="hover"
        // speaker={<Tooltip>{props.vmInIve() ? 'You cannot delete the IVE, it still contains assets.' : 'Delete IVE'}</Tooltip>}
        speaker={<Tooltip>Delete IVE</Tooltip>}
      >
        <IconButton
          icon={<Icon icon="trash" />}
          color="red"
          // disabled={props.ive.locked || !props.ive.isDeletable || props.vmInIve()}
          disabled={props.ive.locked || !props.ive.isDeletable}
          onClick={() =>
            props.setSubmitDialogOpenState({
              dialogId: 'DELETE_IVE',
              payload: { iveId: props.ive.id },
              meta: { name: props.ive.name }
            })
          }
        >
          Delete
        </IconButton>
      </Whisper>
    </span>
  </span>
)

const IveActions = withRouter(ActionsComponent)

type Props = {|
  +ive: Ive,
  +vmId: string,
  +vm: Vm,
  +timezone: string,
  +setModalOpenState: Function,
  +setSubmitDialogOpenState: Function
|}

export const IveCardComponent = (props: Props) => {
  const { setModalOpenState, ive, setSubmitDialogOpenState, vmId } = props

  function vmInIve() {
    if (ive.networks.map((network) => (network.vms && network.vms.length > 0 )).includes(true)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div id={`ive-card-${ive.id}`}>
      <span
        style={{
          backgroundColor: '#FFFFFF',
          display: 'block'
        }}
      >
        <Panel shaded>
          <Panel
            header={
              <div
                style={{
                  fontSize: '24px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <span>
                  <Grid fluid>
                    <Row>
                      <Col>
                        <span style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                         }}>
                          <strong>
                            {props.ive.name} {props.ive.locked ? '(Locked)' : ' '}
                          </strong>
                          <i style={{ fontSize: '20px'}}>
                          {ive.publicIp && ive.publicIp.map((ip) => (
                            <span> @ {ip}</span>
                          ))}
                          </i>
                        </span>
                      </Col>
                    </Row>
                    {/* {!props.ive.isDeletable ? '(Not yet deletable)' : ''} */}
                    <Row style={{ fontSize: '16px' }}>
                      <Col md={35}>{ive.description} </Col>
                    </Row>
                  </Grid>
                </span>
                <span>
                  <IveActions
                    ive={ive}
                    vmInIve={vmInIve}
                    setModalOpenState={setModalOpenState}
                    setSubmitDialogOpenState={setSubmitDialogOpenState}
                  />
                </span>
              </div>
            }
          >
            <div style={{ paddingLeft: '32px' }}>
              <Grid fluid>
                <Row style={{ fontSize: '12px' }}>
                  <Col md={4}>Blueprint</Col>
                  <Col md={4}>Location</Col>
                  <Col md={4}>Availability Profile</Col>
                  <Col md={4}>History Profile</Col>
                </Row>
                <Row style={{ fontSize: '14px', marginTop: '5px' }}>
                  <Col md={4}>
                    <strong>{ive.blueprint.name}</strong>
                  </Col>
                  <Col md={4}>
                    <strong>{ive.datacenter.name}</strong>
                  </Col>
                  <Col md={4}>
                    <strong>{ive.availabilityProfile.name}</strong>
                  </Col>
                  <Col md={4}>
                    <strong>{ive.historyProfile.name}</strong>
                  </Col>
                </Row>
              </Grid>
            </div>
          </Panel>
          <Divider>Networks</Divider>
          {ive.networks.map((vnic, index) => (
            <VnicGroupCard
              iveId={ive.id}
              iveLocked={ive.locked}
              index={index}
              key={`vnic-group-${index}`}
              vnic={vnic}
              vmId={vmId}
              setSubmitDialogOpenState={setSubmitDialogOpenState}
            />
          ))}
        </Panel>
      </span>

      <HelpCharacter modalId='IVE_DASHBOARD' />
    </div>

    
  )
}
