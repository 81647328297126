import React from 'react';

import { Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

// import character from './MascotImage/Sample.svg.svg'
import character from './MascotImage/Helper icon CMC v2.svg'


type Props = {|
  +setHelpModalOpenState: Function,
  +modalId: string,
|}

export const HelpCharacterComponent = (props: Props) => {

  return (
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>Need some explanation?</Tooltip>}
      >
          <img 
            // type="image/svg+xml" 
            // data={character}
            style={{ width: '3vw', position: 'absolute', left: '50px', bottom: '40px' }}
            src={character}
            alt='Need some explanation?'
            onClick={() =>
              props.setHelpModalOpenState({
                modalId: props.modalId,
              })
            }
          /> 
      </Whisper>
  )
};
