// @flow

import React from 'react'
import { Icon, IconButton, Panel, Row, Col, Divider } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import moment from 'moment-timezone'

import { TaskDetailRow } from 'components/TaskDetailRow'
import { ApprovalButton } from '.'

type Props = {|
  +approval: { id: string, task: Task },
  +selected: boolean,
  +timezone: string,
  +expanded: boolean,
  +toggleExpanded: Function
|}

const ApprovalCardComponent = (props: Props) => {
  const { approval, timezone } = props
  const [detailValues] = Object.values(approval.task.detail || {})
  const execution = approval.task.executionDatetime
    ? moment
        .tz(approval.task.executionDatetime, timezone)
        .format('DD MMM YYYY HH:mm:ss')
    : 'Immediately'

  return (
    <span expanded={props.expanded} elevation={0}>
      <Row>
        <Col md={8}>Name</Col>
        <Col md={12}><strong>{approval.task.name}</strong></Col>
      </Row>
      <Row>
        <Col md={8}>
          IVE
        </Col>
        <Col md={12}>
          <strong>{approval.task.ive.name}</strong>
        </Col>
      </Row>
      {approval.task.vm ? (
        <Row>        
          <Col md={8}>VM</Col>
          <Col md={12}><strong>{approval.task.vm && approval.task.vm.name}</strong></Col>
        </Row>
      ) : (
        null
      )}
      <Row style={{ marginBottom: '10px' }}>
        <Col md={8}>
          Execution
        </Col>
        <Col md={12}>
          <strong>{execution}</strong>
        </Col>
      </Row>
      <Row style={{ marginBottom: '5px' }}>
        <Col md={8}>Actions</Col>
        <Col md={12}>
          <ApprovalButton
            key="thumbs-o-down"
            icon="thumbs-o-down"
            approvalId={approval.id}
          />
          <ApprovalButton
            key="thumbs-o-up"
            icon="thumbs-o-up"
            approvalId={approval.id}
          />
          {detailValues && (
            <label onClick={props.toggleExpanded} className="expander">
              <IconButton
                placement='right'
                icon={
                  <Icon icon={props.expanded ? 'arrow-up' : 'arrow-down'} />
                }
              >
                Details
              </IconButton>
            </label>
          )}
        </Col>
      </Row>
      {props.expanded && (
        <Panel bordered header="Task Details">
          <div style={{ marginLeft: '15px' }}>
            {detailValues &&
              (Array.isArray(detailValues)
                ? detailValues.map((item, index) => {
                    const [[type, value]] = Object.entries(item)
                    return (
                      <TaskDetailRow
                        type={type}
                        value={value}
                        executed={false}
                        key={`approval-${approval.task.id}-detail-${index}`}
                      />
                    )
                  })
                : Object.entries(detailValues).map((item, index) => {
                    const [type, value] = item
                    return (
                      <TaskDetailRow
                        type={type}
                        value={value}
                        executed={false}
                        key={`nested-approval-${approval.task.id}-detail-${index}`}
                      />
                    )
                  }))}
          </div>
        </Panel>
      )}
      <Divider style={{ marginBottom: '10px' }}/>
    </span>
  )
}

export { ApprovalCardComponent }
