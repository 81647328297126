// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  items: []
})

const moduleName = 'dashboard'

const {
  dashboard,
  getDashboardRequest,
  getDashboardRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_DASHBOARD_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/dashboard/'
        })
      ],
      reducer: (state) => state,
      onError: (e) => getDashboardRequestFailure(e)
    },
    GET_DASHBOARD_REQUEST_SUCCESS: (state, action) =>
      state.set('items', fromJS(action.response)),
    GET_DASHBOARD_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export { dashboard, getDashboardRequest }
