// @flow
import * as Sentry from '@sentry/browser'

export const errorMiddleware = (store: Store) => (next: Function) => (
  action: Object
) => {
  const { meta, type } = action
  if (!meta || type.split('_').pop() !== 'FAILURE') {
    return next(action)
  } else {
    if (action.errors && action.errors.status) {
      const { status } = action.errors
      if (status) {
        Sentry.configureScope((scope) => {
          scope.addEventProcessor((event, hint) => {
            event.level = 'warning'
            return event
          })
        })
        Sentry.captureMessage(
          `API request error; type: ${action.type} errors: ${JSON.stringify(
            action.errors
          )}`
        )
      }
    } else {
      Sentry.captureException(action)
    }
    return next(action) // ??
  }
}
