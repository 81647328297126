// @flow

import React from 'react'

import {
  Button,
  ButtonToolbar,
  Icon,
  Panel,
  Form,
  FormGroup,
  FormControl,
  Whisper,
  Tooltip,
  IconButton,
  ControlLabel,
  InputNumber
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

const ImmutableDisk = (props: {|
  +id: ?string,
  +name: string,
  +index: number,
  +size: number,
  +type: string
|}) => {
  return (
    <span style={{ display: 'flex', justifyContent: 'left' }}>
      <span style={{ paddingRight: '15px' }}>
        <InputField
          disabled={props.type === 'immutable'}
          label={
            props.index === 0 && props.type === 'immutable' ? 'Disk name' : ''
          }
          value={props.name}
          name="immutable"
        />
      </span>
      <span style={{ paddingRight: '15px' }}>
        <InputField
          disabled={props.type === 'immutable'}
          label={
            props.index === 0 && props.type === 'immutable'
              ? 'Disk size in Gigabytes'
              : ''
          }
          value={props.size}
          postfix="GB"
          name="immutableDiskSize"
          max={2000}
          min={50}
          accepter={InputNumber}
        />
      </span>
    </span>
  )
}

const UserDisk = (props: {|
  +error: ?DiskError,
  +id: ?string,
  +vm: string,
  +name: string,
  +nameCheck: string,
  +sizeCheck: string,
  +index: number,
  +size: number,
  +type: string,
  +handleDeleteDiskSubmitDialog: Function,
  +deleteDiskByIndex: Function,
  +handleDiskSizeChange: Function,
  +handleDiskNameChange: Function
|}) => {
  return (
    <span style={{ display: 'flex', justifyContent: 'left' }}>
      <span style={{ paddingRight: '15px' }}>
        <InputField
          name={props.nameCheck}
          disabled={props.type === 'immutable' || !props.vm.isDiskConfigureable}
          label={
            props.index === 0 && props.type === 'immutable' ? 'Disk name' : ''
          }
          onChange={(value) => props.handleDiskNameChange(props.index, value)}
          value={props.name}
        />
      </span>
      <span style={{ paddingRight: '15px' }}>
        <InputField
          name={props.sizeCheck}
          disabled={props.type === 'immutable' || !props.vm.isDiskConfigureable}
          value={props.size}
          onChange={(value) => props.handleDiskSizeChange(props.index, value)}
          postfix="GB"
          max={2000}
          min={50}
          accepter={InputNumber}
        />
      </span>
      <span>
        {props.type === 'user' ? (
          props.id ? (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Delete Disk</Tooltip>}
            >
              <IconButton
                icon={<Icon icon="trash" />}
                color="orange"
                placement="right"
                disabled={!props.vm.isDiskConfigureable}
                onClick={() => props.handleDeleteDiskSubmitDialog(props)}
              >
                Delete
              </IconButton>
            </Whisper>
          ) : (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Delete Disk</Tooltip>}
            >
              <IconButton
                icon={<Icon icon="trash" />}
                color="orange"
                placement="right"
                disabled={props.vm.snapshotCreatedDatetime|| !props.isDiskConfigureable}
                onClick={() => props.deleteDiskByIndex(props.index)}
              >
                Delete
              </IconButton>
            </Whisper>
          )
        ) : (
          ''
        )}
      </span>
    </span>
  )
}

export const DiskConfigComponent = (props: {|
  +vm: Vm,
  +errors: {
    +disks: DiskError[]
  },
  +handleDiskAdd: Function,
  +deleteDiskByIndex: Function,
  +handleDiskNameChange: Function,
  +handleDiskSizeChange: Function,
  +handleOpenSubmitDialog: Function,
  +handleDeleteDiskSubmitDialog: Function
|}) => {
  const {
    vm,
    errors,
    handleDiskAdd,
    deleteDiskByIndex,
    handleDiskNameChange,
    handleDiskSizeChange,
    handleOpenSubmitDialog,
    handleDeleteDiskSubmitDialog
  } = props

  return (
    <Panel
      bordered
      header={
        <>
          <div
            style={{
              display: 'flex',
              marginRight: '-8px',
              justifyContent: 'space-between'
            }}
          >
            {/* {!vm.isDiskConfigureable ? <div style={{ color: 'orange' }}>Your Disks can't get configured yet.</div> : ''} */}
              <h5>Storage</h5>
            <span>
              <ButtonToolbar>
                <IconButton
                  icon={<Icon icon="plus" />}
                  appearance="ghost"
                  color='blue'
                  label="Submit"
                  type="submit"
                  id="add-disk"
                  disabled={vm.snapshotCreatedDatetime || !vm.isDiskConfigureable}
                  onClick={(e) => {
                    e.preventDefault()
                    handleDiskAdd()
                  }}
                >
                  Disk Add
                </IconButton>
              </ButtonToolbar>
            </span>
          </div>
          <div style={{ margingRight: '90px', marginLeft: '10px', marginTop: '10px', fontSize: '14px', color: '#0000FF' }}>
            Please note that disk size must range between 50-2000 GB, every field is required and allowed characters are A-Z, a-z and 0-9 (no spaces).
          </div>
        </>
      }
      style={{ marginTop: '15px' }}
    >
      <Form fluid checkTrigger="none">
        {vm.hardware.disks.immutable.map((disk, index) => (
          <ImmutableDisk
            key={`disk-immutable-${index}`}
            name={disk.name}
            index={index}
            size={disk.size + ''}
            type={'immutable'}
          />
        ))}
        {vm.hardware.disks.user.map((disk, index) => {
          return (
            <UserDisk
              id={disk.id}
              vm={vm}
              nameCheck={`disk${index}`}
              sizeCheck={`diskSize${index}`}
              deleteDiskByIndex={deleteDiskByIndex}
              handleDeleteDiskSubmitDialog={handleDeleteDiskSubmitDialog}
              handleDiskNameChange={handleDiskNameChange}
              handleDiskSizeChange={handleDiskSizeChange}
              key={`disk-user-${index}`}
              name={disk.name}
              index={index}
              size={disk.size}
              type={'user'}
            />
          )
        })}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
            marginRight: '-8px'
          }}
        >
          {/* {errors ? (
            <span style={{ margingRight: '100px', color: '#0000FF' }}>
              Hint: Every field is required; Allowed name characters: A-Z, a-z, 0-9 (no spaces).
            </span>
          ) : (
            <div></div>
          )} */}
          <ButtonToolbar>
            <Button
              appearance="ghost"
              color='blue'
              label="Submit"
              type="submit"
              id="submit-disk"
              disabled={vm.snapshotCreatedDatetime || !vm.isDiskConfigureable}
              onClick={(e) => {
                e.preventDefault()
                handleOpenSubmitDialog()
              }}
            >
              Submit
            </Button>
          </ButtonToolbar>
        </div>
      </Form>
    </Panel>
  )
}
