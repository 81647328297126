import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import {
  getIveRequest,
  getIveNetworksRequest,
  getVmTemplatesRequest,
  getVmTemplateRequest
} from 'modules/operations/ives'
import {
  setTemplateCatalogFormErrorState,
  clearTemplateCatalogFormErrorState
} from 'modules/components/templateCatalogForm'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

import { CreateVmFormComponent } from './CreateVmFormComponent'

const mapStateToProps = (state) => ({
  iveName: state.getIn(['ives', 'ive', 'name']),
  historyProfile: state.getIn(['ives', 'ive', 'historyProfile']),
  iveNetworks: state.getIn(['ives', 'networks']),
  vmTemplates: state.getIn(['ives', 'vmTemplateList']),
  vmTemplate: state.getIn(['ives', 'vmTemplate']),
  errors: state.getIn(['templateCatalogForm', 'errors'])
})

export class CreateVmFormContainer extends Component {
  static props: {
    +errors: {
      name: string,
      network: string,
      ip: string
    },
    +iveId: string,
    +vmTemplate: ?Object,
    +vmTemplateId: string,
    +getVmTemplatesRequest: Function,
    +getVmTemplateRequest: Function
  }

  constructor(props) {
    super(props)
    this.state = {
      vmname: '',
      vmdescription: '',
      network: '',
      ips: [],
      ip: '',
      username: '',
      password: '',
      vmTemplates: [],
      vmChosenTemplate: undefined,
      focusSet: false,
      capsLockWarning: false,
      template: '',
      disks: []
    }
    this.textRef = React.createRef()
  }
  componentDidMount() {
    const { iveId } = this.props.match.params
    this.props.getIveRequest({ iveId })
    this.props.getIveNetworksRequest({ iveId })
    this.props.getVmTemplatesRequest({ iveId })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.focusSet) {
      setTimeout(() => {
        this.textRef.current && this.textRef.current.focus()
      }, 5)
      this.setState({ focusSet: true })
    }
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }

  componentWillUnmount() {
    this.props.clearTemplateCatalogFormErrorState()
  }

  handleChange = (change) => {
    this.setState({ ...this.state, ...change })
  }

  handleGetTemplateInfo = (item) => {
    const { iveId } = this.props.match.params
    this.props.getVmTemplateRequest({iveId: iveId, vmTemplateId: item.id})
    // this.setState({ ...this.state.vmTemplateNew, vmTemplateNew: this.props.vmTemplate})
  }

  handleOnChangeTemplate = (value: IdentityObject, item: IdentityObject) => {
    // get template info for future disks sizes:
    if (item) {
      this.handleGetTemplateInfo(item) // update is a state refresh to late, hence the calculate button
      this.setState({ ...this.state.vmChosenTemplate, vmChosenTemplate: item })
    }
  }

  handleOnChangeNetwork = (value: IdentityObject, item: IdentityObject) => {
    this.setState({ ...this.state.network, network: item })
    this.setState({ ...this.state.ips, ips: item.availableIps, ip: '' })
  }

  handleOnChangeIp = (value: IdentityObject, item: IdentityObject) => {
    this.setState({ ...this.state.ip, ip: item })
  }

  render() {
    const {
      ips,
      vmname,
      vmdescription,
      network,
      username,
      password,
      ip,
      vmChosenTemplate,
      capsLockWarning
    } = this.state
    const {
      iveName,
      errors,
      setSubmitDialogOpenState,
      vmTemplates
    } = this.props
    const { iveId, vmTemplateId } = this.props.match.params
    const iveNetworks = this.props.iveNetworks.filter(
      (network) => network.dhcpEnabled
    )
    return [
      <CreateVmFormComponent
        textRef={this.textRef}
        key="vm-template-catalog-form"
        vmdescription={vmdescription}
        errors={errors}
        onKeyDown={this.onKeyDown}
        handleChange={this.handleChange}
        handleOnChangeTemplate={this.handleOnChangeTemplate}
        handleOnChangeNetwork={this.handleOnChangeNetwork}
        handleOnChangeIp={this.handleOnChangeIp}
        handleValidate={this.handleValidate}
        handleGetTemplateInfo={this.handleGetTemplateInfo}
        ip={ip}
        ips={ips}
        iveId={iveId}
        username={username}
        password={password}
        vmTemplateId={vmTemplateId}
        vmChosenTemplate={vmChosenTemplate}
        vmTemplates={vmTemplates}
        iveName={iveName}
        iveNetworks={iveNetworks}
        vmname={vmname}
        network={network}
        capsLockWarning={capsLockWarning}
        setIps={this.setIps}
        setSubmitDialogOpenState={setSubmitDialogOpenState}
      />
    ]
  }
}

export const CreateVmForm = connect(mapStateToProps, {
  setTemplateCatalogFormErrorState,
  clearTemplateCatalogFormErrorState,
  getIveRequest,
  getIveNetworksRequest,
  getVmTemplatesRequest,
  getVmTemplateRequest,
  setSubmitDialogOpenState
})(ToJs(CreateVmFormContainer))
