import { fromJS } from 'immutable'
import moducks from 'moducks-bootstrap'

const defaultState = fromJS({
  showHelp: false,
  backdrop: 'static',
  settings: { modalId: '' }
})
const moduleName = 'helpDialogModal'

const {
  helpDialogModal,
  sagas,
  setHelpModalOpenState,
  clearHelpModalOpenState
} = moducks.createModule(
  moduleName,
  {
    SET_HELP_MODAL_OPEN_STATE: (state, { payload: settings }) =>
      state.set('settings', fromJS(settings)).set('showHelp', true),
    CLEAR_HELP_MODAL_OPEN_STATE: (state) => state.set('showHelp', false)
  },
  defaultState
)

export { helpDialogModal, sagas, setHelpModalOpenState, clearHelpModalOpenState }
