// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { IpsecComponent } from './IpsecComponent'

import {
  getIveRequest,
  getIvesRequest,
  getIpsecSitesRequest,
  setIpsecSiteState,
  deleteIpsecSiteState
} from 'modules/operations/ives'
import { ToJs } from 'utils/ToJs'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type ReduxState = {|
  +ipsecSites: ipsecSites,
  +ipsecSitesMutated: Boolean,
  +ive: Ive,
  +ives: ives
|}
type ReduxActions = {|
  +setIpsecSiteState: Function,
  +deleteIpsecSiteState: Function,
  +getIveRequest: Function,
  +getIvesRequest: Function,
  +getIpsecSitesRequest: Function,
  +setSubmitDialogOpenState: Function
|}

type Props = ReduxState & ReduxActions & {| +match: Match |}

const mapStateToProps = (state) =>
  ({
    ive: state.getIn(['ives', 'ive']),
    ives: state.getIn(['ives', 'iveList']),
    ipsecSites: state.getIn(['ives', 'ipsecSites']),
    ipsecSitesMutated: state.getIn(['ives', 'ipsecSitesMutated'])
  }: ReduxState)

class IpsecContainer extends Component<Props> {
  props: Props

  addIpsecSite: Function
  deleteIpsecSite: Function

  componentDidMount() {
    const { iveId } = this.props
    this.props.getIveRequest({ iveId })
    this.props.getIpsecSitesRequest({ iveId })
    this.props.getIvesRequest()
  }

  addIpsecSite = (site: ipsecSite) => {
    const { ipsecSites } = this.props
    const sites = ipsecSites.ipsecSite.concat([site])
    const newSites = { ipsecSite: sites}
    this.props.setIpsecSiteState(newSites)
  }

  deleteIpsecSite = (index: number) => {
    this.props.deleteIpsecSiteState(index)
  }

  render() {
    const { iveId } = this.props
    return [
      <IpsecComponent
        ive={this.props.ive}
        ipsecSites={this.props.ipsecSites}
        iveId={iveId}
        ives={this.props.ives}
        ipsecSitesMutated={this.props.ipsecSitesMutated}
        deleteIpsecSite={this.deleteIpsecSite}
        addIpsecSite={this.addIpsecSite}
        setIpsecSiteState={this.props.setIpsecSiteState}
        deleteIpsecSiteState={this.props.deleteIpsecSiteState}
        setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
        key="ive-ipsec-sites"
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const IveIpsecSite = connect(
  mapStateToProps,
  ({
    getIveRequest,
    getIvesRequest,
    deleteIpsecSiteState,
    setIpsecSiteState,
    getIpsecSitesRequest,
    setSubmitDialogOpenState
  }: ReduxActions)
)(ToJs(IpsecContainer))
