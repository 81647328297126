// @flow

import { fromJS, Map } from 'immutable'

import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({ errors: {} })

const moduleName = 'templateCatalogForm'

export const {
  templateCatalogForm,
  sagas,
  setTemplateCatalogFormErrorState,
  clearTemplateCatalogFormErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_TEMPLATE_CATALOG_FORM_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_TEMPLATE_CATALOG_FORM_ERROR_STATE: (state) =>
      state.setIn(['errors'], Map({}))
  },
  defaultState
)
