import React, { useRef } from 'react'

import {
  Button,
  Panel,
  Form,
  FormGroup,
  FormControl,
  Container,
  Divider,
  ControlLabel,
  Schema,
  Content,
  Grid,
  Row,
  Col,
  Header,
  SelectPicker
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { Check } from 'react-feather'

type Props = {
  +textRef: Object,
  +datacenters: Array,
  +availabilityProfiles: Array,
  +historyProfiles: Array,
  +blueprints: Array,
  +blueprint: Object,
  +handleValidate: Function,
  +setSubmitDialogOpenState: Function,
  +handleChange: Function,
  +handleSubnetChange: Function,
  +handleOnChangeBlueprint: Function,
  +handleOnChangeLocation: Function,
  +handleOnChangeAvailabilityProfile: Function,
  +handleOnChangeHistoryProfile: Function,
  +ivename: string,
  +localSubnet: string,
  +ivedescription: string,
  +datacenter: Object,
  +availabilityProfile: Object,
  +historyProfile: Object,
  +iveListSummary: string
}

const { StringType } = Schema.Types
const model = Schema.Model({
  Name: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_.-]*$/, 'Please use only letters, numbers and _ - ')
    .maxLength(35, 'The maximum number of characters is 35'),
  Description: StringType()
    .isRequired('This field is required.')
    .maxLength(40, 'The maximum number of characters is 40'),
  localSubnet: StringType()
    .isRequired('This field is required.')
    .pattern(/(^192\.168\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.[0]$)|(^172\.([1][6-9]|[2][0-9]|[3][0-1])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.[0]$)|(^10\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.[0]$)/, 'Please use private IPv4 network spaces - 10.x.x.0, 172.16.x.0, 192.168.x.0'),
  blueprint: StringType().isRequired('This field is required.'),
  availabilityProfile: StringType().isRequired('This field is required.'),
  historyProfile: StringType().isRequired('This field is required.'),
  datacenter: StringType().isRequired('This field is required.')
})

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

export const CreateIveFormComponent = (props: Props) => {
  const {
    blueprint,
    blueprints,
    availabilityProfile,
    availabilityProfiles,
    datacenter,
    datacenters,
    ivedescription,
    setSubmitDialogOpenState,
    handleChange,
    handleSubnetChange,
    handleOnChangeBlueprint,
    handleOnChangeLocation,
    handleOnChangeAvailabilityProfile,
    handleOnChangeHistoryProfile,
    historyProfile,
    historyProfiles,
    ivename,
    localSubnet,
    iveListSummary
  } = props
  var createIveForm = useRef(null)

  // check for duplicate networks, which disable the submit.
  const checkDuplicate = iveListSummary && iveListSummary.results.map(e => e.children.map(child => child.network.includes(localSubnet)))
  const checkDuplicateNetwork = checkDuplicate && checkDuplicate.map(e => e.includes(true))

  // const bare blueprint list ready for IVE configuration
  function bareBlueprintList() {
    let blueprintList = []
    blueprints && blueprints.forEach((bluep) => {
      if (bluep.imageUrl == null) {
        blueprintList = blueprintList.concat(bluep)
      }
    })
    return blueprintList
  }
 

  return (
    <Container
      style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', maxHeight: '90vh' }}
    >
      <Header
        style={{
          height: '60px',
          // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)',
          background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
        }}
      >
        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
          Deploy Isolated Virtual Environment
        </h3>
      </Header>
      <Content style={{ overflow: "auto" }}>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Panel>
                <Form
                  model={model}
                  ref={(ref) => (createIveForm = ref)}
                  fluid
                  checkTrigger="none"
                >
                  <Divider>Isolated Virtual Environment settings</Divider>
                  <InputField
                    name="blueprint"
                    label="IVE template"
                    style={{ width: 'inherit' }}
                    id="ive-network-size"
                    labelKey="name"
                    valueKey="id"
                    data={bareBlueprintList()}
                    accepter={SelectPicker}
                    searchable={false}
                    onSelect={handleOnChangeBlueprint}
                    onClean={handleOnChangeBlueprint}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                  />
                  <InputField
                    name="localSubnet"
                    label="Local Subnet"
                    style={{ width: 'inherit' }}
                    id="local-network"
                    disabled={!blueprint}
                    onChange={(localSubnet, event) =>
                      handleSubnetChange({ localSubnet, event })
                    }
                  />
                  <InputField
                    name="datacenter"
                    label="Select a location"
                    style={{ width: 'inherit' }}
                    id="ive-location"
                    labelKey="name"
                    valueKey="id"
                    data={datacenters}
                    onSelect={handleOnChangeLocation}
                    onClean={handleOnChangeLocation}
                    accepter={SelectPicker}
                    searchable={false}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                  />
                  <InputField
                    name="availabilityProfile"
                    label="Select an availability profile"
                    style={{ width: 'inherit' }}
                    id="ive-availability-profile"
                    labelKey="name"
                    valueKey="id"
                    data={availabilityProfiles}
                    onSelect={handleOnChangeAvailabilityProfile}
                    onClean={handleOnChangeAvailabilityProfile}
                    accepter={SelectPicker}
                    searchable={false}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                  />
                  <InputField
                    name="historyProfile"
                    label="Select a history profile"
                    style={{ width: 'inherit' }}
                    id="ive-history-profile"
                    labelKey="name"
                    valueKey="id"
                    data={historyProfiles}
                    onSelect={handleOnChangeHistoryProfile}
                    onClean={handleOnChangeHistoryProfile}
                    accepter={SelectPicker}
                    searchable={false}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                  />
                  <Divider>IVE Personalization</Divider>
                  <InputField
                    name="Name"
                    label="Name"
                    type="name"
                    onChange={(ivename, event) =>
                      handleChange({ ivename, event })
                    }
                  />
                  <InputField
                    name="Description"
                    label="Description"
                    type="description"
                    onChange={(ivedescription, event) =>
                      handleChange({ ivedescription, event })
                    }
                    style={{ marginTop: '-10px' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '25px',
                      marginBottom: '15px'
                    }}
                  >
                    <Button
                      appearance="primary"
                      type="submit"
                      id="ive-deploy"
                      disabled={checkDuplicateNetwork.includes(true)}
                      onClick={(e) => {
                        if (createIveForm.check()) {
                          console.log(localSubnet)
                          e.preventDefault()
                          setSubmitDialogOpenState({
                            dialogId: 'DEPLOY_IVE',
                            payload: {
                              name: ivename,
                              subnet: localSubnet,
                              description: ivedescription,
                              blueprintId: blueprint.id,
                              datacenterId: datacenter.id,
                              availabilityProfileId: availabilityProfile.id,
                              historyProfileId: historyProfile.id
                            },
                            meta: { name: ivename }
                          })
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Panel>
            </Col>
            <Col md={12}>
              <Panel header={<h4>Summary</h4>}>
                {blueprint ? (
                  <>
                    <h5>Template</h5>
                    <ul key="blueprint">
                      <li key="createive-blueprint">
                        <strong>{blueprint.name}</strong>
                      </li>
                      <div>
                        A {blueprint.name} has {blueprint.description} places in
                        its isolated network on which you can deploy Virtual
                        Machines. Please ensure this is enough for your expected
                        workload. <br></br>
                      </div>
                      <br></br>
                      {localSubnet ? (
                        <>
                          <strong>{localSubnet}</strong> will be the local network where your Virtual Machines are deployed. The size of the network is determined by the IVE template and is set automatically.
                          <br></br>
                          {checkDuplicateNetwork.includes(true) ? <strong style={{ color: '#FF0000'}}>{localSubnet} is already in use!</strong> : ''}
                        </>
                      ) : (
                        ''
                      )}
                    </ul>
                  </>
                ) : (
                  ''
                )}
                {datacenter ? (                  
                  <>
                    <h5>Data availablity</h5>
                    <ul key="blueprint">
                      <li key="createive-dc">
                        The primary location of this IVE will be{' '}
                        <strong>{datacenter.city}</strong> in{' '}
                        <strong>{datacenter.country}</strong>
                      </li>
                      <br></br>
                      {availabilityProfile ? (
                        <>
                          <li key="createive-availablity">
                            <strong>{availabilityProfile.name}</strong>
                          </li>
                          <div>{availabilityProfile.description}</div>
                          <br></br>
                        </>
                      ) : (
                        ''
                      )}
                      {historyProfile ? (
                        <>
                          <li key="createive-history">
                            <strong>{historyProfile.name}</strong>
                          </li>
                          <div>{historyProfile.description}</div>
                          <br></br>
                        </>
                      ) : (
                        ''
                      )}
                    </ul>
                  </>
                ) : (
                  ''
                )}
                {ivename ? (
                  <>
                    <h5>Personalization</h5>
                    <ul key="createive-personalization">
                      <li key="createive-name">
                        <strong>{ivename}</strong>
                      </li>
                      {ivedescription ? <div>{ivedescription}</div> : ''}
                    </ul>
                  </>
                ) : (
                  ''
                )}

                {blueprint &&
                localSubnet &&
                datacenter &&
                availabilityProfile &&
                historyProfile &&
                ivename &&
                ivedescription ? (
                  <>
                    <div
                      style={{
                        marginLeft: '25px',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <h3>Ready to deploy.</h3>
                      <Check color="green" size={50} />
                    </div>
                  </>
                ) : (
                  ''
                )}
              </Panel>
            </Col>
          </Row>
        </Grid>
      </Content>
    </Container>
  )
}
