import React from 'react'

import { Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

export const Faq = () => (
    <Panel 
        style={{ width: '80%'}}
    >
    <h5>Frequently Asked Questions</h5>
    <div style={{ marginTop: '15px' }}>
        <h5 style={{ color: '#FF8A00', marginBottom: '5px' }}>Isolated Virtual Environments</h5>

        <strong style={{ fontSize: '16px' }}>What is an Isolated Virtual Environment?</strong><br style={{ marginBottom: '5px' }}></br>
        An Isolated Virtual Environment (IVE) is your secure and validated personal environment. Access to it is restricted and 
        controlled by the included Firewall appliance. This single point off access has its own unique public IP address 
        on which traffic can connect. You decide who and what is allowed to do so by managing the security rules of the IVE.
        Connected to the Firewall appliance is your local network in which you can deploy the servers you need
        to run your application or office environment.
        <br></br>
        <br></br>
        Offcourse all validated and qualified by our GxP-Cloud Cloud Management Console.

        <br></br>
        <br></br>
        <strong style={{ fontSize: '16px' }}>IVE Locked?</strong><br style={{ marginBottom: '5px' }}></br>
        An Isolated Virtual Environment gets locked to protect your environment. 
        Validation and Installation & Qualification has probably gone wrong. 
        To ensure your environment is configured as it is supposed to be, 
        we lock your IVE and Manual intervention of our team is needed. Please contact support.
        <br></br>
        <br></br>
        When your payments are due or not succeeded, after 14 days your IVE gets locked too. 
        If you are having trouble with this, please contact support.

        <br></br>
        <br></br>
        <strong style={{ fontSize: '16px' }}>Security Rules</strong><br style={{ marginBottom: '5px' }}></br>
        An Isolated Virtual Environment is out of the box restricted for access. You can manage and control the access to your environment 
        by setting your own security rules for the IVE. Do this with setting your Firewall and NAT rules, SSL-VPN and IPSEC-VPN.

    </div>

    <div style={{ marginTop: '15px' }}>
        <h5 style={{ color: '#FF8A00', marginBottom: '5px' }}>Virtual Machines</h5>
        <strong style={{ fontSize: '16px' }}>Acces to Virtual Machine</strong><br style={{ marginBottom: '5px' }}></br>
        When deployed the fastest way to access the VM is by our build-in Remote Console via the IVE-dashboard on VM level.
        This however is not the preferred way for access and is only used for setup of the initial first configurations.
        The solution to access the VM is to setup your IVE SSL-VPN accounts and Firewall rules to give SSH (linux) or RDP (windows) {' '}
        access from when your SSL-VPN is connected.
        <br></br>
        <br></br>
        To access the VM you will need your given credentials during deployment. 
        If your having trouble with the access to your VM or you have lost your credentials, please contact support.

        <br></br>
        <br></br>
        <strong style={{ fontSize: '16px' }}>Upgrade your Virtual Machine</strong><br style={{ marginBottom: '5px' }}></br>
        When deployed, Virtual Machines have the minimum amounts of hardware compute specified by the Operation System.
        Most of the time workload require an upgrade of this hardware. You can do this via the IVE-Dashboard on VM level.
        Here you can change the Asset-tier of the VM and add/expand disksizes.

        <br></br>
        <br></br>
        <strong style={{ fontSize: '16px' }}>Powered off your Virtual Machine in the Operating System</strong><br style={{ marginBottom: '5px' }}></br>
        When deployed, Virtual Machines power state are tracked by the CMC. When you power off the VM in the 
        operating system the CMC is not aware of this. There is a power-state mismatch arised and you will need to contact 
        support to resolve this issue.
        <br></br>
        <br></br>
        Be sure to always Reboot inside the VM's OS. And/or manage the power-state inside the Cloud Management Console.

    </div>
    
</Panel>
)