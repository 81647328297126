import React from 'react'
import { Link } from 'react-router-dom'

import {
  Panel,
  Container,
  Content,
  FlexboxGrid,
  Header,
  Divider,
  Whisper,
  Tooltip,
  Button

} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import gxplogo from '../../../img/GxP Cloud_Logo_Orange.svg';
import { BlueprintDialog } from './blueprintdialog/BlueprintDialogContainer';

type Props = {
  +blueprints: Array,
  +blueprint: Object,
  +setSubmitDialogOpenState: Function,
  +getBlueprintRequest: Function
}


export const IveCatalogueComponent = (props: Props) => {
  const {
    blueprints,
    setSubmitDialogOpenState,
    getBlueprintRequest
  } = props

  return (
    <Container
      style={{ 
        backgroundColor: '#FFFFFF', 
        boxShadow: '3px 0 2px 0 #e0e0e0', 
        height: '90vh' 
      }}
    >
      <Header
        style={{
          height: '60px',
          background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)',
          marginBottom: '15px'
        }}
      >
        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
          GxP-Cloud Blueprint Catalogue
        </h3>
      </Header>
      <Content style={{ overflow: "auto" }}>
        <FlexboxGrid justify="start">

            {blueprints && blueprints.map((blueprintItem, index) => {
              // Check for image to determine if this is not a Bare Blueprint
              if (blueprintItem.imageUrl) {
                return (
                  <FlexboxGrid.Item 
                    colspan={7}
                    style={{ marginLeft: '20px', marginBottom: '20px'}}
                  >
                      {/* <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={<Tooltip>Start the deployment of this Blueprint.</Tooltip>}
                      > */}
                        <Panel 
                            shaded 
                            bordered 
                            bodyFill 
                            style={{ 
                                display: 'inline-block', 
                                height: '320px',
                                width: '100%', 
                                // cursor: 'pointer'
                            }}
                        >
                        <img src={blueprintItem.imageUrl} 
                            width='220px' 
                            height='40px'
                            alt='logo' 
                            style={{ objectFit: 'contain', objectPosition: 'left', margin: '20px 0px 0px 20px'}}
                        />
                            <Panel header={<div><strong>{blueprintItem.name}</strong> <small> V{blueprintItem.version}</small></div>}>
                            <p>
                            <Divider style={{ marginTop: '-20px', marginBottom: '5px' }}/>
                                <small>
                                  {blueprintItem.description}
                                </small>
                            </p>
                            <div style={{ width: 'inherit', position: 'absolute', right: '20px', bottom: '20px' }}>
                              <BlueprintDialog
                                blueprintItem={blueprintItem}
                                setSubmitDialogOpenState={setSubmitDialogOpenState}
                                getBlueprintRequest={getBlueprintRequest}
                                key={`${index}BlueprintItem`}
                              >
                                
                                <Button
                                  color="blue"
                                  appearance='ghost'
                                  primary="true"
                                  type="submit"
                                >
                                  Choose Blueprint
                                </Button>
                              </BlueprintDialog>
                            </div>
                            </Panel>
                        </Panel>
                      {/* </Whisper> */}
                  </FlexboxGrid.Item>
                )
              } return null
            })}

            <FlexboxGrid.Item 
              colspan={7}
              style={{ marginLeft: '20px', marginBottom: '20px'}}
            >

                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Start with configuring your Blueprint.</Tooltip>}
                >
                <Panel 
                  shaded 
                  bordered 
                  bodyFill 
                  style={{ 
                      display: 'inline-block', 
                      height: '320px',
                      width: '100%', 
                      cursor: 'pointer',
                      color: 'black'
                  }}
                >
                <img src={gxplogo} 
                    width='220px' 
                    alt='logo' 
                    style={{ objectFit: 'contain', objectPosition: 'left', margin: '20px 0px 0px 20px'}}
                />
                    <Panel header={<strong>Configurable IVE</strong>}>
                    <p>
                    <Divider style={{ marginTop: '-20px', marginBottom: '5px' }}/>
                        <small>
                          Configure your own Isolated Virtual Environment by chosing your own LAN network address and size, history profile and availability profile.
                        </small>
                    </p>
                    <div style={{ width: 'inherit', position: 'absolute', right: '20px', bottom: '20px' }}>
                      <Link to='/createive'>
                        <Button
                          color="blue"
                          appearance='ghost'
                          primary="true"
                          type="submit"
                        >
                            Configure Blueprint
                        </Button>
                      </Link>
                    </div>
                    </Panel>
                </Panel>
                </Whisper>

            </FlexboxGrid.Item>

        </FlexboxGrid>
      </Content>
    </Container>
  )
}
