import React, { useRef } from 'react';

import {
  Button,
  Panel,
  FormGroup,
  ControlLabel,
  FormControl,
  ButtonToolbar,
  Table,
  SelectPicker,
  Form,
  Schema,
  Whisper,
  Tooltip,
  Icon
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { Column, HeaderCell, Cell } = Table

const { StringType } = Schema.Types
const model = Schema.Model({
  vmTiers: StringType()
    .isRequired('This field is required.')})

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

export const HardwareChangeComponent = (props: {|
  +vm: Vm,
  +errors: {
    +disks: DiskError[]
  },
  +vmTierName: String,
  +assetTiers: Array,
  +handleOpenSubmitDialog: Function,
  +handleChange: Function
|}) => {

  const { vm, handleChange, handleOpenSubmitDialog, assetTiers } = props
  var changeTierForm = useRef(null)

  return (
    <Panel bordered>
      {vm && vm.snapshotCreatedDatetime
            ? (<span style={{ color: '#ff0000', marginRight: '50px' }}>A snapshot is present for VM {vm.name} which limits operations.</span>)
            : ('')
          }    
      {/* {!vm.isHardwareChangeable ? <div style={{ color: 'orange' }}>Hardware can't get configured yet.</div> : ''} */}
        <div>
        <Table
              height={500}
              data={assetTiers}
            >
              <Column width={300} align="center">
                <HeaderCell><strong>VM Tier</strong></HeaderCell>
                <Cell dataKey="name"/>
              </Column>

              <Column width={100}>
                <HeaderCell><strong>vCPU count</strong></HeaderCell>
                <Cell dataKey="cpuCores" />
              </Column>

              <Column width={100}>
                <HeaderCell><strong>Memory (GB)</strong></HeaderCell>
                <Cell dataKey="memory" />
              </Column>

              <Column width={40}>
                <HeaderCell></HeaderCell>
                <Cell>
                  {(rowData) => {
                    if (vm.assetTier) {
                      if (rowData.name === vm.assetTier.name){
                        return(
                          <span>
                            <Whisper
                              placement="top"
                              trigger="hover"
                              speaker={<Tooltip>Your VM is {vm.assetTier.name} now.</Tooltip>}
                            >
                              <Icon icon="check"/>
                            </Whisper>
                          </span>
                        )
                    }}}}
                </Cell>
              </Column>
            </Table>
          </div>
          <div>
            <strong>Select VM Tier you want to change too.</strong>
          </div>
          <Form
              model={model}
              ref={(ref) => (changeTierForm = ref)}
              fluid
              checkTrigger="none"
            >
          <div>
          <InputField
            name="vmTiers"
            label=" "
            style={{ width: 'inherit' }}
            id="vmTiers"
            onChange={(vmTierName, event) =>
              handleChange({ vmTierName, event })
            }
            labelKey="name"
            valueKey="name"
            data={assetTiers}
            disabled={vm.snapshotCreatedDatetime || !vm.isHardwareChangeable}
            placeholder="VM Tier will determine your VM Hardware specs"
            searchable={false}
            accepter={SelectPicker}
          />
          </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
            marginRight: '-8px'
          }}
        >
          {vm.assetTier && props.vmTierName === vm.assetTier.name
            ? (<span style={{ color: '#ff0000', marginRight: '50px' }}>VM is already on AssetTier {props.vmTierName}.</span>)
            : ('')
          }        
          <ButtonToolbar>
            <Button
              appearance="ghost"
              color='blue'
              label="Submit"
              type="submit"
              id="vm-deploy"
              disabled={(vm.assetTier && props.vmTierName === vm.assetTier.name) || vm.snapshotCreatedDatetime || !vm.isHardwareChangeable}
              onClick={(e) => {
                if (changeTierForm.check()) {
                  e.preventDefault()
                  handleOpenSubmitDialog()
                }
              }}
            >
              Submit
            </Button>
          </ButtonToolbar>          
        </div>
      </Form>
    </Panel>
  )
}
