// @flow

import React from 'react'
import moment from 'moment-timezone'

import { Button, Modal, Row, Col } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

// import { TaskDetailRow } from 'components/TaskDetailRow'
import { TaskStatusChip } from './TaskStatusChipComponent'

const style = { button: { cursor: 'pointer' }, row: { marginBottom: '15px' } }

const isTaskActive = (status: string) =>
  status === 'PENDING APPROVAL' || status === 'PENDING' || status === 'STARTED'

const IveRow = (props: {| +task: Task, +history: Object |}) => (
  <Row style={style.row}>
    <Col md={10}>
      <div>[IVE]</div>
    </Col>
    <Col md={14}>
      {props.task.status === 'PENDING APPROVAL' ||
      props.task.status === 'PENDING' ||
      props.task.status === 'STARTED' ? (
        <span>
          <strong>{props.task.ive.name}</strong>
        </span>
      ) : (
        <span>
          <strong>{props.task.ive.name}</strong>
        </span>
        // <IconButton
        //   icon={<Icon icon="cloud" />}
        //   onClick={() => props.history.push(`/ives/${props.task.ive.id}`)}
        //   appearance="primary"
        //   label={props.task.ive.name}
        //   id="task-ive-name"
        // >
        //   {props.task.ive.name}
        // </IconButton>
      )}
    </Col>
  </Row>
)

const VmRow = (props: {| +task: Task, +history: Object |}) => (
  <Row style={style.row}>
    <Col md={10}>
      <div>[VM]</div>
    </Col>
    <Col md={14}>
      {isTaskActive(props.task.status) ? (
        <span>
          <strong>{props.task.vm.name}</strong>
        </span>
      ) : (
        <span>
          <strong>{props.task.vm.name}</strong>
        </span>
        // <IconButton
        //   icon={<Icon icon="cloud" />}
        //   onClick={() => props.history.push(`/ives/${props.task.ive.id}`)}
        //   appearance="primary"
        //   label={props.task.vm.name}
        //   id="task-vm-name"
        // >
        //   {props.task.vm.name}
        // </IconButton>
      )}
    </Col>
  </Row>
)

export const TaskInfoDialogComponent = (props: {|
  +disabled: boolean,
  +task: Task,
  +detail: ?any,
  +timezone: string,
  +open: boolean,
  +handleOpen: Function,
  +handleClose: Function,
  +history: Object
|}) => {
  const {
    task,
    timezone,
    // detail,
    open,
    handleOpen,
    handleClose,
    history
  } = props

  // const [detailValues] = Object.values(detail || {})

  return (
    <span id={`task-info-${task.id}`}>
      <Button
        onClick={handleOpen}
        appearance="link"
        style={{ cursor: 'pointer' }}
      >
        Info
      </Button>
      <Modal
        backdrop="static"
        modal={false}
        onHide={handleClose}
        show={open}
        size="xs"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title style={style.row}>{task.name}</Modal.Title>
        </Modal.Header>
        {task.ive && <IveRow task={task} history={history} />}
        {task.vm && <VmRow task={task} history={history} />}
        <Row style={style.row}>
          <Col md={10}>Status</Col>
          <Col md={14}>
            <strong>
              <TaskStatusChip status={task.status} />
            </strong>
          </Col>
        </Row>
        {task.executionDatetime && (
          <Row style={style.row}>
            <Col md={10}>Execution date</Col>
            <Col md={14}>
              <strong>
                {moment
                  .tz(task.executionDatetime, timezone)
                  .format('DD MMM YYYY HH:mm:ss zz')}
              </strong>
            </Col>
          </Row>
        )}
        {task.createdDatetime && (
          <Row style={style.row}>
            <Col md={10}>Created date</Col>
            <Col md={14}>
              <strong>
                {moment
                  .tz(task.createdDatetime, timezone)
                  .format('DD MMM YYYY HH:mm:ss')}
              </strong>
            </Col>
          </Row>
        )}
        <Row style={style.row}>
          <Col md={10}>Initiator</Col>
          <Col md={14}>
            <strong>{task.initiatorFullName}</strong>
          </Col>
        </Row>
        {task.approverFullName && (
          <Row style={style.row}>
            <Col md={10}>Approver</Col>
            <Col md={14}>
              <strong>{task.approverFullName}</strong>
            </Col>
          </Row>
        )}
        {/* {detailValues &&
          (Array.isArray(detailValues)
            ? detailValues.map((item, index) => {
                const [[key, value]] = Object.entries(item)
                return (
                  <TaskDetailRow
                    type={key}
                    value={value}
                    key={`task-${task.id}-detail-${index}`}
                    executed={!isTaskActive(task.status)}
                  />
                )
              })
            : Object.entries(detailValues).map((item, index) => {
                const [key, value] = item
                return (
                  <TaskDetailRow
                    style={style.row}
                    type={key}
                    value={value}
                    key={`nested-task-${task.id}-detail-${index}`}
                    executed={!isTaskActive(task.status)}
                  />
                )
              }))} */}
        <span
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Modal.Footer>
            <Button onClick={props.handleClose} color="orange">
              Return
            </Button>
          </Modal.Footer>
        </span>
      </Modal>
    </span>
  )
}
