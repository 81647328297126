// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  reports: [],
  sslVpnAccounts: []
})

const moduleName = 'overview'

const {
  overview,
  sagas,
  getOverviewReportsRequest,
  getOverviewReportsRequestFailure,
  getOverviewSslVpnAccountsRequest,
  getOverviewSslVpnAccountsRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_OVERVIEW_REPORTS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/overview/reports/'
        })
      ],
      onError: (e) => getOverviewReportsRequestFailure(e)
    },
    GET_OVERVIEW_REPORTS_REQUEST_SUCCESS: (state, action) =>
      state.set('reports', fromJS(action.response.results)),
    GET_OVERVIEW_REPORTS_REQUEST_FAILURE: (state) => state,

    GET_OVERVIEW_SSL_VPN_ACCOUNTS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/overview/ssl-vpn/accounts/'
        })
      ],
      onError: (e) => getOverviewSslVpnAccountsRequestFailure(e)
    },
    GET_OVERVIEW_SSL_VPN_ACCOUNTS_REQUEST_SUCCESS: (state, action) =>
      state.set('sslVpnAccounts', fromJS(action.response.results)),
    GET_OVERVIEW_SSL_VPN_ACCOUNTS_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export {
  overview,
  sagas,
  getOverviewReportsRequest,
  getOverviewSslVpnAccountsRequest
}
