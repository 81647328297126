// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { AccountPasswordComponent } from './AccountPasswordComponent'
import { ToJs } from 'utils/ToJs'
import { putChangePasswordRequest } from 'modules/operations/account'
import {
  setAccountPasswordFormErrorState,
  clearAccountPasswordFormErrorState
} from 'modules/components/accountPasswordForm'

const mapStateToProps = (state) => {
  let errors = state.getIn(['accountPasswordForm', 'errors'])
  const requestErrors = state.getIn(['account', 'errors'])
  if (!errors.get('currentPassword') && requestErrors.get('detail')) {
    errors = errors.set('currentPassword', requestErrors.get('detail'))
  }
  if (!errors.get('newPassword') && requestErrors.get('nonFieldErrors')) {
    errors = errors.set('newPassword', requestErrors.get('nonFieldErrors'))
  }
  return {
    errors
  }
}

type Props = {|
  errors: AccountPasswordErrors,
  putChangePasswordRequest: Function,
  setAccountPasswordFormErrorState: Function,
  clearAccountPasswordFormErrorState: Function,
  currentPasswordRef: Function
|}

class AccountPasswordContainer extends Component<Props, AccountPasswordState> {
  props: Props
  state: AccountPasswordState

  state = {
    currentPassword: '',
    newPassword: '',
    repeatedPassword: '',
    capsLockWarning: false
  }

  componentWillUnmount() {
    this.props.clearAccountPasswordFormErrorState()
  }

  putChangePassword = () => {
    this.props.clearAccountPasswordFormErrorState()
    const { currentPassword, newPassword, repeatedPassword } = this.state
    this.props.putChangePasswordRequest({
      currentPassword,
      newPassword,
      repeatedPassword
    })
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }

  handleChange: AccountPasswordHandleChange = (change) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }

  render() {
    return (
      <AccountPasswordComponent
        currentPasswordRef={this.props.currentPasswordRef}
        handleChange={this.handleChange}
        formData={this.state}
        capsLockWarning={this.state.capsLockWarning}
        onKeyDown={this.onKeyDown}
        putChangePassword={this.putChangePassword}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const AccountPassword = connect(mapStateToProps, {
  putChangePasswordRequest,
  setAccountPasswordFormErrorState,
  clearAccountPasswordFormErrorState
})(ToJs(AccountPasswordContainer))
