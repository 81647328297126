// @flow

import React, { Component } from 'react'

import { ApprovalCardComponent } from './ApprovalCardComponent'

type Props = {|
  approval: Approval,
  selected: boolean,
  timezone: string
|}

type State = {| expanded: boolean |}

class ApprovalCardContainer extends Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)
    // Selected is only used to seed the approval Card
    // E.g., it is true when opening an approval from email,
    // but the component does not need to be aware when another card is selected
    this.state = { expanded: this.props.selected }
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render = () => {
    const { approval, selected, timezone } = this.props
    return (
      <ApprovalCardComponent
        approval={approval}
        selected={selected}
        timezone={timezone}
        expanded={this.state.expanded}
        toggleExpanded={this.toggleExpanded}
      />
    )
  }
}
export { ApprovalCardContainer as ApprovalCard }
