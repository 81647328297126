import React, { Component } from 'react'
import { connect } from 'react-redux'

import { CreateIveFormComponent } from './CreateIveFormComponent'
import { getDatacentersRequest } from 'modules/operations/datacenters'
import {
  getAvailabilityProfilesRequest,
  getHistoryProfilesRequest
} from 'modules/operations/slp'
import { getBlueprintsRequest } from 'modules/operations/blueprints'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'
import { ToJs } from 'utils/ToJs'
import {
  setBlueprintCatalogFormErrorState,
  clearBlueprintCatalogFormErrorState
} from 'modules/components/blueprintCatalogForm'

const mapStateToProps = (state) => ({
  username: state.getIn(['account', 'username']),
  datacenters: state.getIn(['datacenters']),
  availabilityProfiles: state.getIn(['slp', 'availabilityProfiles']),
  historyProfiles: state.getIn(['slp', 'historyProfiles']),
  blueprints: state.getIn(['blueprints', 'blueprintList']),
  errors: state.getIn(['blueprintCatalogForm', 'errors']),
  iveListSummary: state.getIn(['ivelistsummary', 'iveListSummary'])
})

export class CreateIveFormContainer extends Component {
  static props: {
    +errors: {
      name: string,
      datacenter: string,
      availabilityProfile: string,
      historyProfile: string
    },
    +getDatacentersRequest: Function,
    +getAvailabilityProfilesRequest: Function,
    +getHistoryProfilesRequest: Function,
    +getBlueprintsRequest: Function,
    +setSubmitDialogOpenState: Function
  }

  constructor(props) {
    super(props)
    this.state = {
      datacenters: [],
      availabilityProfiles: [],
      historyProfiles: [],
      blueprints: {},
      ivename: '',
      ivedescription: '',
      availabilityProfile: undefined,
      datacenter: undefined,
      blueprint: undefined,
      localSubnet: '',
      historyProfile: undefined,
      focusSet: false
    }
    this.textRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      process.env.NODE_ENV === 'development' &&
      this.props.username !== prevProps.username
    ) {
      this.setState({ name: '' })
    }
    if (!this.state.focusSet) {
      setTimeout(() => {
        this.textRef.current && this.textRef.current.focus()
      }, 5)
      this.setState({ focusSet: true })
    }
  }

  componentDidMount() {
    this.props.getDatacentersRequest()
    this.props.getAvailabilityProfilesRequest()
    this.props.getHistoryProfilesRequest()
    this.props.getBlueprintsRequest()
    // if (process.env.NODE_ENV === 'development' && this.props.username) {
    //   this.setState({ ivename: 'Hen' })
    // }
  }

  componentWillUnmount() {
    this.props.clearBlueprintCatalogFormErrorState()
  }

  handleChange = (change) => {
    this.setState({ ...this.state, ...change })
  }

    // Make sure the IVE blueprint names are the same every environment
    // When creating Customized Blueprints, the else statement makes sure the dataflow is continued, but the real network must be set by hand.
  handleSubnetChange = (change) => {
    if (this.state.blueprint.name === 'BareIVE 5 hosts') {
      let newValue = `${change.localSubnet}/29`
      this.setState({ ...this.state,  localSubnet: newValue })
    } else if (this.state.blueprint.name === 'BareIVE 13 hosts') {
      let newValue = `${change.localSubnet}/28`
      this.setState({ ...this.state,  localSubnet: newValue })
    } else if (this.state.blueprint.name === 'BareIVE 29 hosts') {
      let newValue = `${change.localSubnet}/27`
      this.setState({ ...this.state,  localSubnet: newValue })
    } else if (this.state.blueprint.name === 'BareIVE 61 hosts') {
      let newValue = `${change.localSubnet}/26`
      this.setState({ ...this.state,  localSubnet: newValue })
    } else if (this.state.blueprint.name === 'BareIVE 125 hosts') {
      let newValue = `${change.localSubnet}/25`
      this.setState({ ...this.state,  localSubnet: newValue })
    } else {
      let newValue = `${change.localSubnet}/29`
      this.setState({ ...this.state,  localSubnet: newValue })
    }
  }

  // Rsuite onSelect returns value and item, where we want the item as object in Functions
  // Changing blueprint resets subnet input
  handleOnChangeBlueprint = (value: IdentityObject, item: IdentityObject) => {
    this.setState({ ...this.state.blueprint, blueprint: item })
    this.setState({ ...this.state.localSubnet,  localSubnet: '' })
  }

  handleOnChangeLocation = (value: IdentityObject, item: IdentityObject) => {
    this.setState({ ...this.state.datacenter, datacenter: item })
  }

  handleOnChangeAvailabilityProfile = (
    value: IdentityObject,
    item: IdentityObject
  ) => {
    this.setState({
      ...this.state.availabilityProfile,
      availabilityProfile: item
    })
  }

  handleOnChangeHistoryProfile = (
    value: IdentityObject,
    item: IdentityObject
  ) => {
    this.setState({ ...this.state.historyProfile, historyProfile: item })
  }

  render() {
    const {
      ivename,
      localSubnet,
      ivedescription,
      blueprint,
      datacenter,
      availabilityProfile,
      historyProfile
    } = this.state

    const {
      datacenters,
      availabilityProfiles,
      blueprints,
      historyProfiles,
      setSubmitDialogOpenState
    } = this.props

    return [
      <CreateIveFormComponent
        textRef={this.textRef}
        datacenters={datacenters}
        availabilityProfiles={availabilityProfiles}
        historyProfiles={historyProfiles}
        setSubmitDialogOpenState={setSubmitDialogOpenState}
        blueprint={blueprint}
        blueprints={blueprints}
        availabilityProfile={availabilityProfile}
        datacenter={datacenter}
        ivedescription={ivedescription}
        handleChange={this.handleChange}
        handleSubnetChange={this.handleSubnetChange}
        handleOnChangeBlueprint={this.handleOnChangeBlueprint}
        handleOnChangeLocation={this.handleOnChangeLocation}
        handleOnChangeAvailabilityProfile={
          this.handleOnChangeAvailabilityProfile
        }
        handleOnChangeHistoryProfile={this.handleOnChangeHistoryProfile}
        handlePostIve={this.handlePostIve}
        handleValidate={this.handleValidate}
        historyProfile={historyProfile}
        ivename={ivename}
        localSubnet={localSubnet}
        key="blueprint-catalog-form"
        iveListSummary={this.props.iveListSummary}
      />
    ]
  }
}

export const CreateIveForm = connect(mapStateToProps, {
  getDatacentersRequest,
  getAvailabilityProfilesRequest,
  getHistoryProfilesRequest,
  getBlueprintsRequest,
  setBlueprintCatalogFormErrorState,
  clearBlueprintCatalogFormErrorState,
  setSubmitDialogOpenState
})(ToJs(CreateIveFormContainer))
