import { fromJS } from 'immutable'
import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'
import moducks from './../../moducks-bootstrap'
// import { Notification } from 'rsuite'
import { setSslVpnFormErrorState } from '../components/sslVpnForm'
import { setIpsecFormErrorState } from '../components/ipsecForm'
import { setNetworkFormErrorState } from '../components/createNetworkForm'
import { setBlueprintCatalogFormErrorState } from '../components/blueprintCatalogForm'

const defaultState = fromJS({
  iveList: [],
  iveListSummary: [],
  reports: [
    { type: 'Requirements Specification' },
    { type: 'Technical Specification' },
    { type: 'IVE Installation and Qualification' }
  ],
  ive: {
    id: '',
    name: '',
    description: '',
    version: '',
    locked: false,
    edgeStatus: '',
    edgeStatusDatetime: '',
    publicIp: [],
    blueprint: {},
    datacenter: {},
    availabilityProfile: {},
    historyProfile: {},
    networks: [],
    createdDatetime: '',
    updatedDatetime: ''
  },
  sslVpnAccounts: [],
  ipsecSites: [],
  initialIpsecSites: [],
  ipsecSitesMutated: false,
  peerConfigs: [],
  vnics: [],
  vms: [],
  firewallRules: { immutable: [], user: [] },
  initialFirewallRules: { immutable: [], user: [] },
  firewallRulesMutated: false,

  snatRules: { immutable: [], user: [] },
  dnatRules: { immutable: [], user: [] },
  initialSnatRules: { immutable: [], user: [] },
  initialDnatRules: { immutable: [], user: [] },
  natRulesMutated: false,

  changelog: [],
  networks: [],

  vmTemplateList: [],
  vmTemplate: { hardware: { disks: [] }, operatingSystem: {} }
})

const moduleName = 'ives'

const {
  ives,
  sagas,
  getIvesRequest,
  getIvesRequestFailure,
  getIveRequest,
  getIveRequestFailure,
  postIvesRequest,
  postIvesRequestFailure,
  postIvesDeleteRequest,
  postIvesDeleteRequestFailure,
  getIveVmsRequest,
  getIveVmsRequestFailure,
  getIveVnicsRequest,
  getIveVnicsRequestFailure,
  getFirewallRequest,
  getFirewallRequestFailure,
  getIpsecSitesRequest,
  getIpsecSitesRequestFailure,
  getIpsecPeerConfigRequest,
  getIpsecPeerConfigRequestFailure,
  postIpsecSitesRequest,
  postIpsecSitesRequestFailure,
  postNetworkCreateRequest,
  postNetworkCreateRequestFailure,
  postNetworkDeleteRequest,
  postNetworkDeleteRequestFailure,
  postVnicAddPublicIpRequest,
  postVnicAddPublicIpRequestFailure,
  postFirewallRequest,
  postFirewallRequestFailure,
  getNatRequest,
  getNatRequestFailure,
  postNatRequest,
  postNatRequestFailure,
  setFirewallState,
  setIpsecSiteState,
  setFirewallMutationState,
  setIpsecMutationState,
  setSnatState,
  setDnatState,
  setNatMutationState,
  addFirewallRule,
  addIpsecSite,
  addSnatRule,
  addDnatRule,
  deleteFirewallState,
  deleteIpsecSiteState,
  deleteSnatState,
  deleteDnatState,
  getSslVpnAccountsRequest,
  getSslVpnAccountsRequestFailure,
  postSslVpnAccountRequest,
  postSslVpnAccountRequestFailure,
  postSslVpnAccountDeleteRequest,
  postSslVpnAccountDeleteRequestFailure,
  getIvesReportsRequest,
  getIvesReportsRequestFailure,
  getIveReportRequest,
  getIveReportRequestFailure,
  getIvesChangelogRequest,
  getIvesChangelogRequestFailure,
  getIveNetworksRequest,
  getIveNetworksRequestFailure,
  getVmTemplatesRequest,
  getVmTemplatesRequestFailure,
  getVmTemplateRequest,
  getVmTemplateRequestFailure,
  getIvesSummaryRequest,
  getIvesSummaryRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_IVES_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/ives/'
        })
      ],
      reducer: (state) => state,
      onError: (e) => getIvesRequestFailure(e)
    },
    GET_IVES_REQUEST_SUCCESS: (state, action) =>
      state.set('iveList', fromJS(action.response.results)),
    GET_IVES_REQUEST_FAILURE: (state) => state,

    GET_IVE_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/`
        })
      ],
      onError: (e) => getIveRequestFailure(e)
    },
    GET_IVE_REQUEST_SUCCESS: (state, action) =>
      state.set('ive', fromJS(action.response)),
    GET_IVE_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(push('/not-found'))
      }
    },
    POST_IVES_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: '/ives/'
        })
      ],
      onError: (e) => postIvesRequestFailure(e)
    },
    POST_IVES_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_IVES_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(setBlueprintCatalogFormErrorState(action.errors.body))
      }
    },
    POST_IVES_DELETE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/delete/`
        })
      ],
      onError: (e) => postIvesDeleteRequestFailure(e)
    },
    POST_IVES_DELETE_REQUEST_SUCCESS: {
      saga: function* () {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_IVES_DELETE_REQUEST_FAILURE: (state) => state,
    GET_IVE_VMS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/vms/`
        })
      ],
      onError: (e) => getIveVmsRequestFailure(e)
    },
    GET_IVE_VMS_REQUEST_SUCCESS: (state, action) =>
      state.set('vms', fromJS(action.response)),
    GET_IVE_VMS_REQUEST_FAILURE: (state) => state,
    GET_IVE_VNICS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/vnics/`
        })
      ],
      onError: (e) => getIveVnicsRequestFailure(e)
    },
    GET_IVE_VNICS_REQUEST_SUCCESS: (state, action) =>
      state.set('vnics', fromJS(action.response)),
    GET_IVE_VNICS_REQUEST_FAILURE: (state) => state,
    
    // Create Network 

    POST_NETWORK_CREATE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/networks/`
        })
      ],
      onError: (e) => postNetworkCreateRequestFailure(e)
    },
    POST_NETWORK_CREATE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_NETWORK_CREATE_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(setNetworkFormErrorState(action.errors.body))
      }
    },

    // Delete Network

    POST_NETWORK_DELETE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/network/${payload.networkId}/delete/`
        })
      ],
      onError: (e) => postNetworkDeleteRequestFailure(e)
    },
    POST_NETWORK_DELETE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_NETWORK_DELETE_REQUEST_FAILURE: (state) => state,

    // Add public IP on existing Edge

    POST_VNIC_ADD_PUBLIC_IP_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/vnics/vnic-create-secondary-ip/`
        })
      ],
      onError: (e) => postVnicAddPublicIpRequestFailure(e)
    },
    POST_VNIC_ADD_PUBLIC_IP_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_VNIC_ADD_PUBLIC_IP_REQUEST_FAILURE: (state) => state,

    GET_FIREWALL_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/firewall/`,
          iveId: payload.iveId
        })
      ],
      onError: (e) => getFirewallRequestFailure(e)
    },
    GET_FIREWALL_REQUEST_SUCCESS: {
      reducer: (state, action) => {
        const { firewallRules } = action.response
        return state
          .set('firewallRules', fromJS(firewallRules))
          .set('initialFirewallRules', fromJS(firewallRules))
          .set('firewallRulesMutated', false)
      }
    },
    GET_FIREWALL_REQUEST_FAILURE: (state) => state,

    // IPsec sites

    GET_IPSEC_SITES_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/ipsec/`
        })
      ],
      onError: (e) => getIpsecSitesRequestFailure(e)
    },
    GET_IPSEC_SITES_REQUEST_SUCCESS: {
      reducer: (state, action) => {
        const { ipsecSites } = action.response
      return state
        .set('ipsecSites', fromJS(ipsecSites))
        .set('ipsecSitesMutated', false)
        .set('initialIpsecSites', fromJS(ipsecSites))
      }
    },
    GET_IPSEC_SITES_REQUEST_FAILURE: (state) => state,

    // Post Ipsec Sites

    POST_IPSEC_SITES_REQUEST: {
      creator: [
        (payload) => ({
          ipsecSites: payload.ipsecSites,
          electronicSignature: payload.electronicSignature,
          approvingUsername: payload.approvingUsername
        }),
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/ipsec/`
        })
      ],
      onError: (e) => postIpsecSitesRequestFailure(e)
    },
    POST_IPSEC_SITES_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_IPSEC_SITES_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(setIpsecFormErrorState(action.errors.body))
      }
    },

    GET_IPSEC_PEER_CONFIG_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/ipsec/peerconfig/`,
          iveId: payload.iveId
        })
      ],
      onError: (e) => getIpsecPeerConfigRequestFailure(e)
    },
    GET_IPSEC_PEER_CONFIG_REQUEST_SUCCESS: {
      reducer: (state, action) => {
        return state
        .set('peerConfigs', fromJS(action.response))
      }
    },
    GET_IPSEC_PEER_CONFIG_REQUEST_FAILURE: (state) => state,

    // Nat rules

    GET_NAT_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/nat/`,
          iveId: payload.iveId
        })
      ],
      onError: (e) => getNatRequestFailure(e)
    },
    GET_NAT_REQUEST_SUCCESS: {
      reducer: (state, action) => {
        const { snatRules, dnatRules } = action.response
        return state
          .set('snatRules', fromJS(snatRules))
          .set('initialSnatRules', fromJS(snatRules))
          .set('dnatRules', fromJS(dnatRules))
          .set('initialDnatRules', fromJS(dnatRules))
          .set('natRulesMutated', false)
      }
    },
    GET_NAT_REQUEST_FAILURE: (state) => state,
    SET_FIREWALL_STATE: {
      reducer: (state, action) =>
        state.set('firewallRules', fromJS(action.payload)),
      saga: function* (action) {
        yield put(setFirewallMutationState())
      }
    },
    SET_IPSEC_SITE_STATE: {
      reducer: (state, action) =>
        state.set('ipsecSites', fromJS(action.payload)),
      saga: function* (action) {
        yield put(setIpsecMutationState())
      }
    },
    SET_SNAT_STATE: {
      reducer: (state, action) =>
        state.set('snatRules', fromJS(action.payload)),
      saga: function* (action) {
        yield put(setNatMutationState())
      }
    },
    SET_DNAT_STATE: {
      reducer: (state, action) =>
        state.set('dnatRules', fromJS(action.payload)),
      saga: function* (action) {
        yield put(setNatMutationState())
      }
    },
    SET_FIREWALL_MUTATION_STATE: (state, action) => {
      const initial = state.get('initialFirewallRules')
      const current = state.get('firewallRules')
      const rulesMutated = initial.equals(current)
      return state.set('firewallRulesMutated', !rulesMutated)
    },
    SET_IPSEC_MUTATION_STATE: (state, action) => {
      const initial = state.get('initialIpsecSites')
      const current = state.get('ipsecSites')
      const sitesMutated = initial.equals(current)
      return state.set('ipsecSitesMutated', !sitesMutated)
    },
    SET_NAT_MUTATION_STATE: (state, action) => {
      const initialSnatRules = state.get('initialSnatRules')
      const initialDnatRules = state.get('initialDnatRules')
      const currentSnatRules = state.get('snatRules')
      const currentDnatRules = state.get('dnatRules')
      const isSnatRulesEqual = initialSnatRules.equals(currentSnatRules)
      const isDnatRulesEqual = initialDnatRules.equals(currentDnatRules)
      const rulesMutated = !isSnatRulesEqual || !isDnatRulesEqual
      return state.set('natRulesMutated', rulesMutated)
    },
    ADD_FIREWALL_RULE: {
      reducer: (state, action) =>
        state.mergeIn(['firewallRules', 'user'], fromJS(action.payload)),
      saga: function* (action) {
        yield put(setFirewallMutationState())
      }
    },
    ADD_IPSEC_SITE: {
      reducer: (state, action) =>
        state.mergeIn(['ipsecSites'], fromJS(action.payload)),
      saga: function* (action) {
        yield put(setIpsecMutationState())
      }
    },
    ADD_SNAT_RULE: {
      reducer: (state, action) =>
        state.mergeIn(['snatRules', 'user'], fromJS(action.payload)),
      saga: function* (action) {
        yield put(setNatMutationState())
      }
    },
    ADD_DNAT_RULE: {
      reducer: (state, action) =>
        state.mergeIn(['dnatRules', 'user'], fromJS(action.payload)),
      saga: function* (action) {
        yield put(setNatMutationState())
      }
    },
    DELETE_FIREWALL_STATE: {
      reducer: (state, action) =>
        state.deleteIn(['firewallRules', 'user', action.payload]),
      saga: function* (payload) {
        yield put(setFirewallMutationState())
      }
    },
    DELETE_IPSEC_SITE_STATE: {
      reducer: (state, action) =>
        state.deleteIn(['ipsecSites', 'ipsecSite', action.payload]),
      saga: function* (payload) {
        yield put(setIpsecMutationState())
      }
    },
    DELETE_SNAT_STATE: {
      reducer: (state, action) =>
        state.deleteIn(['snatRules', 'user', action.payload]),
      saga: function* (payload) {
        yield put(setNatMutationState())
      }
    },
    DELETE_DNAT_STATE: {
      reducer: (state, action) =>
        state.deleteIn(['dnatRules', 'user', action.payload]),
      saga: function* (payload) {
        yield put(setNatMutationState())
      }
    },
    POST_FIREWALL_REQUEST: {
      creator: [
        (payload) => ({
          firewallRules: payload.firewallRules,
          electronicSignature: payload.electronicSignature,
          approvingUsername: payload.approvingUsername
        }),

        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/firewall/`
        })
      ],
      onError: (e) => postFirewallRequestFailure(e)
    },
    POST_FIREWALL_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_FIREWALL_REQUEST_FAILURE: (state) => state,
    POST_NAT_REQUEST: {
      creator: [
        (payload) => ({
          snatRules: payload.snatRules,
          dnatRules: payload.dnatRules,
          electronicSignature: payload.electronicSignature,
          approvingUsername: payload.approvingUsername
        }),
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${payload.iveId}/nat/`
        })
      ],
      onError: (e) => postNatRequestFailure(e)
    },
    POST_NAT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_NAT_REQUEST_FAILURE: (state) => state,
    GET_SSL_VPN_ACCOUNTS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/ssl-vpn/accounts/`
        })
      ],
      onError: (e) => getSslVpnAccountsRequestFailure(e)
    },
    GET_SSL_VPN_ACCOUNTS_REQUEST_SUCCESS: (state, action) =>
      state.set('sslVpnAccounts', fromJS(action.response)),
    GET_SSL_VPN_ACCOUNTS_REQUEST_FAILURE: (state) => state,
    POST_SSL_VPN_ACCOUNT_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/ives/${meta.iveId}/ssl-vpn/accounts/`
        })
      ],
      onError: (e) => postSslVpnAccountRequestFailure(e)
    },
    POST_SSL_VPN_ACCOUNT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_SSL_VPN_ACCOUNT_REQUEST_FAILURE: {
      saga: function* (action) {
        yield put(setSslVpnFormErrorState(action.errors.body))
      }
    },
    POST_SSL_VPN_ACCOUNT_DELETE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => {
          const { iveId, accountId } = meta
          return {
            method: 'POST',
            endpoint: `/ives/${iveId}/ssl-vpn/accounts/${accountId}/delete/`
          }
        }
      ],
      onError: (e) => postSslVpnAccountDeleteRequestFailure(e)
    },
    POST_SSL_VPN_ACCOUNT_DELETE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(push('/dashboard/recent'))
      }
    },
    POST_SSL_VPN_ACCOUNT_DELETE_REQUEST_FAILURE: (state) => state,
    GET_IVES_REPORTS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/reports/`
        })
      ],
      onError: (e) => getIvesReportsRequestFailure(e)
    },
    GET_IVES_REPORTS_REQUEST_SUCCESS: (state, action) =>
      state.set('reports', fromJS(action.response.results)),
    GET_IVES_REPORTS_REQUEST_FAILURE: (state) => state,
    GET_IVE_REPORT_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `${payload.url}`,
          isDownload: true
        })
      ],
      onError: (e) => getIveReportRequestFailure(e)
    },
    GET_IVE_REPORT_REQUEST_SUCCESS: (state) => state,
    GET_IVE_REPORT_REQUEST_FAILURE: (state) => state,
    GET_IVES_CHANGELOG_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/changelog/`
        })
      ],
      onError: (e) => getIvesChangelogRequestFailure(e)
    },
    GET_IVES_CHANGELOG_REQUEST_SUCCESS: (state, action) =>
      state.set('changelog', fromJS(action.response.results)),
    GET_IVES_CHANGELOG_REQUEST_FAILURE: (state) => state,
    GET_IVE_NETWORKS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/networks/`
        })
      ],
      onError: (e) => getIveNetworksRequestFailure(e)
    },
    GET_IVE_NETWORKS_REQUEST_SUCCESS: (state, action) =>
      state.set('networks', fromJS(action.response)),
    GET_IVE_NETWORKS_REQUEST_FAILURE: (state) => state,
    GET_VM_TEMPLATES_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `/ives/${payload.iveId}/vm-templates/`
        })
      ],
      onError: (e) => getVmTemplatesRequestFailure(e)
    },
    GET_VM_TEMPLATES_REQUEST_SUCCESS: (state, action) =>
      state.set('vmTemplateList', fromJS(action.response.results)),
    GET_VM_TEMPLATES_REQUEST_FAILURE: (state) => state,
    GET_VM_TEMPLATE_REQUEST: {
      creator: [
        (payload) => undefined,
        (pl, meta) => ({
          method: 'GET',
          endpoint: `/ives/${pl.iveId}/vm-templates/${pl.vmTemplateId}/`
        })
      ],
      onError: (e) => getVmTemplateRequestFailure(e)
    },
    GET_VM_TEMPLATE_REQUEST_SUCCESS: (state, action) =>
      state.set('vmTemplate', fromJS(action.response)),
    GET_VM_TEMPLATE_REQUEST_FAILURE: (state) => state,

    GET_IVES_SUMMARY_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/ives/summary/?must_have_vms=1'
        })
      ],
      onError: (e) => getIvesSummaryRequestFailure(e)
    },
    GET_IVES_SUMMARY_REQUEST_SUCCESS: (state, action) =>
      state.set('iveListSummary', fromJS(action.response)),
    GET_IVES_SUMMARY_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export {
  ives,
  sagas,
  getIvesRequest,
  getIveRequest,
  postIvesRequest,
  postIvesDeleteRequest,
  getIveVmsRequest,
  getIveVnicsRequest,
  getFirewallRequest,
  getIpsecSitesRequest,
  postIpsecSitesRequest,
  getIpsecPeerConfigRequest,
  getNatRequest,
  postNatRequest,
  postNetworkCreateRequest,
  postNetworkDeleteRequest,
  postVnicAddPublicIpRequest,
  postFirewallRequest,
  setFirewallState,
  setIpsecSiteState,
  setSnatState,
  setDnatState,
  addFirewallRule,
  addIpsecSite,
  addSnatRule,
  addDnatRule,
  deleteFirewallState,
  deleteIpsecSiteState,
  deleteSnatState,
  deleteDnatState,
  setFirewallMutationState,
  setNatMutationState,
  getSslVpnAccountsRequest,
  postSslVpnAccountRequest,
  postSslVpnAccountDeleteRequest,
  getIvesReportsRequest,
  getIveReportRequest,
  getIvesChangelogRequest,
  getIveNetworksRequest,
  getVmTemplatesRequest,
  getVmTemplateRequest,
  getIvesSummaryRequest
}