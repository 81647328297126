import React, { useRef } from 'react';
import {
  Button,
  ControlLabel,
  Schema,
  Form,
  FormGroup,
  FormControl,
  Modal,
  Divider,
  SelectPicker,
  Panel,
  PanelGroup,
  Row,
  Col,
  Whisper,
  Tooltip
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { StringType } = Schema.Types
const model = Schema.Model({
  vmName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9-]*$/, 'Please use only letters, numbers and - ')
    .maxLength(35, 'The maximum number of characters is 35'),
  vmDescription: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_. -]*$/, 'Please use only letters, numbers and _ - ')
    .maxLength(40, 'The maximum number of characters is 40'),
  network: StringType().isRequired('This field is required.'),
  ip: StringType().isRequired('This field is required.'),
  username: StringType().isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_. -]*$/, 'Please use only letters, numbers and _ - '),
  password: StringType()
    .isRequired('This field is required.')
    .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Passwords: >8 characters, upper- & lowercase, number and special !@#$&*')
    .rangeLength(
      8,
      30,
      'The number of characters can only be between 8 and 30'
    ),
  repeatedPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
    .isRequired('This field is required.')
})

function InputField(props) {
  const { name, label, type, accepter, data, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        type={type}
        data={data}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
    +children: React$Element<any>,
    +iveId: string,
    +iveName: string,
    +iveNetworks: Array,
    +ips: Array,
    +vmChosenTemplate: ?Object,
    +vmTemplateId: string,
    +onKeyDown: Function,
    +handleChange: Function,
    +handleOnChangeNetwork: Function,
    +handleOnChangeIp: Function,
    +vmName: string,
    +vmDescription: string,
    +network: Object,
    +username: string,
    +password: string,
    +capsLockWarning: Boolean,
    +ip: Object,
    +toggleOpenState: Function,
    +setSubmitDialogOpenState: Function,
    +isOpen: boolean
  |}

export const TemplateDialogComponent = (props: Props) => {
    var TemplateForm = useRef(null)

    return (
        <div style={{ display: 'inline' }}>
          {React.cloneElement(props.children, {
            onClick: props.toggleOpenState
          })}
          <Modal backdrop="static" show={props.isOpen} size="md">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '5px' }}>
                GxP-Template {props.vmChosenTemplate.name}
              </Modal.Title>
            </Modal.Header>
            <Panel style={{ height: '8vh'}}>
              Continuing will deploy a Virtual Machine from template: <strong>{props.vmChosenTemplate.name}</strong>. <br></br>
              <small>{props.vmChosenTemplate.description}</small>
            </Panel>            
            {/* <Divider>Isolated Virtual Environment settings</Divider> */}
            <Form
              model={model}
              ref={(ref) => (TemplateForm = ref)}
              fluid
              checkTrigger="none"
            >

                <Row>
                  <Col md={12}>
                    <InputField
                      autoFocus
                      name="vmName"
                      label="Virtual Machine Name"
                      type="name"
                      onChange={(vmName, event) =>
                        props.handleChange({ vmName, event })
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <InputField
                      name="vmDescription"
                      label="Virtual Machine Description"
                      type="description"
                      onChange={(vmDescription, event) =>
                        props.handleChange({ vmDescription, event })
                      }
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: '25px' }}>
                  <Col md={12}>
                    <InputField
                      name="network"
                      label="Select the local area network the VM will deploy in:"
                      style={{ width: 'inherit' }}
                      id="vm-network"
                      labelKey="name"
                      valueKey="id"
                      data={props.iveNetworks}
                      accepter={SelectPicker}
                      searchable={false}
                      onSelect={props.handleOnChangeNetwork}
                      renderValue={(id, item) => {
                        return <>{item.name}</>
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <InputField
                      name="ip"
                      label="Select an IP address in that network:"
                      style={{ width: 'inherit' }}
                      id="vm-ip"
                      labelKey="ip"
                      valueKey="ip"
                      data={props.ips}
                      accepter={SelectPicker}
                      searchable={false}
                      onSelect={props.handleOnChangeIp}
                      onClean={props.handleOnChangeIp}
                      renderValue={(id, item) => {
                        return <>{item.ip}</>
                      }}
                    />
                  </Col>
                </Row>

                <Divider style={{ marginBottom: '12px' }}>Operating System user settings</Divider>

                <Whisper
                    placement="top"
                    trigger="focus"
                    speaker={<Tooltip>This will be the username used to login to your chosen operating system.</Tooltip>}
                  >
                    <InputField
                      name="username"
                      label="User account Name"
                      type="username"
                      onChange={(username, event) =>
                        props.handleChange({ username, event })
                      }
                    />
                </Whisper>

                <Row>
                  <Col md={12}>
                    <Whisper
                      placement="top"
                      trigger="focus"
                      speaker={<Tooltip>This will be the password used to login to your chosen operating system. Please dont forget this.</Tooltip>}
                    >
                      <InputField
                        name="password"
                        label="User account Password"
                        type="password"
                        onChange={(password, event) =>
                          props.handleChange({ password, event })
                        }
                        onKeyDown={props.onKeyDown}
                      />
                    </Whisper>
                  </Col>
                  <Col md={12}>
                    <InputField
                      name="repeatedPassword"
                      label="User account Repeated Password"
                      type="password"
                      onChange={(repeatedPassword, event) =>
                        props.handleChange({ repeatedPassword, event })
                      }
                      onKeyDown={props.onKeyDown}
                    />
                  </Col>
                </Row>

                <PanelGroup accordion>
                  <Panel style={{ marginTop: '10px', marginBottom: '10px', maxHeight: '30vh', overflow: 'auto'}} header={<h5>Click to see VM Technical Specifications</h5>}>
                  {props.vmChosenTemplate && props.network ? (
                  <>
                    <h5>Operating System</h5>
                    <ul key="createvm-os">
                      <li key="vmtemplate-name">
                        <strong>{props.vmChosenTemplate.name}</strong>
                      </li>
                      <div>{props.vmChosenTemplate.description}</div>
                      <br></br>
                      <div>
                        This Virtual Machine is deployed with minimum specs. The
                        Virtual Machine compute can be reconfigured after
                        deployment.
                        <br></br>
                        This VM will deploy with asset-tier: {props.vmChosenTemplate.assetTier.name} which means it has {' '}
                        {props.vmChosenTemplate.assetTier.cpuCores} CPU cores and {props.vmChosenTemplate.assetTier.memory} GB RAM.
                      </div>
                    </ul>
                    <br></br>
                  </>
                ) : (
                  ''
                )}
                {props.network && props.ip ? (
                  <>
                    <h5>Network</h5>
                    <ul key="createvm-network">
                      <li key="vmcreate-network">
                        This Virtual Machine will be deployed @{' '}
                        <strong>{props.ip.ip}</strong> in network{' '}
                        <strong>{props.network.name}</strong>.
                      </li>
                    </ul>
                  </>
                ) : (
                  ''
                )}
                <br></br>
                {props.vmName ? (
                  <>
                    <h5>Operating System Personalization</h5>
                    <ul key="createvm-personalization">
                      <li key="createvm-name">
                        <strong>{props.vmName}</strong>
                      </li>
                      {props.vmDescription ? <div>{props.vmDescription}</div> : ''}
                      <br></br>
                      {props.username ? (
                        <li key="createvm-username">
                          A user account will be created with username{' '}
                          <strong>{props.username}</strong> and the provided password.
                          Please store the provided credentials safely. They are used to login to the operating system. Please note that GxP-Cloud does not have access to these credentials. 
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </>
                ) : (
                  'This will be filled along your choices.'
                )}
                  </Panel>
                </PanelGroup>     

            <Modal.Footer>
                <div
                  style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '25px',
                      marginBottom: '15px'
                    }}
                  >
                    <div>
                      {props.capsLockWarning ? (
                          <span style={{ margingRight: '100px', color: '#ff0000' }}>
                            Caps Lock is On!
                          </span>
                        ) : (
                          <div></div>
                        )}
                    </div>
                    <div>
                      <Button onClick={props.toggleOpenState} appearance='ghost' color="red">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        appearance='ghost'
                        color="blue"
                        id="submit"
                        onClick={(e) => {
                          if (TemplateForm.check()) {
                            e.preventDefault()
                            props.setSubmitDialogOpenState({
                              dialogId: 'DEPLOY_VM',
                              payload: {
                                vmTemplateId: props.vmChosenTemplate.id,
                                name: props.vmName,
                                description: props.vmDescription,
                                networkId: props.network.id,
                                ipIndex: props.ip.index,
                                username: props.username,
                                password: props.password
                              },
                              meta: { iveId: props.iveId, name: props.iveName }
                            })
                          }
                        }}
                      >
                        Deploy
                      </Button>
                    </div>
                  </div>
            </Modal.Footer>

        </Form>
      </Modal>
    </div>
  )
}