// @flow

import React from 'react'
import { Row, Col, Icon } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { DeleteRuleButtonColumn } from 'components/DeleteRuleButtonColumn'
import { EditRuleButton } from './EditRuleButtonComponent'

type Props = {|
  +deleteDnatRule: Function,
  +editDnatRule: Function,
  +iveId: string,
  +rules: DnatRules,
  +rule: DnatRule,
  +userRuleIndex: number
|}

export const DnatRuleRow = (props: Props) => {
  const { rule, userRuleIndex, rules, iveId } = props
  const deleteRule = () =>
    props.deleteDnatRule(userRuleIndex - rules.immutable.length)
  const editRule = (rule) =>
    props.editDnatRule(rule, userRuleIndex - rules.immutable.length)

  const {
    originalAddress,
    translatedAddress,
    originalPortFrom,
    // originalPortTo,
    translatedPortFrom,
    // translatedPortTo,
    protocol,
    immutable
  } = rule
  return (
    <Row style={{ marginBottom: '5px' }}>
      <Col md={4}>
        <strong>
          {originalAddress ? originalAddress : 'IVE Public IP'}
        </strong>
      </Col>
      <Col md={3}>
        <strong>
          {originalPortFrom}
        </strong>
      </Col>
      <Col md={2}>
        <Icon icon='long-arrow-right'/>
      </Col>
      <Col md={4}>
        <strong style={{ overflowWrap: 'anywhere' }}>
          {typeof translatedAddress === 'object' && translatedAddress.name}
        </strong>
      </Col>
      <Col md={3}>
        <strong>
          {translatedPortFrom}
        </strong>
      </Col>
      <Col md={3}>
        <strong>
          {protocol && protocol.toUpperCase()}
        </strong>
      </Col>
      {immutable ? (
        <Col md={4} className={'small-column'} />
      ) : (
        <Col md={2}>
          <EditRuleButton
            id={`edit-dnat-rule-${rule.id}`}
            dialogAction="Edit"
            editRule={editRule}
            iveId={iveId}
            rule={rule}
            rules={rules}
          />
        </Col>
      )}
      {immutable ? (
        <Col md={2} className={'small-column'} />
      ) : (
        <DeleteRuleButtonColumn
          id={`delete-dnat-rule-${rule.id}`}
          deleteRule={deleteRule}
        />
      )}
    </Row>
  )
}
