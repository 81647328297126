import React from 'react'
import { Panel, List } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import moment from 'moment-timezone'

type Props ={|
    +auditLog: Array
    |}

export const AuditLogComponent = (props: Props) => (
    <Panel header={<h5>Audit Log</h5>} bordered style={{ maxHeight: '20vh', backgroundColor: '#ffffff', marginTop: '25px', overflow: 'auto' }}>
      <List size="sm" style={{ height: '9vh', overflowY: 'scroll' }}>
  {props.auditLog.map((logitem, index) =>
    <List.Item key={`row-${index}`} index={index}>
      <span style={{ marginRight: '100px'}}>
        <strong>{moment
            .tz(logitem.timestamp, props.timezone)
            .format('DD MMM YYYY HH:mm:ss zz')}
        </strong>
      </span> 
      <span style={{ marginRight: '100px'}}>{logitem.email}</span> 
      <span>{logitem.entry}</span>
    </List.Item>
  )}
      </List>
    </Panel>
)

