import { fromJS } from 'immutable'
import moducks from 'moducks-bootstrap'

const defaultState = fromJS({
  show: false,
  backdrop: 'static',
  settings: { modalId: '' }
})
const moduleName = 'gxpModal'

const {
  gxpModal,
  sagas,
  setModalOpenState,
  clearModalOpenState
} = moducks.createModule(
  moduleName,
  {
    SET_MODAL_OPEN_STATE: (state, { payload: settings }) =>
      state.set('settings', fromJS(settings)).set('show', true),
    CLEAR_MODAL_OPEN_STATE: (state) => state.set('show', false)
  },
  defaultState
)

export { gxpModal, sagas, setModalOpenState, clearModalOpenState }
