// @flow

import React from 'react'
import 'rsuite/dist/styles/rsuite-default.css'

import { ApprovalCard } from '.'

type Props = {|
  +approvals: Approval[],
  +approvalId: string,
  +timezone: string
|}

const ApprovalsTable = (props: Props) => {
  const { approvals, timezone, approvalId } = props
  return (
    <div>
      {approvals.map((approval, index) => (
        <ApprovalCard
          key={`approval-${index}`}
          selected={approvalId === approval.id}
          approval={approval}
          timezone={timezone}
        />
      ))}
    </div>
  )
}
export { ApprovalsTable }
