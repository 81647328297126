// @flow
import { validateField } from 'utils/validators'

import {
  DISK_DOWNSIZE_ERROR,
  DISK_ZERO_ERROR,
  ALLOWED_CHARS_WITH_NO_SPACE_ERROR
} from 'utils/errorConsts'

export const validate = (values: Disk[], initialValues: Disk[]) => {
  const disksErrors = []
  values.forEach((value) => {
    const { size, name, id } = value
    const sizeError = validateField({ size })({ minValue: 1, maxValue: 2000 })
    const zeroError = validateField({ size })
    if (size === 0) {
      sizeError.size = DISK_ZERO_ERROR
    }
    const nameError = validateField({ name })({
      maxLength: 32,
      regex: {
        string: /^[A-Za-z0-9]*$/,
        error: ALLOWED_CHARS_WITH_NO_SPACE_ERROR
      }
    })
    const initialDiskValues = initialValues.find((disk) => disk.id === id)
    if (!sizeError.size && initialDiskValues && initialDiskValues.size > size) {
      sizeError.size = DISK_DOWNSIZE_ERROR
    }
    if (sizeError.size || zeroError.size || nameError.name) {
      disksErrors.push({ ...sizeError, ...zeroError, ...nameError })
    }
  })
  return disksErrors.length ? { disks: disksErrors } : {}
}
