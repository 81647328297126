// @flow

import React from 'react'
import { Iterable, List } from 'immutable'

// toJS HOC
//
// From url on the next 3 lines:
//
// http://redux.js.org/docs/recipes/UsingImmutableJS.html
// #use-a-higher-order-component-to-convert-your-smart-components-
// immutablejs-props-to-your-dumb-components-javascript-props
const BLACK_LIST = [
  'dispatch',
  'location',
  'logout',
  'params',
  'route',
  'router',
  'routes',
  'routeParams'
]

export function ToJs(WrappedComponent: Object, actionCreators: Object): Object {
  return (wrappedComponentProps: Map<*, *>) => {
    const KEY = 0
    const actionCreatorNames = actionCreators ? Object.keys(actionCreators) : []
    const propsJs = Object.entries(wrappedComponentProps).reduce(
      (newProps: List<*>, wrappedComponentProp: List<*>) => {
        if (!BLACK_LIST.includes(wrappedComponentProp[KEY])) {
          newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(
            wrappedComponentProp[1]
          )
            ? wrappedComponentProp[1].toJS()
            : wrappedComponentProp[1]
        }
        if (actionCreatorNames.includes(wrappedComponentProp[KEY])) {
          newProps[wrappedComponentProp[KEY]] = wrappedComponentProp[1]
        }
        return newProps
      },
      {}
    )
    return <WrappedComponent {...propsJs} />
  }
}
