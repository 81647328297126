import React from 'react'
import moment from 'moment-timezone'

import { ButtonToolbar, Button, Panel, Row, Col, Grid } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const VmReportsTable = (props: {|
  +reports: Report[],
  +timezone: string,
  +getVmReportRequest: Function
|}) => (
  <span>
    <Grid fluid>
      <Row style={{ fontSize: '12px' }}>
        <Col md={4}>Document ID</Col>
        <Col md={4}>Version</Col>
        <Col md={4}>Status</Col>
        <Col md={6}>Created</Col>
        <Col md={4}>Download</Col>
      </Row>
      {props.reports &&
        props.reports.map((report, index) => (
          <Row style={{ fontSize: '14px', marginTop: '5px' }}>
            <Col md={4}>
              <strong>{report.documentId}</strong>
            </Col>
            <Col md={4}>
              <strong>{report.version}</strong>
            </Col>
            <Col md={4}>
              <strong>{report.status}</strong>
            </Col>
            <Col md={6}>
              <strong>
                {report.createdDatetime &&
                  moment
                    .tz(report.createdDatetime, props.timezone)
                    .format('DD MMM YYYY HH:mm:ss zz')}
              </strong>
            </Col>
            <Col md={4}>
              <ButtonToolbar>
                <Button
                  appearance="ghost"
                  color='blue'
                  label="Download"
                  onClick={() => props.getVmReportRequest({ url: report.pdf })}
                >
                  Download
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        ))}
    </Grid>
  </span>
)

const VmReportsComponent = (props: {|
  +reports: Report[],
  +timezone: string,
  +getVmReportRequest: Function,
  +name: string
|}) => (
  <Panel bordered header={<h5>{'Installation and Qualification'}</h5>}>
    <VmReportsTable
      reports={props.reports}
      timezone={props.timezone}
      getVmReportRequest={props.getVmReportRequest}
    />
  </Panel>
)
export { VmReportsComponent }

// Add +type: string and +title: string to the props for future reports (see ive component)
