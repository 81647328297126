// @flow

import React from 'react'
import {
  Modal,
  Button,
  Panel
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { TermsAndConditions } from 'routes/help/TermsAndConditions'

type Props = {|
  +hasAgreedTerms: boolean,
  +groupActivated: boolean,
  +postAgreeTermsRequest: Function,
  +bottom: boolean,
  +handleScroll: Function
|}

export const InternalAgreeTermsDialogComponent = (props: Props) => {
  const {
    hasAgreedTerms,
    groupActivated,
    postAgreeTermsRequest,
    bottom,
    handleScroll
  } = props


  return (
      <Modal backdorp="static" show={!hasAgreedTerms && groupActivated} size="md">
        <Modal.Header closeButton={false}>
          <Modal.Title style={{ marginBottom: '15px' }}>Please Agree to the Terms and Conditions</Modal.Title>
        </Modal.Header>
            <div>

                <Panel 
                    bordered
                    style={{ height: '60vh', overflow: 'auto' }}
                    onScroll={handleScroll}
                >
                        <TermsAndConditions/>
                </Panel>
                <div style={{ width: '760px' }}>
                    <strong style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                        Scroll down and click I Agree to Continue
                    </strong>

                    <Button
                        color="blue"
                        style={{ 
                            marginTop: '15px', 
                            width: 'inherit',
                            display: 'flex', 
                            justifyContent: 'center' 
                        }}
                        appearance='ghost'
                        label="I Agree"
                        type="submit"
                        id="internal-agree-terms"
                        disabled={!bottom}
                        onClick={(e) => {
                            e.preventDefault()
                            postAgreeTermsRequest()
                        }}
                    >
                        I Agree
                    </Button>
                </div>
            </div>

      </Modal>
  )
}
