import { connect } from 'react-redux'

import { DashboardLogoComponent } from './DashboardLogoComponent'
import { getDashboardRequest } from 'modules/operations/dashboard'
import { ToJs } from 'utils'

const mapStateToProps = (state) => ({
  dashboard: state.getIn(['dashboard', 'items'])
})

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const DashboardLogo = connect(mapStateToProps, { getDashboardRequest })(
  ToJs(DashboardLogoComponent)
)
