// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({ loading: false, endpoints: {} })

const moduleName = 'fetch'

const { fetch, setFetch } = moducks.createModule(
  moduleName,
  {
    SET_FETCH: (state, action) => {
      const { endpoint, fetching } = action.payload
      const newState =
        fetching === true
          ? state.mergeIn(['endpoints'], fromJS({ [endpoint]: fetching }))
          : state.deleteIn(['endpoints', endpoint])
      const loading = Object.keys(newState.toJS().endpoints).length > 0
      return newState.set('loading', loading)
    }
  },
  defaultState
)
export { fetch, setFetch }
