import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HelpCharacterComponent } from './HelpCharacterComponent';
import { setHelpModalOpenState } from 'modules/components/helpDialogModal';

type Props = {|
    +setHelpModalOpenState: Function,
    +modalId: string,
|}

class HelpCharacterContainer extends Component<Props, State> {
    props: Props
    state: State

    render() {
        return(
            <HelpCharacterComponent
              setHelpModalOpenState={this.props.setHelpModalOpenState}
              modalId={this.props.modalId}
            />
        )
    }
}

export const HelpCharacter = connect(() => ({}), {
    setHelpModalOpenState
  })(HelpCharacterContainer)
  