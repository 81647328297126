import React from 'react'
import { Link } from 'react-router-dom'

import {
  Panel,
  Container,
  Content,
  FlexboxGrid,
  Header,
  Divider,
  Whisper,
  Tooltip,
  Button

} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import gxplogo from '../../../img/GxP Cloud_Logo_Orange.svg';

import { TemplateDialog } from './dialog/TemplateDialogContainer';

type Props = {
  +iveId: String,
  +vmTemplates: Array,
  +vmTemplate: Object,
  +getVmTemplatesRequest: Function
}


export const VmCatalogueComponent = (props: Props) => {
  const {
    iveId,
    vmTemplates
  } = props

  return (
    <Container
      style={{ 
        backgroundColor: '#FFFFFF', 
        boxShadow: '3px 0 2px 0 #e0e0e0', 
        height: '90vh' 
      }}
    >
      <Header
        style={{
          height: '60px',
          background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)',
          marginBottom: '15px'
        }}
      >
        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
          GxP-Cloud VM Template Catalogue
        </h3>
      </Header>
      <Content style={{ overflow: "auto" }}>
        <FlexboxGrid justify="start">

            {vmTemplates && vmTemplates.map((vmTemplateItem, index) => {

                return (
                  <FlexboxGrid.Item 
                    colspan={7}
                    style={{ marginLeft: '20px', marginBottom: '20px'}}
                  >
                        <Panel 
                            shaded 
                            bordered 
                            bodyFill 
                            style={{ 
                                display: 'inline-block', 
                                height: '320px',
                                width: '100%', 
                            }}
                        >
                        <img src={vmTemplateItem.imageUrl} 
                            width='220px' 
                            height='40px'
                            alt='logo' 
                            style={{ objectFit: 'contain', objectPosition: 'left', margin: '20px 0px 0px 20px'}}
                        />
                            <Panel header={<div><strong>{vmTemplateItem.name}</strong></div>}>
                                <p>
                                    <Divider style={{ marginTop: '-20px', marginBottom: '5px' }}/>
                                    <small>
                                    {vmTemplateItem.description}
                                    </small>
                                </p>

                                <div style={{ width: 'inherit', position: 'absolute', right: '20px', bottom: '20px' }}>
                                    <TemplateDialog
                                        iveId={iveId}
                                        vmTemplateId={vmTemplateItem.id}
                                        key={`${index}TemplateItem`}
                                      >
                                        <Button
                                            color="blue"
                                            appearance='ghost'
                                            primary="true"
                                            type="submit"
                                        >
                                            Choose Template
                                        </Button>
                                    </TemplateDialog>
                                </div>

                            </Panel>
                        </Panel>
                  </FlexboxGrid.Item>
                )
            })}

            <FlexboxGrid.Item 
              colspan={7}
              style={{ marginLeft: '20px', marginBottom: '20px'}}
            >
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Start with configuring your Virtual Machine by hand.</Tooltip>}
                >
                <Panel 
                  shaded 
                  bordered 
                  bodyFill 
                  style={{ 
                      display: 'inline-block', 
                      height: '320px',
                      width: '100%', 
                      cursor: 'pointer',
                      color: 'black'
                  }}
                >
                <img src={gxplogo} 
                    width='220px' 
                    alt='logo' 
                    style={{ objectFit: 'contain', objectPosition: 'left', margin: '20px 0px 0px 20px'}}
                />
                    <Panel header={<strong>Configurable Virtual Machine</strong>}>
                    <p>
                    <Divider style={{ marginTop: '-20px', marginBottom: '5px' }}/>
                        <small>
                          Configure your Virtual Machine using the original Virtual Machine deploy form.
                        </small>
                    </p>
                    <div style={{ width: 'inherit', position: 'absolute', right: '20px', bottom: '20px' }}>
                      <Link to={`/ives/${iveId}/vm`}>
                        <Button
                          color="blue"
                          appearance='ghost'
                          primary="true"
                          type="submit"
                        >
                            Configure Template
                        </Button>
                      </Link>
                    </div>
                    </Panel>
                </Panel>
                </Whisper>
            </FlexboxGrid.Item>

        </FlexboxGrid>
      </Content>
    </Container>
  )
}
