import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

import { SidebarComponent } from './SidebarComponent'
import { logoutRequest } from 'modules/operations/auth'
import { getAccountRequest } from 'modules/operations/account'

type ReduxState = {| +account: string |}
type ReduxActions = {| +getAccountRequest: Function |}
type Props = ReduxState & ReduxActions

const mapStateToProps = (state) => ({
  pathname: state.getIn(['router', 'location', 'pathname']),
  account: state.getIn(['account'])
})

export class SidebarContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getAccountRequest()
  }


  render() {
        return (
          <SidebarComponent
          account={this.props.account}
          />
        )
      } 
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Sidebar = connect(mapStateToProps, { logoutRequest, getAccountRequest })(ToJs(
  SidebarComponent)
)
