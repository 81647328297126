import React, { Component } from 'react'
import { connect } from 'react-redux'

import { IveCatalogueComponent } from './IveCatalogueComponent'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'
import { 
    getBlueprintsRequest, 
    getBlueprintRequest 
} from 'modules/operations/blueprints'
import { ToJs } from 'utils/ToJs'

const mapStateToProps = (state) => ({
  username: state.getIn(['account', 'username']),
  blueprints: state.getIn(['blueprints', 'blueprintList'])
})

export class IveCatalogueContainer extends Component {
  static props: {
    +setSubmitDialogOpenState: Function,
    +getBlueprintsRequest: Function,
    +getBlueprintRequest: Function
  }

  constructor(props) {
    super(props)
    this.state = {
      blueprints: {},
      blueprint: undefined,
      focusSet: false
    }
    this.textRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      process.env.NODE_ENV === 'development' &&
      this.props.username !== prevProps.username
    ) {
      this.setState({ name: '' })
    }
    if (!this.state.focusSet) {
      setTimeout(() => {
        this.textRef.current && this.textRef.current.focus()
      }, 5)
      this.setState({ focusSet: true })
    }
  }

  componentDidMount() {
    this.props.getBlueprintsRequest()
  }

  render() {
    const {
      blueprint,
    } = this.state

    const {
      blueprints,
      setSubmitDialogOpenState,
      getBlueprintRequest
    } = this.props

    return [
      <IveCatalogueComponent
        textRef={this.textRef}
        blueprint={blueprint}
        blueprints={blueprints}
        setSubmitDialogOpenState={setSubmitDialogOpenState}
        getBlueprintRequest={getBlueprintRequest}
        key="blueprint-catalog"
      />
    ]
  }
}

export const IveCatalogue = connect(mapStateToProps, {
  setSubmitDialogOpenState,
  getBlueprintsRequest,
  getBlueprintRequest
})(ToJs(IveCatalogueContainer))
