import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ApprovalButtonComponent } from './ApprovalButtonComponent'

import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type Props = {|
  +icon: string,
  +approvalId: string,
  +setSubmitDialogOpenState: Function
|}

class ApprovalButtonContainer extends Component {
  props: Props

  handleOpenSubmitDialog = (settings) => {
    this.props.setSubmitDialogOpenState(settings)
  }
  render() {
    const { icon, approvalId } = this.props
    return (
      <ApprovalButtonComponent
        icon={icon}
        approvalId={approvalId}
        handleOpenSubmitDialog={this.handleOpenSubmitDialog}
      />
    )
  }
}

export const ApprovalButton = connect(() => ({}), {
  setSubmitDialogOpenState
})(ApprovalButtonContainer)
