// @flow

import React from 'react';

import {
  ButtonToolbar,
  Icon,
  IconButton,
  Whisper,
  Tooltip,
  Dropdown
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const VmActionsComponent = (props: {|
  +iveId: string,
  +vm: Vm,
  +iveLocked: boolean,
  +handleOpenSubmitDialog: Function,
  +setModalOpenState: Function,
  +handleGetUrl: Function
|}) => {
  const { vm, iveLocked, handleOpenSubmitDialog, setModalOpenState, handleGetUrl } = props
  return (
    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ButtonToolbar>
      <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>VM Html Console</Tooltip>}
        >
          <IconButton
              icon={<Icon icon="desktop" />}
              appearance='ghost'
              disabled={iveLocked || !vm.poweredOn}
              onClick={() => 
                handleGetUrl({ vmId: vm.id })
              }
            />
        </Whisper>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Manage VM Power State</Tooltip>}
        >
          <Dropdown
            trigger={['click', 'hover']}
            appearance='ghost'
            disabled={iveLocked}
            title={vm.poweredOn ? 'Powered On' : 'Powered Off'}
            color={vm.poweredOn ? 'blue' : 'red'}
            icon={<Icon icon="power-off" />}
          >
            <Dropdown.Item
              icon={<Icon icon="power-off" />}
              disabled={iveLocked}
              onClick={() =>
                handleOpenSubmitDialog({
                  dialogId: `${vm.poweredOn ? 'POWER_OFF_VM' : 'POWER_ON_VM'}`,
                  meta: { vmId: vm.id, name: vm.name }
                })
              }
            >
              {`${vm.poweredOn ? 'Power off' : 'Power on'}`}
            </Dropdown.Item>
            <Dropdown.Item
              icon={<Icon icon="refresh" />}
              disabled={iveLocked || !vm.poweredOn}
              onClick={() =>
                handleOpenSubmitDialog({
                  dialogId: 'REBOOT_VM',
                  meta: { vmId: vm.id, name: vm.name }
                })
              }
            >
              Reboot
            </Dropdown.Item>
          </Dropdown>
        </Whisper>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Manage VM Hardware</Tooltip>}
        >
          <IconButton
            icon={<Icon icon="microchip" />}
            appearance='ghost'
            disabled={iveLocked}
            onClick={() =>
              setModalOpenState({
                modalId: 'HARDWARE_CHANGE',
                meta: { vmId: vm.id, name: vm.name }
              })
            }
          />
        </Whisper>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Manage VM Snapshots</Tooltip>}
        >
          <Dropdown
            trigger={['click', 'hover']}
            appearance='ghost'
            disabled={iveLocked}
            color='blue'
            title="Snapshots"
          >
            <Dropdown.Item
              icon={<Icon icon="crosshairs" />}
              disabled={props.vm.snapshotCreatedDatetime}
              onClick={() => {
                handleOpenSubmitDialog({
                  dialogId: 'VM_SNAPSHOT_CREATE',
                  meta: { vmId: vm.id, name: vm.name }
                })
              }}
            >
              Create Snapshot
            </Dropdown.Item>
            <Dropdown.Item
              icon={<Icon icon="backward" />}
              disabled={!props.vm.snapshotCreatedDatetime}
              onClick={() => {
                handleOpenSubmitDialog({
                  dialogId: 'VM_SNAPSHOT_REVERT',
                  meta: { vmId: vm.id, name: vm.name }
                })
              }}
            >
              Revert Snapshot
            </Dropdown.Item>
            <Dropdown.Item
              icon={<Icon icon="trash" />}
              disabled={!props.vm.snapshotCreatedDatetime}
              onClick={() => {
                handleOpenSubmitDialog({
                  dialogId: 'VM_SNAPSHOT_DELETE',
                  meta: { vmId: vm.id, name: vm.name }
                })
              }}
            >
              Delete Snapshot
            </Dropdown.Item>
          </Dropdown>
        </Whisper>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Manage VM Restore Points</Tooltip>}
        >
          <IconButton
            icon={<Icon icon="fast-backward" />}
            disabled={iveLocked}
            appearance='ghost'
            onClick={() =>
              setModalOpenState({
                modalId: 'RESTORE_POINTS',
                meta: { vmId: vm.id, name: vm.name }
              })
            }
          />
        </Whisper>
      </ButtonToolbar>
      <span style={{ display: 'flex', marginLeft: '15px' }}>
        <ButtonToolbar>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>VM Changelog</Tooltip>}
          >
            <IconButton
              icon={<Icon icon="check-square-o" />}
              appearance='ghost'
              color='orange'
              onClick={() =>
                setModalOpenState({
                  modalId: 'VM_CHANGELOG',
                  meta: { vmId: vm.id, name: vm.name }
                })
              }
            />
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            disabled={iveLocked}
            speaker={<Tooltip>VM Reports</Tooltip>}
          >
            <IconButton
              icon={<Icon icon="detail" />}
              appearance='ghost'
              color='orange'
              onClick={() =>
                setModalOpenState({
                  modalId: 'VM_REPORTS',
                  meta: { vmId: vm.id, name: vm.name }
                })
              }
            />
          </Whisper>
        </ButtonToolbar>
      </span>
      <span style={{ display: 'flex', marginLeft: '15px' }}>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Delete VM</Tooltip>}
        >
          <IconButton
            icon={<Icon icon="trash" />}
            color="red"
            disabled={iveLocked || !vm.isDeletable}
            onClick={() =>
              handleOpenSubmitDialog({
                dialogId: 'DELETE_VM',
                meta: { vmId: vm.id, name: vm.name }
              })
            }
          >
            Delete
          </IconButton>
        </Whisper>
      </span>
    </span>
  )
}
export { VmActionsComponent }
