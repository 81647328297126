// @flow
import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'
import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { Notification } from 'rsuite'


const defaultState = fromJS({
  token: '',
  user: {
    username: null,
    firstName: null,
    lastName: null,
    group: null,
    timezone: null,
    otp: { confirmed: false, configUrl: '' },
    hasElectronicSignatureSet: false,
    hasPasswordSet: false,
    apiVersion: ''
  },
  errors: {}
})

export const moduleName = 'auth'

const {
  auth,
  sagas,
  postLoginRequest,
  postLoginRequestSuccess,
  postLoginRequestFailure,
  resetAuth,
  logoutRequest,
  logoutRequestFailure,
  postPasswordResetEmailRequest,
  postPasswordResetEmailRequestFailure,
  postPasswordResetRequest,
  postPasswordResetRequestFailure,
  setElectronicSignatureState,
  setPasswordState,
  setHasAgreedTermsState
} = moducks.createModule(
  moduleName,
  {

    // Login 

    POST_LOGIN_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: '/auth/login/',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization:
              'Basic ' +
              btoa(
                encodeURIComponent(
                  `${payload.email}:${payload.password}`
                ).replace(/%([0-9A-F]{2})/g, (match, p1) =>
                  String.fromCharCode(parseInt(p1, 16))
                )
              ),
            // Hack to avoid login prompt upon Unauthorized login (401)
            // https://loudvchar.blogspot.com/2010/11/avoiding-browser-popup-for-401.html
            'WWW-Authenticate': 'xBasic'
          },
          payload,
        })
      ],
      onError: (e) => {
        postLoginRequestFailure(e)
      }
    },
    POST_LOGIN_REQUEST_SUCCESS: (state, action) => {
      const { token, user } = action.response
      const { hasElectronicSignatureSet, hasPasswordSet, apiVersion } = user
      return state
        .set('token', token)
        .set(
          'user',
          fromJS({ hasElectronicSignatureSet, hasPasswordSet, apiVersion })
          )          
    },
    RESET_AUTH: () => defaultState,

    // Logout

    LOGOUT_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: '/auth/logout/'
        })
      ],
      onError: (e) => logoutRequestFailure(e)
    },
    LOGOUT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.success({
            title: 'Success',
            placement: 'bottomEnd',
            description: 'Successfull Logout'
          })
        )
      },
      reducer: () => defaultState
    },
    LOGOUT_REQUEST_FAILURE: () => defaultState,
    
    // Get password reset email

    POST_PASSWORD_RESET_EMAIL_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: '/auth/request-reset-email/',
          payload,
        })
      ],
      onError: (e) => postPasswordResetEmailRequestFailure(e)
    },
    POST_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS: (state, action) =>
      state.merge(fromJS(action.response)),
    POST_PASSWORD_RESET_EMAIL_REQUEST_FAILURE: (state) => state,

    // Post Reset Password request

    POST_PASSWORD_RESET_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PATCH',
          endpoint: '/auth/password-reset-complete/',
          payload,
        })
      ],
      onError: (e) => postPasswordResetRequestFailure(e)
    },
    POST_PASSWORD_RESET_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.success({
            title: 'Success',
            placement: 'bottomEnd',
            duration: 10000,
            description: 'Your Password has been reset. You have been redirected to the Login page.'
          })
        )
        yield put(push('/login'))
      }
    },
    POST_PASSWORD_RESET_REQUEST_REQUEST_FAILURE: (state) => state,

    // Set Electronic signature state after PUT

    SET_ELECTRONIC_SIGNATURE_STATE: (state) =>
      state.setIn(['user', 'hasElectronicSignatureSet'], true),

    // Set Password state after PUT

    SET_PASSWORD_STATE: (state) => state.setIn(['user', 'hasPasswordSet'], true),

    // Set Agreed to terms and conditions state after PUT

    SET_HAS_AGREED_TERMS_STATE: (state) =>
      state.setIn(['account', 'groupInfo', 'groups', '0', 'termsAndConditionsAccepted'], true),
  },
  defaultState
)

export {
  auth,
  sagas,
  postLoginRequest,
  postLoginRequestSuccess,
  resetAuth,
  logoutRequest,
  postPasswordResetEmailRequest,
  postPasswordResetRequest,
  setElectronicSignatureState,
  setPasswordState,
  setHasAgreedTermsState
}
