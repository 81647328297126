// @flow

import { connect } from 'react-redux'

import { getAccountRequest } from 'modules/operations/account'
import { getApproversRequest } from 'modules/operations/approvers'
import { RoutesComponent } from './RoutesComponent'
import { ToJs } from 'utils/ToJs'

const mapStateToProps = (state) => ({
  token: state.getIn(['auth', 'token'])
})

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Routes = connect(mapStateToProps, {
  getAccountRequest,
  getApproversRequest
})(ToJs(RoutesComponent))
