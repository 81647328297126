import React from 'react'

import { Panel, Row, Col, Divider, Progress } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
    +esxiHost: EsxiHost,
    +index: Number
  |}

  const { Line } = Progress
  
  const EsxiHostPanel = (props: Props) => {
    const { esxiHost, index } = props
  
    return (
        <Panel 
            bordered
            header={`ESXi Host-${index + 1}`}
            key={`host-${index}`}
        >
            <div style={{ width: '100%', marginBottom: '20px'}}>{esxiHost.hostId.slice(1,-1)}</div> 
            <Divider/>
            <Row style={{ alignItems: 'center' }}>
                <Col md={14}>
                <strong>CPU Usage</strong>
                </Col>
                <Col md={10}>
                <strong>{esxiHost.cpuCapacityGhz} Cores(Max)</strong>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <Line percent={esxiHost.cpuUsagePct} strokeColor="#FF8A00" />
                </Col>
            </Row>
            <Row>
                <Col md={14}>
                <strong>Memory Usage</strong>
                </Col>
                <Col md={10}>
                <strong>{esxiHost.memCapacityGb} GB(Max)</strong>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <Line percent={esxiHost.memUsagePct} strokeColor="#FF8A00" />
                </Col>
            </Row>

        </Panel>
    )
    
}

export { EsxiHostPanel }