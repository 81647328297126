import React, { Component } from 'react'
import { connect } from 'react-redux'

import { VmCatalogueComponent } from './VmCatalogueComponent'
import {
    getVmTemplatesRequest,
    getVmTemplateRequest
  } from 'modules/operations/ives'
import { ToJs } from 'utils/ToJs'

const mapStateToProps = (state) => ({
  vmTemplates: state.getIn(['ives', 'vmTemplateList']),
  vmTemplate: state.getIn(['ives', 'vmTemplate']),
})

export class VmCatalogueContainer extends Component {
  static props: {
    +getVmTemplatesRequest: Function,
    +getVmTemplateRequest: Function
  }

  constructor(props) {
    super(props)
    this.state = {
        iveId: '',
        vmTemplates: {},
        vmTemplate: undefined,
    }
  }


  componentDidMount() {
    const { iveId } = this.props.match.params
    this.setState({ iveId: iveId})
    if (iveId) {
      this.props.getVmTemplatesRequest({ iveId })
    }
    
  }

  render() {
    const {
        iveId,
        vmTemplate,
    } = this.state

    const {
        vmTemplates,
        getVmTemplateRequest
    } = this.props

    return [
      <VmCatalogueComponent
        iveId={iveId}
        vmTemplate={vmTemplate}
        vmTemplates={vmTemplates}
        getVmTemplateRequest={getVmTemplateRequest}
        key="blueprint-catalog"
      />
    ]
  }
}

export const VmCatalogue = connect(mapStateToProps, {
    getVmTemplatesRequest,
    getVmTemplateRequest
})(ToJs(VmCatalogueContainer))
