// @flow

import React from 'react'

import { ChangelogItemCard } from './card'

type Props = {|
  +name: string,
  +changelog: ChangelogItem[],
  +timezone: string,
  +type: string
|}

export const Changelog = (props: Props) =>
  props.changelog.map<Function>((change, index) => (
    <ChangelogItemCard
      key={change.changeId}
      name={props.name}
      change={change}
      timezone={props.timezone}
      showHeader={index === 0}
      type={props.type}
    />
  ))
