// @flow

import { fromJS } from 'immutable'
import { put } from 'redux-saga/effects'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  items: []
})

const moduleName = 'approvals'

const {
  approvals,
  sagas,
  getApprovalsRequest,
  getApprovalsRequestFailure,
  putApprovalsRequest,
  putApprovalsRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_APPROVALS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/approvals/'
        })
      ],
      onError: (e) => getApprovalsRequestFailure(e)
    },
    GET_APPROVALS_REQUEST_SUCCESS: (state, action) =>
      state.set('items', fromJS(action.response.results)),
    GET_APPROVALS_REQUEST_FAILURE: (state) => state,
    PUT_APPROVALS_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PUT',
          endpoint: `/approvals/${payload.approvalId}/`
        })
      ],
      onError: (e) => putApprovalsRequestFailure(e)
    },
    PUT_APPROVALS_REQUEST_SUCCESS: {
      saga: function* () {
        yield put(getApprovalsRequest())
      }
    },
    PUT_APPROVALS_REQUEST_FAILURE: (state) => state
  },
  defaultState
)
export { approvals, sagas, getApprovalsRequest, putApprovalsRequest }
