// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { VmRestorePointsComponent } from './VmRestorePointsComponent'
import {
  getVmRestorePointsRequest,
  getVmRequest,
  setVmRevertRestorePoint
} from 'modules/operations/vms'

import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type ReduxState = {|
  +vm: VM,
  +timezone: string,
  +name: string,
  +vms: IdentityObject[],
  +restorePoints: RestorePoint[]
|}
type ReduxActions = {|
  +getVmRequest: Function,
  +getVmRestorePointsRequest: Function,
  +setVmRevertRestorePoint: Function,
  +setSubmitDialogOpenState: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}

type State = {
  backdrop: Boolean,
  show: Boolean
}

const mapStateToProps = (state) =>
  ({
    vm: state.getIn(['vms', 'vm']),
    timezone: state.getIn(['account', 'timezone']),
    name: state.getIn(['vms', 'vm', 'name']),
    vms: state.getIn(['ives', 'vms']),
    restorePoints: state.getIn(['vms', 'restorePoints'])
  }: ReduxState)

class VmRestorePointsContainer extends Component<Props, State> {
  props: Props
  state: State

  constructor() {
    super()
    this.state = {
      backdrop: false,
      show: false
    }
    this.close = this.close.bind(this)
    this.open = this.open.bind(this)
  }

  close() {
    this.setState({ show: false })
  }
  open() {
    this.setState({ show: true })
  }

  componentDidMount() {
    const { vmId } = this.props
    this.props.getVmRequest({ vmId })
    this.props.getVmRestorePointsRequest({ vmId })
  }

  render() {
    return (
      <VmRestorePointsComponent
        vm={this.props.vm}
        timezone={this.props.timezone}
        restorePoints={this.props.restorePoints}
        name={this.props.name}
        setVmRevertRestorePoint={this.props.setVmRevertRestorePoint}
        handleOpenSubmitDialog={this.handleOpenSubmitDialog}
        setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
        vms={this.props.vms}
        key="vm-restore-points"
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const VmRestorePoints = connect(
  mapStateToProps,
  ({
    getVmRestorePointsRequest,
    getVmRequest,
    setVmRevertRestorePoint,
    setSubmitDialogOpenState
  }: ReduxActions)
)(ToJs(VmRestorePointsContainer))
