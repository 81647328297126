import React from 'react'
import moment from 'moment-timezone'
import { Panel, Col, Row, Icon, IconButton, Whisper, Tooltip } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

export const VmRestorePointsComponent = (props: {|
  +vm: Vm,
  +setSubmitDialogOpenState: Function
|}) => {
  const { vm, setSubmitDialogOpenState } = props

  return (
    <Panel>
      {props.restorePoints && props.restorePoints.length ? (
        <Panel>
          <Row style={{ marginBottom: '10px' }}>
            <Col md={8}>Timestamp</Col>
            <Col md={4}>Algorithm</Col>
            <Col md={3}>Type</Col>
            <Col md={2}>Restore Points</Col>
          </Row>
          {props.restorePoints.map((restorePoint, index) => (
            <Row>
              <Col md={8}>
                <strong>
                  {restorePoint.createdDatetime &&
                    moment
                      .tz(restorePoint.createdDatetime, props.timezone)
                      .format('DD MMM YYYY HH:mm:ss zz')}
                </strong>
              </Col>
              <Col md={4}>
                <strong>{restorePoint.algorithm}</strong>
              </Col>
              <Col md={3}>
                <strong>{restorePoint.type}</strong>
              </Col>
              <Col md={2}>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Revert to Restore Point</Tooltip>}
                >
                  <IconButton
                    icon={<Icon icon="backward" />}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setSubmitDialogOpenState({
                        dialogId: 'VM_REVERT_RESTORE_POINT',
                        payload: {
                          restorePointUid: restorePoint.uid
                        },
                        meta: { vmId: props.vm.id, name: vm.name }
                      })
                    }
                  >
                    Restore
                  </IconButton>
                </Whisper>
              </Col>
            </Row>
          ))}
        </Panel>
      ) : (
        <span style={{ fontSize: '1.5em' }}>
          There are no restore points for this VM.
        </span>
      )}
    </Panel>
  )
}
