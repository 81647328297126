// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  errors: {}
})

const moduleName = 'ipsecForm'

export const {
  ipsecForm,
  sagas,
  setIpsecFormErrorState,
  clearIpsecFormErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_IPSEC_FORM_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_IPSEC_FORM_ERROR_STATE: () => defaultState
  },
  defaultState
)
