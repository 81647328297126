import React from 'react';

import {
  Container,
  Header,
  Grid,
  Row,
  Col,
  Content,
  Icon,
  Button,
  Whisper,
  Tooltip,
  Panel
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { BillingInformation } from './BillingInformation';
import { UpdateBilling } from './UpdatePayment/UpdatePaymentInfo';

type Props = {
    // +textRef: Object
}

export const BillingComponent = (props: Props) => {
    
    return(
        <Container
            style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', maxHeight: '90vh' }}
            >
            <Header
                style={{
                height: '60px',
                // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)'
                background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
                }}
            >
                <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
                Billing Information
                </h3>
            </Header>
              <Content style={{ overflow: "auto" }}>
                <Grid fluid>
                    <Row>
                        <Col md={20}>
                        <BillingInformation
                            // maybe props
                        />
                        </Col>
                        <Col md={4}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '25px',
                                    marginBottom: '15px',
                                    marginRight: '15px'
                                }}
                            >
                                <UpdateBilling
                                    // maybe props
                                >
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={<Tooltip>Update CreditCard Info</Tooltip>}
                                >
                                    <Button appearance="primary" >
                                    <Icon icon="credit-card"  /> Update CreditCard
                                    </Button>
                                </Whisper>
                                </UpdateBilling>
                            </div>
                        </Col>
                    </Row>

                    <Panel>
                    <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>Please Contact Support for Cancelation</Tooltip>}
                        >
                            <a href="https://gxp-cloud.com/onboarding-support/" target="_blank" rel="noopener noreferrer">
                                <Button color='red' appearance='ghost'>
                                    <Icon icon="close-circle"  /> Cancelation of Subscription
                                </Button>
                            </a>
                        </Whisper>
                    </Panel>
                </Grid>
      </Content>
    </Container>
    )
}