// @flow

import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { Icon, Col } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const style = { cursor: 'move', width: '50px' }

export const DragHandle = SortableHandle(() => (
  <Col style={style}>
    <Icon icon="bullseye" />
  </Col>
))
