// @flow

import { push } from 'connected-react-router'
import { snakeCase } from 'lodash'
import createHumps from 'lodash-humps/lib/createHumps'
import 'unfetch/polyfill'
import FileSaver from 'file-saver'
import humps from 'lodash-humps'

import { Alert } from 'rsuite'
import {
  setSubmitDialogErrorState,
  clearSubmitDialogSubmittingState,
  setSubmitDialogIsValidState,
  clearSubmitDialogOpenState
} from 'modules/components/submitDialog'
import { resetAuth } from 'modules/operations/auth'
import { setFetch } from 'modules/components/fetch'

import {
  BAD_REQUEST,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  LOCKED,
  NO_CONTENT,
  NOT_FOUND,
  UNAUTHORIZED
} from 'http-status-codes'

const callApi = (endpoint, config, apiVersion, dispatch) => {
  // Async call starts
  dispatch(setFetch({ endpoint, fetching: true }))
  // authentication is the only module that is versionless
  const moduleName = endpoint.split('/')[1]
  const version = moduleName === 'auth' ? '' : `/${apiVersion}`
  const url = `/api${version}${endpoint}`

  return fetch(url, config).then((response) => {
    if (config.isDownload) {
      const fileHeader = response.headers.get('content-disposition')
      if (fileHeader) {
        const fileName = response.headers
          .get('content-disposition')
          .split('"')[1]
        return response
          .blob()
          .then((pdf) => FileSaver.saveAs(new File([pdf], fileName)))
      }
    }
    if (response.status === BAD_REQUEST) {
      return response.json().then((errors) =>
        Promise.reject({
          status: response.status,
          body: humps(errors)
        })
      )
    } else if (!response.ok) {
      return response.text().then((text) =>
        Promise.reject({
          status: response.status,
          body: text
        })
      )
    } else if (config.isSvg) {
      return response.text().then((text) => text)
    } else if (response.status === NO_CONTENT) {
      return Promise.resolve(response)
    } else {
      return response.json().then((json) => {
        const camelizedJson = humps(json)
        return camelizedJson
      })
    }
  })
}

export const errorHandling = (
  actionWith: Function,
  dispatch: Function,
  errors: { status: number, body: Object, event?: Object },
  failureType: string,
  next: Function,
  token: string
) => {
  if (errors.body && typeof errors.body === 'string') {
    try {
      errors.body = JSON.parse(errors.body)
    } catch (event) {
      errors.event = event
    }
  }
  switch (errors.status) {
    case BAD_REQUEST: // 400
      break
    case UNAUTHORIZED: // 401
      if (token) {
        return dispatch(resetAuth())
      }
      break
    case FORBIDDEN: // 403
      break
    case NOT_FOUND: // 404
      dispatch(push('/not-found'))
      break
    case LOCKED: // 423
      Alert.error(`${errors.body.detail}`, 10000)
      break
    case INTERNAL_SERVER_ERROR: // 500
      Alert.error('A server error has occurred.', 10000)
      break
    default:
      throw new Error(`Unhandled error: ${JSON.stringify(errors)}.`)
  }

  if (
    errors.body.electronicSignature ||
    errors.body.approvingUsername ||
    errors.body.executionDatetime
  ) {
    dispatch(setSubmitDialogErrorState(errors.body))
    // Still give a error alert in this cases: 
    const nestedObjectValues = (obj) =>
      typeof obj === 'object'
        ? Object.values(obj).map((subObj) => nestedObjectValues(subObj))
        : obj
    const bodyValues = nestedObjectValues(errors.body)
    const errorMessage = Array.isArray(bodyValues)
        ? bodyValues.join(', ')
        : bodyValues
    Alert.error(`${errorMessage}`, 10000)
  } else {
    const nestedObjectValues = (obj) =>
      typeof obj === 'object'
        ? Object.values(obj).map((subObj) => nestedObjectValues(subObj))
        : obj

    if (Object.keys(errors.body).length) {
      const bodyValues = nestedObjectValues(errors.body)
      const errorMessage = Array.isArray(bodyValues)
        ? bodyValues.join(', ')
        : bodyValues
      Alert.error(`${errorMessage}`, 10000)
    }
    dispatch(clearSubmitDialogOpenState())
  }
  dispatch(clearSubmitDialogSubmittingState())

  next(
    actionWith({
      type: failureType,
      errors: errors
    })
  )
}

// A Redux middleware that interprets REQUEST actions.
// Performs the call and promises when such actions are dispatched.
export const apiMiddleware = (store: Store) => (next: Function) => (
  action: Action
) => {
  const HTTP_METHODS = ['GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'PATCH']
  if (!action.meta || !HTTP_METHODS.includes(action.meta.method)) {
    return next(action)
  }

  const { endpoint, method } = action.meta

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  const actionWith = (data) => ({ ...action, ...data })
  const successType = action.type + '_SUCCESS'
  const failureType = action.type + '_FAILURE'

  const snakeify = createHumps(snakeCase)
  const state = store.getState()
  const token = state.getIn(['auth', 'token'])
  const apiVersion = state.getIn(['auth', 'user', 'apiVersion'])

  const { payload, meta } = action
  const { isDownload, isSvg } = meta
  const body = payload && JSON.stringify(snakeify(payload))
  const headers = action.meta.headers
    ? action.meta.headers
    : {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Token ${token}`
      }
  const config = { headers, body, method, isDownload, isSvg }

  return callApi(endpoint, config, apiVersion, store.dispatch).then(
    // Async call finished
    (response) => {
      store.dispatch(setFetch({ endpoint, fetching: false }))
      if (
        payload &&
        (payload.electronicSignature || payload.approvingUsername)
      ) {
        store.dispatch(setSubmitDialogIsValidState())
        store.dispatch(clearSubmitDialogSubmittingState())
      }
      next(actionWith({ response, type: successType }))
    },
    (errors) =>
      errorHandling(
        actionWith,
        store.dispatch,
        errors,
        failureType,
        next,
        token
      )
  )
}
