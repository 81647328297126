// @flow
import {
  REQUIRED_ERROR,
  UNICODE_ERROR,
  minLengthError,
  maxLengthError,
  minValueError,
  maxValueError
} from './errorConsts'

const valueContainsUnicode = (value: string) => {
  const unicodeRegex = /[^\u0020-\u00ff]/
  return !!unicodeRegex.test(value)
}

const validateField = (field: Object) => (options: Object = {}): Object => {
  const { minLength, maxLength, minValue, maxValue, noUnicode, regex } = options
  const required =
    [undefined, true].indexOf(options.required) >= 0 ? true : false
  const [[name, value]] = Object.entries(field)
  if (
    required &&
    (!value ||
      (typeof value === 'string' && !value.trim()) ||
      (typeof value === 'object' && !Object.keys(value).length) ||
      (Array.isArray(value) && !value.length))
  ) {
    return { [name]: REQUIRED_ERROR }
  }
  if (typeof value === 'string') {
    const valueTrimmed = value.trim()
    if (minLength && valueTrimmed.length < minLength) {
      return { [name]: minLengthError(minLength) }
    } else if (maxLength && valueTrimmed.length > maxLength) {
      return { [name]: maxLengthError(maxLength) }
    } else if (noUnicode && valueContainsUnicode(valueTrimmed)) {
      return { [name]: UNICODE_ERROR }
    } else if (regex && !regex.string.test(value)) {
      return { [name]: regex.error }
    }
  }
  if (typeof value === 'number') {
    if (typeof minValue === 'number' && value < minValue) {
      return { [name]: minValueError(minValue) }
    } else if (typeof maxValue === 'number' && value > maxValue) {
      return { [name]: maxValueError(maxValue) }
    }
  }
  return {}
}

export { valueContainsUnicode, validateField }
