// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { ApprovalsComponent } from './ApprovalsComponent'
import { getApprovalsRequest } from 'modules/operations/approvals'

const mapStateToProps = (state) => ({
  timezone: state.getIn(['account', 'timezone']),
  approvals: state.getIn(['approvals', 'items'])
})

type Props = {|
  +approvals: Approval[],
  +match: Match,
  +timezone: string,
  +getApprovalsRequest: Function
|}

class ApprovalsContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getApprovalsRequest()
  }

  render() {
    // const { approvalId } = this.props.match.params
    const { approvals, timezone } = this.props
    return (
      <ApprovalsComponent
        key="approvals"
        //approvalId={approvalId}
        approvals={approvals}
        timezone={timezone}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
const Approvals = connect(mapStateToProps, {
  getApprovalsRequest
})(ToJs(ApprovalsContainer))

export { Approvals }
