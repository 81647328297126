import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'

import {
  getIveRequest,
  getIveNetworksRequest,
  getVmTemplatesRequest,
  getVmTemplateRequest
} from 'modules/operations/ives'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

import { TemplateDialogComponent } from './TemplateDialogComponent'

const mapStateToProps = (state) => ({
  iveName: state.getIn(['ives', 'ive', 'name']),
  historyProfile: state.getIn(['ives', 'ive', 'historyProfile']),
  iveNetworks: state.getIn(['ives', 'networks']),
  vmTemplate: state.getIn(['ives', 'vmTemplate']),
})


type Props = {|
  +setSubmitDialogOpenState: Function,
  +iveId: string,
  +vmTemplate: ?Object,
  +vmTemplateId: string,
  +getVmTemplateRequest: Function,
  +children: React$Element<any>
|}

class TemplateDialogContainer extends Component<Props, State> {
    props: Props
    state: State
  
    state = {
      isOpen: false,
      vmName: '',
      vmDescription: '',
      network: '',
      ips: [],
      ip: '',
      username: '',
      password: '',
      vmChosenTemplate: {},
      focusSet: false,
      capsLockWarning: false,
      disks: []
    }

    componentDidMount() {
      if (this.props.iveId) {
        this.props.getIveRequest({ iveId: this.props.iveId });
        this.props.getIveNetworksRequest({ iveId: this.props.iveId });
        this.props.getVmTemplateRequest({ iveId: this.props.iveId, vmTemplateId: this.props.vmTemplateId });
      } 
    }

    // componentDidUpdate(prevProps) {
    //   if (this.props.vmTemplateId !== prevProps.vmTemplateId) {
    //     this.props.getVmTemplateRequest({ iveId: this.props.iveId, vmTemplateId: this.props.vmTemplateId });
    //   }
    // }

    handleChange = (change) => {
        this.setState({ ...this.state, ...change })
      }

    handleOnChangeLocation = (value: IdentityObject, item: IdentityObject) => {
      this.setState({ ...this.state.datacenter, datacenter: item })
    }

    toggleOpenState = () => {
        this.setState({ isOpen: !this.state.isOpen })
        if (this.props.vmTemplateId)  {
            this.props.getVmTemplateRequest({ iveId: this.props.iveId, vmTemplateId: this.props.vmTemplateId });
        }
    }  

    onKeyDown = (keyEvent) => {
      if (keyEvent.getModifierState('CapsLock')) {
        this.setState({ capsLockWarning: true })
      } else {
        this.setState({ capsLockWarning: false })
      }
    }

    handleOnChangeNetwork = (value: IdentityObject, item: IdentityObject) => {
      this.setState({ ...this.state.network, network: item })
      this.setState({ ...this.state.ips, ips: item.availableIps, ip: '' })
    }
  
    handleOnChangeIp = (value: IdentityObject, item: IdentityObject) => {
      this.setState({ ...this.state.ip, ip: item })
    }

    render() {
      const iveNetworks = this.props.iveNetworks.filter(
        (network) => network.dhcpEnabled
      )
        return(
            <TemplateDialogComponent
                children={this.props.children}
                vmName={this.state.vmName}
                vmDescription={this.state.vmDescription}
                onKeyDown={this.onKeyDown}
                handleChange={this.handleChange}
                handleOnChangeNetwork={this.handleOnChangeNetwork}
                handleOnChangeIp={this.handleOnChangeIp}
                ip={this.state.ip}
                ips={this.state.ips}
                iveId={this.props.iveId}
                username={this.state.username}
                password={this.state.password}
                vmTemplateId={this.props.vmTemplateId}
                vmChosenTemplate={this.props.vmTemplate}
                iveName={this.props.iveName}
                network={this.state.network}
                capsLockWarning={this.state.capsLockWarning}
                iveNetworks={iveNetworks}
                toggleOpenState={this.toggleOpenState}
                isOpen={this.state.isOpen}
                setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
            />
        )
    }
}  

export const TemplateDialog = connect(
    mapStateToProps,
    {
      getIveRequest,
      getIveNetworksRequest,
      getVmTemplatesRequest,
      getVmTemplateRequest,
      setSubmitDialogOpenState
    }
  )(ToJs(TemplateDialogContainer))
  
  
  
  