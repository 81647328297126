import React, { useRef } from 'react'
import {
  Button,
  Panel,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  SelectPicker,
  Row, Col, Icon, Divider, List, Whisper, Tooltip, IconButton, FlexboxGrid
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { AccountPassword } from '../password'
import { AccountOtp } from '../otp/AccountOtpContainer'
import { UserInvite } from '../user-invite/UserInviteContainer'

import { timezones } from './Timezones.js'

// const { StringType } = Schema.Types
// const model = Schema.Model({
//   firstname: StringType().isRequired('This field is required.'),
//   lastname: StringType().isRequired('This field is required.')
// })

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}
const timezonesMap = timezones.map((timezone) => ({
  id: timezone,
  name: timezone
}))

const userRoles = [
  {
    roleNumber: '1',
    roleName: 'Administrator'},
  {
    roleNumber: '2',
    roleName: 'Regular'},
  {
    roleNumber: '3',
    roleName: 'View Only'}
  ]

type Props = {|
  +formData: AccountInfoState,
  +groupInfo: Array,
  +otp: { 
    confirmed: boolean, 
    configUrl: string 
  },
  +role: String,
  +putUserRole: Function,
  +putAccount: Function,
  +setSubmitDialogOpenState: Function,
  +getAccountOtpQrRequest: Function,
  +handleChange: AccountInfoHandleChange,
  +handleRoleChange: Function,
  +errors: AccountInfoErrors
|}

const AccountInfoComponent = (props: Props) => {
  const { firstName, lastName, timezone, username, profileRole, group, role, otp } = props.formData
  const { groups } = props.groupInfo
  var changeAccountInfoForm = useRef(null)
  return (
    <>
    <Panel
      header={<h5>User Account Info</h5>}
      style={{ overflow: 'visible' }}
      key="accountinfocomp"
    >
      <Row key="statsrow">
        <Col md={6}>
          Username <br></br>
        <strong>{username}</strong>
        <br></br>
        <br></br>
        Primary Group <br></br>
        <strong>{group}</strong>
        <br></br>
        <br></br>
        <AccountOtp
          getAccountOtpQrRequest={props.getAccountOtpQrRequest}
          setSubmitDialogOpenState={props.setSubmitDialogOpenState}
          username={username}
          otp={otp}
        >
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Two Factor Authentication Setup</Tooltip>}
          >
            <Button appearance='ghost' color="green">
              <Icon icon="key"  /> 2FA Setup
            </Button>
          </Whisper>
        </AccountOtp>
        </Col>
        <Col md={9} key="accountinfoform">
        <Form
        autoComplete="off"
        // model={model}
        ref={(ref) => (changeAccountInfoForm = ref)}
        checkTrigger="none"
      >
        <InputField
          label="First name"
          name="firstname"
          onChange={(firstName) => props.handleChange({ firstName })}
          value={firstName}
        />
        <InputField
          label="Last name"
          name="lastname"
          onChange={(lastName) => props.handleChange({ lastName })}
          value={lastName}
        />
        <InputField
            name="timezone"
            id="timezone-picker"
            style={{ width: 'inherit' }}
            data={timezonesMap}
            onChange={(timezone) =>
              props.handleChange({ timezone })
            }
            label="Timezone"
            labelKey="name"
            valueKey="id"
            placeholder={timezone}
            accepter={SelectPicker}
            searchable={true}
            cleanable={false}
          />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '-8px'
          }}
        >
          <Button
            appearance="ghost"
            color='blue'
            label="Submit"
            type="submit"
            onClick={(e) => {
              if (changeAccountInfoForm.check()) {
                e.preventDefault()
                props.putAccount()
              }
            }}
          >
            Submit Change
          </Button>
        </div>
      </Form>
        </Col>
      <Col md={9} key="accountpasswordform">
        <AccountPassword key="accountpasswordcomp"/>
      </Col>
      </Row>
    </Panel>
    <Divider/>
    <Panel header={<h5>Group Account Info</h5>}
      style={{ overflow: 'visible' }} key="accountgroupinfocomp">
        {groups && groups.map((membergroup, index) =>
        <Row key={`group-${index}`} style={{ marginBottom: '25px' }}>
          <Col md={6}>
            Group Name<br></br>
            <strong>{membergroup.groupName}</strong>
            <br></br>
            <br></br>
            Admin User<br></br>
            <strong>{membergroup.adminUser}</strong>
            <br></br>
            <br></br>
            <UserInvite
              groupMembers={membergroup.groupMembers}
              setSubmitDialogOpenState={props.setSubmitDialogOpenState}
            >
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>Invite User to your Group account</Tooltip>}
              >
                <IconButton  
                  icon={<Icon icon="plus-square" />}
                  appearance="ghost" 
                  color='blue'
                  placement="left"
                  disabled={profileRole === 2 || profileRole === 3}
                >
                  Invite User
                </IconButton>
              </Whisper>
            </UserInvite>
          </Col>
          <Col md={18}>
            <Row>
              <Col md={8}>
                <strong>Username</strong>
              </Col>
              <Col md={12}>
                <strong>Role</strong>
              </Col>
              <Col md={4}>
                <strong>Action</strong>
              </Col>
            </Row>
            <List key={`group-list-${index}`}>
              {membergroup.groupMembers.map((groupMember, index) =>
                <Form>
                {groupMember.active ? (
                <List.Item key={`user-${groupMember.username}-${index}`} index={index}>
                  <FlexboxGrid>
                  <FlexboxGrid.Item colspan={7}>
                    <span>{groupMember.username}</span>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={13}>
                  <span style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around'}}>
                    <InputField
                      name="userrole"
                      id="userrole-picker"
                      style={{ width: '20vw' }}
                      data={userRoles}
                      onChange={( role ) =>
                        props.handleRoleChange({ role })
                      }
                      disabled={username === groupMember.username || profileRole === 2 || profileRole === 3 || groupMember.role === 1 || groupMember.role === 4 || groupMember.role === 5}
                      labelKey="roleName"
                      valueKey="roleNumber"
                      placeholder={groupMember.role === 1 ? 
                                    <span>Administrator</span>
                                  : (
                                    groupMember.role === 2 ? 
                                      <span>Regular</span>
                                  : 
                                    groupMember.role === 4 ? 
                                      <span>GxP-Admin</span>
                                  : 
                                    groupMember.role === 5 ? 
                                      <span>GxP-Admin Plus</span>
                                  : 
                                    <span>View only</span>
                                  )}
                      accepter={SelectPicker}
                      searchable={false}
                      cleanable={false}
                    />
                    <Button
                      color="orange"
                      appearance='ghost'
                      label="Submit"
                      type="submit"
                      disabled={username === groupMember.username || profileRole === 2 || profileRole === 3 || groupMember.role === 1 || groupMember.role === 4 || groupMember.role === 5}
                      onClick={() =>
                        props.setSubmitDialogOpenState({
                          dialogId: 'CHANGE_USER_ROLE',
                          payload: { username: groupMember.username, group: membergroup.groupName, role: role }
                        })
                      }
                    >
                      Submit
                    </Button>
                  </span>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4}>
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={<Tooltip>Delete User</Tooltip>}
                  >
                    <IconButton
                      icon={<Icon icon="trash" />}
                      color="red"
                      disabled={username === groupMember.username || profileRole === 2 || profileRole === 3 || groupMember.role === 1 || groupMember.role === 4 || groupMember.role === 5 }
                      onClick={() =>
                        props.setSubmitDialogOpenState({
                          dialogId: 'DELETE_USER',
                          payload: { username: groupMember.username, group: membergroup.groupName }
                        })
                      }
                    >
                      Delete
                    </IconButton>
                  </Whisper>
                  </FlexboxGrid.Item>
                  </FlexboxGrid>
                </List.Item>
                ) : (
                  ''
                )}
                </Form>
                )}
            </List>
            </Col>
          </Row>
       )}
    </Panel>
    </>
  )
}

export { AccountInfoComponent }
