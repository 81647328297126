// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  applications: [],
  vnicGroups: []
})

const moduleName = 'networking'

const {
  networking,
  getApplicationsRequest,
  getApplicationsRequestFailure,
  getVnicGroupsRequest
} = moducks.createModule(
  moduleName,
  {
    GET_APPLICATIONS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/networking/applications/'
        })
      ],
      onError: (e) => getApplicationsRequestFailure(e)
    },
    GET_APPLICATIONS_REQUEST_SUCCESS: (state, action) =>
      state.set('applications', fromJS(action.response)),
    GET_APPLICATIONS_REQUEST_FAILURE: (state) => state,
    GET_VNIC_GROUPS_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/networking/vnic-groups/'
        })
      ]
    },
    GET_VNIC_GROUPS_REQUEST_SUCCESS: (state, action) =>
      state.set('vnicGroups', fromJS(action.response))
  },
  defaultState
)
export { networking, getApplicationsRequest, getVnicGroupsRequest }
