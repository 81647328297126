// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { Changelog } from 'components/Changelog'
import { getIvesChangelogRequest, getIveRequest } from 'modules/operations/ives'

type ReduxState = {| +changelog: [], +name: string, +timezone: string |}
type ReduxActions = {|
  getIvesChangelogRequest: Function,
  getIveRequest: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}

const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    changelog: state.getIn(['ives', 'changelog']),
    name: state.getIn(['ives', 'ive', 'name'])
  }: ReduxState)

export class IveChangelogContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    const { iveId } = this.props
    this.props.getIveRequest({ iveId })
    this.props.getIvesChangelogRequest({ iveId })
  }

  render() {
    return [
      <Changelog
        key="ive-changelog-component"
        timezone={this.props.timezone}
        changelog={this.props.changelog}
        name={this.props.name}
        type={'IVE'}
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const IveChangelog = connect(
  mapStateToProps,
  ({ getIveRequest, getIvesChangelogRequest }: ReduxActions)
)(ToJs(IveChangelogContainer))
