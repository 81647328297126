import { fromJS } from 'immutable'
import moducks from 'moducks-bootstrap'

const defaultState = fromJS({
  isOpen: false,
  isValid: false,
  isSubmitting: false,
  settings: { dialogId: '' },
  executionDate: undefined,
  executionTime: undefined,
  executionDateTime: undefined,
  errors: {
    executionDate: '',
    electronicSignature: '',
    approver: ''
  }
})

const moduleName = 'submitDialog'

const {
  submitDialog,
  sagas,
  setSubmitDialogOpenState,
  clearSubmitDialogOpenState,
  setSubmitDialogSubmittingState,
  clearSubmitDialogSubmittingState,
  setSubmitDialogErrorState,
  clearSubmitDialogErrorState,
  setSubmitDialogIsValidState,
  clearSubmitDialogIsValidState,
  setDatetime
} = moducks.createModule(
  moduleName,
  {
    SET_SUBMIT_DIALOG_OPEN_STATE: (state, { payload: settings }) =>
      state
        .set('settings', fromJS(settings))
        .set('isOpen', true)
        .set('executionDate', fromJS(new Date()))
        .set('executionTime', fromJS(new Date())),
    CLEAR_SUBMIT_DIALOG_OPEN_STATE: (state) => state.set('isOpen', false),
    SET_SUBMIT_DIALOG_SUBMITTING_STATE: (state) =>
      state.set('isSubmitting', true),
    CLEAR_SUBMIT_DIALOG_SUBMITTING_STATE: (state) =>
      state.set('isSubmitting', false),
    SET_SUBMIT_DIALOG_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_SUBMIT_DIALOG_ERROR_STATE: (state) =>
      state.setIn(['errors'], defaultState.get('errors')),
    SET_SUBMIT_DIALOG_IS_VALID_STATE: (state) => state.set('isValid', true),
    CLEAR_SUBMIT_DIALOG_IS_VALID_STATE: (state) => state.set('isValid', false),
    SET_DATETIME: (state, action) =>
      state
        .set('executionDate', fromJS(action.payload.executionDate))
        .set('executionTime', fromJS(action.payload.executionTime))
  },
  defaultState
)

export {
  submitDialog,
  sagas,
  setSubmitDialogOpenState,
  clearSubmitDialogOpenState,
  setSubmitDialogSubmittingState,
  clearSubmitDialogSubmittingState,
  setSubmitDialogErrorState,
  clearSubmitDialogErrorState,
  setSubmitDialogIsValidState,
  clearSubmitDialogIsValidState,
  setDatetime
}
