import React, { Component } from 'react'
import {
  Container,
  Header,
  Content
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { getAccountRequest } from 'modules/operations/account'

import { Contact } from './Contact'
import { ReleaseNotes } from './ReleaseNotes'
import { Faq } from './Faq'

import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

const styles = {
    tabSpan: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'inherit',
        top: '50%'
    },
    tabs: {
        width: '10vw', 
        color: '#FFFFFF',
        cursor: 'pointer',
        height: 'inherit',
        textAlign: 'center',
        marginTop: '5px'
    }
}

type ReduxState = {| +account: string |}
type ReduxActions = {| +getAccountRequest: Function |}
type Props = ReduxState & ReduxActions

const mapStateToProps = (state) => ({
  account: state.getIn(['account'])
})


function TabContent(props) {
    const { activeTab } = props
    if (activeTab === 0) {
        return <Contact/>;
    }
    if (activeTab === 1) {
        return <Faq/>;
    }
    if (activeTab === 2) {
        return <ReleaseNotes/>; 
    }
}

class HelpContainer extends Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super()
    this.state = {
      activeTab: 0
    }
  }
  getState() {
    return this.state.activeTab
  }

  handleTabChange(activeTab) {
    this.setState({ activeTab: activeTab })
  }

  componentDidMount() {
    this.props.getAccountRequest()
  }

  render() {
    const activeTab = this.getState()

    return (
        <Container
            style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', height: '90vh' }}
        >
            <Header
                style={{
                    height: '60px',
                    // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)'
                    background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
                }}
            >
                <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
                    Help and Support
                </h3>
            </Header>
            <Content style={{ overflow: "auto", marginTop: '25px' }}>
                
                <span style={styles.tabSpan}>
                    <div
                        style={
                            activeTab === 0
                              ? { backgroundColor: '#FF8A00', borderRadius: '5px 0px 0px 5px', ...styles.tabs }
                              : { backgroundColor: '#373F51', borderRadius: '5px 0px 0px 5px', ...styles.tabs }
                        }
                        onClick={() => this.handleTabChange(0)}            
                    >
                        Contact
                    </div>
                    <div
                        style={
                            activeTab === 1
                              ? { backgroundColor: '#FF8A00', ...styles.tabs }
                              : { backgroundColor: '#373F51', ...styles.tabs }
                        }
                        onClick={() => this.handleTabChange(1)}            
                    >
                        FAQs
                    </div>
                    <div
                        style={
                            activeTab === 2
                              ? { backgroundColor: '#FF8A00', borderRadius: '0px 5px 5px 0px', ...styles.tabs }
                              : { backgroundColor: '#373F51', borderRadius: '0px 5px 5px 0px', ...styles.tabs }
                        }
                        onClick={() => this.handleTabChange(2)}            
                    >
                        Release Notes
                    </div>
                </span>
                
                <TabContent activeTab={activeTab} />

            </Content>
        </Container>      
    )
  }
}

//   https://github.com/facebook/flow/issues/7125
//   $FlowFixMe
export const Help = connect(mapStateToProps, { getAccountRequest })(ToJs(
    HelpContainer))

// not subscribed to ReduxState, loginForm error state has been removed 2021-01-06
