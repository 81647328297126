// @flow
import { clearAccountPasswordFormErrorState } from '../components/accountPasswordForm'
import { fromJS } from 'immutable'
import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { setElectronicSignatureState, setPasswordState, logoutRequest } from './auth'
import { Notification } from 'rsuite'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
    username: null,
    profileRole: null,
    firstName: null,
    lastName: null,
    group: null,
    invoices: [],
    ccInfo: [],
    timezone: null,
    otp: { confirmed: false, configUrl: '' },
    auditLog: [],
    errors: {}
})

const moduleName = 'account'


const {
  account,
  sagas,
  getAccountRequest,
  getAccountRequestFailure,
  postUserInviteRequest,
  postUserInviteRequestFailure,
  postUserInviteActivateRequest,
  postUserInviteActivateRequestFailure,
  putAccountDeactivateRequest,
  putAccountDeactivateRequestFailure,
  putAccountRoleChangeRequest,
  putAccountRoleChangeRequestFailure,
  getAccountOtpQrRequest,
  getAccountOtpQrRequestFailure,
  postAccountOtpActivateRequest,
  postAccountOtpActivateRequestFailure,
  getAuditLogRequest,
  getAuditLogRequestFailure,
  putAccountRequest,
  putAccountRequestFailure,
  putChangePasswordRequest,
  putChangePasswordRequestFailure,
  putElectronicSignatureRequest,
  putElectronicSignatureRequestFailure,
  postSignUpRequest,
  postSignUpRequestFailure,
  postCreditCardSignUpRequest,
  postCreditCardSignUpRequestFailure,
  postActivateCustomerRequest,
  postActivateCustomerRequestFailure,
  postAgreeTermsRequest,
  postAgreeTermsRequestFailure,
  setHasAgreedTermsState,
  postCreditCardInAppSignUpRequest,
  postCreditCardInAppSignUpRequestFailure,
  getInvoiceListRequest,
  getInvoiceListRequestFailure,
  getDraftInvoiceRequest,
  getDraftInvoiceRequestFailure,
  getFinalInvoiceRequest,
  getFinalInvoiceRequestFailure,
  getCcInfoRequest,
  getCcInfoRequestFailure,
  clearErrorState
} = moducks.createModule(
  moduleName,
  {
    GET_ACCOUNT_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/account/'
        })
      ],
      onError: (e) => getAccountRequestFailure(e)
    },
    GET_ACCOUNT_REQUEST_SUCCESS: (state, action) =>
      state.merge(fromJS(action.response)),
    GET_ACCOUNT_REQUEST_FAILURE: (state) => state,
    PUT_ACCOUNT_DEACTIVATE_REQUEST: {
    creator: [
      (payload) => payload,
      (payload, meta) => ({
        method: 'PUT',
        endpoint: `/account/deactivate-user/`
      })
    ],
    onError: (e) => putAccountDeactivateRequestFailure(e)
    },

    // Invite User to group

    POST_USER_INVITE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/account/invite-user/`
        })
      ],
      onError: (e) => postUserInviteRequestFailure(e)
    },
    POST_USER_INVITE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully invited the user.'
          })
        )
        yield put(push('/dashboard'))
      }
    },
    POST_USER_INVITE_REQUEST_FAILURE: (state) => state,

    // Register invited User

    POST_USER_INVITE_ACTIVATE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PATCH',
          endpoint: `/auth/activate-invite/`
        })
      ],
      onError: (e) => postUserInviteActivateRequestFailure(e)
    },
    POST_USER_INVITE_ACTIVATE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully registered your account, and been redirected to the login screen.'
          })
        )
        yield put(push('/login'))
      }
    },
    POST_USER_INVITE_ACTIVATE_REQUEST_FAILURE: (state) => state,

    // Delete frontend / deactivate backend Customer

    PUT_ACCOUNT_DEACTIVATE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully deleted the selected user.'
          })
        )
        yield put(push('/account'))
      }
    },
    PUT_ACCOUNT_DEACTIVATE_REQUEST_FAILURE: (state) => state,
    PUT_ACCOUNT_ROLE_CHANGE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PUT',
          endpoint: `/account/role-change/`
        })
      ],
      onError: (e) => putAccountRoleChangeRequestFailure(e)
    },
    PUT_ACCOUNT_ROLE_CHANGE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully changed your account info.'
          })
        )
        yield put(push('/account'))
      }
    },
    PUT_ACCOUNT_ROLE_CHANGE_REQUEST_FAILURE: (state) => state,
    
    // Get OTP QRcode

    GET_ACCOUNT_OTP_QR_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/account/totp/qrcode/'
        })
      ],
      onError: (e) => getAccountOtpQrRequestFailure(e)
    },
    GET_ACCOUNT_OTP_QR_REQUEST_SUCCESS: (state, action) =>
      state.merge(fromJS(action.response)),
    GET_ACCOUNT_OTP_QR_REQUEST_FAILURE: (state) => state,
    
    // Activate and verify OTP device

    POST_ACCOUNT_OTP_ACTIVATE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/account/totp/activate/${payload.otpToken}/`
        })
      ],
      onError: (e) => postAccountOtpActivateRequestFailure(e)
    },
    POST_ACCOUNT_OTP_ACTIVATE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully activated TOPT for the selected user.'
          })
        )
        yield put(push('/account'))
      }
    },
    POST_ACCOUNT_OTP_ACTIVATE_REQUEST_FAILURE: (state) => state,

    // Get Audit Log

    GET_AUDIT_LOG_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/account/audit-log'
        })
      ],
      onError: (e) => getAuditLogRequestFailure(e)
    },
    GET_AUDIT_LOG_REQUEST_SUCCESS: (state, action) =>
      state.set('auditLog', fromJS(action.response.results)),
    GET_AUDIT_LOG_REQUEST_FAILURE: (state) => state,

    // Change Account details

    PUT_ACCOUNT_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PUT',
          endpoint: '/account/'
        })
      ],
      onError: (e) => putAccountRequestFailure(e)
    },
    PUT_ACCOUNT_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully changed your account info.'
          })
        )
      },
      reducer: (state, action) => state.merge(fromJS(action.payload))
    },
    PUT_ACCOUNT_REQUEST_FAILURE: (state) => state,

    // Change Password

    PUT_CHANGE_PASSWORD_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PUT',
          endpoint: '/account/change-password/'
        })
      ],
      onError: (e) => {
        putChangePasswordRequestFailure(e)
      },
      saga: function* (action) {
        yield put(clearAccountPasswordFormErrorState())
      }
    },
    PUT_CHANGE_PASSWORD_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully changed your password.'
          })
        )
        yield put(push('/'))
        yield put(setPasswordState())
        yield put(clearErrorState())
      }
    },
    CLEAR_ERROR_STATE: (state) => state.set('errors', fromJS({})),
    PUT_CHANGE_PASSWORD_REQUEST_FAILURE: (state, action) =>
      state.setIn(['errors'], fromJS(action.errors.body)),

    // Enter Electronic Signature

    PUT_ELECTRONIC_SIGNATURE_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PUT',
          endpoint: '/account/electronic-signature/'
        })
      ],
      onError: (e) => putElectronicSignatureRequestFailure(e)
    },
    PUT_ELECTRONIC_SIGNATURE_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Info',
            placement: 'bottomEnd',
            description: 'You have successfully set your electronic signature.'
          })
        )
        yield put(setElectronicSignatureState())
      }
    },
    PUT_ELECTRONIC_SIGNATURE_REQUEST_FAILURE: (state) => state,

    // Customer Sign Up

    POST_SIGN_UP_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `account/register/`,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
      ],
      onError: (e) => postSignUpRequestFailure(e)
    },
    POST_SIGN_UP_REQUEST_SUCCESS: {
      reducer: (state, action) => state.set('customerId', fromJS(action.response.customerId)),
      saga: function* (action) {
        yield put(
          Notification.info({
            title: 'Success',
            placement: 'bottomEnd',
            description: 'Redirecting to credit card info page' 
          })
        )
        yield put(push('/creditcardsignup'))
      }
    },
    POST_SIGN_UP_REQUEST_FAILURE: (state) => state,

    // Credit Card Sign Up Outside routing
    // Will let arcus send activation email

    POST_CREDIT_CARD_SIGN_UP_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/account/send-activation-mail/`,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
      ],
      onError: (e) => postCreditCardSignUpRequestFailure(e)
    },
    POST_CREDIT_CARD_SIGN_UP_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.success({
            title: 'Success',
            placement: 'bottomEnd',
            description: 'An Activation Mail will be send to the payment email address' 
          })
        )
        yield put(push('/activate-mail-send'))
      }
    },
    POST_CREDIT_CARD_SIGN_UP_REQUEST_FAILURE: (state) => state,

    // Activate Customer and redirect to Login

    POST_ACTIVATE_CUSTOMER_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `account/activate-customer/`,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
      ],
      onError: (e) => postActivateCustomerRequestFailure(e)
    },
    POST_ACTIVATE_CUSTOMER_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.success({
            title: 'Success',
            placement: 'bottomEnd',
            description: 'Redirecting to login' 
          })
        )
        yield put(push('/login'))
      }
    },
    POST_ACTIVATE_CUSTOMER_REQUEST_FAILURE: (state) => state,

    // Internal Agree Customer to Terms and Conditions postAgreeTermsRequest

    POST_AGREE_TERMS_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'PUT',
          endpoint: `/account/internal-agree-terms/`,
        })
      ],
      onError: (e) => postAgreeTermsRequestFailure(e)
    },
    POST_AGREE_TERMS_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.success({
            title: 'Success',
            placement: 'bottomEnd',
            description: 'Successfully agreed to Terms and Conditions ' 
          })
        )
        yield put(setHasAgreedTermsState())
      }
    },
    POST_AGREE_TERMS_REQUEST_FAILURE: (state) => state,

    // Set Agreed to terms and conditions state after PUT

    SET_HAS_AGREED_TERMS_STATE: (state) =>
      state.setIn(['groupInfo', 'groups', '0', 'termsAndConditionsAccepted'], true),

    // Credit Card Signup inside Routing

    POST_CREDIT_CARD_IN_APP_SIGN_UP_REQUEST: {
      creator: [
        (payload) => payload,
        (payload, meta) => ({
          method: 'POST',
          endpoint: `/account/send-activation-mail/`,
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
      ],
      onError: (e) => postCreditCardInAppSignUpRequestFailure(e)
    },
    POST_CREDIT_CARD_IN_APP_SIGN_UP_REQUEST_SUCCESS: {
      saga: function* (action) {
        yield put(
          Notification.success({
            title: 'Success',
            placement: 'bottomEnd',
            description: 'Successfully entered credit card details. Check your Email to activate your account.' 
          })
        )
        yield put(logoutRequest())
      }
    },
    POST_CREDIT_CARD_IN_APP_SIGN_UP_REQUEST_FAILURE: (state) => state,

    // Get Invoices list

    GET_INVOICE_LIST_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/account/invoices/'
        })
      ],
      onError: (e) => getInvoiceListRequestFailure(e)
    },
    GET_INVOICE_LIST_REQUEST_SUCCESS: (state, action) =>
      state.set('invoices', fromJS(action.response)),
    GET_INVOICE_LIST_REQUEST_FAILURE: (state) => state,
    
    // Get Draft Invoice

    GET_DRAFT_INVOICE_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `${payload.url}`,
          isDownload: true
        })
      ],
      onError: (e) => getDraftInvoiceRequestFailure(e)
    },
    GET_DRAFT_INVOICE_REQUEST_SUCCESS: (state) => state,
    GET_DRAFT_INVOICE_REQUEST_FAILURE: (state) => state,

    // Get Final Invoice

    GET_FINAL_INVOICE_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: `${payload.url}`,
          isDownload: true
        })
      ],
      onError: (e) => getFinalInvoiceRequestFailure(e)
    },
    GET_FINAL_INVOICE_REQUEST_SUCCESS: (state) => state,
    GET_FINAL_INVOICE_REQUEST_FAILURE: (state) => state,

  // Get CC info of customer

  GET_CC_INFO_REQUEST: {
    creator: [
      (payload) => undefined,
      (payload, meta) => ({
        method: 'GET',
        endpoint: '/account/credit-card-info/'
      })
    ],
    onError: (e) => getCcInfoRequestFailure(e)
  },
  GET_CC_INFO_REQUEST_SUCCESS: (state, action) =>
    state.set('ccInfo', fromJS(action.response)),
  GET_CC_INFO_REQUEST_FAILURE: (state) => state,  
},

  defaultState
)

export {
  account,
  sagas,
  getAccountRequest,
  postUserInviteRequest,
  postUserInviteActivateRequest,
  putAccountDeactivateRequest,
  putAccountRoleChangeRequest,
  getAccountOtpQrRequest,
  postAccountOtpActivateRequest,
  getAuditLogRequest,
  putAccountRequest,
  putChangePasswordRequest,
  putElectronicSignatureRequest,
  postSignUpRequest,
  postCreditCardSignUpRequest,
  postActivateCustomerRequest,
  postAgreeTermsRequest,
  setHasAgreedTermsState,
  postCreditCardInAppSignUpRequest,
  getInvoiceListRequest,
  getDraftInvoiceRequest,
  getFinalInvoiceRequest,
  getCcInfoRequest,
  clearErrorState
}
