// @flow

// first load Raven to ensure it catches errors from the very beginning
// eslint-disable-next-line
import * as Sentry from '@sentry/browser'
import { createBrowserHistory } from 'history'
import { BrowserRouter } from 'react-router-dom'

// import 'modern-normalize/modern-normalize.css'
import './styles/index.css'
import './styles/roboto.css'

import { Provider } from 'react-redux'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'
import React from 'react'
import ReactDOM from 'react-dom'

import { configureStore } from './app/store'
import { unregister } from './registerServiceWorker'
import { Routes } from './app/routes'

async function renderApp() {
  const dsn = process.env.REACT_APP_RAVEN_DSN
  const debug = process.env.NODE_ENV === 'development'
  const release = process.env.REACT_APP_RELEASE
  if (dsn) {
    Sentry.init({ dsn, debug, release })
  } else if (process.env.NODE_ENV !== 'test') {
    throw new Error('Raven DSN environment variable not set')
  }
  const history = createBrowserHistory()
  const store = await configureStore(history)
  store.runSaga()
  const rootElement = document.getElementById('root')
  unregister()
  if (rootElement) {
    ReactDOM.render(
      <BrowserRouter>
        <Provider store={store}>
          <BreadcrumbsProvider>
            <Routes history={history} />
          </BreadcrumbsProvider>
        </Provider>
      </BrowserRouter>,
      rootElement
    )
  }
}

renderApp()
