// @flow

import React from 'react'

import {
  ButtonToolbar,
  Button,
  Panel,
  Icon,
  IconButton,
  Whisper,
  Tooltip
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { FirewallTable } from './FirewallTableComponent'
import { FirewallDialog } from './dialog/FirewallDialogContainer'
import { SyncIndicator } from 'components/SyncIndicator'

const style = {
  card: {
    marginBottom: '32px'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'right'
  },
  saveContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

type Props = {|
  +addFirewallRule: Function,
  +applications: IdentityObject[],
  +deleteFirewallRule: Function,
  +firewallRules: FirewallRules,
  +firewallRulesMutated: boolean,
  +iveId: string,
  +onSortEnd: Function,
  +setFirewallState: Function,
  +setSubmitDialogOpenState: Function,
  +vms: IdentityObject[],
  +vnics: IdentityObject[],
  +vnicGroups: IdentityObject[]
|}

export const FirewallComponent = (props: Props) => {
  const {
    firewallRules,
    firewallRulesMutated,
    iveId,
    setSubmitDialogOpenState
  } = props
  
  const handleSave = (): void => {
    setSubmitDialogOpenState({
      dialogId: 'SAVE_FIREWALL_RULES',
      payload: { firewallRules },
      meta: { iveId }
    })
  }
  const hasFirewallRules =
    firewallRules.immutable.length || firewallRules.user.length

  return (
    <>
      <Panel>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SyncIndicator rulesMutated={firewallRulesMutated} />
          {firewallRulesMutated ? (
            <strong>
              Make sure to Publish your changes to make them final
            </strong>
          ) : (
            ''
          )}
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Publish changes</Tooltip>}
          >
            <ButtonToolbar>
              <Button
                appearance="ghost"
                color='blue'
                label="Save"
                type="save"
                id="save-firewall-rules"
                onClick={handleSave}
                disabled={!firewallRulesMutated}
              >
                Publish
              </Button>
            </ButtonToolbar>
          </Whisper>
        </span>
      </Panel>
      <Panel bordered>
        <div style={style.buttonsContainer}>
          <FirewallDialog
            firewallRules={firewallRules}
            applications={props.applications}
            submit={props.addFirewallRule}
            vms={props.vms}
            vnicGroups={props.vnicGroups}
            dialogAction="Add"
          >
            <ButtonToolbar style={{ marginBottom: '15px' }}>
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>Add Firewall rule to IVE</Tooltip>}
              >
                <IconButton
                  icon={<Icon icon="plus-square" />}
                  appearance="ghost"
                  color='blue'
                  label="Add Rule"
                  type="add"
                  id="add-firewall-rules"
                >
                  Add Rule
                </IconButton>
              </Whisper>
            </ButtonToolbar>
          </FirewallDialog>
        </div>
        {hasFirewallRules ? (
          <FirewallTable
            applications={props.applications}
            deleteFirewallRule={props.deleteFirewallRule}
            iveId={iveId}
            onSortEnd={props.onSortEnd}
            rules={firewallRules}
            useDragHandle={true}
            vms={props.vms}
            vnicGroups={props.vnicGroups}
            key="firewall-rule-table"
          />
        ) : (
          <div style={{ fontSize: '1.5em' }}>
            There are no firewall rules for this IVE.
          </div>
        )}
      </Panel>
    </>
  )
}
