// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  errors: {}
})

const moduleName = 'accountInfoForm'

const rules = {
  SET_ACCOUNT_INFO_FORM_ERROR_STATE: (state, action) =>
    state.setIn(['errors'], fromJS(action.payload)),
  CLEAR_ACCOUNT_INFO_FORM_ERROR_STATE: () => defaultState
}

export const {
  accountInfoForm,
  sagas,
  setAccountInfoFormErrorState,
  clearAccountInfoFormErrorState
} = moducks.createModule(moduleName, rules, defaultState)
