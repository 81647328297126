import React from 'react'
import { Container, Header, Content, Panel, FlexboxGrid } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { EsxiHostPanel } from './StatisticsEsxiHostPanel'
import { DatastorePanel } from './StatisticsDatastorePanel'

type Props = {
    +dcStatistics: string,
}
export const StatisticsComponent = (props: Props) => {
    const { dcStatistics } = props
    const results = dcStatistics[0]

    return(
                <Container
                    style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', maxHeight: '90vh' }}
                    >
                    <Header
                        style={{
                        height: '60px',
                        // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)'
                        background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
                        }}
                    >
                        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
                        GxP-Cloud Datacenter Statistics
                        </h3>
                    </Header>
                    <Content style={{ overflow: "auto" }}>
                        <Panel header={<h4>ESXi Hosts statistics</h4>}>
                            <FlexboxGrid justify="center">
                                {results.results.esxiHosts.map((esxiHost, index) =>(
                                    <FlexboxGrid.Item 
                                        colspan={7}
                                        style={{ marginRight: '10px', marginBottom: '20px'}}
                                    >
                                        <EsxiHostPanel
                                            esxiHost={esxiHost}
                                            index={index}
                                        />
                                    </FlexboxGrid.Item>
                                ))}
                            </FlexboxGrid>
                        </Panel>
                        <Panel header={<h4>ESXi Datastores statistics</h4>}>
                            <FlexboxGrid justify="center">
                                {results.results.datastores.map((datastore, index) =>(
                                    <FlexboxGrid.Item 
                                        colspan={7}
                                        style={{ marginRight: '10px', marginBottom: '20px'}}
                                    >
                                        <DatastorePanel
                                            datastore={datastore}
                                            index={index}
                                        />
                                    </FlexboxGrid.Item>
                                ))}
                            </FlexboxGrid>
                        </Panel>
                    </Content>
                </Container>
)}