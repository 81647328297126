// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { Changelog } from 'components/Changelog'
import { getVmRequest, getVmChangelogRequest } from 'modules/operations/vms'

type ReduxState = {|
  +changelog: ChangelogItem[],
  +name: string,
  +timezone: string
|}
type ReduxActions = {|
  +getVmChangelogRequest: Function,
  +getVmRequest: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}

const mapStateToProps = (state) =>
  ({
    changelog: state.getIn(['vms', 'changelog']),
    name: state.getIn(['vms', 'vm', 'name']),
    timezone: state.getIn(['account', 'timezone'])
  }: ReduxState)

export class VmChangelogContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    const { vmId } = this.props
    this.props.getVmRequest({ vmId })
    this.props.getVmChangelogRequest({ vmId })
  }

  render() {
    const { timezone, changelog, name } = this.props
    return [
      <Changelog
        timezone={timezone}
        changelog={changelog}
        name={name}
        key="vm-changelog"
        type="VM"
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const VmChangelog = connect(
  mapStateToProps,
  ({
    getVmRequest,
    getVmChangelogRequest
  }: ReduxActions)
)(ToJs(VmChangelogContainer))
