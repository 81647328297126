// @flow

import React from 'react'
import { Grid, Row, Col, Panel } from 'rsuite'
import { SslVpnPortalButton } from './SslVpnPortalButtonComponent'
import { SslVpnForm } from './SslVpnFormContainer'
import { SslVpnAccountsTable } from './SslVpnAccountsTableComponent'

type Props = {|
  +accounts: SslVpnAccount[],
  +ive: Ive,
  +history: History,
  +setSubmitDialogOpenState: Function
|}

export const SslVpnComponent = (props: Props) => (
  <Grid style={{ maxWidth: '100%' }}>
    <Row>
      <Col>
        <SslVpnPortalButton
          iveName={props.ive.name}
          publicIp={props.ive.publicIp[0]}
        />
      </Col>
    </Row>
    <Row style={{ boxShadow: '5px 0 3px 0 #e0e0e0' }}>
      <Col md={12}>
        <SslVpnForm
          iveId={props.ive.id}
          setSubmitDialogOpenState={props.setSubmitDialogOpenState}
        />
      </Col>
      <Col md={12}>
      {props.accounts && props.accounts.length ? (
        <SslVpnAccountsTable
          accounts={props.accounts}
          iveId={props.ive.id}
          history={props.history}
          setSubmitDialogOpenState={props.setSubmitDialogOpenState}
        />
        ) : (
          <Panel header={<h5>SSL-VPN accounts</h5>}>
            <span style={{ fontSize: '1.2em' }}>
              There are no SSL-VPN accounts for this IVE yet.
            </span>
          </Panel>
        )}

      </Col>
    </Row>
  </Grid>
)
