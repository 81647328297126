import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getAuditLogRequest } from 'modules/operations/account'
import { AuditLogComponent } from './AuditLogComponent'
import { ToJs } from 'utils/ToJs'

const mapStateToProps = (state) => ({
    timezone: state.getIn(['account', 'timezone']),
    auditLog: state.getIn(['account', 'auditLog'])
  })
  
  type Props = {|
    +getAuditLogRequest: Function
    |}
  
  class AuditLogContainer extends Component<Props> {
    props: Props

    render() {
      return (
        <AuditLogComponent
          auditLog={this.props.auditLog}
          timezone={this.props.timezone}
        />
      )
    }
  }
  
  export { AuditLogContainer }
  
  // https://github.com/facebook/flow/issues/7125
  // $FlowFixMe
  export const AuditLog = connect(mapStateToProps, {
      getAuditLogRequest
  })(ToJs(AuditLogContainer))
  