import React from 'react'

import 'rsuite/dist/styles/rsuite-default.css'
import { HelpCharacter } from '../HelpCharacter/HelpCharacterContainer'

export const NotFound = () => (
  <div>
    <h5>Page Not Found</h5>
    The requested page does not exist.
    <HelpCharacter modalId='NOT_FOUND'/>
  </div>
)
