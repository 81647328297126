// @flow

import React from 'react'
import { Icon, IconButton, Whisper, Tooltip } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const style = {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}
const STATUS_ICON_COLOR_MAP = {
  REVOKED: {   
    icon: <IconButton
          icon={<Icon icon='thumbs-down'/>}
          color='red'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Approval Revoked'
  },
  DECLINED: {
    icon: <IconButton
          icon={<Icon icon='thumbs-down'/>}
          color='red'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Approval Declined'
  },
  FAILURE: {
    icon: <IconButton
          icon={<Icon icon='close'/>}
          color='red'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Failure'
  },
  'IQ FAILED': {
    icon: <IconButton
          icon={<Icon icon='exclamation'/>}
          color='red'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Installation Qualification Failed'
  },
  STARTED: {
    // icon: <IconButton
    //       icon={<Icon icon='spinner'/>}
    //       color='blue'
    //       appearance='ghost'
    //       circle
    //       pulse
    //       size='lg'
    //   />
  },
  SUCCESS: {
    icon: <IconButton
          icon={<Icon icon='check'/>}
          color='green'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Succes'
  },
  PENDING: {
    icon: <IconButton
          icon={<Icon icon='eye'/>}
          color='orange'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Pending for Execution'
  },
  RETRY: {
    icon: <IconButton
          icon={<Icon icon='refresh'/>}
          color='green'
          appearance='ghost'
          circle
          size='lg'
      />,
      whisperText: 'Retrying'
  },
  'PENDING APPROVAL': {
    icon: <IconButton
          icon={<Icon icon='signing'/>}
          color='orange'
          appearance='ghost'
          circle
          size='lg'
      />,
    whisperText: 'Pending Approval'
  }
}

export const TaskStatusChip = (props: {| +status: string |}) => {
  const { status } = props

  return status === 'STARTED' ? (
    <div style={style}>
      <div style={{ color: '#2589f5', ...style }}>
        <Icon icon="spinner" size="3x" pulse />
      </div>
    </div>
  ) : (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={<Tooltip>{STATUS_ICON_COLOR_MAP[status].whisperText}</Tooltip>}
    >
      <div style={style}>
        {STATUS_ICON_COLOR_MAP[status].icon}
      </div>
    </Whisper>
  )
}

