// @flow
import React, { Component } from 'react'
import { Container, Header, Content } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { IveReportsOverviewCard } from './card/IveReportsOverviewCardComponent'

type Props = {|
  +reports: ReportOverview[],
  +timezone: string,
  +getIveReportRequest: Function,
  +getVmReportRequest: Function
|}

export class OverviewComponent extends Component<Props> {
  props: Props

  render() {
    const {
      getIveReportRequest,
      getVmReportRequest,
      reports,
      timezone
    } = this.props
    return (
      <Container
        style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', maxHeight: '90vh', minHeight: '80vh' }}
      >
        <Header
          style={{
            height: '60px',
            // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)'
            background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
          }}
        >
          <h3
            style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}
          >
            GxP Documents overview
          </h3>
        </Header>
        <Content style={{ overflow: "auto" }}>
          {reports ? (
              reports.map((ive, index) => (
                <IveReportsOverviewCard
                  getIveReportRequest={getIveReportRequest}
                  getVmReportRequest={getVmReportRequest}
                  ive={ive}
                  key={`ive-${index}`}
                  timezone={timezone}
                />
              ))
          ) : (
            <div style={{ marginTop: '25px', marginLeft: '25px' }}>
              <strong>You have no documents yet.</strong>
            </div>
          )}
          
        </Content>
      </Container>
    )
  }
}
