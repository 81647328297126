// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SetElectronicSignatureDialogComponent } from './SetElectronicSignatureDialogComponent'
import { ToJs } from 'utils/ToJs'
import { putElectronicSignatureRequest } from 'modules/operations/account'
import {
  setElectronicSignatureDialogErrorState,
  clearElectronicSignatureDialogErrorState
} from 'modules/components/setElectronicSignatureDialog'

const mapStateToProps = (state) => ({
  hasElectronicSignatureSet: state.getIn([
    'auth',
    'user',
    'hasElectronicSignatureSet'
  ]),
  hasPasswordSet: state.getIn(['auth', 'user', 'hasPasswordSet']),
  groupActivated: state.getIn(['account', 'groupInfo', 'groups', '0', 'activated'])
})

type Props = {|
  +hasElectronicSignatureSet: boolean,
  +hasPasswordSet: boolean,
  +groupActivated: boolean,
  +putElectronicSignatureRequest: Function,
  +setElectronicSignatureDialogErrorState: Function,
  +clearElectronicSignatureDialogErrorState: Function
|}

type State = {
  electronicSignature: string,
  repeatedElectronicSignature: string,
  capsLockWarning: boolean
}

export class SetElectronicSignatureContainer extends Component<Props, State> {
  props: Props
  state: State

  constructor() {
    super()
    this.state = {
      electronicSignature: '',
      repeatedElectronicSignature: '',
      capsLockWarning: false
    }
  }

  componentWillUnmount() {
    this.props.clearElectronicSignatureDialogErrorState()
  }

  handleChange = (change: Object) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }

  handlePutElectronicSignature = () => {
    this.props.putElectronicSignatureRequest({
      electronicSignature: this.state.electronicSignature,
      repeatedElectronicSignature: this.state.repeatedElectronicSignature
    })
  }

  render() {
    const { hasElectronicSignatureSet, hasPasswordSet, groupActivated } = this.props
    return (
      <SetElectronicSignatureDialogComponent
        electronicSignature={this.state.electronicSignature}
        capsLockWarning={this.state.capsLockWarning}
        onKeyDown={this.onKeyDown}
        handleChange={this.handleChange}
        handlePutElectronicSignature={this.handlePutElectronicSignature}
        hasElectronicSignatureSet={hasElectronicSignatureSet}
        hasPasswordSet={hasPasswordSet}
        groupActivated={groupActivated}
        repeatedElectronicSignature={this.state.repeatedElectronicSignature}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const SetElectronicSignatureDialog = connect(mapStateToProps, {
  putElectronicSignatureRequest,
  setElectronicSignatureDialogErrorState,
  clearElectronicSignatureDialogErrorState
})(ToJs(SetElectronicSignatureContainer))
