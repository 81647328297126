import React, { Component } from 'react'
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema,
  Panel,
  Modal,
  Notification
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { postLoginRequest, postPasswordResetEmailRequest } from 'modules/operations/auth'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

import logo from '../../../img/Logo_GxP-Cloud_Cloud-Management-Console-A.svg'

const styles = {
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    flexDirection: 'column'
  },
  logoContainer: {
    marginBottom: '25px'
  },
  slaText:{
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '15px'
  },
  capsLockOn: {
    fontSize: '30px',
    fontFamily: 'Streetvertising Bold, Helvetica Neue, sans-serif',
    color: '#ff0000'
  }
}

type ReduxActions = {|
  +postLoginRequest: Function,
  +postPasswordResetEmailRequest: Function
|}
type Props = ReduxActions
type State = {|
  +email: string,
  +password: string,
  +otpToken: number,
  +warning: Boolean
|}

const { StringType } = Schema.Types
const model = Schema.Model({
  password: StringType().isRequired('This field is required.'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.')
})

function TextField(props) {
  const { name, label, type, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl name={name} accepter={accepter} type={type} {...rest} />
    </FormGroup>
  )
}

class LoginContainer extends Component<Props, State> {
  props: Props
  state: State

  state = { capsLockWarning: false, emailModalOpen: false,  redirectUrl: '', signupUrl: '' }

  constructor(props) {
    super()
  }

  handleChange = (value) => {
    const newState = { ...this.state, ...value }
    this.setState(newState)
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }

  componentDidMount() {
    if (document.documentElement) {
      document.documentElement.style.overflowY = 'auto'
    }

    if (document.body) {
      document.body.style.backgroundSize = 'cover'
    }

    if (process.env.NODE_ENV === 'development') {
      let url = "localhost:3000/reset/"
      this.setState({ ...this.state, redirectUrl: url })
    } else if (process.env.NODE_ENV === 'production') {
      let url = "cmc.gxp-cloud.com/reset/"
      this.setState({ ...this.state, redirectUrl: url })
    } else {
      let url = "staging.gxpcloud.org/reset/"
      this.setState({ ...this.state, redirectUrl: url })
    }

    if (process.env.NODE_ENV === 'development') {
      let url = "http://localhost:3000/terms-and-conditions/"
      this.setState({ ...this.state, signupUrl: url })
    } else if (process.env.NODE_ENV === 'production') {
      let url = "https://cmc.gxp-cloud.com/terms-and-conditions/"
      this.setState({ ...this.state, signupUrl: url })
    } else {
      let url = "https://staging.gxpcloud.org/terms-and-conditions/"
      this.setState({ ...this.state, signupUrl: url })
    }
  }

  handlePostLogin = () => {
    if (this.form.check()) {
      const { email, password, otpToken } = this.state
      this.props.postLoginRequest({ email, password, otpToken })
    }
  }

  toggleOpenState = () => {
    this.setState({ emailModalOpen: !this.state.emailModalOpen })
  }

  handlePostEmailPwRequest = () => {
    const { emailRequest } = this.state
    this.props.postPasswordResetEmailRequest({ email: emailRequest, redirectUrl: this.state.redirectUrl })
    Notification.info({
      title: 'Info',
      placement: 'bottomEnd',
      duration: 10000,
      description: 'If this mail address is known to us, an email will be sent to your account. Please check your Spam too.'
    })
    this.toggleOpenState()
  }

  render() {
    return (
      <div style={styles.loginContainer}>
        <img
          style={styles.logoContainer}
          src={logo}
          alt="GxP-Cloud"
          width="400px"
        />


        <Panel style={{ 
            backgroundColor: '#ffffff', 
            width: '350px',
            height: '450px'
          }}
          
        >
          <Form
            model={model}
            ref={(ref) => (this.form = ref)}
            checkTrigger="none"
          >
            <TextField
              autoFocus
              name="email"
              label="Email"
              type="email"
              onChange={(email, event) => this.handleChange({ email, event })}
            />
            <TextField
              name="password"
              label={this.state.capsLockWarning ? (
                <>
                  <span>Password / </span>
                  <span style={{ color: '#ff0000' }}>Caps Lock is On!</span>
                </>
              ) : (
                <span>Password</span>
              )}
              type="password"
              onChange={(password, event) =>
                this.handleChange({ password, event })
              }
              onKeyDown={this.onKeyDown}
            />
            <TextField
              name="otp"
              label="2FA OTP token"
              type="otp"
              onChange={(otpToken, event) => this.handleChange({ otpToken, event })}
            />
            <div>
              <Button
                appearance="ghost"
                color='blue'
                type="submit"
                style={{ marginTop: '15px', width: '300px' }}
                onClick={(e) => {
                  e.preventDefault()
                  this.handlePostLogin()
                }}
              >
                Sign In
              </Button>
            </div>
            <div style={{ width: '300px', display: 'inline-block', textAlign: 'center' }}>
              <Button 
                appearance="link"
                onClick = {() => {
                  this.toggleOpenState()
                }}
              >
                Forgot Password?
              </Button>
            </div>
            <Modal
              backdrop="static"
              show={this.state.emailModalOpen}
              size="sm"
            >
              <Modal.Header closeButton={false}>
                <Modal.Title>
                  Request password recovery email.
                </Modal.Title>
              </Modal.Header>
              <Panel>
                <div>
                  <TextField
                    autoFocus
                    style={{ width: '520px' }}
                    name="email"
                    label="Email Address"
                    type="email"
                    onChange={(emailRequest, event) => this.handleChange({ emailRequest, event })}
                  />
                </div>
              </Panel>
              <Modal.Footer>
                <Button
                  appearance='ghost'
                  onClick={this.toggleOpenState}
                  color="red"
                >
                  Cancel
                </Button>
                <Button
                  appearance="ghost"
                  color='blue'
                  onClick={(e) => {
                    e.preventDefault()
                    this.handlePostEmailPwRequest()
                  }}
                >
                  Send Email
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          <div style={styles.slaText}>
            Not a customer yet? <br></br>
            Visit:&nbsp;<a style={{ color: '#ff8a00' }} target="_blank" href="https://www.gxp-cloud.com/" rel="noopener noreferrer">GxP-Cloud.com</a> for more information 
            <br></br> or to request a trial.
        </div>
        </Panel>
      </div>
    )
  }
}

//   https://github.com/facebook/flow/issues/7125
//   $FlowFixMe
export const Login = connect(
  null,
  ({
    postLoginRequest,
    postPasswordResetEmailRequest
  }: ReduxActions)
)(ToJs(LoginContainer))

// not subscribed to ReduxState, loginForm error state has been removed 2021-01-06
