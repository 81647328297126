import React from 'react';
import { Link } from 'react-router-dom';

import { Nav, Icon, Sidenav } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import logo from '../../../img/GxP Cloud_Logo_White.svg';

export const SidebarComponent = (props: {|
  +pathname: string,
  +account: string,
  +logoutRequest: Function
|}) => {
  const styles = {
    width: 188,
    display: 'inline-table',
    marginRight: 10,
    minHeight: '100%',
    position: 'absolute'
  }

  const allowedGroups = props.account.group === "Billing" || props.account.group === "GxP-Cloud" || props.account.group === "Iperion IT" || props.account.group === "GxP-Cloud Dev" || props.account.group === "Staging"
  const allowedPriceGroups = props.account.group === "GxP-Cloud" || props.account.group === "GxP-Cloud Dev"

  return (
    <div style={styles}>
      <Sidenav {...props.logoutRequest} appearance="inverse">
        <Sidenav.Header
          id= "sidenavHeader"
          style={{
            // background: 'linear-gradient(90deg, rgba(255,138,0,1) 65%, rgba(235,235,235,1) 100%)',
            background: 'linear-gradient(50deg, rgba(255,138,0,1) 0%, rgba(55,63,81,1) 95%)',
            fontSize: '40px',
            align: 'center',
            color: '#ffffff',
            height: '65px',
            padding: 20,
            fontFamily: 'Streetvertising Bold, Helvetica Neue, sans-serif'
          }}
          icon={<Icon icon="cloud" />}
        >
          <img 
            alt="GxP-Cloud" 
            src={logo} 
            width="150px" 
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} 
          />
        </Sidenav.Header>
        <Sidenav.Body
          style={{ 
            minHeight: 'calc(100vh - 65px)', 
            background: 'linear-gradient(90deg, rgba(55,63,81,1) 0%, rgba(125,135,161,1) 100%)', 
            color: '#ffffff' }}
        >
          <Nav>
            <Nav.Item
              icon={<Icon icon="dashboard" />}
              componentClass={Link}
              to={'/dashboard/recent'}
            >
              Dashboard
            </Nav.Item>
            <Nav.Item
              componentClass={Link}
              to={'/ive-catalogue'}
              icon={<Icon icon="plus-square-o" />}
            >
              Add IVE
            </Nav.Item>
            <Nav.Item
              componentClass={Link}
              to={'/overview'}
              icon={<Icon icon="file-pdf-o" />}
            >
              Documents
            </Nav.Item>
            <Nav.Item
              componentClass={Link}
              to={'/account'}
              icon={<Icon icon="user-o" />}
            >
              Account
            </Nav.Item>
            { allowedGroups ? (
              <Nav.Item
                componentClass={Link}
                to={'/statistics'}
                icon={<Icon icon="bar-chart" />}
              >
                Statistics
              </Nav.Item>
            ) : (
              ''
            )}
            { allowedPriceGroups ? (
              <Nav.Item
                componentClass={Link}
                to={'/management'}
                icon={<Icon icon="setting" />}
              >
                Management
              </Nav.Item>
            ) : (
              ''
            )}
            <Nav.Item
              componentClass={Link}
              to={'/help'}
              icon={<Icon icon="help-o" />}
            >
              Help
            </Nav.Item>
            <Nav.Item
              onClick={() => props.logoutRequest()}
              componentClass={Link}
              to={'/'}
              icon={<Icon icon="sign-out" />}
            >
              Logout
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  )
}
