// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  iveListSummary: ''
})

const moduleName = 'ivelistsummary'

const {
  ivelistsummary,
  sagas,
  getIvesSummaryRequest,
  getIvesSummaryRequestFailure
} = moducks.createModule(
  moduleName,
  {
    GET_IVES_SUMMARY_REQUEST: {
      creator: [
        (payload) => undefined,
        (payload, meta) => ({
          method: 'GET',
          endpoint: '/ives/summary/?must_have_vms=1'
        })
      ],
      onError: (e) => getIvesSummaryRequestFailure(e)
    },
    GET_IVES_SUMMARY_REQUEST_SUCCESS: (state, action) =>
      state.set('iveListSummary', fromJS(action.response)),
    GET_IVES_SUMMARY_REQUEST_FAILURE: (state) => state
  },
  defaultState
)

export { ivelistsummary, sagas, getIvesSummaryRequest }
