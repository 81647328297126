// @flow

import React from 'react'
import { Icon } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const style = {
  icon: { cursor: 'pointer' }
}

export const DeleteRuleButtonColumn = (props: {|
  deleteRule: Function,
  id: string
|}) => (
  <Icon
    icon="trash-o"
    size="lg"
    id={props.id}
    onClick={props.deleteRule}
    style={style.icon}
  />
)
