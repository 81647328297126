// @flow

import { connect } from 'react-redux'
import { Component } from 'react'
import NchanSubscriber from 'nchan'
import moment from 'moment-timezone'
import { Notification } from 'rsuite'

import { ToJs } from '../../utils/ToJs'
import { getTasksRequest } from '../../modules/operations/tasks'

import { getIvesSummaryRequest } from 'modules/operations/ivelistsummary'

type ReduxState = {
  +token: string,
  +username: string,
  +group: string,
  +tasks: Task[],
  +ivelistsummary: string
}
type ReduxActions = {|
  +getTasksRequest: Function,
  +getIvesSummaryRequest: Function
|}
type Props = ReduxState & ReduxActions

const mapStateToProps = (state: Object) =>
  ({
    username: state.getIn(['account', 'username']),
    group: state.getIn(['account', 'group']),
    token: state.getIn(['auth', 'token']),
    tasks: state.getIn(['tasks', 'items']),
    iveListSummary: state.getIn(['ivelistsummary', 'iveListSummary'])
    // $FlowFixMe
  }: ReduxState)

class WebsocketContainer extends Component<Props> {
  props: Props
  subscriber: NchanSubscriber

  handleMessage = (message: string, message_metadata: Object) => {
    const messageObj = JSON.parse(message)
    const { type, data, ts } = messageObj
    const now = Date.now()
    const isNewMessage = moment(ts).isAfter(now - 60e3)
    if (isNewMessage && type === 'task') {
      this.handleTaskMessage(data)
    }
  }

  handleTaskMessage = (data) => {
    const { tasks, getTasksRequest, getIvesSummaryRequest } = this.props
    const { id, name, status } = data
    const [task] = tasks.filter((task) => task.id === id)
    if (!task || task.status !== status) {
      getTasksRequest()
      getIvesSummaryRequest()
      if (status.toLowerCase() === 'success') {
        Notification.success({
          title: 'Success',
          placement: 'bottomEnd',
          description: `${name} task is ${status.toLowerCase()}`
        })
      } else if (status.toLowerCase() === 'started') {
        Notification.info({
          title: 'Info',
          placement: 'bottomEnd',
          description: `${name} task is ${status.toLowerCase()}`
        })
      } else if (status.toLowerCase() === 'failure' || 'iq failed') {
        Notification.error({
          title: 'Error',
          placement: 'bottomEnd',
          description: `${name} task is ${status.toLowerCase()}`
        })
      } else {
        Notification.info({
          title: 'Info',
          placement: 'bottomEnd',
          description: `${name} task is ${status.toLowerCase()}`
        })
      }
    }
  }

  componentDidUpdate() {
    const { username, group, token } = this.props
    if (!this.subscriber && username && group && token) {
      const url = `/!/ws/sub/${group}/${username}/?token=${token}`
      const options = { subscriber: 'websocket', reconnect: 'persist' }
      this.subscriber = new NchanSubscriber(url, options)
      this.subscriber.on('message', this.handleMessage)
      this.subscriber.reconnect = true
      // No errors local, See on staging if this really works on refresh
      this.subscriber.start()
    } else if (username && !group) {
      throw new Error('Group is undefined')
    } else if (!username && group) {
      throw new Error('Username is undefined')
    }
  }

  componentWillUnmount() {
    if (this.subscriber) {
      this.subscriber.stop()
    }
  }

  render() {
    return null
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Websocket = connect(
  mapStateToProps,
  ({ getTasksRequest, getIvesSummaryRequest }: ReduxActions)
)(ToJs(WebsocketContainer))
