// @flow

import React, { useRef } from 'react'
import {
  Button,
  Modal,
  Schema,
  ControlLabel,
  FormControl,
  FormGroup,
  InputNumber,
  SelectPicker,
  Form,
  Divider
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { NumberType, StringType } = Schema.Types
const model = Schema.Model({
  publicIp: StringType().isRequired('This field is required.'),
  ruleVM: StringType().isRequired('This field is required.'),
  ruleProtocol: StringType().isRequired('This field is required.'),
  originalPortFrom: NumberType()
    .isRequired('This field is required.')
    .isInteger('It can only be an integer')
    .addRule((value) => {
      if (value == 0 || value == 22 || value == 4443) {
        return false
      } else if (value > 65535) {
        return false
      } else {
        return true
      }
    }, 'Please enter a number between 0 - 65535, Ports 22 and 4443 are reserved'),
  translatedPortFrom: NumberType()
    .isRequired('This field is required.')
    .isInteger('It can only be an integer')
    .addRule((value) => {
      if (value == 0 || value > 65535) {
        return false
      } else {
        return true
      }
    }, 'Please enter a number between 0 - 65535')
})

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
  +ive: Ive,
  +children: React$Element<any>,
  +dialogAction: string,
  +handleOnChangeOriginalPublicIp: Function,
  +handleOnChangeProtocol: Function,
  +handleOnChangeVm: Function,
  +handleOriginalPortFromChange: Function,
  +handleOriginalPortToChange: Function,
  +handleTranslatedPortFromChange: Function,
  +handleTranslatedPortToChange: Function,
  +isOpen: boolean,
  +rule: DnatRule,
  +submit: Function,
  +toggleOpenState: Function,
  +vms: Array<IdentityObject>
|}

const PROTOCOLS = [
  { id: 'tcp', name: 'TCP' },
  { id: 'udp', name: 'UDP' }
]

export const DnatDialogComponent = (props: Props) => {
  const dialogTitle = props.dialogAction + ' DNAT rule'
  const containerStyle = { display: 'inline' }
  const { rule } = props
  var dNatForm = useRef(null)

  const publicIps = props.ive.publicIp.map(ip => ({id: ip, name: ip}));
  // publicIps[0] = {id: '185.121.188.212', name: '185.121.188.212'} object

  return (
    <div style={containerStyle}>
      {React.cloneElement(props.children, {
        onClick: props.toggleOpenState
      })}
      <Modal backdrop="static" show={props.isOpen} size="sm">
        <Modal.Header closeButton={false}>
          <Modal.Title>{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Form
          model={model}
          ref={(ref) => (dNatForm = ref)}
          fluid
          style={{ marginTop: '25px' }}
          checkTrigger="none"
        >
          <Divider>Original Address</Divider>
          <InputField
            name="publicIp"
            id="public-ip"
            style={{ width: 'inherit' }}
            data={publicIps}
            onSelect={props.handleOnChangeOriginalPublicIp}
            label="Original Public IP"
            labelKey="name"
            valueKey="id"
            accepter={SelectPicker}
            searchable={false}
          />
          <InputField
            name="originalPortFrom"
            id="rule-original-from"
            label="Original port from"
            style={{ width: 'inherit' }}
            onChange={props.handleOriginalPortFromChange}
            value={rule.originalPortFrom}
            accepter={InputNumber}
            prefix="Port"
          />
          <InputField
            name="originalPortTo"
            id="rule-original-to"
            label="Original port to"
            type="name"
            style={{ width: 'inherit' }}
            onChange={props.handleOriginalPortToChange}
            value={rule.originalPortTo}
            disabled={true}
            accepter={InputNumber}
            prefix="Port"
          />
          <Divider>Translated Address</Divider>
          <InputField
            name="ruleVM"
            id="rule-vm"
            style={{ width: 'inherit' }}
            data={props.vms}
            onSelect={props.handleOnChangeVm}
            label="Virtual Machine"
            labelKey="name"
            valueKey="id"
            accepter={SelectPicker}
            searchable={false}
            renderValue={(id, item) => {
              return <>{item.name}</>
            }}
          />
          <InputField
            name="translatedPortFrom"
            id="rule-translated-from"
            label="Translated port from"
            type="translatedPortFrom"
            style={{ width: 'inherit' }}
            onChange={props.handleTranslatedPortFromChange}
            value={rule.translatedPortFrom}
            accepter={InputNumber}
            prefix="Port"
          />

          <InputField
            name="translatedPortTo"
            id="rule-translated-to"
            label="Translated port to"
            type="translatedPortTo"
            style={{ width: 'inherit' }}
            onChange={props.handleTranslatedPortToChange}
            value={rule.translatedPortTo}
            disabled={true}
            accepter={InputNumber}
            prefix="Port"
          />
          <InputField
            name="ruleProtocol"
            id="rule-protocol"
            style={{ width: 'inherit' }}
            data={PROTOCOLS}
            onSelect={props.handleOnChangeProtocol}
            label="Protocol"
            labelKey="name"
            valueKey="id"
            accepter={SelectPicker}
            searchable={false}
          />
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Modal.Footer>
              <Button onClick={props.toggleOpenState} color="red">
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                onClick={(e) => {
                  if (dNatForm.check()) {
                    e.preventDefault()
                    props.submit()
                  }
                }}
              >
                {props.dialogAction}
              </Button>
            </Modal.Footer>
          </span>
        </Form>
      </Modal>
    </div>
  )
}
