// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { NatComponent } from './NatComponent'
import {
  getIveVnicsRequest,
  setSnatState,
  setDnatState,
  getNatRequest,
  deleteSnatState,
  deleteDnatState
} from 'modules/operations/ives'
import { ToJs } from 'utils/ToJs'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type ReduxState = {|
  +snatRules: SnatRules,
  +dnatRules: DnatRules,
  +natRulesMutated: boolean
|}
type ReduxActions = {|
  +getNatRequest: Function,
  +getIveVnicsRequest: Function,
  +setSnatState: Function,
  +setDnatState: Function,
  +setSubmitDialogOpenState: Function,
  +deleteSnatState: Function,
  +deleteDnatState: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}

const mapStateToProps = (state) =>
  ({
    snatRules: state.getIn(['ives', 'snatRules']),
    dnatRules: state.getIn(['ives', 'dnatRules']),
    natRulesMutated: state.getIn(['ives', 'natRulesMutated'])
  }: ReduxState)

export class NatContainer extends Component<Props> {
  props: Props

  addSnatRule: Function
  editSnatRule: Function
  deleteSnatRule: Function

  addDnatRule: Function
  editDnatRule: Function
  deleteDnatRule: Function

  componentDidMount() {
    const { iveId } = this.props
    this.props.getNatRequest({ iveId })
    this.props.getIveVnicsRequest({ iveId })
  }

  addSnatRule = (rule: SnatRule) => {
    const { snatRules } = this.props
    const { immutable, user: userRules } = snatRules
    const user = [...userRules, rule]
    const rules = { immutable, user }
    this.props.setSnatState(rules, this.props.iveId)
  }

  addDnatRule = (rule: DnatRule) => {
    const { dnatRules } = this.props
    const { immutable, user: userRules } = dnatRules
    const user = [...userRules, rule]
    const rules = { immutable, user }
    this.props.setDnatState(rules, this.props.iveId)
  }

  editSnatRule = (rule: SnatRule, ruleIndex: number) => {
    const user = [...this.props.snatRules.user]
    user[ruleIndex] = rule
    const immutable = this.props.snatRules.immutable
    const snatRules = { immutable, user }
    this.props.setSnatState(snatRules, this.props.iveId)
  }

  editDnatRule = (rule: DnatRule, ruleIndex: number) => {
    const user = [...this.props.dnatRules.user]
    user[ruleIndex] = rule
    const immutable = this.props.dnatRules.immutable
    const dnatRules = { immutable, user }
    this.props.setDnatState(dnatRules, this.props.iveId)
  }

  deleteSnatRule = (index: number) => this.props.deleteSnatState(index)
  deleteDnatRule = (index: number) => this.props.deleteDnatState(index)

  render() {
    const { iveId } = this.props
    return [
      <NatComponent
        addDnatRule={this.addDnatRule}
        addSnatRule={this.addSnatRule}
        deleteDnatRule={this.deleteDnatRule}
        deleteSnatRule={this.deleteSnatRule}
        dnatRules={this.props.dnatRules}
        editSnatRule={this.editSnatRule}
        editDnatRule={this.editDnatRule}
        iveId={iveId}
        key="ive-nat-table"
        natRulesMutated={this.props.natRulesMutated}
        setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
        snatRules={this.props.snatRules}
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const Nat = connect(
  mapStateToProps,
  ({
    getNatRequest,
    getIveVnicsRequest,
    setSubmitDialogOpenState,
    setSnatState,
    setDnatState,
    deleteSnatState,
    deleteDnatState
  }: ReduxActions)
)(ToJs(NatContainer))
