import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getAccountRequest } from 'modules/operations/account'
import { ManagementComponent } from './ManagementComponent'

import { ToJs } from 'utils/ToJs'

type ReduxActions = {|  +getAccountRequest: Function |}
type Props = ReduxActions 

const mapStateToProps = (state) =>
({
  account: state.getIn(['account']),
  groups: state.getIn(['account', 'groups']),
})

export class ManagementContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getAccountRequest()
  }

  render() {
    if (this.props.account) {
      const allowedGroups = this.props.account.group === "Billing" || this.props.account.group === "GxP-Cloud" || this.props.account.group === "Iperion IT" || this.props.account.group === "GxP-Cloud Dev" || this.props.account.group === "Staging"
      if (allowedGroups) {
        return (
          <ManagementComponent/>
        )
      } else {
        return (
          <div>Loading</div>
        )
      }
    } else {
      return (
        <div><h5>Not Found</h5></div>
      )
    }
  }
}

export const Management = connect(mapStateToProps, {
  getAccountRequest
})(ToJs(ManagementContainer))