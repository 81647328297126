import React, { Component } from 'react'

import { Panel, Grid, Row, Col } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import logo from '../../../img/GxP Cloud_Logo_White.svg'

const styles = {
  // background: 'linear-gradient(50deg, #FF8A00 40%, #373F51 40%)'
  // background: 'linear-gradient(90deg, rgba(55,63,81,1) 1%, rgba(255,138,0,1) 25%, rgba(255,255,255,1) 100%)'
  // background: 'linear-gradient(50deg, rgba(255,138,0,1) 10%, rgba(55,63,81,1) 100%)'
  background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
}

type DashboardItem = {|
  +name: string,
  +quantity: number
|}

type Props = {|
  +dashboard: [DashboardItem],
  +getDashboardRequest: Function
|}

export class DashboardLogoComponent extends Component<Props> {
  props: Props

  componentDidMount() {
    this.props.getDashboardRequest()
  }

  render() {
    return (
      <Panel style={styles} bordered>
        <Grid style={{ width: '100%' }}>
          <Row>
            <Col md={6}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <img alt="GxP-Cloud" src={logo} style={{ height: '5vh'}} />
              </div>
            </Col>
          </Row>
        </Grid>
      </Panel>
    )
  }
}
