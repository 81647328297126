import React from 'react'

import { Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

export const ReleaseNotes = () => (
  <Panel>
    <div style={{ direction: 'ltr' }}>
      <h5 style={{ color: '#FF8A00' }}>Q2 2023 Blueprinting</h5>
      <br></br>
        Q2 2023 <br></br>
        <ul>
          <li>
            <strong>GxP Blueprinting</strong>
            <br></br>
            Addition of blueprinting to the GxP-Cloud management console. This has some user interface implications, which has 
            resulted in the creation of both the blueprint and virtual machine catalogue. Via this catalogues you can 
            still choose the configurable options to fully customize your infrastructure.<br></br>
            <br></br>
          </li>
          <li>
            <strong>Functional Blueprint</strong>
            <br></br>
            Functional blueprinting provides ready to use application environments.
            Instead of setting up the environment and installing the software, we have compressed this work into a blueprint.
            Leverage the power of functional blueprinting by deploying ready-to-use environments with your application embedded. 
            Configuration of network settings and firewalls can be preconfigured allowing availability of your application within minutes.<br></br>
            <br></br>
          </li>
          <li>
            <strong>Infrastructure Blueprint</strong>
            <br></br>
            Infrastructure blueprinting provides ready to use standarized infrastructure environments.
            Use predefined Infrastructure Blueprints which allow you to quickly deploy a ready-to-use infrastructure whilst still allowing for full customization. <br></br>
            <br></br>          
          </li>
          <li>
            <strong>Bug fixes</strong>
            <br></br>
            Some small bug fixes back- and front end.
          </li>
          {/* <li>
            <strong>title</strong>
            <br></br>
            Explanation
          </li> */}
        </ul>
        <br></br>
    </div>
  </Panel>
)
