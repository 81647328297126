// @flow

import { fromJS, Map } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({ errors: {} })

const moduleName = 'blueprintCatalogForm'

export const {
  blueprintCatalogForm,
  sagas,
  setBlueprintCatalogFormErrorState,
  clearBlueprintCatalogFormErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_BLUEPRINT_CATALOG_FORM_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_BLUEPRINT_CATALOG_FORM_ERROR_STATE: (state) =>
      state.setIn(['errors'], Map({}))
  },
  defaultState
)
