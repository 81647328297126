import React, { Component } from 'react';

import { AccountOtpComponent } from './AccountOtpComponent';

type Props = {|
    +children: React$Element<any>,
    +username: string,
    +otp: { 
        confirmed: boolean, 
        configUrl: string 
      },
    +getAccountOtpQrRequest: Function,
    +setSubmitDialogOpenState: Function
  |}

class AccountOtpContainer extends Component<Props, State> {
    props: Props
    state: State
  
    state = {
      isOpen: false,
      otpToken: ''
    }


    toggleOpenState = () => {
        this.setState({ isOpen: !this.state.isOpen })
      }

    handleChange = (change) => {
        this.setState({ ...this.state, ...change })
      }

    render() {
        return(
            <AccountOtpComponent
                children={this.props.children}
                username={this.props.username}
                handleChange={this.handleChange}
                otp={this.props.otp}
                otpToken={this.state.otpToken}
                toggleOpenState={this.toggleOpenState}
                getAccountOtpQrRequest={this.props.getAccountOtpQrRequest}
                setSubmitDialogOpenState={this.props.setSubmitDialogOpenState}
                isOpen={this.state.isOpen}

            />
        )
    }
}

export const AccountOtp = AccountOtpContainer