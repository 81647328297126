import React, { Component } from 'react'
import { connect } from 'react-redux'

import { LoadingComponent } from './LoadingComponent'
import { ToJs } from 'utils/ToJs'

class LoadingContainer extends Component<Props> {
  state = { renderLoader: false, breakcondition: false, reset: false }

  componentDidUpdate(prevProps, prevState) {
    // assumption for solution: loading is always true! 
    // Will happen if an error breaks the normal loading through ws flow.
    const { loading } = this.props
    const { renderLoader, breakcondition, reset } = this.state

        // 1: First cant trigger 1. Only after 3. Sets break: true so 3 doesnt trigger again. Sets reset: true to start the reset flow
    if (loading && renderLoader) {
        setTimeout(() => {
          if (this.props.loading && this.state.renderLoader) {
            this.setState({ renderLoader: false, breakcondition: true, reset: true })
          }
        }, 10000)
        // 2: If !loading after succes answer: stop renderLoader. resets Break flow
    } else if (!loading && renderLoader) {
        this.setState({ renderLoader: false, breakcondition: false })
        // 3: Loading: true. Set renderLoader: true so next hit= 1
    } else if (loading && !breakcondition && !renderLoader) {
        setTimeout(() => {
          if (!this.state.renderLoader) {
            this.setState({ renderLoader: true })
          }
        }, 250)
        // 4: Reset default State if (!loading && reset) after correct answer from Back-end. So loading will work fine after.
    } else if (!loading && reset) {
      this.setState({ renderLoader: false, breakcondition: false, reset: false })
    }
  }

  render() {
    const { renderLoader } = this.state

    return renderLoader && <LoadingComponent />
  }
}

const mapStateToProps = (state) => ({
  loading: state.getIn(['fetch', 'loading'])
})

export const Loading = connect(mapStateToProps)(ToJs(LoadingContainer))