import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'
import { BillingComponent } from './BillingComponent'

const mapStateToProps = (state) => ({
    
  })

export class BillingContainer extends Component {

    render () {
        return (
            <BillingComponent/>
        )
    }
}

export const Billing = connect(mapStateToProps, {
    // request
  })(ToJs(BillingContainer))
  