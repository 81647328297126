import React from 'react'
import { Row, Col, Icon, Grid } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { DeleteRuleButtonColumn } from 'components/DeleteRuleButtonColumn'

type Props = {
  deleteIpsecSite: Function,
  iveId: string,
  ive: Ive,
  site: ipsecSite,
  sites: ipsecSites,
  index: number
}

export const IpsecSiteRow = (props: Props) => {
  const { site, index, ive } = props
  const deleteRule = () => props.deleteIpsecSite(index)

  return (
    <Grid fluid>
      <Row style={{ marginTop: '8px' }}>
        <Col md={4}>
          <strong style={{ overflowWrap: 'anywhere' }}>{site.name}</strong>
        </Col>
        <Col md={4}>
          <strong>{ive.name}</strong>
        </Col>
        <Col md={3}>
          {site.localSubnets.map((localSubnet, index) => (
            <div key={index}>
              <strong>{localSubnet}</strong>
            </div>
          ))}
        </Col>
        <Col md={2}>
          <Icon icon='long-arrow-right'/>
        </Col>
        <Col md={3}>
          <strong>{site.peerIp}</strong>
        </Col>
        <Col md={4}>
          {site.peerSubnets.map((peerSubnet, index) => (
            <div key={index}>
              <strong>{peerSubnet}</strong>
            </div>
          ))}
        </Col>
        <Col md={4}>
          <DeleteRuleButtonColumn
            id={`delete-ipsec-site-${site.id}`}
            deleteRule={deleteRule}
          />
        </Col>
      </Row>
    </Grid>
  )
}
