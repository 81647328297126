import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema,
  Panel,
  Notification,
  Grid,
  Row,
  Col,
  Whisper,
  Tooltip
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

import publicIp from 'public-ip'
import { logoutRequest } from 'modules/operations/auth'

const public_api_key = process.env.REACT_APP_FUSEBILL_PUBLIC_API_KEY
const recaptcha_key = process.env.REACT_APP_FUSEBILL_RECAPTCHA_KEY
const currentYear = parseInt(new Date().getFullYear().toString().substr(2))
const maxNumberOfYears = 20

const styles = {
  slaText:{
    width: '650',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '12px'
  },
  breadcrum: {
    marginTop: '10px',
    color: '#000000',
  }
}

const mapStateToProps = (state) => ({
  customerId: state.getIn(['account', 'customerId']),
  groupInfo: state.getIn(['account', 'groupInfo'])
 })


type ReduxActions = {|
  +postCreditCardSignUpRequest: Function,
  +logoutRequest: Function
|}
type Props = ReduxActions
type State = {|
  +creditCardFirstName: string,
  +creditCardLastName: string,
  +creditCardEmail: string,
  +creditCardNumber: string,
  +expirationMonth: number,
  +expirationYear: number,
  +cvv: string,
  +recaptchaResponse: string,
  +warning: Boolean
|}

const { StringType, NumberType } = Schema.Types
const model = Schema.Model({
  creditCardFirstName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z\s]*$/, 'Please use only letters and/or spaces.'),
  creditCardLastName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z\s]*$/, 'Please use only letters and/or spaces.'),
  creditCardEmail: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.')
    .pattern(/^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/, 'No public email allowed'),
  creditCardNumber: StringType().isRequired('This field is required.'),
  // Check CC regexes
  expirationMonth: NumberType()
  .isRequired('This field is required.')
  .isInteger('Please input a number.')
  .range(1, 12, 'Please input numbers 1-12 in format MM'),
  expirationYear: NumberType()
  .isRequired('This field is required.')
  .isInteger('Please input a number.')
  .range(currentYear, currentYear + maxNumberOfYears, 'Invalid expiration year. Input in YY.'),
  cvv: StringType()
  .isRequired('This field is required.')
  .pattern(/^[0-9]*$/,'Please input a number')
  .rangeLength(3, 4, 'Must be 3-4 digits.')
})

function TextField(props) {
  const { name, label, type, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl name={name} accepter={accepter} type={type} {...rest} />
    </FormGroup>
  )
}


class CreditCardSignUpContainer extends Component<Props, State> {
  props: Props
  state: State

  state = { capsLockWarning: false }

  constructor(props) {
    super()
  }

  handleChange = (value) => {
    const newState = { ...this.state, ...value }
    this.setState(newState)
  }

  componentDidMount() {
    if (document.documentElement) {
      document.documentElement.style.overflowY = 'auto'
    }
    if (document.body) {
      document.body.style.backgroundSize = 'cover'
    }
  }

  handlePostCreditCardSignUp = async () => {
    if (this.form.check()) {
      const { 
        creditCardFirstName,
        creditCardLastName,
        creditCardEmail,
        creditCardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        recaptchaResponse
      } = this.state

      const wePay = window.WePay
      wePay.risk.generate_risk_token()
      const riskToken = wePay.risk.get_risk_token()

      const clientIp = await publicIp.v4()
      let customerId = null;
      if (this.props.customerId) {
        customerId = this.props.customerId
      } else if (this.props.groupInfo) {
        customerId = this.props.groupInfo.groups[0].customerId
      }
      const data = {
        "customerId": customerId,
        "PublicApiKey": public_api_key,
        "cardNumber": creditCardNumber,
        "firstName": creditCardFirstName,
        "lastName": creditCardLastName,
        "expirationMonth": expirationMonth,
        "expirationYear": expirationYear,
        "cvv": cvv,
        "makeDefault": true,
        "recaptcha": recaptchaResponse,
        "email": creditCardEmail,
        "clientIp": clientIp,
        "riskToken": riskToken,
      }


      fetch('https://payments.subscriptionplatform.com/api/paymentsv2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(data)
      })
      .then(async response =>  {
        if (response.status === 400) {
          Notification.error({
            title: 'Error',
            placement: 'bottomEnd',
            description: 'Something did go wrong. Check your input data, then please try again or contact Support.'
          })
          this.props.logoutRequest()
        }
        if (response.ok) {
          this.props.postCreditCardSignUpRequest({customerId, creditCardEmail, creditCardFirstName, creditCardLastName})

        }
      })
      .catch(error => {
          Notification.error({
            title: 'Error',
            placement: 'bottomEnd',
            description: 'Something did go wrong.'
          })
      })
    }
  }


  render() {
    return (
      <>
        <Helmet>
          <script type="text/javascript" src="https://static.wepay.com/min/js/risk.1.latest.js"></script>
        </Helmet>
        <Panel style={{ backgroundColor: '#ffffff', width: '650px' }}>
          <Form
            model={model}
            ref={(ref) => (this.form = ref)}
            checkTrigger="none"
            fluid
          >
                <strong>Credit Card info</strong>
                <Grid fluid style={{ marginTop: '15px' }}>
                  <Row>
                    <Col md={12}>
                      <TextField
                        name="creditCardFirstName"
                        label="Cardholder First Name"
                        type="text"
                        onChange={(creditCardFirstName, event) => this.handleChange({ creditCardFirstName, event })}
                      />
                      <TextField
                        name="creditCardLastName"
                        label="Cardholder Last Name"
                        type="text"
                        onChange={(creditCardLastName, event) => this.handleChange({ creditCardLastName, event })}
                      />
                      <Whisper
                        placement="top"
                        trigger="focus"
                        speaker={<Tooltip>An email will be send to this address to complete the sign up.</Tooltip>}
                      >
                        <TextField
                          name="creditCardEmail"
                          label="Cardholder Email"
                          type="email"
                          onChange={(creditCardEmail, event) => this.handleChange({ creditCardEmail, event })}
                        />
                      </Whisper>
                    </Col>
                    <Col md={12}>
                      <TextField
                        name="creditCardNumber"
                        label="Card Number"
                        type="text"
                        onChange={(creditCardNumber, event) => this.handleChange({ creditCardNumber, event })}
                      />
                      <Whisper
                        placement="top"
                        trigger="focus"
                        speaker={<Tooltip>Please use the format MM of your Creditcard.</Tooltip>}
                      >
                        <TextField
                          name="expirationMonth"
                          label="Expiration Month"
                          type="text"
                          placeholder="Input MM"
                          onChange={(expirationMonth, event) => this.handleChange({ expirationMonth, event })}
                        />
                      </Whisper>
                      <Whisper
                        placement="top"
                        trigger="focus"
                        speaker={<Tooltip>Please use the format YY of your Creditcard.</Tooltip>}
                      >
                        <TextField
                          name="expirationYear"
                          label="Expiration Year"
                          type="text"
                          placeholder="Input YY"
                          onChange={(expirationYear, event) => this.handleChange({ expirationYear, event })}
                        />
                      </Whisper>
                      <TextField
                        name="cvv"
                        label="CVV"
                        type="text"
                        onChange={(cvv, event) => this.handleChange({ cvv, event })}
                      />
                    </Col>
                  </Row>
                </Grid>

            <div style={{ marginTop: '50px', width: '610px', justifyContent: 'center', display: 'flex' }}>
              <ReCAPTCHA
                sitekey={recaptcha_key}
                onChange={(recaptchaResponse, event) => this.handleChange({ recaptchaResponse, event })}
              />
            </div>
            <div style={{ width: '610px', justifyContent: 'center', display: 'flex' }}>
              <Button
                appearance="primary"
                type="submit"
                style={{ marginTop: '15px', width: '310px' }}
                onClick={(e) => {
                  e.preventDefault()
                  this.handlePostCreditCardSignUp()
                }}
              >
                Submit
              </Button>
              
            </div>
            <div style={styles.slaText}>
                <div>
                  By proceeding this registration form you are following the onboarding process of GxP-Cloud.<br></br>
                  All Terms and Conditions and Privacy Policy apply when using any of the 
                  GxP-Cloud products.
                  <br></br>Need Help? <a href="https://gxp-cloud.com/onboarding-support/" target="_blank" rel="noopener noreferrer">Contact Support</a>
                </div>
                <br></br>
                <div>Already have an account? <a href="/login">Sign in now</a></div>
            </div>
          </Form>
        </Panel>

        <div style={styles.breadcrum}>
          Login / Terms and Conditions / Customer information / <strong>Payment information</strong> / Activation (Mail) / Finish
        </div>
      </>
    )
  }
}

//   https://github.com/facebook/flow/issues/7125
//   $FlowFixMe
export const CreditCardSignUpForm = connect(mapStateToProps, {
  logoutRequest
}
) (ToJs(CreditCardSignUpContainer))