import React from 'react'

import {
  ButtonToolbar,
  IconButton,
  Button,
  Icon,
  Tooltip,
  Whisper,
  Panel
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { SyncIndicator } from 'components/SyncIndicator'

import { IpsecForm } from './dialog/IpsecFormContainer'
import { IpsecTable } from './IpsecTableComponent'

type Props = {|
  +ipsecSites: ipsecSite[],
  +ipsecSitesMutated: boolean,
  +addIpsecSite: Function,
  +deleteIpsecSite: Function,
  +setIpsecSiteState: Function,
  +ive: Ive[],
  +iveId: string,
  +ives: ives,
  +setSubmitDialogOpenState: Function
|}
export const IpsecComponent = (props: Props) => {
  const {
    ive,
    iveId,
    ipsecSites,
    ipsecSitesMutated,
    setSubmitDialogOpenState
  } = props

  const handleSave = () => {
    const ipsecSiteSend = ipsecSites.ipsecSite
    setSubmitDialogOpenState({
      dialogId: 'ADD_IPSEC_SITES',
      payload: { ipsecSites: ipsecSiteSend },
      meta: { iveId }
    })
  }

  return (
    <div>
      <Panel>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SyncIndicator rulesMutated={ipsecSitesMutated} />
          {ipsecSitesMutated ? (
            <strong>
              Make sure to Publish your changes to make them final
            </strong>
          ) : (
            ''
          )}
          <ButtonToolbar>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Publish changes</Tooltip>}
            >
              <Button
                color="blue"
                appearance='ghost'
                type="publish"
                id="save-ipsec-sites"
                onClick={handleSave}
                disabled={!ipsecSitesMutated}
              >
                Publish
              </Button>
            </Whisper>
          </ButtonToolbar>
        </span>
      </Panel>
      <Panel bordered>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <IpsecForm
            ipsecSites={ipsecSites.ipsecSite}
            submit={props.addIpsecSite}
            dialogAction="Add"
            ive={ive}
          >
            <ButtonToolbar style={{ marginBottom: '15px' }}>
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>Add IPsec site to IVE</Tooltip>}
              >
                <IconButton
                  icon={<Icon icon="plus-square" />}
                  color="blue"
                  appearance='ghost'
                  type="add"
                  id="add-ipsec-sites"
                >
                  Add Rule
                </IconButton>
              </Whisper>
            </ButtonToolbar>
          </IpsecForm>
        </div>
        {ipsecSites.ipsecSite && ipsecSites.ipsecSite.length ? (
          <IpsecTable
            ipsecSitesMutated={ipsecSitesMutated}
            deleteIpsecSite={props.deleteIpsecSite}
            ive={ive}
            iveId={iveId}
            ipsecSites={ipsecSites.ipsecSite}
          />
        ) : (
          <div style={{ fontSize: '1.5em' }}>
            There are no IPsec Sites for this IVE yet.
          </div>
        )}
      </Panel>
    </div>
  )
}
