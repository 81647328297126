// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { VmReportsComponent } from './VmReportsComponent'
import {
  getVmRequest,
  getVmReportRequest,
  getVmReportsRequest
} from 'modules/operations/vms'

type ReduxState = {| +reports: Report[], +name: string, +timezone: string |}
type ReduxActions = {|
  +getVmRequest: Function,
  +getVmReportRequest: Function,
  +getVmReportsRequest: Function
|}
type Props = ReduxState &
  ReduxActions & {| +history: History, +match: Match, +staticContext: any |}

const mapStateToProps = (state) =>
  ({
    reports: state.getIn(['vms', 'reports']),
    name: state.getIn(['vms', 'vm', 'name']),
    timezone: state.getIn(['account', 'timezone'])
  }: ReduxState)

class VmReportsContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    const { vmId } = this.props
    this.props.getVmRequest({ vmId })
    this.props.getVmReportsRequest({ vmId })
  }

  render() {
    const { reports, timezone, name, getVmReportRequest } = this.props
    return [
      <VmReportsComponent
        key="vm-reports"
        reports={reports}
        timezone={timezone}
        name={name}
        getVmReportRequest={getVmReportRequest}
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const VmReports = connect(
  mapStateToProps,
  ({ getVmRequest, getVmReportRequest, getVmReportsRequest }: ReduxActions)
)(ToJs(VmReportsContainer))
