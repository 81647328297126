// @flow
import React, { Component } from 'react'
import moment from 'moment-timezone'

import { Panel, Col, Row, Grid } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { VmActions } from './VmActionsContainer'

type Props = {
  +iveId: string,
  +iveLocked: ?boolean,
  +timezone: ?string,
  +vm: Vm
}

export class VmCardComponent extends Component<Props> {
  render() {
    const { vm, iveId, iveLocked, timezone } = this.props
    return (
      <Panel
        style={{ marginTop: '10px', overflow: 'visible' }}
        header={
          <div
            style={{
              fontSize: '24px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span>
              <Grid fluid>
                <Row>
                  <Col>
                    <strong>{vm.name}</strong> <small style={{ fontSize: '14px' }}> ID: {vm.id} <i>@{vm.ipAddress}</i></small>
                  </Col>
                </Row>
                <Row style={{ fontSize: '16px' }}>
                  <Col md={35}>{vm.description}</Col>
                </Row>
              </Grid>
            </span>
            <span>
              <VmActions iveId={iveId} iveLocked={iveLocked} vm={vm} />
            </span>
          </div>
        }
      >
        <div style={{ paddingLeft: '32px' }}>
          <Grid fluid>
            <Row style={{ fontSize: '12px' }}>
              <Col md={4}>Operating System</Col>
              <Col md={4}>
                CPU 
              </Col>
              <Col md={4}>
                Memory 
              </Col>
              <Col md={4}>Storage</Col>
              <Col md={4}>Snapshot Created</Col>
              <Col md={4}>Snapshot Expires</Col>
            </Row>
            <Row style={{ fontSize: '14px', marginTop: '5px' }}>
              <Col md={4}>
                <strong>{vm.operatingSystem}</strong>
              </Col>
              <Col md={4}>
                <strong>{vm.hardware.cpuCores} Core(s)</strong>
              </Col>
              <Col md={4}>
                <strong>{vm.hardware.memory} GB</strong>
              </Col>
              <Col md={4}>
                <strong>
                  {' '}
                  {vm.hardware.disks.immutable.reduce(
                    (size, disk) => size + disk.size,
                    0
                  ) +
                    vm.hardware.disks.user.reduce(
                      (size, disk) => size + disk.size,
                      0
                    )}{' '}
                  GB (
                  {vm.hardware.disks.immutable.length +
                    vm.hardware.disks.user.length}{' '}
                  Disks)
                </strong>
              </Col>
              <Col md={4}>
                <strong>
                  {vm.snapshotCreatedDatetime
                    ? moment
                        .tz(vm.snapshotCreatedDatetime, timezone)
                        .format('DD MMM YYYY HH:mm:ss zz')
                    : 'No Snapshot present'}
                </strong>
              </Col>
              <Col md={4}>
                <strong>
                  {vm.snapshotExpiresDatetime
                    ? moment
                        .tz(vm.snapshotExpiresDatetime, timezone)
                        .format('DD MMM YYYY HH:mm:ss zz')
                    : 'No Snapshot present'}
                </strong>
              </Col>
            </Row>
          </Grid>
        </div>
      </Panel>
    )
  }
}
