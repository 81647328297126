import React, { useRef } from 'react';
import {
  Button,
  ControlLabel,
  Schema,
  Form,
  FormGroup,
  FormControl,
  Modal
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { StringType } = Schema.Types
const model = Schema.Model({
firstName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9]*$/, 'Please use only letters and numbers.')
    .maxLength(35, 'The maximum number of characters is 35'),
lastName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9]*$/, 'Please use only letters and numbers.')
    .maxLength(35, 'The maximum number of characters is 35'),
email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.')
})

function InputField(props) {
  const { name, label, type, accepter, data, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        type={type}
        data={data}
        {...rest}
      />
    </FormGroup>
  )
}

type Props = {|
    +email: string,
    +lastName: string,
    +firstName: string,
    +groupMembers: Array,
    +redirectUrl: string,
    +handleChange: Function,
    +toggleOpenState: Function,
    +setSubmitDialogOpenState: Function,
    +isOpen: boolean
  |}

export const UserInviteComponent = (props: Props) => {
    var inviteUserForm = useRef(null)

    // Check for existing mailaddresses. 
    // checkEmailExists return array of falses and trues, emailExists looks for trues
    const checkEmailExists = props.groupMembers.map(e => e.username.includes(props.email));
    const emailExists = checkEmailExists.includes(true);

    return (
        <div style={{ display: 'inline' }}>
          {React.cloneElement(props.children, {
            onClick: props.toggleOpenState
          })}
          <Modal backdrop="static" show={props.isOpen} size="sm">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '25px' }}>
                Invite User to GxP-Cloud Console
              </Modal.Title>
            </Modal.Header>
            <Form
              model={model}
              ref={(ref) => (inviteUserForm = ref)}
              fluid
              checkTrigger="none"
            >
                <InputField
                    autoFocus
                    id="email"
                    name="email"
                    style={{ width: 'inherit' }}
                    label="User Email"
                    onChange={(email, event) =>
                        props.handleChange({ email, event })
                      }
                    value={props.networkName}
                />
                <InputField
                    id="firstName"
                    name="firstName"
                    style={{ width: 'inherit' }}
                    label="First Name"
                    onChange={(firstName, event) =>
                      props.handleChange({ firstName, event })
                    }
                  />
                <InputField
                    id="lastName"
                    name="lastName"
                    style={{ width: 'inherit' }}
                    label="Last Name"
                    onChange={(lastName, event) =>
                      props.handleChange({ lastName, event })
                    }
                  />                 
            <Modal.Footer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '25px',
                  marginBottom: '15px'
                }}
              >
                <div>
                  <Button onClick={props.toggleOpenState} color="red">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="blue"
                    id="submit"
                    disabled={emailExists}
                    onClick={(e) => {
                      if (inviteUserForm.check()) {
                        e.preventDefault()
                        props.setSubmitDialogOpenState({
                          dialogId: 'INVITE_USER',
                          payload: {
                            email: props.email,
                            firstName: props.firstName,
                            lastName: props.lastName,
                            redirectUrl: props.redirectUrl
                          }
                        })
                      }
                    }}
                  >
                    Invite User
                  </Button>
                </div>
                {emailExists ? (
                <span style={{ margingRight: '100px', color: '#ff0000' }}>
                  Please dont use duplicate Emails.
                </span>
              ) : (
                <div></div>
              )}
              </div>
            </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}