// @flow

import React, { useRef } from 'react'

import {
  Button,
  Panel,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema,
  Whisper,
  Tooltip
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
  +textRef: Object,
  +iveId: string,
  +username: string,
  +password: string,
  +repeatedPassword: string,
  +handleChange: Function,
  +onKeyDown: Function,
  +capsLockWarning: Boolean,
  +setSubmitDialogOpenState: Function
|}

const { StringType } = Schema.Types
const model = Schema.Model({
  username: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_.-]*$/, 'Please use only letters, numbers and _ - .'),
  password: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_@.!#%&]*$/, 'Please use only letters, numbers and _@.!#%&')
    .rangeLength(
      8,
      30,
      'The number of characters can only be between 8 and 30'
    ),
  repeatedPassword: StringType()
    .isRequired('This field is required.')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
})

function TextField(props) {
  const { errorMessage, name, label, type, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl name={name} accepter={accepter} type={type} {...rest} />
    </FormGroup>
  )
}

export const SslVpnFormComponent = (props: Props) => {
  const {
    iveId,
    username,
    password,
    handleChange,
    onKeyDown,
    capsLockWarning,
    setSubmitDialogOpenState
  } = props
  var SslVpnForm = useRef(null)

  return (
    <Panel header={<h5>Create SSL-VPN account</h5>}>
      <Form
        model={model}
        style={{ backgroundColor: '#ffffff', marginBottom: '5px' }}
        autoComplete="off"
        ref={(ref) => (SslVpnForm = ref)}
        checkTrigger="none"
      >
        <TextField
          autoFocus
          name="username"
          label="Username"
          type="name"
          onChange={(username, event) => handleChange({ username, event })}
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          onKeyDown={onKeyDown}
          onChange={(password, event) => handleChange({ password, event })}
          style={{ marginTop: '-10px' }}
        />
        <TextField
          name="repeatedPassword"
          label="Repeat Password"
          type="password"
          onKeyDown={onKeyDown}
          onChange={(repeatedPassword, event) =>
            handleChange({ repeatedPassword, event })
          }
          style={{ marginTop: '-10px' }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
            marginRight: '-8px',
            marginBottom: '-16px'
          }}
        >
           {capsLockWarning ? (
              <span style={{ margingRight: '100px', color: '#ff0000' }}>
                Caps Lock is On!
              </span>
            ) : (
              <div></div>
            )}
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Add SSL-VPN account to IVE</Tooltip>}
            >
              <Button
                appearance="ghost"
                color='blue'
                label="Submit"
                type="submit"
                id="vm-deploy"
                onClick={(e) => {
                  if (SslVpnForm.check()) {
                    e.preventDefault()
                    setSubmitDialogOpenState({
                      dialogId: 'ADD_SSL_VPN_ACCOUNT',
                      payload: { username, password },
                      meta: { iveId }
                    })
                  }
                }}
              >
                Submit
              </Button>
            </Whisper>
        </div>
      </Form>
    </Panel>
  )
}
