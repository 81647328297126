import React from 'react'
import { Icon } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const style = {
  paper: {
    background: '#ffffff',
    borderStyle: 'solid',
    borderColor: '#ebebeb',
    padding: '16px',
    borderRadius: '24px',
    fontSize: '48px',
    position: 'absolute',
    top: 'calc(50vh - 16px)',
    left: 'calc(50vw - 20px)',
    zIndex: 1500
  }
}

export const LoadingComponent = () => (
  <div style={{ ...style.paper }}>
    <Icon style={{ color: '#2589f5' }} icon="spinner" size="4x" pulse /> Loading{' '}
  </div>
)
