// @flow

import React from 'react'
import { Row, Col, Grid } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { SortableElement } from 'react-sortable-hoc'

import { DragHandle } from 'components/DragHandle'
import { OkIcon, NotOkIcon } from 'components/Icons'
import { DeleteRuleButtonColumn } from 'components/DeleteRuleButtonColumn'

type Props = {
  applications: Array<IdentityObject>,
  deleteFirewallRule: Function,
  immutable: boolean,
  userRuleIndex: number,
  isRuleDialogOpen: boolean,
  iveId: string,
  rule: FirewallRule,
  rules: FirewallRules,
  toggleEditRuleDialog: Function,
  vms: Array<IdentityObject>,
  vnicGroups: Array<IdentityObject>
}

export const FirewallRuleRowComponent = (props: Props) => {
  const { rule, userRuleIndex, immutable } = props
  const deleteRule = () => props.deleteFirewallRule(userRuleIndex)
  const {
    applications,
    destinationVnicGroups,
    destinationVms,
    destinations,
    sourceVnicGroups,
    sourceVms,
    sources
  } = rule

  const isSourceAny =
    !sourceVnicGroups.length && !sourceVms.length && !sources.length
  const isDestinationAny =
    !destinationVnicGroups.length &&
    !destinationVms.length &&
    !destinations.length

  return (
    <Grid fluid>
      <Row style={{ marginTop: '8px' }}>
        <Col md={2}>{immutable ? '' : <DragHandle />}</Col>
        <Col md={2}>{rule.enabled ? <OkIcon /> : <NotOkIcon />}</Col>
        <Col md={4}>
          <strong style={{ overflowWrap: 'anywhere' }}>{rule.name}</strong>
        </Col>
        <Col md={5}>
          {isSourceAny ? (
            <strong>
              <div>Any</div>
            </strong>
          ) : (
            <strong>
              <div>
                {sourceVnicGroups &&
                  sourceVnicGroups.map((sourceVnicGroup) => (
                    <div key={sourceVnicGroup.id}>{sourceVnicGroup.name}</div>
                  ))}
                {sourceVms &&
                  sourceVms.map((sourceVm) => (
                    <div style={{ overflowWrap: 'anywhere' }} key={sourceVm.id}>
                      {sourceVm.name}
                    </div>
                  ))}
                {sources &&
                  sources.map((source) => <div key={source}>{source}</div>)}
              </div>
            </strong>
          )}
        </Col>
        <Col md={5}>
          {isDestinationAny ? (
            <strong>
              <div>Any</div>
            </strong>
          ) : (
            <strong>
              <div>
                {destinationVnicGroups &&
                  destinationVnicGroups.map((destinationVnicGroup) => (
                    <div key={destinationVnicGroup.id}>
                      {destinationVnicGroup.name}
                    </div>
                  ))}
                {destinationVms &&
                  destinationVms.map((destinationVm) => (
                    <div
                      style={{ overflowWrap: 'anywhere' }}
                      key={destinationVm.id}
                    >
                      {destinationVm.name}
                    </div>
                  ))}
                {destinations &&
                  destinations.map((destination) => (
                    <div key={destination}>{destination}</div>
                  ))}
              </div>
            </strong>
          )}
        </Col>
        <Col md={3}>
          <div>
            {applications.length ? (
              applications.map((application) => (
                <strong key={application.id}>
                  <div>{application.name}</div>
                </strong>
              ))
            ) : (
              <strong>Any</strong>
            )}
          </div>
        </Col>
        <Col md={2}>{rule.matchTranslated ? <OkIcon /> : <NotOkIcon />}</Col>
        {immutable ? (
          <Col md={1} />
        ) : (
          <DeleteRuleButtonColumn
            id={`delete-fw-rule-${rule.id}`}
            deleteRule={deleteRule}
          />
        )}
      </Row>
    </Grid>
  )
}

export const FirewallRuleRow = SortableElement(FirewallRuleRowComponent)
