// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { VmActionsComponent } from './VmActionsComponent';
import { setSubmitDialogOpenState } from 'modules/components/submitDialog';
import { setModalOpenState } from 'modules/components/gxpModal';

import { getVmTicketRequest } from 'modules/operations/vms';

type Props = {|
  +iveId: string,
  +vm: Vm,
  +iveLocked: boolean,
  +setSubmitDialogOpenState: Function,
  +setModalOpenState: Function,
  +getVmTicketRequest: Function
|}

type DialogSettings = {
  dialogId: string,
  meta: {
    vmId: string,
    name: string
  }
}

class VmActionsContainer extends Component<Props> {
  props: Props

  handleOpenSubmitDialog = (settings: DialogSettings) => {
    this.props.setSubmitDialogOpenState(settings)
  }

  handleGetUrl = () => {
    const vmId = this.props.vm.id
    this.props.getVmTicketRequest({ vmId })
  }


  render() {
    const { iveId, vm, iveLocked, setModalOpenState } = this.props

    return (
      <VmActionsComponent
        iveId={iveId}
        vm={vm}
        iveLocked={iveLocked}
        handleOpenSubmitDialog={this.handleOpenSubmitDialog}
        setModalOpenState={setModalOpenState}
        handleGetUrl={this.handleGetUrl}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const VmActions = connect(() => ({}), {
  setSubmitDialogOpenState,
  setModalOpenState,
  getVmTicketRequest
})(VmActionsContainer)
