// @flow

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { all } from 'redux-saga/effects'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { applyMiddleware, createStore } from 'redux'
import { autoRehydrate, persistStore } from 'redux-persist-immutable'
import { combineReducers } from 'redux-immutable'
import createSagaMiddleware from 'redux-saga'
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router/immutable'

import { apiMiddleware, errorMiddleware } from './middleware'

import moducks from './moducks-bootstrap'
import * as sagas from './modules/sagas'
import * as reducers from './modules/reducers'

export function configureStore(history: Object): Promise<Store> {
  return new Promise((resolve: Function, reject: Function) => {
    try {
      const sagaMiddleware = createSagaMiddleware()

      const middleware = [
        apiMiddleware,
        routerMiddleware(history),
        sagaMiddleware,
        errorMiddleware
      ]

      const enhancer = composeWithDevTools(
        autoRehydrate(),
        applyMiddleware(...middleware)
      )

      const rootReducer = combineReducers({
        router: connectRouter(history),
        ...reducers
      })

      const store = createStore(rootReducer, enhancer)
      // Persist store to the local storage
      persistStore(store, { whitelist: ['auth'] }, () =>
        resolve({
          ...store,
          runSaga: () => {
            const gen = function* () {
              yield all(moducks.util.flattenSagas(sagas))
            }
            // no clue
            // $FlowFixMe
            sagaMiddleware.run(gen)
          }
        })
      )
    } catch (e) {
      reject(e)
    }
  })
}
