import React from 'react'

import { Panel, Row, Col, Divider, Progress } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
    +datastore: Datastore,
    +index: Number
  |}

  const { Line } = Progress
  
  const DatastorePanel = (props: Props) => {
    const { datastore, index } = props
    const capacityGb = Number(datastore.capacity / 1024 / 1024 / 1024)
    const freeGb = Number((datastore.freeSpace / 1024 / 1024 / 1024).toFixed(2))
  
    return (
        <Panel 
            bordered
            header={`Datastore -${index + 1}`}
            key={`host-${index}`}
        >
            <div style={{ width: '100%', marginBottom: '20px'}}>{datastore.name}</div> 
            <Divider/>
            <Row style={{ alignItems: 'center' }}>
                <Col md={18}>
                <strong>Datastore Capacity</strong>
                </Col>
                <Col md={6}>
                <strong>{capacityGb} GB</strong>
                </Col>
            </Row>
            <Row>
                <Col md={18}>
                <strong>Datastore Free</strong>
                </Col>
                <Col md={6}>
                <strong>{freeGb} GB</strong>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <Line percent={Number(((capacityGb - freeGb) / capacityGb * 100).toFixed(2))} strokeColor="#FF8A00" />
                </Col>
            </Row>

        </Panel>
    )
    
}

export { DatastorePanel }