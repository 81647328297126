// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  errors: {}
})

const moduleName = 'createNetworkForm'

export const {
  createNetworkForm,
  sagas,
  setNetworkFormErrorState,
  clearNetworkFormErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_NETWORK_FORM_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_NETWORK_FORM_ERROR_STATE: () => defaultState
  },
  defaultState
)