import React, { Component } from 'react'

import {
    Button,
    Panel,
    Notification,
    Checkbox
  } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { Link } from 'react-router-dom'

import { postActivateCustomerRequest } from 'modules/operations/account'
import { logoutRequest } from 'modules/operations/auth'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'
import logo from '../../../img/Logo_GxP-Cloud_Cloud-Management-Console-A.svg'
import { TermsAndConditions } from 'routes/help/TermsAndConditions'

const styles = {
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    flexDirection: 'column'
  },
  logoContainer: {
    marginBottom: '25px'
  },
  breadcrum: {
    marginTop: '10px',
    color: '#000000',
  }
}

type ReduxActions = {|
  +logoutRequest: Function,
  +postActivateCustomerRequest: Function
|}
type Props = ReduxActions
type State = {|
  +customeridb64: string
|}

  
class ActivateCustomerContainer extends Component<Props, State> {
    props: Props
    state: State

    state = { customerAgree: false, bottom: false }
  
    constructor(props) {
      super()
    }

    componentDidMount() {
        if (document.documentElement) {
          document.documentElement.style.overflowY = 'auto'
        }
        if (document.body) {
          document.body.style.backgroundSize = 'cover'
        }
        const { customeridb64 } = this.props.match.params
        this.setState({ ...this.state, customeridb64: customeridb64})
        this.props.logoutRequest()
    }

    handleActivate = () => {
            const { customeridb64 } = this.state
  
            customeridb64 ? (this.props.postActivateCustomerRequest({ customeridb64 })) 
            : (Notification.info({
                title: 'Info',
                placement: 'bottomEnd',
                description: 'Please check your activation Link' 
              })
            )
      }
    
    toggleCustomerAgree = () => {
      this.setState({ customerAgree: !this.state.customerAgree })
    }

    handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= (1.1 * e.target.clientHeight);
      this.setState({ bottom: bottom })
    };


    render() {

        return (
            <div style={styles.loginContainer}>
                <img
                style={styles.logoContainer}
                src={logo}
                alt="GxP-Cloud"
                width="300px"
                />
  

                <Panel style={{ backgroundColor: '#ffffff', width: '35vw' }} header={<h5>Activate Customer Terms and Conditions</h5>}>
                  
                  <Panel 
                    bordered
                    style={{ height: '60vh', overflow: 'auto' }}
                    onScroll={this.handleScroll}
                >
                        <TermsAndConditions/>
                  </Panel>


                  <div style={{ width: '33vw', display: 'flex', justifyContent: 'space-between' }}>
                    <Link to="/login">
                      <Button 
                        appearance="link"
                        color='red'
                      >
                        Decline
                      </Button>
                    </Link>
                    <Checkbox
                      checked={this.state.customerAgree}
                      onChange={this.toggleCustomerAgree}
                    >
                      I Agree
                    </Checkbox>
                  </div>
                    <div>
                      
                      <strong style={{ display: 'flex', justifyContent: 'center' }}>
                        Scroll down and Agree to activate
                      </strong>
                        
                        <Button
                            type="submit"
                            appearance='primary'
                            style={{ marginTop: '15px', width: '33vw' }}
                            onClick={() => {
                              this.handleActivate()
                            }}
                            disabled={!this.state.customerAgree || !this.state.bottom}
                        >
                            Activate
                        </Button>
                    </div>
                </Panel>

                <div style={styles.breadcrum}>
                  Login / Terms and Conditions / Customer information / Payment information / <strong>Activation (Mail)</strong> / Finish
                </div>

            </div>
        )}

}

export const ActivateCustomer = connect(
    null,
    ({
        postActivateCustomerRequest,
        logoutRequest
    }: ReduxActions)
)(ToJs(ActivateCustomerContainer))