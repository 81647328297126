// @flow
import hash from 'object-hash'
import { DUPLICATE_RULE_ERROR, DUPLICATE_NAME_ERROR } from 'utils/errorConsts'

const checkRuleDuplicateName = (rule: FirewallRule, rules: FirewallRule[]) =>
  rules.some((r) => r.name === rule.name)

const checkRuleDuplicateValues = (
  rule: FirewallRule,
  rules: FirewallRule[]
) => {
  const excludedFields = [
    'name',
    'id',
    'createdDatetime',
    'updatedDatetime',
    'ruleTag',
    'immutable'
  ]
  const ruleCopy = JSON.parse(JSON.stringify(rule))
  excludedFields.forEach((field) => delete ruleCopy[field])
  const ruleHash = hash(ruleCopy)
  const rulesCopy = JSON.parse(JSON.stringify(rules))
  const rulesHash = rulesCopy.map((rule) => {
    excludedFields.forEach((field) => delete rule[field])
    return hash(rule)
  })
  return rulesHash.some((r) => r === ruleHash)
}

export const validate = (values: Object) => {
  const { rule, rules, dialogAction } = values
  let nameError = {}
  let generalError = {}
  if (dialogAction === 'Add') {
    if (!nameError.name && checkRuleDuplicateName(rule, rules)) {
      nameError = { name: DUPLICATE_NAME_ERROR }
    }
    if (checkRuleDuplicateValues(rule, rules)) {
      generalError = { general: DUPLICATE_RULE_ERROR }
    }
  }
  return {
    ...nameError,
    ...generalError
  }
}
