// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SslVpnComponent } from './SslVpnComponent'
import { ToJs } from 'utils/ToJs'
import {
  getSslVpnAccountsRequest,
  getIveRequest
} from 'modules/operations/ives'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'

type ReduxState = {| +accounts: SslVpnAccount[], +ive: Ive |}
type ReduxActions = {|
  +getIveRequest: Function,
  +getSslVpnAccountsRequest: Function,
  +setSubmitDialogOpenState: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match, +history: History |}

const mapStateToProps = (state) =>
  ({
    accounts: state.getIn(['ives', 'sslVpnAccounts']),
    ive: state.getIn(['ives', 'ive'])
  }: ReduxState)

class SslVpnContainer extends Component<Props> {
  props: Props

  componentDidMount() {
    const { iveId } = this.props
    this.props.getIveRequest({ iveId })
    this.props.getSslVpnAccountsRequest({ iveId })
  }

  render() {
    const { accounts, history, ive, setSubmitDialogOpenState } = this.props
    return [
      <SslVpnComponent
        accounts={accounts}
        history={history}
        ive={ive}
        key={`ssl-vpn`}
        setSubmitDialogOpenState={setSubmitDialogOpenState}
      />
    ]
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const SslVpn = connect(
  mapStateToProps,
  ({
    getIveRequest,
    getSslVpnAccountsRequest,
    setSubmitDialogOpenState
  }: ReduxActions)
)(ToJs(SslVpnContainer))
