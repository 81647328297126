import React from 'react'

import { Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

export const TermsAndConditions = () => (
  <Panel>
    <div style={{ direction: 'ltr' }}>
      {/* <h5 style={{ color: '#FF8A00' }}>Terms and Conditions</h5> */}
        {/* <br></br> */}
        <strong>Cloud Management Console Service Customer Agreement</strong><br></br>
        <br></br>
        <strong>Last Updated: 26 October 2022</strong><br></br>
        <br></br>
        This Cloud Management Console (hereafter also referred to as “CMC”) Customer Agreement (hereafter referred to as “Agreement”) contains the terms and conditions that govern your access to and use of the Services provided by GxP Cloud with the Cloud Management Console. <br></br>
        <br></br>
        These terms and conditions serve as an agreement between GxP Cloud, registered at the Dutch Chamber of Commerce under number 54677637, with it’s registered office and principal place of business in (5251 CG) Vlijmen at the Wolput 36, represented by it’s CEO Karel Bastiaanssen (hereinafter referred to as “GxP Cloud”) and you and the entity you represent (hereinafter referred to as “Customer”).<br></br>
        <br></br>
        This agreement takes effect when Customer clicks the “I accept” button or checkbox presented with this Agreement. By accepting this Agreement Customer declares they are not a minor and have the legal authority to bind the company they represent into a contract. <br></br>
        <br></br>
        From the effective date as stated in the Agreement, GxP Cloud will commence provision of the non-exclusive, non-transferable right to access and use the Cloud Management Console and the Service provided with it.<br></br>
        <br></br>
        This Agreement is the entire Agreement between GxP Cloud and Customer regarding the subject matter of this Agreement. This Agreement supersedes all prior or contemporaneous representations, understandings, agreements, or communications between GxP Cloud and Customer, whether written or verbal, regarding the subject matter of this Agreement.<br></br>
        <br></br>
        <br></br>
        <strong>1.	Use of the Services</strong><br></br><br></br>
        1.1.	Customer may access and use the Cloud Management Console and it’s services in accordance with this Agreement. Customer will comply with the terms of this Agreement and all laws, rules and regulations applicable to the use thereof.<br></br>
        1.2.	To access the Cloud Management Console, customer must create an account associated with a valid email address and a valid form of payment. Unless explicitly permitted by the Service Terms, customer will only create one account per email address.<br></br>
        <br></br>
        <strong>2.	Provision of the Services</strong><br></br><br></br>
        2.1.	From the effective date as stated in this Agreement, GxP Cloud will commence provision of the Cloud Management Console and it’s services which includes a non-exclusive, non-transferable right to access and use the Cloud Management Console for the term of the Agreement and under the conditions as set forth in the Agreement, including the right to sublicense solely to the extent this right to sublicense has been explicitly agreed otherwise in writing between parties.<br></br>
        2.2.	Customer and GxP Cloud agree that this Agreement does not include provision of support other than directly related to the functionality of the Cloud Management Console and it’s services. Additional support can be provided when agreed between GxP Cloud and Customer in the form of a Service Level Agreement or a written and signed quotation.<br></br>
        2.3.	GxP Cloud’s activities in regards to this Agreement do not include the direct processing of any Personal Data other than the Personal Data required to provide the resources and services (such as contact and billing information). As is described in our Privacy Policy. It can however not be ruled out that GxP Cloud, during the fulfilment of it’s contractual obligations under this Agreement or under further contracts entered between the parties in connection, gain access to or obtain additional personal data within the contractual relationship of the parties, dependent on the service requests from Customer. Section 15 provides for this.<br></br>
        <br></br>
        <strong>3.	Changes</strong><br></br><br></br>
        3.1.	GxP Cloud may change or discontinue any of the services provided by the Cloud Management Console from time to time. GxP Cloud will provide Customer with at least 6 months prior notice if GxP Cloud discontinues material functionality, except that this notice will not be required if the 6 month notice period (a) would pose a security or intellectual property issue to GxP Cloud or the Cloud Management Console, (b) is economically or technically burdensome, or (c) would cause GxP Cloud to violate legal requirements.<br></br>
        3.2.	GxP Cloud may modify this Agreement (including any policies) at any time by posting a revised version in the Cloud Management Console or by otherwise notifying Customer in accordance with section 14.6; provided, however, that GxP Cloud will provide at least 90 days advance for adverse changes to the Agreement.<br></br>
        <br></br>
        Subject to the 90 days advance notice requirement, the modified Agreement will become effective upon posting or, if Customer is informed by email, as stated in the email message.<br></br>
        <br></br>
        By continuing to use Cloud Management Console after the effective date of any modifications to this Agreement, Customer Agrees to be bound by the modified terms. <br></br>
        <br></br>
        It is the responsibility of the Customer to check the GxP Cloud Website  and Cloud Management Console regularly for modifications to this Agreement. The last modified date will be at the top of the document.<br></br>
        <br></br>
        <strong>4.	Security and Data Protection</strong><br></br><br></br>
        4.1.	Without limiting section 11, section 15 or Customers obligations under section 5.4, GxP Cloud will implement reasonable and appropriate measures designed to help secure Customer’s Data against unlawful loss, access or disclosure. These measures are limited to the resources and services provided in by the Cloud Management Console and will be appropriate to comply with the requirements of the GDPR, in particular measures ensuring confidentiality, integrity, availability and resilience of the systems and services used for Data Processing.<br></br>
        4.2.	Customer is entitled to upload and download its own data on the Customer content created with the Cloud Management Console. Customer is aware that the location of data storage is in the country and location of the choice made within the Cloud Management Console. Customer is responsible for ensuring that it is allowed to undertake cross-border data-transfer. <br></br>
        4.3.	GxP Cloud does not control or have any insight into the data traffic from and/or to the Customer content created by the Cloud Management Console. GxP Cloud does not give any warranties with regard to content of data, including but not limited to reliability and completeness. Customer understands that the technical processing and transmission of customer’s data is fundamentally necessary in order to provide the services. Customer expressly consents to GxP Cloud’s processing and storage of customer’s electronic communications and/or process data, and customer acknowledges and understands that this will involve transmission over the internet, and over various networks.<br></br>
        <br></br>
        <strong>5.	Customer Responsibility</strong><br></br><br></br>
        5.1.	Except to the extent cause by a breach of this Agreement by GxP Cloud, (a) Customer is responsible for all activities that occur under your account, regardless of whether the activities are authorized by Customer, it’s employees or a third party (including Customer contractors, agents or end-users), and (b) GxP Cloud and it’s affiliates are not responsible for unauthorized access to Customer’s account.<br></br>
        5.2.	Customer has the obligation to ensure that it’s content and use thereof (by both itself and it’s end users) will not violate the terms of this Agreement or any applicable law.<br></br>
        5.3.	Customer has the obligation to only use Cloud Management Console within the boundaries of the terms of this Agreement or any applicable law.<br></br>
        5.4.	Customer is responsible for properly configuring and using the Cloud Management Console and otherwise taking appropriate action to secure, protect and back-up Customer’s accounts and content in a manner that will provide appropriate security and protection, which might include the use of encryption to protect Customer’s content from unauthorized access and loss.<br></br>
        5.5.	Cloud Management Console credentials are for Customer’s internal use and Customer will not sell, transfer or sublicense these to any other entity or person.<br></br>
        5.6.	Customer is responsible for end users use of Customer’s content and the Cloud Management Console. Customer will ensure that all end users comply with Customer’s obligations under this Agreement. If Customer becomes aware of any violation of it’s obligations under this Agreement caused by an end user, customer will immediately suspend access to it’s content and the Cloud Management Console. GxP Cloud does not provide any support or services to end users unless there is a separate agreement with Customer.<br></br>
        <br></br>
        <strong>6.	Fees and Payment</strong><br></br><br></br>
        6.1.	GxP Cloud calculates and bill fees and charges monthly. GxP Cloud preserves the right to bill more frequently for fees accrued if there is suspicion of fraudulent behavior or risk of non-payment.<br></br>

        Customer will pay the applicable fees and charges for use of the Cloud Management Console and it’s services as described in the Cloud Management Console by using the payment methods provided. All amount payable under this Agreement will be paid without any set-off, deduction or suspension.<br></br>
        6.2.	Fees and charges for any new service or feature of a service will be effective when GxP Cloud posts updated fees and charges in the Cloud Management Console, unless stated otherwise in a notice.<br></br>
        6.3.	GxP Cloud  is authorized to adjust the applicable fee(s) yearly within reasonable limits. If such adjustment concerns an increase that is higher than the index figure for CAO wages (per hour including special remunerations) category private businesses, as published by the Central Bureau for Statistics, GxP Cloud will notify the increase to customer at least 90 days prior to fee increase in accordance with section 13.2. With respect to the component third party licenses fees as part of the fee, GxP Cloud is authorized to adjust the applicable fee(s) based on the current price charged to GxP Cloud for these licenses at any given moment.<br></br>
        6.4.	In case of late payment, Customer shall be in default. In the event of a default Customer, the Customer shall pay to GxP Cloud the amount due accumulated with interest of at least one 1.5% percent per month, as well as additional debt collection costs, and GxP Cloud (in addition to its other rights to which it is entitled under the agreement) will be authorized to suspend the services or part thereof after not less than 10 days prior notification by GxP Cloud, all this without prejudice to the other rights GxP Cloud may have.<br></br>
        <br></br>
        <strong>7.	Temporary Suspension</strong><br></br><br></br>
        7.1.	GxP Cloud may suspend Customer or it’s end user’s right to access the Cloud Management Console or any service provided by the Cloud Management Console immediately upon notice to Customer if GxP Cloud determines: <br></br>

        (a) Customer or it’s end user’s use of the Cloud Management Console and it’s services (i) poses a security risk to the services or any third party, (ii) could adversely impact GxP Cloud’s systems, Cloud Management Console, it’s services or any other GxP Cloud customer, (iii) could subject GxP Cloud, our affiliates or any third party to liability, or (iv) could be fraudulent;<br></br>

        (b) Customer or it’s end user is in breach of this Agreement; or<br></br>

        (c) Customer is in breach of the payment obligations under section 6;<br></br>

        (d) Customer has ceased to operate in the ordinary course, made an assignment for the benefit of creditors or similar disposition of Customer assets, or becomes the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.<br></br>
        7.2.	If GxP Cloud suspends the rights to access or use any portion of the Cloud Management Console or it’s services Customer remains responsible for all fees and charges that are incurred during the period of suspension.<br></br>
        <br></br>
        <strong>8.	Term; Termination</strong><br></br><br></br>
        8.1.	The term of this Agreement will commence on the Effective Date and will remain in effect until terminated as described under this section. By notice of termination of this Agreement by either party to the other must include a Termination Date that complies with the notice periods described below.<br></br>
        8.2.	Termination: <br></br>
        (a) Customer may terminate this Agreement for any reason by providing GxP Cloud a 30 day notice;<br></br>

        (b) Either party may terminate this Agreement for cause if the other party is in material breach of this Agreement and the material breach remains uncured for a period of 30 days from receipt of notice by the other party; or<br></br>

        (c) GxP Cloud may also terminate this Agreement immediately upon notice to Customer (a) for cause if GxP Cloud has the right to suspend under section 7, (b) if GxP Cloud’s relationship with a third-party partner who provides software or other technology required to provide the Cloud Management Console and it’s services expires, terminates or requires GxP Cloud to change the way we provide the software or other technology as part of the Cloud Management Console. <br></br>
        <br></br>
        8.3.	Effect of termination:<br></br>
        (a) Upon the termination date all Customer’s rights under this Agreement immediately terminate;<br></br>

        (b) Customer’s right to access or use the services immediately ceases, and GxP Cloud shall have no obligation to maintain any data;<br></br>

        (c) Customer is responsible for retrieving it’s content before the termination date;<br></br>

        (d) Customer remains responsible for all fees and charges that have incurred through the termination date; and<br></br>

        (e) Sections (5.1, 6, 8.3, 9, 10, 11, 12, 14) will continue to apply in accordance with their terms.<br></br>
        <br></br>
        <strong>9.	Intellectual Property Rights</strong><br></br><br></br>
        9.1.	All rights of intellectual property of Customer to data and information of Customer are and will remain the property of Customer. GxP Cloud and its licensors are the exclusive owner(s) of all rights and title of all segments of Cloud Management Console, including the intellectual property rights and the functionality thereof. Customer shall acquire no rights whatsoever other than explicitly described in these terms and/or the Agreement.<br></br>
        9.2.	GxP Cloud holds Customer indemnified against any claims based on the substantiated allegation of a third party that the services infringe intellectual property rights valid in the US, UK, Netherlands and other member states of the European Union. In such case, Customer shall inform GxP Cloud thereof immediately in writing, in detail, and further offer its cooperation to GxP Cloud. In the case of aforementioned claims by third parties GxP Cloud may at its discretion replace or amend the services or any part thereof, or dissolve the agreement in part or in whole, in which latter case GxP Cloud will return the relevant fee(s) paid by customer for services not yet provided to Customer, on a pro rated basis. GxP Cloud shall have no obligation to indemnify and no liability if the alleged infringement is based on a modification of the services by anyone other than GxP Cloud, Customer's use of the services in a manner contrary to the instructions given to Customer by GxP Cloud, or Customer's use of the services after notice of the alleged or actual infringement from GxP Cloud or any appropriate authority. <br></br>
        <br></br>
        <strong>10.	Limitation of Liability</strong><br></br><br></br>
        10.1.	Except for breaches of the Agreement due to intent or gross negligence of GxP Cloud, GxP Cloud will not be liable for any damage that Customer suffers by any acting and/or refraining from acting of GxP Cloud, except for any direct damage on account of an attributable shortcoming of GxP Cloud.<br></br>
        10.2.	Direct damage will be understood to mean damage, except indirect damage such as (amongst others) consequential damage, lost profit, missed savings, damage through business stagnation and damage on account of corrupted or lost data resulting from negligence, errors or other activities on the part of Customer or its agents. In any event, GxP Cloud’s aggregate liability for any reason and upon any cause of action or claim, including, without limitation, GxP Cloud’s obligation to indemnify and hold harmless under the agreement, shall be limited to the amount equal to the related fees, exclusive VAT, paid to GxP Cloud by Customer hereunder in the 6 months period prior to the relevant claim. If and insofar any damage is caused because GxP Cloud is culpably in default on account of intent or gross negligence of the management of GxP Cloud in its responsibilities, GxP Cloud will be maximally liable for an amount of EUR 500.000,- per event, at which a series of connected events will apply as one event. The limitations set above apply to all causes of action or claims in the aggregate, including, without limitation, breach of contract, breach of warranty, indemnity, negligence, strict liability, misrepresentations, and other torts.<br></br>
        10.3.	A right of Customer to compensation will only arise if Customer has informed GxP Cloud accordingly in writing within 30 days or immediately after customer could discover in reason the arising thereof.<br></br>
        10.4.	Notwithstanding the above provisions of this clause, GxP Cloud is solely liable for damages covered by the GxP Cloud insurance policy, which GxP Cloud will be made available for information purposed to Customer on first written request.<br></br>
        10.5.	GxP Cloud will not be liable for entire or partial non-observance of any obligations or partial non-observance of and/or on behalf of GxP Cloud on account of the agreement, if the non-observance concerned or other delay or failure to perform hereunder is due to: Customer’s delay in supplying or in failing to supply approvals, information, documentation, goods or services reasonably requested by or necessary for GxP Cloud to execute the agreement, or causes beyond the control of GxP Cloud, including, but not limited to, acts of God, acts of any government or political division of government, fires, floods, epidemics, quarantine restrictions, strikes, under which any non-attributable shortcoming of supplier(s) of GxP Cloud are included.<br></br>
        <br></br>
        <strong>11.	Disclaimer</strong><br></br><br></br>
        11.1.	All Services shall be considered to be accepted unconditionally at effective date. During the term of the Agreement, GxP Cloud will make commercially reasonable efforts to provide the Services. However, and except as explicitly otherwise set forth in the Agreement, without prejudice to actual availability, Customer’s obligation to pay the fee(s) shall remain in full force.<br></br>
        11.2.	GxP Cloud does not warrant that the Services shall meet Customer's requirements or shall operate in combinations with hardware, middleware, software (license and type), platforms, systems or databases selected for use by or on behalf of Customer. GxP Cloud will charge at that moment applicable rates and the costs of repair or replacement, as the case may be, if it concerns any errors or damage due to issues caused by Customer selected materials, or due to Customer’s negligent or improper use, or other causes not attributable to GxP Cloud. The warranty obligation will be cancelled if Customer changes or has changes made to the services without the written permission of GxP Cloud. Repair or replacement, as the case may be, of mutilated or lost data will not fall under the warranty.<br></br>
        11.3.	The warranties set forth in clauses 11.1 and 11.2 are the only warranty made by GxP Cloud. GxP Cloud expressly disclaims and Customer hereby expressly waives, all other warranties, express or implied, including, without limitation, warranties of merchantability and fitness for a particular purpose. GxP Cloud does not warrant and specifically disclaims any representations that the services shall meet customer’s requirements or that the operation of the services and/or its use shall be uninterrupted or error-free, or that errors or other defects in the services, if any, shall be corrected. GxP Cloud’s limited warranty set forth herein is in lieu of all liabilities or obligations of GxP Cloud for damages arising out of or in connection with the use of the services or additional services. Except for the above mentioned warranty, the entire risk as to the use, quality and performance of the services are with customer, who explicitly acknowledges that the services are ‘as is’. Any use of the services by or on behalf of Customer and any (direct and indirect) consequences thereof are at the expense and risk of Customer.<br></br>
        <br></br>
        <strong>12.	Confidentiality</strong><br></br><br></br>
        12.1.	Both GxP Cloud and Customer will treat all data and information that each obtains from the other party with respect to the services as well as commercial, strategic, technical data, knowledge and/or other information connected with that other party strictly confidential. For the avoidance of doubt, any data or information uploaded by Customer is considered confidential information of customer. <br></br>
        12.2.	It is only allowed to deviate from the provisions in the above clause if the relevant deviation has been explicitly provided for in the Agreement, the related information was already generally known without any breach of confidence by a party or any third party before the agreement was realized, the prior written permission of the party concerned was obtained, or the related information must be made public to satisfy a mandatory decision to that extent of a legal authority, in which case the party concerned will inform the other party in advance.<br></br>
        12.3.	Each party may divulge that Customer has entered into the agreement and GxP Cloud may describe its activities on account of the Agreement in its marketing materials in general, non-confidential terms. Customer agrees furthermore that GxP Cloud may issue a press release in that case in terms agreed with the Customer.<br></br>
        <br></br>
        <strong>13.	Communication and Support</strong><br></br><br></br>
        13.1.	All communications and notices made or given by GxP Cloud pursuant to this Agreement must be in English language.<br></br>
        13.2.	GxP Cloud may provide any notice to Customer under this Agreement by (i) posting a notice on the GxP Cloud website , (ii) the Cloud Management Console, or (iii) sending a message to the email address associated with Customers account. Notices GxP Cloud provides through the website or the Cloud Management Console will be effective upon posting and notices provided by email will be effective when GxP Cloud sends the email. It is Customers responsibility to keep the email address current. Customer will be deemed to have received any email sent to the email address associated with Customers account at the moment of sending the email, whether or not is has been received.<br></br>
        13.3.	As stated in 2.2 support is provided on the functionality of the Cloud Management Console and it’s services. Please use the link provided in the Cloud Management Console to request support.<br></br>
        <br></br>
        <strong>14.	Miscellaneous</strong><br></br><br></br>
        14.1.	GxP Cloud is entitled to subcontract or otherwise assign the rights and/or obligations arising from the agreement. Customer is not permitted to assign the agreement and/or any of its rights or obligations in this matter, without express prior written permission from GxP Cloud.<br></br>
        14.2.	Nothing in these terms nor in the Agreement is intended to, or shall be deemed to, establish any partnership or joint venture between any of the parties, constitute any party the agent of another party, nor authorise any party to make or enter into any commitments for or on behalf of any other party.<br></br>
        14.3.	Each party undertakes towards the other party that for the duration of the agreement and for 2 years after termination (irrespective of the reason of termination and/or which party has proceeded to termination) it will not directly or indirectly (either for itself or on the behalf of others) employ or offer to employ or contract personnel or other employees of the other party who were involved in carrying out the agreement or have them work otherwise for them, unless the prior explicit written permission of that other party to do so has first been obtained. In the event of violation of the provisions in this clause, the infringing party will owe to the other party amongst others a penalty immediately due and payable of 1 year gross annual salary per personnel member or employee in question, without prejudice to the right of that other party to recover its full damage from the infringing party.<br></br>
        14.4.	These Terms, the Agreement and related matters hereto shall be governed by the laws of the Netherlands, without giving effect to the principles of conflict of laws. The application of the United Nations Convention on Contracts for the International Sale of Goods dated 11 April 1980 is hereby explicitly excluded. <br></br>
        14.5.	Without prejudice to the right of each party to apply for disposition by summary proceedings, all disputes arising in connection with the agreement, these terms and related matters (‘Disputes’), and which cannot be solved through amicable negotiations, shall be exclusively and finally settled by the competent court of ‘s-Hertogenbosch, the Netherlands, if both parties hereto are based in the European Union, or if one of the parties is based outside the European Union, by binding arbitration administered by the World Intellectual Property Organization (‘WIPO’), pursuant to the Expedited Rules of WIPO in effect at the time of submission. Such arbitration shall be held in ‘s-Hertogenbosch, the Netherlands, before a single neutral arbitrator with 10 years experience concerning commercial agreements. The official language of the arbitration shall be English, and all evidence shall be submitted in English, or in a certified English translation, except if customer is based in The Netherlands, in which case the official language of the arbitration shall be Dutch, and evidence may be submitted in both Dutch and English. The arbitrator’s award shall be a final and binding determination of all disputes and related claims and counterclaims that are presented to the arbitrator.<br></br>
        14.6.	Headings have been inserted into the agreement for convenience only and shall not affect the interpretation of the agreement.<br></br>
        <br></br>
        <strong>15.	Data Processing</strong><br></br><br></br>
        15.1.	GxP Cloud and Customer agree that this Agreement constitutes Customer’s documented instruction regarding GxP Cloud’s processing of Customer Data. <br></br>
        15.2.	GxP Cloud will only process Customer Data required to provide the resources and services (such as contact and billing information) covered in this Agreement unless requested in writing by Customer. <br></br>
        15.3.	Taken in account the nature of the processing; <br></br>

        (a) Customer agrees that is unlikely for GxP Cloud to form an opinion on whether instructions provided by Customer infringe on GDPR. If GxP Cloud forms such an opinion, it will immediately inform Customer, in which case, Customer is entitled to withdraw or modify it’s written instructions. <br></br>

        (b) In the event that an affected data subject addresses GxP Cloud directly, GxP Cloud shall, where reasonably possible, immediately forward such request to Customer.<br></br>
        15.4.	GxP Cloud will not access or use, or disclose to any third party, any Customer Data, except, in each case, as necessary to maintain or provide the Services, or as necessary to maintain and provide the services, or as necessary to comply with the law or a valid and binding order of a governmental body. If a governmental body sends the demand for (access to) personal data directly to GxP Cloud, GxP Cloud will firstly attempt to redirect the governmental body to request that data directly from customer. As part of this effort, GxP Cloud may share the contact information of Customer with the governmental body. If compelled to disclose Customer Data to a governmental body, GxP Cloud will give Customer reasonable notice of the demand to allow Customer to seek a protective order or other appropriate remedy unless GxP Cloud is legally prohibited from doing so.<br></br>
        15.5.	GxP Cloud will not access or use Customer’s content except as necessary to maintain or provide the Services, or after written consent by Customer to provide additional support. <br></br>
        15.6.	GxP Cloud can engage with other service providers to perform it’s services. Where GxP Cloud engages another service provider for carrying out specific activities on behalf of the Customer, at least the same data protection obligations as required by the GDPR shall apply. GxP Cloud shall on request provide Controller with a list of sub processors used by it.<br></br>
        15.7.	GxP Cloud shall notify Controller without undue delay upon GxP Cloud becoming aware of a Personal Data Breach affecting Customer’s Personal Data, providing Customer with sufficient information to allow them to meet any obligations to report or inform Data Subjects of the Personal Data Breach under the Data Protection Laws. <br></br>
        <br></br>
        <strong>16.	Data Processing</strong><br></br><br></br>
        <strong>17.	The corresponding SLA and DPA</strong><br></br><br></br>
        17.1. Link to SLA: <br></br> <a href="https://gxp-cloud.com/SLA/" target="_blank" rel="noopener noreferrer"> GxP-CLoud SLA</a><br></br><br></br>
        17.2. Link to DPA: <br></br> <a href="https://gxp-cloud.com/DPA/" target="_blank" rel="noopener noreferrer"> GxP-CLoud DPA</a><br></br>
        26-Oktober-2022<br></br>
        Initial Version<br></br>
        <br></br>
        <br></br>
        End of Document.<br></br>

    </div>
  </Panel>
)
