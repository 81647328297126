// @flow
import React, { useRef } from 'react'

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Schema,
  Modal,
  Checkbox,
  CheckPicker,
  CheckboxGroup,
  TagPicker
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
  +applications: IdentityObject[],
  +children: React$Element<any>,
  +dialogAction: string,
  +errors?: FirewallDialogErrors,
  +handleNameChange: Function,
  +handleOnChangeApplications: Function,
  +handleOnChangeDestinations: Function,
  +handleOnChangeDestinationVms: Function,
  +handleOnChangeDestinationVnicGroups: Function,
  +handleOnChangeSources: Function,
  +handleOnChangeSourceVms: Function,
  +handleOnChangeSourceVnicGroups: Function,
  +isOpen: boolean,
  +applications: IdentityObject[],
  +vms: IdentityObject[],
  +vnicGroups: IdentityObject[],
  +rule: FirewallRule,
  +submit: Function,
  +toggleEnabledState: Function,
  +toggleMatchTranslatedState: Function,
  +toggleOpenState: Function
|}

const containerStyle = { display: 'inline' }

const { StringType, ArrayType } = Schema.Types
const model = Schema.Model({
  Name: StringType().isRequired('This field is required.'),
  sourceIps: ArrayType().addRule((value) => {
    const subnetRegExp = new RegExp(
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
    )
    var check = true
    value.forEach((subnet) => {
      if (subnetRegExp.test(subnet) === false) {
        check = false
      }
    })
    return check
  }, 'No valid IPv4 Address'),
  destinationIps: ArrayType().addRule((value) => {
    const subnetRegExp = new RegExp(
      /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
    )
    var check = true
    value.forEach((subnet) => {
      if (subnetRegExp.test(subnet) === false) {
        check = false
      }
    })
    return check
  }, 'No valid IPv4 Address')
})

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

export const FirewallDialogComponent = (props: Props) => {
  const dialogTitle = props.dialogAction + ' firewall rule'
  const errors = props.errors && props.errors.general
  var firewallForm = useRef(null)

  return (
    <div style={containerStyle}>
      {React.cloneElement(props.children, {
        onClick: props.toggleOpenState
      })}
      <Modal backdrop="static" show={props.isOpen} size="sm">
        <Form
          model={model}
          ref={(ref) => (firewallForm = ref)}
          fluid
          checkTrigger="none"
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>{dialogTitle}</Modal.Title>
          </Modal.Header>
          {/* <span style={{ color: '#FF0000', fontSize: '.7em' }}>{errors}</span> */}
          <InputField
            autoFocus
            name="Name"
            label="Name"
            type="name"
            onChange={props.handleNameChange}
            value={props.rule.name}
          />
          {/* Always enabled */}
          <CheckboxGroup>
            <div>
              <Checkbox
                id="rule-enabled"
                label="Enabled"
                defaultChecked
                onChange={props.toggleEnabledState}
                checked={props.rule && props.rule.enabled}
              >
                Rule Enabled
              </Checkbox>
            </div>
            <div>
              <Checkbox
                id="rule-match-translated"
                label="Match translated"
                onChange={props.toggleMatchTranslatedState}
                checked={props.rule && props.rule.matchTranslated}
              >
                Match Translated
              </Checkbox>
            </div>
          </CheckboxGroup>
          <InputField
            name="sourceVnicGroups"
            id="rule-source-vnic-groups"
            style={{ width: 'inherit' }}
            data={props.vnicGroups}
            onSelect={props.handleOnChangeSourceVnicGroups}
            label="Source vNIC Groups"
            labelKey="name"
            valueKey="id"
            accepter={CheckPicker}
            searchable={false}
          />
          <InputField
            name="sourceVms"
            id="rule-source-vms"
            style={{ width: 'inherit' }}
            data={props.vms}
            onSelect={props.handleOnChangeSourceVms}
            label="Source VMs"
            labelKey="name"
            valueKey="id"
            accepter={CheckPicker}
            searchable={false}
          />
          <InputField
            name="sourceIps"
            id="rule-source-ips"
            style={{ width: 'inherit' }}
            onChange={props.handleOnChangeSources}
            label="Source IPs"
            value={props.rule.sources}
            creatable
            accepter={TagPicker}
          />
          <InputField
            name="destinationVnicGroups"
            id="rule-destination-vnic-groups"
            style={{ width: 'inherit' }}
            data={props.vnicGroups}
            onSelect={props.handleOnChangeDestinationVnicGroups}
            label="Destination vNIC Groups"
            labelKey="name"
            valueKey="id"
            accepter={CheckPicker}
            searchable={false}
          />
          <InputField
            name="destinationVms"
            id="rule-destination-vms"
            style={{ width: 'inherit' }}
            data={props.vms}
            onSelect={props.handleOnChangeDestinationVms}
            label="Destination VMs"
            labelKey="name"
            valueKey="id"
            accepter={CheckPicker}
            searchable={false}
          />
          <InputField
            name="destinationIps"
            id="rule-destination-ips"
            style={{ width: 'inherit' }}
            onChange={props.handleOnChangeDestinations}
            label="Destination IPs"
            value={props.rule.destinations}
            creatable
            accepter={TagPicker}
          />
          <InputField
            name="applications"
            id="rule-applications"
            style={{ width: 'inherit' }}
            data={props.applications}
            onSelect={props.handleOnChangeApplications}
            label="Applications"
            labelKey="name"
            valueKey="id"
            accepter={CheckPicker}
            searchable={false}
          />
          <Modal.Footer>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {errors ? (
                <span style={{ margingRight: '100px', color: '#ff0000' }}>
                  {errors}
                </span>
              ) : (
                <div></div>
              )}
            </div>
            <div>
              <Button onClick={props.toggleOpenState} color="red">
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                onClick={(e) => {
                  if (firewallForm.check()) {
                    e.preventDefault()
                    props.submit()
                  }
                }}
              >
                {props.dialogAction}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}
