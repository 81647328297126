// @flow

import React from 'react'

import { Panel, Row, Col, Divider, PanelGroup, Whisper, Icon, IconButton, Tooltip } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { VmCardComponent } from './VmCardComponent'

type Props = {|
  +vnic: Vnic,
  +iveLocked: boolean,
  +index: integer,
  +timezone: string,
  +iveId: string,
  +vmId: string,
  +setSubmitDialogOpenState: Function
|}

const VnicGroupCard = (props: Props) => {
  const { vnic, iveLocked, timezone, iveId, setSubmitDialogOpenState } = props

  return (
    <Panel
      bordered
      style={{ marginBottom: '15px' }}
      header={
        <div
          style={{
            fontSize: '24px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span style={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
            <span>
              <strong>{vnic.name}</strong> &nbsp;{' '}
              <i>{vnic.network ? vnic.network : vnic.staticIpNetwork}</i>
            </span>
            <span style={{width: '10px'}}/>
              <span>
                <Row style={{ fontSize: '12px' }}>
                  <Col md={12}>DHCP</Col>
                </Row>
                <Row style={{ fontSize: '14px' }}>
                  <Col md={12} style={{ color: vnic.dhcpEnabled ? 'green' : 'red' }}>
                    <strong>{vnic.dhcpEnabled ? 'Enabled' : 'Disabled'}</strong>
                  </Col>

                </Row>
              </span>
            </span>
          <span> 
            {/* styling ^ */}
            {/* {!props.index == 0 && !vnic.isDeletable ? <i style={{ fontSize: '12px', color: 'orange' }}>Network is under minimal uptime.</i> : ''}
            <span style={{ width: '10px'}}/> */}
            {/* {!props.index === 0 ? ( */}
            <Row>
              <Col md={12}>
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={<Tooltip>Delete Network</Tooltip>}
                  >
                    <IconButton
                      icon={<Icon icon="trash" />}
                      disabled={props.index === 0 || vnic.vms.length > 0 || iveLocked || !vnic.isDeletable}
                      color='red'
                      onClick={() =>
                        setSubmitDialogOpenState({
                          dialogId: 'DELETE_IVE_NETWORK',
                          payload: {
                            iveId: iveId,
                            networkId: vnic.id
                          },
                          meta: { iveId: iveId }
                        })
                      }
                    >
                      Delete
                    </IconButton>
                  </Whisper>
                </Col>
            </Row>
             {/* ) : ('')} */}
          </span>
        </div>
      }
    >
      <Divider style={{ marginTop: '-15px' }}>Virtual Machines</Divider>
      <PanelGroup style={{ overflow: 'visible', marginTop: '-30px' }}>
        {vnic.vms.length > 0 ? (
          vnic.vms.map((vm, index) => (
            <VmCardComponent
              key={`vm-card-${index}-${vm.id}`}
              iveId={iveId}
              iveLocked={iveLocked}
              lastVm={vnic.vms.length === index + 1}
              firstVm={index === 0}
              timezone={timezone}
              vm={vm}
            />
          ))
        ) : (
          <div style={{ marginTop: '50px' }}>
            <strong>There are no Virtual Machines in this Network yet.</strong>
          </div>
        )}
      </PanelGroup>
    </Panel>
  )
}

export { VnicGroupCard }
