// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ToJs } from 'utils/ToJs'
import { DiskConfigComponent } from './DiskConfigComponent'
import { getVmRequest, setVmDiskConfig } from 'modules/operations/vms'
import {
  setVmHardwareChangeFormErrorState,
  clearVmHardwareChangeFormErrorState
} from 'modules/components/vmHardwareChangeForm'
import { validate } from './validate'
import { setSubmitDialogOpenState } from 'modules/components/submitDialog'
import { Notification } from 'rsuite'

type ReduxState = {|
  +vm: Vm,
  +errors: {}
|}
type ReduxActions = {|
  +getVmRequest: Function, 
  +setVmDiskConfig: Function,
  +setSubmitDialogOpenState: Function,
  +setVmHardwareChangeFormErrorState: Function,
  +clearVmHardwareChangeFormErrorState: Function
|}
type Props = ReduxState & ReduxActions & {| +match: Match |}
type State = {| componentUpdateCount: number, initialDiskValues: Disk[] |}

const mapStateToProps = (state) =>
  ({
    vm: state.getIn(['vms', 'vm']),
    errors: state.getIn(['vmHardwareChangeForm', 'errors']),
  }: ReduxState)

const DEFAULT_STATE = { componentUpdateCount: 0, initialDiskValues: [] }

export class DiskConfigContainer extends Component<Props, State> {
  props: Props
  state: State = DEFAULT_STATE

  componentDidMount() {
    const { vmId } = this.props
    this.props.getVmRequest({ vmId })
  }

  componentWillUnmount() {
    this.props.clearVmHardwareChangeFormErrorState()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.state.componentUpdateCount === 0) {
      this.setState({ initialDiskValues: nextProps.vm.hardware.disks.user })
    }
    this.setState({ componentUpdateCount: this.state.componentUpdateCount + 1 })
  }

  getNewState = (userDisks: Disk[]) => {
    const vm = this.props.vm
    const { hardware } = vm
    const { disks } = hardware
    const newState = {
      ...vm,
      hardware: { ...hardware, disks: { ...disks, user: userDisks } }
    }
    return newState
  }

  handleValidate = () => {
    const validationErrors = validate(
      this.props.vm.hardware.disks.user,
      this.state.initialDiskValues
    )
    this.setState({ errors: validationErrors })
    const validForm = !Object.keys(validationErrors).length
    if (validForm) {
      this.props.clearVmHardwareChangeFormErrorState()
      return true
    } else {
      this.props.setVmHardwareChangeFormErrorState(validationErrors)
      return false
    }
  }

  changeDisk = (index: number, value: string | number, type: string) => {
    const { user, immutable } = this.props.vm.hardware.disks
    user[index][type] = value
    this.props.setVmDiskConfig({
      ...this.props.vm.hardware,
      disks: { immutable, user }
    })
  }

  handleDiskSizeChange = (index: number, value: string) => {
    if (!this.props.vm.snapshotCreatedDatetime) {
      this.changeDisk(index, value ? parseInt(value, 0) : '', 'size')
    }
  }

  handleDiskNameChange = (index: number, value: string) => {
    this.changeDisk(index, value, 'name')
  }

  handleDiskAdd = () => {
    const { user, immutable } = this.props.vm.hardware.disks
    // Add a new disk to state when there are less than seven disks and NO snapshot
    if (
      user.length + immutable.length <= 6 &&
      !this.props.vm.snapshotCreatedDatetime
    ) {
      user.push({ name: '', size: 0 })
      const newState = this.getNewState(user)
      this.props.setVmDiskConfig(newState.hardware)
    } else if (
      user.length + immutable.length <= 6 &&
      this.props.vm.snapshotCreatedDatetime
    ) {
      // Show Notification error when snapshot is present
      Notification.error({
        title: 'Error',
        placement: 'bottomEnd',
        description: ' VM Snapshot presence prevents this action'
      })
    } else {
      // Show Notification error when user is not able to add more disk due to resctrictions in number of disks
      Notification.error({
        title: 'Error',
        placement: 'bottomEnd',
        description: 'Maximum number of disks reached'
      })
    }
  }

  deleteDiskByIndex = (index: number) => {
    const { user } = this.props.vm.hardware.disks
    const newUserDisks = user.slice(0, index).concat(user.slice(index + 1))
    const newState = this.getNewState(newUserDisks)
    this.props.setVmDiskConfig(newState.hardware, { vmId: this.props.vm.id })
  }

  handleOpenSubmitDialog = () => {
    if (this.handleValidate() && !this.props.vm.snapshotCreatedDatetime) {
      this.props.setSubmitDialogOpenState({
        dialogId: 'VM_DISK_CONFIG',
        payload: { ...this.props.vm.hardware },
        meta: { vmId: this.props.vm.id, name: this.props.vm.name }
      })
    } else if (this.props.vm.snapshotCreatedDatetime) {
      // Show Notification error when snapshot is present
      Notification.error({
        title: 'Error',
        placement: 'bottomEnd',
        description: 'VM Snapshot presence prevents this action'
      })
    }
  }

  handleDeleteDiskSubmitDialog = (disk: Disk) => {
    if (!this.props.vm.snapshotCreatedDatetime) {
      this.props.setSubmitDialogOpenState({
        dialogId: 'DELETE_DISK_VM',
        meta: {
          vmId: this.props.vmId,
          diskId: disk.id,
          name: disk.name
        }
      })
    } else {
      // Show Notification error when snapshot is present
      Notification.error({
        title: 'Error',
        placement: 'bottomEnd',
        description: 'VM Snapshot presence prevents this action'
      })
    }
  }

  render() {
    return (
      <div>
        <DiskConfigComponent
          vm={this.props.vm}
          errors={this.state.errors}
          setVmDiskConfig={this.props.setVmDiskConfig}
          deleteDiskByIndex={this.deleteDiskByIndex}
          handleDiskNameChange={this.handleDiskNameChange}
          handleDiskSizeChange={this.handleDiskSizeChange}
          handleDiskAdd={this.handleDiskAdd}
          handleOpenSubmitDialog={this.handleOpenSubmitDialog}
          handleDeleteDiskSubmitDialog={this.handleDeleteDiskSubmitDialog}
        />
      </div>
    )
  }
}
// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const DiskConfig = connect(
  mapStateToProps,
  ({
    getVmRequest,
    setVmDiskConfig,
    setVmHardwareChangeFormErrorState,
    clearVmHardwareChangeFormErrorState,
    setSubmitDialogOpenState
  }: ReduxActions)
)(ToJs(DiskConfigContainer))
