// @flow

import React from 'react'

import { Icon, IconButton } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const getIconColor = (icon) => (icon === 'thumbs-o-up' ? 'green' : 'red')

type Props = {|
  +icon: string,
  +approvalId: string,
  +handleOpenSubmitDialog: Function
|}

const ApprovalButtonComponent = (props: Props) => (
  <IconButton
    icon={<Icon icon={props.icon}/>}
    color={getIconColor(props.icon)}
    appearance='ghost'
    touch={true}
    onClick={() =>
      props.handleOpenSubmitDialog({
        dialogId: `${
          props.icon === 'thumbs-o-up' ? 'APPROVE_TASK' : 'DISAPPROVE_TASK'
        }`,
        payload: {
          approved: `${
            props.icon === 'thumbs-o-up' ? true.toString() : false.toString()
          }`,
          approvalId: props.approvalId
        }
      })
    }
  />
)
export { ApprovalButtonComponent }
