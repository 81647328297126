import React, { Component } from 'react'

import {
    Button,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    Schema,
    Panel,
    Message
  } from 'rsuite'
  import 'rsuite/dist/styles/rsuite-default.css'

import { postUserInviteActivateRequest } from 'modules/operations/account'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'
import logo from '../../../img/Logo_GxP-Cloud_Cloud-Management-Console-A.svg'

const styles = {
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    flexDirection: 'column'
  },
  logoContainer: {
    marginBottom: '35px'
  },
  capsLockOn: {
    fontSize: '30px',
    fontFamily: 'Streetvertising Bold, Helvetica Neue, sans-serif',
    color: '#ff0000'
  }
}

type ReduxActions = {|
  +postUserInviteActivateRequest: Function
|}
type Props = ReduxActions
type State = {|
  +email: string,
  +password: string,
  +token: string,
  +uidb64: string
|}

const { StringType } = Schema.Types
const model = Schema.Model({
  password: StringType()
    .isRequired('This field is required.')
    .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Must contain: Letters, numbers and special characters (!@#$&*).')
    .rangeLength(8,30, 'The number of characters can only be between 8 and 30.'),
  repeatedPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
    .isRequired('This field is required.')
})

function TextField(props) {
    const { name, label, type, accepter, ...rest } = props
    return (
      <FormGroup>
        <ControlLabel>{label} </ControlLabel>
        <FormControl name={name} accepter={accepter} type={type} {...rest} />
      </FormGroup>
    )
  }
  
class InvitedUserContainer extends Component<Props, State> {
    props: Props
    state: State
  
    state = { capsLockWarning: false }
  
    constructor(props) {
      super()
    }

    componentDidMount() {
        if (document.documentElement) {
          document.documentElement.style.overflowY = 'auto'
        }
        if (document.body) {
          document.body.style.backgroundSize = 'cover'
        }
        const { uidb64 } = this.props.match.params
        this.setState({ ...this.state, uidb64: uidb64})
        const { token } = this.props.match.params
        this.setState({ ...this.state, token: token})
    }

    handleChange = (value) => {
        const newState = { ...this.state, ...value }
        this.setState(newState)
      }


      handleRegisterInvitedUser = () => {
        if (this.form.check()) {
            const { password, uidb64, token } = this.state
            this.props.postUserInviteActivateRequest({ password, token, uidb64 })
        }
      }

    onKeyDown = (keyEvent) => {
        if (keyEvent.getModifierState('CapsLock')) {
            this.setState({ capsLockWarning: true })
        } else {
            this.setState({ capsLockWarning: false })
        }
      }

    render() {
        return (
            <div style={styles.loginContainer}>
                <img
                style={styles.logoContainer}
                src={logo}
                alt="GxP-Cloud"
                width="300px"
                />
  
                <Panel style={{ backgroundColor: '#ffffff' }} header={<h5>Signup to GxP-Cloud</h5>}>
                    <Form
                        model={model}
                        ref={(ref) => (this.form = ref)}
                        checkTrigger="none"
                    >
                        <Message
                            showIcon
                            type="info"
                            style={{ marginBottom: '15px' }}
                            description={
                            <div
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    maxWidth: '226px'
                                }}
                            >
                                Please provide your password for your account. Other account information is already been set.
                            </div>
                            }
                            title="Information"
                        />
                        <TextField
                            name="password"
                            label="Password"
                            type="password"
                            onChange={(password, event) =>
                                this.handleChange({ password, event })
                            }
                            onKeyDown={this.onKeyDown}
                        />
                        <TextField
                            name="repeatedPassword"
                            label="Repeat Password"
                            type="password"
                            onChange={(repeatedPassword, event) =>
                                this.handleChange({ repeatedPassword, event })
                            }
                            onKeyDown={this.onKeyDown}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        {this.state.capsLockWarning ? (
                            <span style={{ color: '#ff0000' }}>Caps Lock is On!</span>
                        ) : (
                            <div></div>
                        )}
                        </div>
                        <div>
                        <Button
                            appearance="ghost"
                            color='blue'
                            type="submit"
                            style={{ marginTop: '15px', width: '300px' }}
                            onClick={(e) => {
                            e.preventDefault()
                            this.handleRegisterInvitedUser()
                            }}
                        >
                            Submit
                        </Button>
                        </div>
                    </Form>
                </Panel>
            </div>
        )}

}

export const InvitedUser = connect(
    null,
    ({
      postUserInviteActivateRequest
    }: ReduxActions)
)(ToJs(InvitedUserContainer))