import React from 'react'

import { Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

export const Contact = () => (
    <Panel 
        header={<h4>Contact</h4>}
        style={{ width: '80%'}}
    >

    <Panel style={{ marginTop: '15px' }}>
        <h5 style={{ color: '#FF8A00' }}>Contact details</h5>
        <br></br>

        <strong>
            Wolput 36<br></br>
            5251 CG Vlijmen<br></br>
            The Netherlands<br></br>
            <br></br>

            +31(0) 73 64 88 000<br></br>
            info@gxp-cloud.com<br></br>
            www.gxp-cloud.com<br></br>
        </strong>
        <br></br>

        <div>
            Logging a Ticket: <a href='https://colbright.topdesk.net/' target="_blank" rel="noopener noreferrer">Managed Customer TOPdesk</a>
        </div>

    </Panel>
    
</Panel>
)