// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setSubmitDialogOpenState } from 'modules/components/submitDialog'
import { IveCardComponent } from './IveCardComponent'
import { setModalOpenState } from 'modules/components/gxpModal'

type Props = {|
  +ive: Ive,
  +timezone: string,
  +setModalOpenState: Function,
  +setSubmitDialogOpenState: Function
|}

class IveCardContainer extends Component<Props> {
  props: Props

  vmInIve = () => {
    console.log(this.props.ive.networks.vms.length > 0)
    if (this.props.ive.networks.vms) {
      if (this.props.ive.networks.map((network) => (network.vms.length > 0 ))) {
        return true
      }
    } else {
      return false
    }
  }

  render() {
    const {
      ive,
      iveId,
      timezone,
      setModalOpenState,
      setSubmitDialogOpenState
    } = this.props
    return (
      <IveCardComponent
        ive={ive}
        iveId={iveId}
        vmInIve={this.vmInIve}
        timezone={timezone}
        setModalOpenState={setModalOpenState}
        setSubmitDialogOpenState={setSubmitDialogOpenState}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const IveCard = connect(() => ({}), {
  setSubmitDialogOpenState,
  setModalOpenState
})(IveCardContainer)
