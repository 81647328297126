// @flow

import React from 'react'
import { Row, Col, Grid, Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { DnatRuleRow } from './DnatRuleRowComponent'

type Props = {|
  +editDnatRule: Function,
  +deleteDnatRule: Function,
  +iveId: string,
  +rules: DnatRules
|}

export const DnatTable = (props: Props) => {
  const { immutable, user } = props.rules
  const rules = immutable.concat(user)
  return (
    <span>
      <Panel header={<strong>Configured Destinaton NAT rule(s)</strong>}>
        <Grid fluid>
          <Row style={{ marginBottom: '15px' }}>
            <Col md={4}>Original Address</Col>
            <Col md={3}>Original port</Col>
            <Col md={2}></Col>
            <Col md={4}>VM</Col>
            <Col md={3}>Translated port</Col>
            <Col md={3}>Protocol</Col>
            <Col md={2} className={'small-column'}>
              Edit
            </Col>
            <Col md={2} className={'small-column'}>
              Delete
            </Col>
          </Row>
          {rules.map((rule, index) => (
            <DnatRuleRow
              deleteDnatRule={props.deleteDnatRule}
              editDnatRule={props.editDnatRule}
              iveId={props.iveId}
              userRuleIndex={index - immutable.length}
              key={`dnat-rule-${rule.id}-${index}`}
              rule={rule}
              rules={props.rules}
            />
          ))}
        </Grid>
      </Panel>
    </span>
  )
}
