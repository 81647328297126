// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SslVpnFormComponent } from './SslVpnFormComponent'
import { ToJs } from 'utils/ToJs'
import {
  setSslVpnFormErrorState,
  clearSslVpnFormErrorState
} from 'modules/components/sslVpnForm'

type ReduxActions = {|
  +setSslVpnFormErrorState: Function,
  +clearSslVpnFormErrorState: Function
|}
type Props = ReduxState &
  ReduxActions & {|
    +iveId: string,
    +setSubmitDialogOpenState: Function
  |}
type State = {|
  username: string,
  password: string,
  repeatedPassword: string,
  focusSet: boolean
|}

const mapStateToProps = (state) =>
  ({
    errors: state.getIn(['sslVpnForm', 'errors'])
  }: ReduxState)

class SslVpnFormContainer extends Component<Props, State> {
  props: Props
  state: State
  textRef: Object

  constructor() {
    super()
    this.state = {
      username: '',
      password: '',
      repeatedPassword: '',
      focusSet: false,
      capsLockWarning: false
    }
    this.textRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.focusSet) {
      setTimeout(() => {
        this.textRef.current && this.textRef.current.focus()
      }, 5)
      this.setState({ focusSet: true })
    }
  }

  componentWillUnmount() {
    this.props.clearSslVpnFormErrorState()
  }

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState('CapsLock')) {
      this.setState({ capsLockWarning: true })
    } else {
      this.setState({ capsLockWarning: false })
    }
  }

  handleChange = (change) => {
    const newState = { ...this.state, ...change }
    this.setState(newState)
  }

  render() {
    const { iveId, setSubmitDialogOpenState } = this.props
    const { username, password, repeatedPassword } = this.state
    return (
      <SslVpnFormComponent
        textRef={this.textRef}
        iveId={iveId}
        setSubmitDialogOpenState={setSubmitDialogOpenState}
        username={username}
        password={password}
        repeatedPassword={repeatedPassword}
        handleChange={this.handleChange}
        handleValidate={this.handleValidate}
        capsLockWarning={this.state.capsLockWarning}
        onKeyDown={this.onKeyDown}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const SslVpnForm = connect(
  mapStateToProps,
  ({ setSslVpnFormErrorState, clearSslVpnFormErrorState }: ReduxActions)
)(ToJs(SslVpnFormContainer))
