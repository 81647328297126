// @flow

import React from 'react'
import {
  ButtonToolbar,
  Button,
  Panel,
  IconButton,
  Icon,
  Whisper,
  Tooltip
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { SyncIndicator } from 'components/SyncIndicator'

// import { SnatTable } from './snat'
// import { SnatDialog } from './snat/dialog'
import { DnatTable } from './dnat'
import { DnatDialog } from './dnat/dialog'

const style = {
  addRuleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '5px'
  },
  card: {
    marginBottom: '32px'
  },
  cardHeader: {
    fontSize: '24px'
  },
  saveContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  noRulesContainer: {
    fontSize: '1.5em',
    paddingTop: '1em'
  }
}

type Props = {|
  +addDnatRule: Function,
  +addSnatRule: Function,
  +deleteDnatRule: Function,
  +deleteSnatRule: Function,
  +dnatRules: DnatRules,
  +editDnatRule: Function,
  +editSnatRule: Function,
  +iveId: string,
  +natRulesMutated: boolean,
  +setSubmitDialogOpenState: Function,
  +snatRules: SnatRules
|}

export const NatComponent = (props: Props) => {
  const {
    // addSnatRule,
    addDnatRule,
    // deleteSnatRule,
    deleteDnatRule,
    // editSnatRule,
    editDnatRule,
    snatRules,
    natRulesMutated,
    dnatRules,
    iveId,
    setSubmitDialogOpenState
  } = props

  // const hasSnatRules = snatRules.immutable.length || snatRules.user.length
  const hasDnatRules = dnatRules.immutable.length || dnatRules.user.length

  const handleSave = () => {
    setSubmitDialogOpenState({
      dialogId: 'SAVE_NAT_RULES',
      payload: { snatRules, dnatRules },
      meta: { iveId }
    })
  }

  return (
    <>
      <Panel>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SyncIndicator rulesMutated={natRulesMutated} />
          {natRulesMutated ? (
            <strong>
              Make sure to Publish your changes to make them final
            </strong>
          ) : (
            ''
          )}
          <ButtonToolbar>
            <Button
              id="save-nat"
              appearance='ghost'
              color='blue'
              onClick={handleSave}
              disabled={!natRulesMutated}
            >
              Publish
            </Button>
          </ButtonToolbar>
        </span>
      </Panel>
      <Panel bordered>
        {/* Commented out snat rules as functionality is standard on, maybe look at in future */}
        {/* <div
        style={{
          display: 'flex',
          justifyContent: 'right'
        }}
      >
        <SnatDialog submit={addSnatRule} rules={snatRules} dialogAction={'Add'}>
          <ButtonToolbar style={{ marginBottom: '15px' }}>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Add SNAT rule to IVE</Tooltip>}
            >
              <IconButton
                icon={<Icon icon="plus-square" />}
                color="blue"
                label="Add SNAT Rule"
                type="add"
                id="add-snat-rule"
              >
                Add SNAT Rule
              </IconButton>
            </Whisper>
          </ButtonToolbar>
        </SnatDialog>
      </div> */}
        {/* {hasSnatRules ? (
        <SnatTable
          // key="snat-table"
          deleteSnatRule={deleteSnatRule}
          editSnatRule={editSnatRule}
          iveId={iveId}
          rules={snatRules}
          useDragHandle={true}
        />
      ) : (
        <div style={style.noRulesContainer}>
          There are no source NAT rules for this IVE.
        </div>
      )} */}
        {/* <Divider /> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <DnatDialog
            dialogAction={'Add'}
            iveId={iveId}
            rules={dnatRules}
            submit={addDnatRule}
          >
            <ButtonToolbar style={{ marginBottom: '15px' }}>
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>Add DNAT rule to IVE</Tooltip>}
              >
                <IconButton
                  icon={<Icon icon="plus-square" />}
                  appearance="ghost"
                  color='blue'
                  label="Add DNAT Rule"
                  type="add"
                  id="add-dnat-rule"
                >
                  Add DNAT Rule
                </IconButton>
              </Whisper>
            </ButtonToolbar>
          </DnatDialog>
        </div>
        {hasDnatRules ? (
          <DnatTable
            key="dnat-table"
            deleteDnatRule={deleteDnatRule}
            editDnatRule={editDnatRule}
            iveId={iveId}
            rules={dnatRules}
            useDragHandle={true}
          />
        ) : (
          <div style={style.noRulesContainer}>
            There are no destination NAT rules for this IVE.
          </div>
        )}
      </Panel>
    </>
  )
}
