// @flow

import React from 'react'

import { Icon, IconButton, Whisper, Tooltip, Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
  +iveName: string,
  +publicIp: string
|}

export const SslVpnPortalButton = (props: Props) => (
  <Panel
    header={
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <h5>SSL-VPN Portal download</h5>
        <a
          href={`https://${props.publicIp}:4443/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Redirect to SSLVPN portal download</Tooltip>}
          >
            <IconButton
              icon={<Icon icon="link" />}
              appearance="primary"
              placement="right"
            >
              SSL-VPN Portal
            </IconButton>
          </Whisper>
        </a>
      </span>
    }
  />
)
