import { fromJS } from 'immutable'
import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { Notification } from 'rsuite'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  dcStatistics: '',
  priceList: [],
  price: {
    name: '',
    cost: '',
    unit: ''
  }

})

const moduleName = 'statistics'

const {
    statistics,
    sagas,
    getDcStatisticsRequest,
    getDcStatisticsRequestFailure,
    getPriceListRequest,
    getPriceListRequestFailure,
    getPriceRequest,
    getPriceRequestFailure,
    putPriceChangeRequest,
    putPriceChangeRequestFailure
  } = moducks.createModule(
    moduleName,
    {

      // Datacenter statistics

      GET_DC_STATISTICS_REQUEST: {
        creator: [
          (payload) => undefined,
          (payload, meta) => ({
            method: 'GET',
            endpoint: '/vdcs/usage/'
          })
        ],
        onError: (e) => getDcStatisticsRequestFailure(e)
      },
      GET_DC_STATISTICS_REQUEST_SUCCESS: (state, action) =>
        state.set('dcStatistics', fromJS(action.response)),
      GET_DC_STATISTICS_REQUEST_FAILURE: (state) => state,

      // Price List Get List:

      GET_PRICE_LIST_REQUEST: {
        creator: [
          (payload) => undefined,
          (payload, meta) => ({
            method: 'GET',
            endpoint: '/billing/price-list'
          })
        ],
        onError: (e) => getPriceListRequestFailure(e)
      },
      GET_PRICE_LIST_REQUEST_SUCCESS: (state, action) =>
        state.set('priceList', fromJS(action.response.results)),
      GET_PRICE_LIST_REQUEST_FAILURE: (state) => state,

      // Get Price from list:

      GET_PRICE_REQUEST: {
        creator: [
          (payload) => undefined,
          (payload) => ({
            method: 'GET',
            endpoint: `/billing/${payload.name}/price`
          })
        ],
        onError: (e) => getPriceRequestFailure(e)
      },
      GET_PRICE_REQUEST_SUCCESS: (state, action) =>
        state.set('price', fromJS(action.response)),
      GET_PRICE_REQUEST_FAILURE: (state) => state,

      // Put new Price to arcus:

      PUT_PRICE_CHANGE_REQUEST: {
        creator: [
          (payload) => payload,
          (payload, meta) => ({
            method: 'PUT',
            endpoint: `/billing/${payload.name}/price/`
          })
        ],
        onError: (e) => putPriceChangeRequestFailure(e)
      },
      PUT_PRICE_CHANGE_REQUEST_SUCCESS: {
        saga: function* (action) {
          yield put(
            Notification.info({
              title: 'Info',
              placement: 'bottomEnd',
              description: 'You have successfully changed the Price'
            })
          )
          yield put(push('/management'))
        }
      },
      PUT_PRICE_CHANGE_REQUEST_FAILURE: (state) => state,
  
    },
    defaultState
  )
  
  export { 
    statistics, 
    sagas, 
    getDcStatisticsRequest,
    getPriceListRequest,
    getPriceRequest ,
    putPriceChangeRequest
  }
