// @flow

import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { Approvals } from './components'

const ApprovalsRouter = () => [
  <Switch key="approvals-switch">
    <Route
      key="approvals-route"
      exact
      path="/approvals"
      component={Approvals}
    />
    <Route
      key="approvals-id-route"
      exact
      path="/approvals/:approvalId"
      component={Approvals}
    />
  </Switch>
]

export { ApprovalsRouter }
