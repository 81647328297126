import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToJs } from 'utils/ToJs';

import { getIpsecPeerConfigRequest } from 'modules/operations/ives';
import { IpsecPeerConfigComponent } from './IpsecPeerConfigComponent';

type Props = {|
    +getIpsecPeerConfigRequest: Function,
    +ive: Ive,
    +children: React$Element<any>
  |}

const mapStateToProps = (state) =>
  ({
    ive: state.getIn(['ives', 'ive']),
    peerConfigs: state.getIn(['ives', 'peerConfigs'])
  }: ReduxState)

class IpsecPeerConfigContainer extends Component<Props>{
    props: Props
    state: State

    state = {
        isOpen: false
    }

    componentDidMount() {
        const { iveId } = this.props
        this.props.getIpsecPeerConfigRequest({ iveId })
      }

    toggleOpenState = () => {
        this.setState({ isOpen: !this.state.isOpen })
      }

    render() {
        const { iveId } = this.props
        return [
            <IpsecPeerConfigComponent
                children={this.props.children}
                peerConfigs={this.props.peerConfigs}
                ive={this.props.ive}
                iveId={iveId}
                toggleOpenState={this.toggleOpenState}
                isOpen={this.state.isOpen}
            />
        ]
    }
}

// export const IpsecPeerConfig = IpsecPeerConfigContainer
export const IpsecPeerConfig = connect(
    mapStateToProps,
    ({
      getIpsecPeerConfigRequest
    }: ReduxActions)
  )(ToJs(IpsecPeerConfigContainer))