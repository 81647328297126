// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  errors: {
    disks: []
  }
})

const moduleName = 'vmHardwareChangeForm'

export const {
  vmHardwareChangeForm,
  sagas,
  setVmHardwareChangeFormErrorState,
  clearVmHardwareChangeFormErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_VM_HARDWARE_CHANGE_FORM_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_VM_HARDWARE_CHANGE_FORM_ERROR_STATE: () => defaultState
  },
  defaultState
)
