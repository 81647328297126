// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { InternalAgreeTermsDialogComponent } from './InternalAgreeTermsDialogComponent'
import { postAgreeTermsRequest } from 'modules/operations/account'
import { ToJs } from 'utils/ToJs'


const mapStateToProps = (state) => ({
  hasAgreedTerms: state.getIn(['account', 'groupInfo', 'groups', '0', 'termsAndConditionsAccepted']),
  groupActivated: state.getIn(['account', 'groupInfo', 'groups', '0', 'activated'])
})

type Props = {|
  +hasAgreedTerms: boolean,
  +groupActivated: boolean,
  +postAgreeTermsRequest: Function
|}

export class InternalAgreeTermsDialogContainer extends Component<Props, State> {
  props: Props
  state: State

  constructor() {
    super()
    this.state = {
      bottom: false
    }
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (1.1 * e.target.clientHeight);
    this.setState({ bottom: bottom })
  };

  render() {
    const { hasAgreedTerms, postAgreeTermsRequest, groupActivated } = this.props
    return (
      <InternalAgreeTermsDialogComponent
        postAgreeTermsRequest={postAgreeTermsRequest}
        hasAgreedTerms={hasAgreedTerms}
        groupActivated={groupActivated}
        bottom={this.state.bottom}
        handleScroll={this.handleScroll}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const InternalAgreeTermsDialog = connect(mapStateToProps, {
  postAgreeTermsRequest
})(ToJs(InternalAgreeTermsDialogContainer))
