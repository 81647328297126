// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'
import { clearErrorState } from '../operations/account'
import { put } from 'redux-saga/effects'

const defaultState = fromJS({
  errors: { currentPassword: '', newPassword: '', repeatedPassword: '' }
})

const moduleName = 'setPasswordDialog'

export const {
  setPasswordDialog,
  sagas,
  setPasswordDialogErrorState,
  clearPasswordDialogErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_PASSWORD_DIALOG_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_PASSWORD_DIALOG_ERROR_STATE: {
      reducer: (state) => defaultState,
      saga: function* (action) {
        yield put(clearErrorState())
      }
    }
  },
  defaultState
)
