import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Schema,
  Notification,
  Modal
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { connect } from 'react-redux';
import { ToJs } from 'utils/ToJs';
import publicIp from 'public-ip';

// should become an update credit card ex.
import { postCreditCardSignUpRequest } from 'modules/operations/account'

const public_api_key = process.env.REACT_APP_FUSEBILL_PUBLIC_API_KEY
const recaptcha_key = process.env.REACT_APP_FUSEBILL_RECAPTCHA_KEY
const currentYear = parseInt(new Date().getFullYear().toString().substr(2))
const maxNumberOfYears = 20

const mapStateToProps = (state) => ({
  customerId: state.getIn(['account', 'groupInfo', 'groups', '0', 'customerId'])
 })

type ReduxActions = {|
  +children: React$Element<any>,
  +postCreditCardSignUpRequest: Function,
|}
type Props = ReduxActions
type State = {|
  +creditCardFirstName: string,
  +creditCardLastName: string,
  +creditCardEmail: string,
  +creditCardNumber: string,
  +expirationMonth: number,
  +expirationYear: number,
  +cvv: string,
  +recaptchaResponse: string,
  +warning: Boolean
|}

const { StringType, NumberType } = Schema.Types
const model = Schema.Model({
  creditCardFirstName: StringType().isRequired('This field is required.'),
  creditCardLastName: StringType().isRequired('This field is required.'),
  creditCardEmail: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
  creditCardNumber: StringType().isRequired('This field is required.'),
  expirationMonth: NumberType()
  .isRequired('This field is required.')
  .isInteger('Please input a number.')
  .range(1, 12, 'Please input numbers 1-12.'),
  expirationYear: NumberType()
  .isRequired('This field is required.')
  .isInteger('Please input a number.')
  .range(currentYear, currentYear + maxNumberOfYears, 'Invalid expiration year.'),
  cvv: StringType()
  .isRequired('This field is required.')
  .rangeLength(3, 4, 'Must be 3-4 digits.')
})

function TextField(props) {
  const { name, label, type, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl name={name} accepter={accepter} type={type} {...rest} />
    </FormGroup>
  )
}


class UpdateBillingContainer extends Component<Props, State> {
  props: Props
  state: State

  state = { isOpen: false }

  constructor(props) {
    super()
  }

  handleChange = (value) => {
    const newState = { ...this.state, ...value }
    this.setState(newState)
  }

  toggleOpenState = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  componentDidMount() {
    if (document.documentElement) {
      document.documentElement.style.overflowY = 'auto'
    }
    if (document.body) {
      document.body.style.backgroundSize = 'cover'
    }
  }

//   Should become Fusebill PUT req ?
//   Based on that, change around the textfields etc.. which are needed.
  handlePostCreditCardSignUp = async () => {
    if (this.form.check()) {
      const { 
        creditCardFirstName,
        creditCardLastName,
        creditCardEmail,
        creditCardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        recaptchaResponse
      } = this.state

      // console.log(this.props.customerId)

      const wePay = window.WePay
      wePay.risk.generate_risk_token()
      const riskToken = wePay.risk.get_risk_token()

      const clientIp = await publicIp.v4()
      const customerId = this.props.customerId
      const data = {
        "customerId": customerId,
        "PublicApiKey": public_api_key,
        "cardNumber": creditCardNumber,
        "firstName": creditCardFirstName,
        "lastName": creditCardLastName,
        "expirationMonth": expirationMonth,
        "expirationYear": expirationYear,
        "cvv": cvv,
        "makeDefault": true,
        "recaptcha": recaptchaResponse,
        "email": creditCardEmail,
        "clientIp": clientIp,
        "riskToken": riskToken,
      }

      // we handle everything in nimbus side

      fetch('https://payments.subscriptionplatform.com/api/paymentsv2', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(data)
      })
      .then(async response =>  {
        if (response.status === 400) {
          const data = await response.json()
          Notification.error({
            title: 'Error',
            placement: 'bottomEnd',
            description: data.Errors[0].Value
          })
        }
        if (response.ok) {
          Notification.error({
            title: 'Succes',
            placement: 'bottomEnd',
            description: "You succesfully updated your creditcard information"
          })
        }
      })
      .catch(error => {
          Notification.error({
            title: 'Error',
            placement: 'bottomEnd',
            description: 'Something did go wrong.'
          })
      })
    }
  }


  render() {
    return (
        <div style={{ display: 'inline' }}>
          {React.cloneElement(this.props.children, {
            onClick: this.toggleOpenState
          })}
          <Modal backdrop="static" show={this.state.isOpen} size="xs">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '25px' }}>
                Update CreditCard Information
              </Modal.Title>
            </Modal.Header>
                <Helmet>
                  <script type="text/javascript" src="https://static.wepay.com/min/js/risk.1.latest.js"></script>
                </Helmet>
                <Form
                    model={model}
                    ref={(ref) => (this.form = ref)}
                    checkTrigger="none"
                    fluid
                >
                    <TextField
                        name="creditCardFirstName"
                        label="Cardholder First Name"
                        type="text"
                        onChange={(creditCardFirstName, event) => this.handleChange({ creditCardFirstName, event })}
                    />
                    <TextField
                        name="creditCardLastName"
                        label="Cardholder Last Name"
                        type="text"
                        onChange={(creditCardLastName, event) => this.handleChange({ creditCardLastName, event })}
                    />
                    <TextField
                        name="creditCardEmail"
                        label="Cardholder Email"
                        type="email"
                        onChange={(creditCardEmail, event) => this.handleChange({ creditCardEmail, event })}
                    />
                    <TextField
                        name="creditCardNumber"
                        label="Card Number"
                        type="text"
                        onChange={(creditCardNumber, event) => this.handleChange({ creditCardNumber, event })}
                    />
                    <TextField
                        name="expirationMonth"
                        label="Expiration Month"
                        type="text"
                        placeholder="Input MM month"
                        onChange={(expirationMonth, event) => this.handleChange({ expirationMonth, event })}
                    />
                    <TextField
                        name="expirationYear"
                        label="Expiration Year"
                        type="text"
                        placeholder="Input YY year"
                        onChange={(expirationYear, event) => this.handleChange({ expirationYear, event })}
                    />
                    <TextField
                        name="cvv"
                        label="CVV"
                        type="text"
                        onChange={(cvv, event) => this.handleChange({ cvv, event })}
                    />

                    <div style={{ marginTop: '10px', width: '360px', justifyContent: 'center', display: 'flex' }}>
                        <ReCAPTCHA
                          sitekey={recaptcha_key}
                          onChange={(recaptchaResponse, event) => this.handleChange({ recaptchaResponse, event })}
                        />
                    </div>
                    <div>
                    <Button
                        appearance="primary"
                        type="submit"
                        style={{ marginTop: '15px', width: '360px' }}
                        onClick={(e) => {
                        e.preventDefault()
                        this.handlePostCreditCardSignUp()
                        }}
                    >
                        Submit
                    </Button>
                    <Button 
                        onClick={this.toggleOpenState} color="red"
                        style={{ marginTop: '15px', width: '360px' }}
                    >
                        Cancel
                    </Button>
                    </div>
                </Form>
        </Modal>
      </div>
    )
  }
}

//   https://github.com/facebook/flow/issues/7125
//   $FlowFixMe
export const UpdateBilling = connect(
  mapStateToProps,
  ({
    postCreditCardSignUpRequest,
  }: ReduxActions)
) (ToJs(UpdateBillingContainer))

// not subscribed to ReduxState, loginForm error state has been removed 2021-01-06
