// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { getIveRequest } from 'modules/operations/ives'
import { IveDetailsComponent } from './IveDetailsComponent'
import { NotFound } from 'components/NotFound'
import { ToJs } from 'utils/ToJs'
import { CreateVmForm } from '../../createvm/CreateVmFormContainer'

type ReduxState = {| +timezone: string, +ive: Ive |}
type ReduxActions = {| +getIveRequest: Function |}
type Props = ReduxState & ReduxActions & {| +match: Match, +vmId: string |}

const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    ive: state.getIn(['ives', 'ive'])
  }: ReduxState)

class IveDetailsContainer extends PureComponent<Props> {
  props: Props

  componentDidMount() {
    const { iveId } = this.props.match.params
    this.props.getIveRequest({ iveId })
  }

  componentDidUpdate(prevProps) {
    const { iveId } = this.props.match.params
    if (iveId !== prevProps.ive.id) {
      this.props.getIveRequest({ iveId })
      return true
    } else {
      return false
    }
  }

  render() {
    const { ive, timezone, vmId } = this.props
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/ives/:iveId"
            render={(props) => (
              <IveDetailsComponent ive={ive} timezone={timezone} vmId={vmId} />
            )}
          />
          <Route path="/ives/:iveId/vm" component={CreateVmForm} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const IveDetails = connect(
  mapStateToProps,
  ({ getIveRequest }: ReduxActions)
)(ToJs(IveDetailsContainer))
