// @flow

import React, { Component } from 'react'

import { Row, Col, Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import moment from 'moment-timezone'

import './ChangelogItemCardComponent.css'

type Props = {|
  +name: string,
  +change: ChangelogItem,
  +timezone: string,
  +showHeader: boolean,
  +type: Object
|}

type State = { expanded: boolean }

export class ChangelogItemCard extends Component<Props, State> {
  props: Props
  state: State
  isExpandable: boolean

  state = { expanded: false }

  toggleExpanded = () => {
    if (this.isExpandable) {
      this.setState({ expanded: !this.state.expanded })
    }
  }
  render() {
    const { change, timezone, showHeader } = this.props
    const RuleList = ({ rule }) => (
      <ul>
        {rule.ruleTag && <li>Rule tag: {rule.ruleTag}</li>}
        {rule.enabled && <li>Enabled: {rule.enabled}</li>}
        {rule.ruleTag && (
          <li>Match translated: {(!!rule.matchTranslated).toString()}</li>
        )}
        {rule.source && <li>Source: {rule.source}</li>}
        {rule.destination && <li>Destination: {rule.destination}</li>}
        {rule.application && <li>Application: {rule.application}</li>}
        {rule.originalAddress && (
          <li>Original address: {rule.originalAddress}</li>
        )}
        {rule.translatedAddress && (
          <li>Translated address: {rule.translatedAddress}</li>
        )}
        {rule.originalPort && <li>Original port: {rule.originalPort}</li>}
        {rule.translatedPort && <li>Translated port: {rule.translatedPort}</li>}
        {rule.protocol && <li>Protocol: {rule.protocol}</li>}
        {rule.action && <li>Action: {rule.action}</li>}
      </ul>
    )
    this.isExpandable =
      (change.addedRules && change.addedRules.length > 0) ||
      (change.addedVms && change.addedVms.length > 0) ||
      (change.deletedRules && change.deletedRules.length > 0) ||
      (change.deletedVms && change.deletedVms.length > 0) ||
      (change.addedDisks && change.addedDisks.length > 0) ||
      (change.deletedDisks && change.deletedDisks.length > 0) ||
      (change.modifiedDisks && change.modifiedDisks.length > 0) ||
      !!change.valuesChanged.rootMemory ||
      !!change.valuesChanged.rootCpuCores
    return (
      <Panel>
        <Panel>
          {showHeader && (
            <span>
              <Row>
                <Col md={1}>Ver.</Col>
                <Col md={3}>Change ID</Col>
                <Col md={5}>Initiator</Col>
                <Col md={5}>Authenticator</Col>
                <Col md={5}>Revision</Col>
                <Col md={5}>Change date</Col>
              </Row>
            </span>
          )}
          <Row>
            <Col md={1}>
              <strong>{change.version}</strong>
            </Col>
            <Col md={3}>
              <strong>{change.changeId}</strong>
            </Col>
            <Col md={5}>
              <div>
                <strong>
                  {change.revisionUser.fullName}
                </strong>
                <strong style={{ lineBreak: 'anywhere'}}>
                  ({change.revisionUser.username})
                </strong>
              </div>
            </Col>
            <Col md={5}>
              {change.authenticators.map((authenticator) => (
                <div key={authenticator.username}>
                  <strong>
                    <div>{authenticator.fullName}</div>
                  </strong>
                  <strong style={{ lineBreak: 'anywhere'}}>({authenticator.username})</strong>
                </div>
              ))}
            </Col>
            <Col md={5}>
              <strong>{change.revisionComment}</strong>
            </Col>
            <Col md={5}>
              <strong>
                {moment
                  .tz(change.changeDate, timezone)
                  .format('DD MMM YYYY HH:mm:ss zz')}
              </strong>
            </Col>
          </Row>
        </Panel>
        <Panel style={{ marginTop: '-30px' }}>
          <Row>
            <Col md={12}>
              {change.valuesChanged.rootMemory && (
                <ul>
                  <li>
                    {`Memory changed from
                        ${change.valuesChanged.rootMemory.oldValue} GB
                        to
                        ${change.valuesChanged.rootMemory.newValue} GB`}
                  </li>
                </ul>
              )}
              {change.valuesChanged.rootCpuCores && (
                <ul>
                  <li>
                    {`CPU cores changed from
                        ${change.valuesChanged.rootCpuCores.oldValue}
                        to
                        ${change.valuesChanged.rootCpuCores.newValue}`}
                  </li>
                </ul>
              )}
              {change.modifiedDisks &&
                change.modifiedDisks.map((disq, index) => (
                  <ul key={`${change.changeId}-mD-${index}`}>
                    <li>
                      {`Changed disk size of disk ${disq.name} from 
                            ${disq.oldValue} GB to 
                            ${disq.newValue} GB`}
                    </li>
                  </ul>
                ))}
              {change.addedDisks &&
                change.addedDisks.map((disk, index) => (
                  <ul key={`${change.changeId}-aD-${index}`}>
                    <li>{`Added disk ${disk.name} (${disk.size} GB)`}</li>
                  </ul>
                ))}
              {change.addedRules &&
                change.addedRules.map((rule, index) => (
                  <ul key={`${change.changeId}-aR-${index}`}>
                    <li>Added rule {rule.name}</li>
                    <RuleList rule={rule} />
                  </ul>
                ))}
              {change.addedVms &&
                change.addedVms.map((vm, index) => (
                  <ul key={`${change.changeId}-aV-${index}`}>
                    <li>Added VM {vm}</li>
                  </ul>
                ))}
              {change.deletedDisks &&
                change.deletedDisks.map((disk, index) => (
                  <ul key={`${change.changeId}-aD-${index}`}>
                    <li>{`Deleted disk ${disk.name} (${disk.size} GB)`}</li>
                  </ul>
                ))}
              {change.deletedRules &&
                change.deletedRules.map((rule, index) => (
                  <ul key={`${change.changeId}-dR-${index}`}>
                    <li>Deleted rule {rule.name}</li>
                    <RuleList rule={rule} />
                  </ul>
                ))}
              {change.deletedVms &&
                change.deletedVms.map((vm, index) => (
                  <ul key={`${change.changeId}-dV-${index}`}>
                    <li>Deleted VM {vm}</li>
                  </ul>
                ))}
            </Col>
          </Row>
        </Panel>
      </Panel>
    )
  }
}
