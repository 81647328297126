import React, { Component } from 'react'

import { Modal, Button, Whisper, Tooltip } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

type Props = {|
  +title: ?React$Element<any> | string,
  +content: ?React$Element<any> | string,
  +showHelp: boolean,
  +backdrop: string,
  +size: string,
  +clearHelpModalOpenState: Function
|}

export class HelpDialogComponent extends Component<Props> {
  props: Props

  render() {
    const {
      title,
      content,
      showHelp,
      backdrop,
      size,
      clearHelpModalOpenState
    } = this.props

    return (
      <span>
        <Modal backdrop={backdrop} show={showHelp} onHide={this.close} size={size}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{content}</Modal.Body>
          <Modal.Footer>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Return to dashboard</Tooltip>}
            >
              <Button
                style={{ marginTop: '10px'}}
                key="return-button"
                onClick={clearHelpModalOpenState}
                color="orange"
              >
                Return
              </Button>
            </Whisper>
          </Modal.Footer>
        </Modal>
      </span>
    )
  }
}
