import React, { useRef } from 'react'

import {
  Button,
  Panel,
  Form,
  FormGroup,
  FormControl,
  Container,
  Divider,
  ControlLabel,
  Schema,
  Content,
  Grid,
  Row,
  Col,
  Header,
  SelectPicker,
  Whisper,
  Tooltip
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'
import { Check } from 'react-feather'

type Props = {
  +iveId: string,
  +iveName: string,
  +iveNetworks: Array,
  +ips: Array,
  +handleValidate: Function,
  +setSubmitDialogOpenState: Function,
  +vmChosenTemplate: ?Object,
  +vmTemplateId: string,
  +vmTemplates: Array,
  +onKeyDown: Function,
  +handleChange: Function,
  +handleOnChangeTemplate: Function,
  +handleOnChangeNetwork: Function,
  +handleOnChangeIp: Function,
  +handleGetTemplateInfo: Function,
  +setIps: Function,
  +vmname: string,
  +vmdescription: string,
  +network: Object,
  +username: string,
  +password: string,
  +capsLockWarning: Boolean,
  +ip: Object
}

const { StringType } = Schema.Types
const model = Schema.Model({
  Name: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9-]*$/, 'Please use only letters, numbers and -')
    .maxLength(35, 'The maximum number of characters is 35'),
  Description: StringType()
    .isRequired('This field is required.')
    .maxLength(40, 'The maximum number of characters is 40'),
  vmTemplate: StringType().isRequired('This field is required.'),
  network: StringType().isRequired('This field is required.'),
  ip: StringType().isRequired('This field is required.'),
  username: StringType().isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_. -]*$/, 'Please use only letters, numbers and _ - '),
  password: StringType()
    .isRequired('This field is required.')
    .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Passwords: >8 characters, upper- & lowercase, number and special !@#$&*')
    .rangeLength(
      8,
      30,
      'The number of characters can only be between 8 and 30'
    ),
  repeatedPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false
      }
      return true
    }, 'The two passwords do not match.')
    .isRequired('This field is required.')
})

function InputField(props) {
  const { name, label, type, data, accepter, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        data={data}
        type={type}
        {...rest}
      />
    </FormGroup>
  )
}

export const CreateVmFormComponent = (props: Props) => {
  const {
    iveName,
    iveNetworks,
    ips,
    iveId,
    vmChosenTemplate,
    vmTemplates,
    handleChange,
    onKeyDown,
    handleOnChangeTemplate,
    handleOnChangeNetwork,
    handleOnChangeIp,
    vmname,
    vmdescription,
    network,
    ip,
    username,
    password,
    capsLockWarning,
    setSubmitDialogOpenState
  } = props
  var createVmForm = useRef(null)

  return (
    <Container
      style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0', maxHeight: '90vh' }}
    >
      <Header
        style={{
          height: '60px',
          background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
        }}
      >
        <h3 style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}>
          Deploy Virtual Machine
        </h3>
      </Header>
      <Content style={{ overflow: "auto" }}>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Panel>
                <Form
                  model={model}
                  ref={(ref) => (createVmForm = ref)}
                  fluid
                  checkTrigger="none"
                >
                  <Divider style={{ marginBottom: '12px', marginTop: '12px' }}>Virtual Machine settings</Divider>
                  <InputField
                    name="vmTemplate"
                    label="Select base VM Template"
                    style={{ width: 'inherit' }}
                    id="ive-vmTemplate"
                    labelKey="name"
                    valueKey="id"
                    data={vmTemplates}
                    accepter={SelectPicker}
                    searchable={false}
                    onSelect={
                      handleOnChangeTemplate
                    }
                    onClean={handleOnChangeTemplate}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                  />
                  <InputField
                    name="network"
                    label="Select a network"
                    style={{ width: 'inherit' }}
                    id="vm-network"
                    labelKey="name"
                    valueKey="id"
                    data={iveNetworks}
                    accepter={SelectPicker}
                    searchable={false}
                    onSelect={handleOnChangeNetwork}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                  />
                  <InputField
                    name="ip"
                    label="Select an IP address"
                    style={{ width: 'inherit' }}
                    id="vm-ip"
                    labelKey="ip"
                    valueKey="ip"
                    data={ips}
                    accepter={SelectPicker}
                    searchable={false}
                    onSelect={handleOnChangeIp}
                    onClean={handleOnChangeIp}
                    renderValue={(id, item) => {
                      return <>{item.ip}</>
                    }}
                  />
                  <Divider style={{ marginBottom: '12px' }}>Virtual Machine Personalization</Divider>
                  <InputField
                    autoFocus
                    name="Name"
                    label="Virtual Machine Name"
                    type="name"
                    onChange={(vmname, event) =>
                      handleChange({ vmname, event })
                    }
                  />
                  <InputField
                    name="Description"
                    label="Virtual Machine Description"
                    type="description"
                    onChange={(vmdescription, event) =>
                      handleChange({ vmdescription, event })
                    }
                  />
                  <Whisper
                    placement="top"
                    trigger="focus"
                    speaker={<Tooltip>This will be the username used to login to your chosen operating system.</Tooltip>}
                  >
                    <InputField
                      name="username"
                      label="User account Name"
                      type="username"
                      onChange={(username, event) =>
                        handleChange({ username, event })
                      }
                    />
                  </Whisper>
                  <Whisper
                    placement="top"
                    trigger="focus"
                    speaker={<Tooltip>This will be the password used to login to your chosen operating system. Please dont forget this.</Tooltip>}
                  >
                    <InputField
                      name="password"
                      label="User account Password"
                      type="password"
                      onChange={(password, event) =>
                        handleChange({ password, event })
                      }
                      onKeyDown={onKeyDown}
                    />
                  </Whisper>
                  <InputField
                    name="repeatedPassword"
                    label="User account Repeated Password"
                    type="password"
                    onChange={(repeatedPassword, event) =>
                      handleChange({ repeatedPassword, event })
                    }
                    onKeyDown={onKeyDown}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '25px',
                      marginBottom: '15px'
                    }}
                  >
                    {capsLockWarning ? (
                      <span style={{ margingRight: '100px', color: '#ff0000' }}>
                        Caps Lock is On!
                      </span>
                    ) : (
                      <div></div>
                    )}
                    <Button
                      appearance="primary"
                      label="Deploy"
                      type="submit"
                      id="vm-deploy"
                      onClick={(e) => {
                        if (createVmForm.check()) {
                          e.preventDefault()
                          setSubmitDialogOpenState({
                            dialogId: 'DEPLOY_VM',
                            payload: {
                              vmTemplateId: vmChosenTemplate.id,
                              name: vmname,
                              description: vmdescription,
                              networkId: network.id,
                              ipIndex: ip.index,
                              username,
                              password
                            },
                            meta: { iveId, name: iveName }
                          })
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Panel>
            </Col>
            <Col md={12}>
              <Panel header={<h4>Summary</h4>}>
                {vmChosenTemplate ? (
                  <>
                    <h5>Operating System</h5>
                    <ul key="createvm-os">
                      <li key="vmtemplate-name">
                        <strong>{vmChosenTemplate.name}</strong>
                      </li>
                      <div>{vmChosenTemplate.description}</div>
                      <br></br>
                      <div>
                        This Virtual Machine is deployed with minimum specs. The
                        Virtual Machine compute can be reconfigured after
                        deployment.
                        <br></br>
                        This VM will deploy with asset-tier: {vmChosenTemplate.assetTier.name} which means it has {' '}
                        {vmChosenTemplate.assetTier.cpuCores} CPU cores and {vmChosenTemplate.assetTier.memory} GB RAM.
                      </div>
                    </ul>
                    <br></br>
                  </>
                ) : (
                  ''
                )}
                {network && ip ? (
                  <>
                    <h5>Network</h5>
                    <ul key="createvm-network">
                      <li key="vmcreate-network">
                        This Virtual Machine will be deployed @{' '}
                        <strong>{ip.ip}</strong> in network{' '}
                        <strong>{network.name}</strong>.
                      </li>
                    </ul>
                  </>
                ) : (
                  ''
                )}
                <br></br>
                {vmname ? (
                  <>
                    <h5>Personalization</h5>
                    <ul key="createvm-personalization">
                      <li key="createvm-name">
                        <strong>{vmname}</strong>
                      </li>
                      {vmdescription ? <div>{vmdescription}</div> : ''}
                      <br></br>
                      {username ? (
                        <li key="createvm-username">
                          A user account will be created with username{' '}
                          <strong>{username}</strong> and the provided password.
                          Please store the provided credentials safely. They are used to login to the operating system. Please note that GxP-Cloud does not have access to these credentials. 
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </>
                ) : (
                  ''
                )}
                {vmChosenTemplate &&
                vmname &&
                username &&
                password &&
                network &&
                ip &&
                vmdescription ? (
                  <>
                    <div
                      style={{
                        marginLeft: '25px',
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <h3>Ready to deploy.</h3>
                      <Check color="green" size={50} />
                    </div>
                </>
                ) : (
                  ''
                )}
              </Panel>
            </Col>
          </Row>
        </Grid>
      </Content>
    </Container>
  )
}
