import React, { Component } from 'react'
import { Panel, Content, Container, Header } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { AccountInfo } from './info'

export class AccountComponent extends Component<Props> {

  render() {
    return (
      <Container
        style={{ backgroundColor: '#FFFFFF', boxShadow: '3px 0 2px 0 #e0e0e0' }}
      >
        <Header
          style={{
            height: '60px',
            // background: 'linear-gradient(50deg, #FF8A00 30%, #373F51 30%)'
            background: 'linear-gradient(50deg, rgba(255,138,0,1) 1%, rgba(55,63,81,1) 65%, rgba(255,255,255,1) 100%)'
          }}
        >
          <h3
            style={{ marginLeft: '15px', marginTop: '10px', color: '#FFFFFF' }}
          >
            Account Information
          </h3>
        </Header>
        <Content>
          <Panel style={{ backgroundColor: '#ffffff', maxHeight: '90vh', overflow: 'auto' }}>
            <AccountInfo/>
          </Panel>
        </Content>
      </Container>
    )
  }
}
