// @flow

import { fromJS } from 'immutable'
import moducks from './../../moducks-bootstrap'

const defaultState = fromJS({
  errors: {}
})

const moduleName = 'setElectronicSignatureDialog'

export const {
  setElectronicSignatureDialog,
  sagas,
  setElectronicSignatureDialogErrorState,
  clearElectronicSignatureDialogErrorState
} = moducks.createModule(
  moduleName,
  {
    SET_ELECTRONIC_SIGNATURE_DIALOG_ERROR_STATE: (state, action) =>
      state.setIn(['errors'], fromJS(action.payload)),
    CLEAR_ELECTRONIC_SIGNATURE_DIALOG_ERROR_STATE: () => defaultState
  },
  defaultState
)
