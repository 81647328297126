import React from 'react'
import moment from 'moment-timezone'
import {
  List,
  Icon,
  IconButton,
  FlexboxGrid,
  Panel,
  Grid,
  Row,
  Modal,
  Button,
  Col,
  Nav
} from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { TaskInfoDialog } from './TaskInfoDialogContainer'
import { TaskStatusChip } from './TaskStatusChipComponent'
import { NO_TASKS_LAST_WEEK, NO_TASKS } from './consts'
// For Dashboard implementation
import { Approvals } from '../approvals/components'
import { DashboardLogo } from 'components/Logo'
import { AuditLog } from 'components/AuditLog'
import { HelpCharacter } from 'components/HelpCharacter/HelpCharacterContainer'

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '55px'
}

const slimText = {
  fontSize: '0.666em',
  color: '#97969B',
  fontWeight: 'lighter',
  paddingBottom: 5
}

const titleStyle = {
  paddingBottom: 5,
  whiteSpace: 'nowrap',
  fontWeight: 500
}

const TASK_MAPPING = {
  recent: {
    index: 0,
    status: [
      'REVOKED',
      'PENDING',
      'PENDING APPROVAL',
      'RETRY',
      'STARTED',
      'SUCCESS',
      'FAILURE',
      'DECLINED',
      'IQ FAILED'
    ],
    icon: 'task',
    label: 'Recent Tasks'
  },
  all: {
    index: 2,
    status: [
      'REVOKED',
      'PENDING',
      'PENDING APPROVAL',
      'RETRY',
      'STARTED',
      'SUCCESS',
      'FAILURE',
      'DECLINED',
      'IQ FAILED'
    ],
    icon: 'task',
    label: 'All Tasks'
  }
}

type Props = {|
  +tableData: Array<Object>,
  +handleDeleteTask: Function,
  +toggleOpenState: Function,
  +taskDeleteOpen: boolean,
  +timezone: string,
  +selectedFilter: string,
  +history: History
|}

export const TasksListComponent = (props: Props) => {
  const {
    tableData,
    timezone,
    handleDeleteTask,
    toggleOpenState,
    selectedFilter,
    history
  } = props
  const filter = TASK_MAPPING[selectedFilter]
  const data = tableData.filter(
    (item) => filter && filter.status.indexOf(item.status) >= 0
  )
  //const [detailValues] = Object.values(detail || {})

  const headerNavigationItems = Object.entries(TASK_MAPPING).map(
    (item: any, index: number) => {
      const [filterType, filterProps] = item
      return (
        <Nav.Item
          key={`bottom-nav-${index}`}
          icon={<Icon icon={filterProps.icon} />}
          onClick={() => history.push(`/dashboard/${filterType}`)}
        >
          {filterProps.label}
        </Nav.Item>
      )
    }
  )

  const noTasksMessage =
    selectedFilter === 'recent' ? NO_TASKS_LAST_WEEK : NO_TASKS
  const noTaskCards = (
    <Panel elevation={3} style={{ padding: '32px' }}>
      <span style={{ fontSize: '1.5em' }}>{noTasksMessage}</span>
    </Panel>
  )

  return (
    <div>
      <Grid fluid>
        <Row>
          <Col md={24}>
            <DashboardLogo />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col md={16}>
            <Panel
              header={<h5>Tasks Log</h5>}
              bordered
              style={{ backgroundColor: '#ffffff', height: '63vh' }}
            >
              <div>
                <Nav>{headerNavigationItems}</Nav>
                {data.length === 0 ? (
                  noTaskCards
                ) : (
                  <Panel>
                    <List hover style={{ height: '49vh', overflowY: 'scroll' }}>
                      {data
                        .map((task, index) => (
                          <List.Item key={task.id} index={index}>
                            <FlexboxGrid>
                              <FlexboxGrid.Item
                                colspan={5}
                                style={{ ...styleCenter, marginTop: '-8px' }}
                              >
                                <TaskStatusChip status={task.status} />
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item
                                colspan={9}
                                style={{
                                  ...styleCenter,
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  overflow: 'hidden'
                                }}
                              >
                                <div style={titleStyle}>{task['name']}</div>
                                <div style={slimText}>
                                  <div>
                                    <Icon icon="user-circle-o" />
                                    {' ' + task['initiatorFullName']}
                                  </div>
                                  <div>
                                    {task['executionDatetime'] &&
                                      moment
                                        .tz(task['executionDatetime'], timezone)
                                        .format('DD MMM YYYY HH:mm:ss zz')}
                                  </div>
                                </div>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={5}>
                                {task.vm ? (
                                  <div>
                                    [VM] <strong>{task.vm.name}</strong>
                                  </div>
                                ) : task.ive ? (
                                  <div>
                                    [IVE] <strong>{task.ive.name}</strong>
                                  </div>
                                ) : (
                                  'Asset deleted'
                                )}
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={5} style={styleCenter}>
                                <span id={`task-info-${task.id}`}>
                                  <span style={slimText}>
                                    <TaskInfoDialog task={task} />
                                  </span>
                                  <span style={slimText}>
                                    {!task.immutable &&
                                      (task.status === 'PENDING APPROVAL' ||
                                        task.status === 'PENDING') && (
                                        <span>
                                          <span style={{ padding: 5 }}>|</span>
                                          <Button
                                            appearance="link"
                                            style={{ cursor: 'pointer' }}
                                            id="delete-task"
                                            onClick={() => {
                                              toggleOpenState()
                                            }}
                                          >
                                            Delete
                                          </Button>
                                          <Modal
                                            backdrop="static"
                                            show={props.taskDeleteOpen}
                                            size="sm"
                                          >
                                            <Modal.Header closeButton={false}>
                                              <Modal.Title>
                                                Are you sure to delete this task?
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Panel
                                              style={{
                                                marginTop: '30px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginBottom: '25px'
                                              }}
                                            >
                                              <IconButton
                                                icon={<Icon icon="trash-o" />}
                                                style={{ cursor: 'pointer' }}
                                                id="delete-task"
                                                onClick={() => {
                                                  handleDeleteTask(task.id)
                                                }}
                                              >
                                                Delete
                                              </IconButton>
                                            </Panel>
                                            <span
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                              }}
                                            >
                                              <Modal.Footer>
                                                <Button
                                                  onClick={props.toggleOpenState}
                                                  color="red"
                                                >
                                                  Cancel
                                                </Button>
                                              </Modal.Footer>
                                            </span>
                                          </Modal>
                                        </span>
                                      )}
                                  </span>
                                </span>
                              </FlexboxGrid.Item>
                            </FlexboxGrid>
                          </List.Item>
                        ))
                        .reverse()}
                    </List>
                  </Panel>
                )}
              </div>
            </Panel>
          </Col>
          <Col md={8}>
            <Approvals />
          </Col>
        </Row>
        <Row>
          <AuditLog key="audit-log"/>
        </Row>
      </Grid>

    <HelpCharacter modalId='HOME_DASHBOARD' />
  </div>
  )
}
