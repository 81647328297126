// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TaskInfoDialogComponent } from './TaskInfoDialogComponent'
import { ToJs } from 'utils/ToJs'
import { getTaskRequest } from 'modules/operations/tasks'

type ReduxState = {| +timezone: string, +detail: ?any |}
type ReduxActions = {| +getTaskRequest: Function |}
type Props = ReduxState &
  ReduxActions & {| +task: Task, +disabled: boolean, +history: Object |}
type State = {| open: boolean |}

const mapStateToProps = (state) =>
  ({
    timezone: state.getIn(['account', 'timezone']),
    detail: state.getIn(['tasks', 'item', 'detail'])
  }: ReduxState)

class TaskInfoDialogContainer extends Component<Props, State> {
  props: Props
  state: State = { open: false }

  // Check in future
  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  handleOpen = () => {
    const taskId = this.props.task.id
    this.setState({ open: true })
    this.props.getTaskRequest({ taskId })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { task, disabled, timezone, detail, history } = this.props
    return (
      <TaskInfoDialogComponent
        detail={detail}
        disabled={disabled}
        task={task}
        timezone={timezone}
        open={this.state.open}
        handleOpen={this.handleOpen}
        handleClose={this.handleClose}
        history={history}
      />
    )
  }
}

// https://github.com/facebook/flow/issues/7125
// $FlowFixMe
export const TaskInfoDialog = connect(
  mapStateToProps,
  ({ getTaskRequest }: ReduxActions)
)(ToJs(TaskInfoDialogContainer))
