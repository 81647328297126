//import { render } from 'enzyme'
//Delete if everything keeps working
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToJs } from 'utils/ToJs'

import { ModalComponent } from './ModalComponent'
import {
  setModalOpenState,
  clearModalOpenState
} from 'modules/components/gxpModal'

import { SslVpn } from 'routes/ives/detail/ssl-vpn'
import { Firewall } from 'routes/ives/detail/firewall'
import { Nat } from 'routes/ives/detail/nat'
import { IveIpsecSite } from 'routes/ives/detail/ipsec'
import { IveChangelog } from 'routes/ives/detail/changelog'
import { IveReports } from 'routes/ives/detail/reports'

import { VmRestorePoints } from 'routes/ives/detail/vms/detail/restore-points'
import { VmHardwareChange } from 'routes/ives/detail/vms/detail/hardware-change'
import { VmDiskConfig } from 'routes/ives/detail/vms/detail/disk-config'
import { VmChangelog } from 'routes/ives/detail/vms/detail/changelog'
import { VmReports } from 'routes/ives/detail/vms/detail/reports'

type Props = {|
  +setModalOpenState: Function,
  +clearModalOpenState: Function,
  +show: boolean,
  +backdrop: string,
  +settings: {
    +payload: {},
    +meta: { +name: string, +iveId: string, +vmId: string },
    +modalId: string
  }
|}

class ModalContainer extends Component<Props> {
  props: Props

  // It complaines ther is no default (the '' is default here) maybe there is another way.
  getModalConfig = () => {
    const { settings } = this.props
    switch (settings.modalId) {
      default:
        return {
          title: 'Error',
          content: 'Content not Found'
        }
      case 'RESTORE_POINTS':
        return {
          title: `Restore Points for ${settings.meta.name}`,
          content: <VmRestorePoints vmId={settings.meta.vmId} />,
          size: 'sm'
        }
      case 'HARDWARE_CHANGE':
        return {
          title: `Hardware Configuration for ${settings.meta.name}`,
          content: (
            <div>
              <VmHardwareChange vmId={settings.meta.vmId} />
              <VmDiskConfig vmId={settings.meta.vmId} />
            </div>
          ),
          size: 'lg'
        }
      case 'VM_CHANGELOG':
        return {
          title: `Changelog for ${settings.meta.name}`,
          content: <VmChangelog vmId={settings.meta.vmId} />,
          size: 'lg'
        }
      case 'VM_REPORTS':
        return {
          title: `VM GxP Documentations ${settings.meta.name}`,
          content: <VmReports vmId={settings.meta.vmId} />,
          size: 'lg'
        }
      case 'SSL_VPN':
        return {
          title: `SSL-VPN accounts for ${settings.meta.name}`,
          content: <SslVpn iveId={settings.meta.iveId} />,
          size: 'lg'
        }
      case 'FIREWALL':
        return {
          title: `Firewall rules for ${settings.meta.name}`,
          content: <Firewall iveId={settings.meta.iveId} />,
          size: 'lg'
        }
      case 'NAT_RULES':
        return {
          title: `NAT rules for ${settings.meta.name}`,
          content: <Nat iveId={settings.meta.iveId} />,
          size: 'lg'
        }
      case 'IPSEC':
        return {
          title: `IPsec sites for ${settings.meta.name}`,
          content: <IveIpsecSite iveId={settings.meta.iveId} />,
          size: 'lg'
        }
      case 'IVE_CHANGELOG':
        return {
          title: `Changelog for ${settings.meta.name}`,
          content: <IveChangelog iveId={settings.meta.iveId} />,
          size: 'lg'
        }
      case 'IVE_REPORTS':
        return {
          title: `IVE GxP documents for ${settings.meta.name}`,
          content: <IveReports iveId={settings.meta.iveId} />,
          size: 'lg'
        }
    }
  }

  render() {
    const { show, backdrop, clearModalOpenState } = this.props
    const { title, content, size } = this.getModalConfig()

    return (
      <ModalComponent
        title={title}
        content={content}
        show={show}
        backdrop={backdrop}
        size={size}
        clearModalOpenState={clearModalOpenState}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  show: state.getIn(['gxpModal', 'show']),
  backdrop: state.getIn(['gxpModal', 'backdrop']),
  settings: state.getIn(['gxpModal', 'settings'])
})

export const Modal = connect(mapStateToProps, {
  setModalOpenState,
  clearModalOpenState
})(ToJs(ModalContainer))
