// @flow

import React from 'react'
import { Panel } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

import { ApprovalsTable } from '.'

type Props = {|
  +approvals: Approval[],
  +approvalId: string,
  +timezone: string
|}

const ApprovalsComponent = (props: Props) => {
  const { approvalId, timezone } = props
  const approvals = approvalId
    ? props.approvals.filter((approval) => approval.id === approvalId)
    : props.approvals

  return (
    <Panel
      bordered
      style={{ height: '63vh', overflowY: 'scroll', backgroundColor: '#ffffff' }}
      header={<h5>Approvals</h5>}
    >
      <span>
        {approvals && approvals.length ? (
          <ApprovalsTable
            approvalId={approvalId}
            approvals={approvals}
            timezone={timezone}
          />
        ) : (
          <span style={{ fontSize: '1.5em' }}>
            {approvalId
              ? 'There is no active approval with the given ID.'
              : 'There are no approvals.'}
          </span>
        )}
      </span>
    </Panel>
  )
}
export { ApprovalsComponent }
