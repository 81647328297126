import React, { useRef } from 'react';
import {
  Button,
  ControlLabel,
  Schema,
  Form,
  FormGroup,
  FormControl,
  Modal,
  Divider,
  SelectPicker,
  Panel,
  PanelGroup,
  Row,
  Col
} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

const { StringType } = Schema.Types
const model = Schema.Model({
  blueprintName: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_.-]*$/, 'Please use only letters, numbers and _ - ')
    .maxLength(35, 'The maximum number of characters is 35'),
  description: StringType()
    .isRequired('This field is required.')
    .pattern(/^[a-zA-Z0-9_. -]*$/, 'Please use only letters, numbers and _ - ')
    .maxLength(40, 'The maximum number of characters is 40'),
  datacenter: StringType().isRequired('This field is required.')
})

function InputField(props) {
  const { name, label, type, accepter, data, ...rest } = props
  return (
    <FormGroup>
      <ControlLabel>{label} </ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        type={type}
        data={data}
        {...rest}
      />
    </FormGroup>
  )
}

// Construct netmask for IPnetwork
function subnet(blue) {
  if (blue.usableIps >= 254) {
    return `${blue.staticIpNetwork}/24`
  } else if (blue.usableIps >= 125) {
    return `${blue.staticIpNetwork}/25`
  } else if (blue.usableIps >= 62) {
    return `${blue.staticIpNetwork}/26`
  } else if (blue.usableIps >= 30) {
    return `${blue.staticIpNetwork}/27`
  } else if (blue.usableIps >= 13) {
    return `${blue.staticIpNetwork}/28`
  } else if (blue.usableIps >= 5) {
    return `${blue.staticIpNetwork}/29`
  }
}  

type Props = {|
    +children: React$Element<any>,
    +blueprint: Object,
    +datacenter: Object,
    +datacenters: Array,
    +blueprintItem: Object,
    +blueprintName: string,
    +description: string,
    +handleChange: Function,
    +handleOnChangeLocation: Function,
    +toggleOpenState: Function,
    +setSubmitDialogOpenState: Function,
    +isOpen: boolean
  |}

export const BlueprintDialogComponent = (props: Props) => {
    var BlueprintForm = useRef(null)

    return (
        <div style={{ display: 'inline' }}>
          {React.cloneElement(props.children, {
            onClick: props.toggleOpenState
          })}
          <Modal backdrop="static" show={props.isOpen} size="md">
            <Modal.Header closeButton={false}>
              <Modal.Title style={{ marginBottom: '5px' }}>
                GxP-Blueprint {props.blueprintItem.name}
              </Modal.Title>
            </Modal.Header>
            <Panel style={{ height: '12vh'}}>
              <br></br>
              Continuing will deploy the GxP-Blueprint: <strong>{props.blueprintItem.name}</strong>. <br></br>
              <small>{props.blueprintItem.description}</small>
              
            </Panel>
            <PanelGroup accordion>
              <Panel style={{ maxHeight: '40vh', overflow: 'auto'}} header={<h5>Click to see Technical Specifications</h5>}>

                  The deployed managed Isolated Virtual Environment contains the following security rule(s): <br></br>
                  <strong>Source NAT rule(s):</strong>
                  
                    <ul>
                      <li>
                        A Source NAT rule to translate internal-external traffic to your public IP address.
                      </li>
                    </ul>

                  <strong>Destination NAT rule(s):</strong>
                  {props.blueprint.dnatRules.map((rule) =>(
                    <ul>
                      <li>
                        To VM: {Object.values(rule.translatedAddress)[1]} from port {rule.originalPortFrom} {'>'} port {rule.translatedPortFrom} protocol: {rule.protocol}
                      </li>
                    </ul>
                  ))}
                  <strong>Firewall rule(s):</strong>
                  {props.blueprint.firewallRules.map((rule) =>(
                    <ul>
                      <li>
                        {rule.name}
                      </li>
                    </ul>
                  ))}

                  <br></br>
                  The Blueprint contains the Local Area Network(s):                 
                  {props.blueprint.networks.map((network) => (
                    <ul>
                      <li><strong>{network.name}</strong> {network.staticIpNetwork} with {network.usableIps} usable IP addresses. (of which one is the gateway)</li>

                      <br></br>
                      Deployed in this Local Area Network the following Virtual Machines:
                        {network.vmTemplates.map((vmTemplate) => (
                          <ul>
                            <li><strong>{vmTemplate.name}</strong> installed {vmTemplate.operatingSystem}</li>
                            With {vmTemplate.hardware.cpuCores} vCPU and {vmTemplate.hardware.memory} GB's Memory.
                          </ul>))}
                    </ul>                  
                  ))}

                  <br></br>
                  <Row>
                    <Col md={6}>
                      History profile:
                    </Col>
                    <Col md={18}>
                      {props.blueprint.historyProfile && <strong>{Object.values(props.blueprint.historyProfile)[1]}</strong>}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      Availability profile:
                    </Col>
                    <Col md={18}>
                      {props.blueprint.availabilityProfile && <strong>{Object.values(props.blueprint.availabilityProfile)[1]}</strong>}
                    </Col>
                  </Row>             
              </Panel>
            </PanelGroup>
            <Divider>Isolated Virtual Environment settings</Divider>
            <Form
              model={model}
              ref={(ref) => (BlueprintForm = ref)}
              fluid
              checkTrigger="none"
            >

                <InputField
                    autoFocus
                    id="name"
                    name="blueprintName"
                    style={{ width: 'inherit' }}
                    label="Isolated Virtual Environment Name"
                    onChange={(blueprintName, event) =>
                        props.handleChange({ blueprintName, event })
                      }
                    value={props.blueprintName}
                />

                <InputField
                    name="description"
                    label="Isolated Virtual Environment Description"
                    style={{ width: 'inherit' }}
                    id="local-network"
                    onChange={(description, event) =>
                      props.handleChange({ description, event })
                    }
                  />  

                <InputField
                    name="datacenter"
                    label="Select a location for your data"
                    style={{ width: 'inherit' }}
                    id="ive-location"
                    labelKey="name"
                    valueKey="id"
                    data={props.datacenters}
                    onSelect={props.handleOnChangeLocation}
                    onClean={props.handleOnChangeLocation}
                    accepter={SelectPicker}
                    searchable={false}
                    renderValue={(id, item) => {
                      return <>{item.name}</>
                    }}
                />        

            <Modal.Footer>
                <div>
                  <Button onClick={props.toggleOpenState} appearance='ghost' color="red">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    appearance='ghost'
                    color="blue"
                    id="submit"
                    onClick={(e) => {
                      if (BlueprintForm.check()) {
                        e.preventDefault()
                        props.setSubmitDialogOpenState({
                            dialogId: 'DEPLOY_IVE',
                            payload: {
                              name: props.blueprintName,
                              description: props.description,
                              subnet: subnet(props.blueprint.networks[0]),
                              blueprintId: props.blueprint.id,
                              datacenterId: props.datacenter.id,
                              availabilityProfileId: Object.values(props.blueprint.availabilityProfile)[0],
                              historyProfileId: Object.values(props.blueprint.historyProfile)[0]
                            },
                            meta: { name: props.blueprintName }
                          })
                      }
                    }}
                  >
                    Deploy
                  </Button>
                </div>
            </Modal.Footer>

        </Form>
      </Modal>
    </div>
  )
}